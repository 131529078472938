import axios from 'axios';

import {
    GET_INVOICES,
    CREATE_INVOICES,
    INVOICE_LOADING,
    SEND_BILLING_EMAIL
} from './types';

import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';


export const getInvoices = () => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios.get('/api/payments', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_INVOICES,
                payload: res.data
            })
        })
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const sendBillingEmail = (email, _id) => (dispatch, getState) => {
    var emailObject = {
        template: 'ResetPassword',
        email,
        title: 'Your Free Trial is Ending',
        description:
            'We hope you enjoyed your experience so far. Set up your billing to continue using our apps',
        descriptiontitle: 'Join Us',
        buttonLink: `https://qworky.herokuapp.com/registerbilling/${_id}`,
        buttontitle: 'Billing Registration',
        key: 'nnnlwe19219n1b2--u0qjasca',
    };
    var object = JSON.stringify(emailObject);
    axios.post(
        'https://script.google.com/macros/s/AKfycbwaVATD7Fvhqs3xKzhr-dvgbSTXY52h_rbFxN3PRfRuC3U58VD8/exec',
        object
    )
        .then(() => dispatch({
            type: SEND_BILLING_EMAIL,
        }))
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
}


export const createInvoices = paymentDetails => (dispatch, getState) => {
    axios.get(`api/companies/invoice/${paymentDetails.company_id}`, tokenConfig(getState))
        .then(res => {
            console.log(res.data)
            const date = new Date()
            const currentDate = date.toISOString().substr(0, 10)
            const requestBody = {
                invoicer: {
                    business_name: 'Jtk Technologies Sdn Bhd',
                    name: {
                        given_name: "Avinash",
                        surname: "Naidu"
                    },
                    address: {
                        address_line_1: "21, Lorong Datuk Sulaiman 7",
                        address_line_2: "Taman Tun Dr Ismail",
                        admin_area_1: "Kuala Lumpur",
                        postal_code: "60000",
                        country_code: "MY"
                    },
                    email_address: "avinash@qworky.tech",
                    phones: [
                        {
                            country_code: "60",
                            national_number: "123820075",
                            phone_type: "MOBILE"
                        }
                    ],
                    website: "https://qworky.tech",
                    logo_url: "https://pics.paypal.com/00/s/MTY2OFgzMzM0WEpQRw/p/NjBjZjAxYTktOGVkNi00NDMxLTg1MzItZDU5ZTkyZGIyNGVi/image_109.JPG",
                },
                detail: {
                    invoice_date: currentDate,
                    currency_code: "MYR",
                    terms_and_conditions: "Monthly Postpaid Billing for App usage. If payment for monthly subscription fee is not paid for 2 months in a row, system access will be limited",
                    payment_term: {
                        term_type: "DUE_ON_RECEIPT",
                    },
                    memo: paymentDetails.company_id
                },
                primary_recipients: [
                    {
                        billing_info: {
                            business_name: paymentDetails.companyname,
                            email_address: paymentDetails.email,
                        },
                    }
                ],
                items: [
                    {
                        name: "Monthly User Fee",
                        description: `User fee for ${paymentDetails.startdate} untill ${paymentDetails.enddate}`,
                        quantity: paymentDetails.activeUsers,
                        unit_amount: {
                            currency_code: "MYR",
                            value: paymentDetails.userFee
                        },
                        unit_of_measure: "QUANTITY"
                    }
                ],
            }
            dispatch(setItemsLoading());
            axios.post('/api/payments/create', requestBody, tokenConfig(getState))
                .then(res => {
                    const obj = {
                        invoiceNo: res.data.href.substr(res.data.href.length - 24),
                        date: new Date()
                    }
                    dispatch({
                        type: CREATE_INVOICES,
                        payload: res.data
                    })
                    axios.post(`api/companies/invoice/${paymentDetails.company_id}`, obj, tokenConfig(getState))
                        .then(res => console.log(res.data))
                        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
                })
                .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
        })
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const setItemsLoading = () => {
    return {
        type: INVOICE_LOADING,
    };
};