import React, { Component, Fragment } from 'react';
import { Container, Table, Input, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import { toggleTaskIndex } from '../../actions/taskActions';
import AddReportModal from './AddReportModal';
import Tooltip from './Tooltip';
import {
	getReports,
	deleteReport,
	toggleReportIndex,
	filterReports,
} from '../../actions/reportActions';

import TaskDetails from '../taskComponents/TaskDetails';
import ProjectDetails from '../projectComponents/ProjectDetails';
import PropTypes from 'prop-types';
import { getEmployees } from '../../actions/employeeActions';
import { getProjects, toggleProjectIndex } from '../../actions/projectActions';
import { getTasks } from '../../actions/taskActions';
import { getCompany } from '../../actions/companyActions';
import { getDetails } from '../../actions/detailActions';

class TaskList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			currentDate: new Date(''),
			SelectedTabStyle: {
				width: '50%',
				backgroundColor: '#ffd4d1',
				borderBottom: '1px solid #ff4e42',
				color: '#ff4e42',
				padding: '0.5rem',
				opacity: 1,
			},
			TabStyle: {
				width: '50%',
				backgroundColor: '#ffd4d1',
				borderBottom: '0px solid #ff4e42',
				color: '#ff4e42',
				padding: '0.5rem',
				opacity: 0.3,
			},
			Individual: localStorage.getItem('IndividualRr')
				? JSON.parse(window.localStorage.getItem('IndividualRr'))
				: false, // fiter related
			Department: localStorage.getItem('DepartmentRr')
				? JSON.parse(localStorage.getItem('DepartmentRr'))
				: true, // fiter related
			Month: localStorage.getItem('MonthRr')
				? localStorage.getItem('MonthRr')
				: new Date().getMonth() + 1,
			Year: localStorage.getItem('YearRr')
				? localStorage.getItem('YearRr')
				: new Date().getFullYear(),
			Overdue: localStorage.getItem('OverdueRr')
				? JSON.parse(window.localStorage.getItem('OverdueRr'))
				: false, // fiter related

			SortDefault: localStorage.getItem('SortDefaultRr')
				? localStorage.getItem('SortDefaultRr')
				: true,
			DepartmentSelector: localStorage.getItem('DepartmentSelectorRr')
				? localStorage.getItem('DepartmentSelectorRr')
				: '',
			EmployeeSelector: localStorage.getItem('EmployeeSelectorRr')
				? localStorage.getItem('EmployeeSelectorRr')
				: '', // fiter related
			departments: localStorage.getItem('departmentsRr')
				? JSON.parse(localStorage.getItem('departmentsRr'))
				: [], // fiter related
			employees: [],
			project: localStorage.getItem('projectRr') ? localStorage.getItem('projectRr') : '', // fiter related
			dateRange: [],
			resetFilterRr: false, // reset fiter related
			filtercolors: [
				'#e1bee7',
				'#64b5f6',
				'#c5e1a5',
				'#f48fb1',
				'#f06292',
				'#ffee58',
				'#ffab91',
				'#80cbc4',
			],

			tooltip: [],
		};
		this.getDaysArray = this.getDaysArray.bind(this);
		this.checkDate = this.checkDate.bind(this);
		this.tooltipReveal = this.tooltipReveal.bind(this);
	}

	componentDidMount() {
		this.props.getReports();
		this.props.getEmployees();
		this.props.getProjects();
		this.props.getTasks();
		this.props.getCompany();
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	updateDate = (date) => {
		this.setState({
			currentDate: new Date(date).toISOString().substr(0, 10),
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.company.company !== this.props.company.company) {
			this.setState({
				departments:
					this.state.departments.length > 0
						? this.state.departments
						: this.props.company.company.departments
							.filter(({ active }) => active)
							.map((e, index) => {
								return {
									name: e.name,
									id: e.id,
									filterState: true,
									color: this.state.filtercolors[index],
									index: index,
								};
							}),
				employees: this.props.employee.employees.filter(
					(employee) => employee.permissionLevel !== 'Disabled'
				),
				dateRange: this.getDaysArray(
					new Date(this.state.Month + '/1/' + this.state.Year)
				).map((date) => date.toISOString().substr(0, 10)),
			});
		}

		if (
			prevProps.report.reports !== this.props.report.reports ||
			prevState.departments !== this.state.departments ||
			prevState.Year !== this.state.Year ||
			prevState.Month !== this.state.Month ||
			prevState.Individual !== this.state.Individual ||
			prevState.EmployeeSelector !== this.state.EmployeeSelector ||
			prevState.Overdue !== this.state.Overdue ||
			prevState.project !== this.state.project ||
			prevState.SortDefault !== this.state.SortDefault
		) {
			localStorage.setItem('EmployeeSelectorRr', this.state.EmployeeSelector);
			localStorage.setItem('departmentsRr', JSON.stringify(this.state.departments));
			localStorage.setItem('DepartmentSelectorRr', this.state.DepartmentSelector);
			localStorage.setItem('projectRr', this.state.project);
			localStorage.setItem('OverdueRr', this.state.Overdue);
			localStorage.setItem('DepartmentRr', this.state.Department);
			localStorage.setItem('IndividualRr', this.state.Individual);
			localStorage.setItem('SortDefaultRr', this.state.SortDefault);
			localStorage.setItem('MonthRr', this.state.Month);
			localStorage.setItem('YearRr', this.state.Year);

			var filters = {}; // create object for filter criteria for redux
			filters.department = this.state.departments.filter((e) => e.filterState).map((e) => e.id);
			filters.reportdate = this.getDaysArray(
				new Date(this.state.Month + '/1/' + this.state.Year)
			).map((date) => date.toISOString().substr(0, 10));

			if (this.state.EmployeeSelector && this.state.Individual) {
				filters.user_id = this.state.EmployeeSelector;
			}
			if (this.state.project) {
				filters.project_id = this.state.project;
			}
			if (this.state.Overdue) {
				filters.overdue = this.state.Overdue;
			}

			filters.SortDefault = this.state.SortDefault;
			this.props.filterReports(filters, true); // filter function to redux
			this.setState({
				dateRange: this.getDaysArray(
					new Date(this.state.Month + '/1/' + this.state.Year)
				).map((date) => date.toISOString().substr(0, 10)),
			});
		}
		if (prevState.DepartmentSelector !== this.state.DepartmentSelector) {
			this.setState({
				employees: this.props.employee.employees.filter(
					({ department }) => department === this.state.DepartmentSelector
				),
			});
		}

		if (prevState.resetFilterRr !== this.state.resetFilterRr) {
			this.setState({
				Overdue: false,
				EmployeeSelector: '',
				Department: true,
				Individual: false,
				project: '',
				DepartmentSelector: '',
				SortDefault: true,
				Month: new Date().getMonth() + 1,
				Year: new Date().getFullYear(),
			});
			this.setState({
				departments: this.props.company.company.departments
					.filter(({ active }) => active)
					.map((e, index) => {
						return {
							name: e.name,
							id: e.id,
							filterState: true,
							color: this.state.filtercolors[index],
							index: index,
						};
					}),
			});
			// Removing the user filter preferences from the local storage.
			localStorage.setItem('DepartmentRr', '');
			localStorage.setItem('IndividualRr', '');
			localStorage.setItem('OverdueRl', '');
			localStorage.setItem('EmployeeSelectorRl', '');
			localStorage.setItem('departmentsRl', '');
			localStorage.setItem('projectRl', '');
			localStorage.setItem('DepartmentSelectorRl', '');
			localStorage.setItem('SortDefaultRr', '');
			localStorage.setItem('MonthRr', this.state.Month);
			localStorage.setItem('YearRr', this.state.Year);
		}
	}

	getDaysArray = (date) => {
		// uses javascript date
		var day = date.getDay(); //getting the day to find the monday of the week
		var startDate = new Date(date.setDate(date.getDate() - day + 1)); //getting the day to find the monday of the week
		var arr = [];
		for (var i = 0; i < 42; i++) {
			arr.push(new Date(startDate.setDate(startDate.getDate() + 1)));
		}
		return arr;
	};

	checkDate = (item) => {
		if (item !== '') {
			var year = item.getFullYear();
			var date = item.getDate();
			var monthIndex = item.getMonth();
			var monthsNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
			return date + '/' + monthsNum[monthIndex] + '/' + year;
		} else {
			return '';
		}
	};

	changeTab = () => {
		this.setState({
			Individual: !this.state.Individual,
			Department: !this.state.Department,
		});
	};

	overdueFilter = () => {
		this.setState({
			Overdue: !this.state.Overdue,
		});
	};

	departmentFilter = (i) => {
		this.setState({
			departments: this.state.departments.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};

	tooltipReveal = (_id, state) => {
		this.setState({
			[_id]: state,
		});
	};

	defaultSort = (state) => {
		this.setState({
			SortDefault: state,
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	hideWeek = (index) => {
		this.setState({
			['week' + index]: !this.state['week' + index],
		});
	};

	render() {
		const { employees } = this.props.employee;
		const { tasks } = this.props.task;
		const DepartmentFilters = (
			<Col>
				<Table
					style={{
						maxWidth: '10rem',
						marginBottom: '0px',
					}}>
					<tbody>
						<tr>
							<td
								style={{
									backgroundColor: '#808080',
									padding: '8px',
									opacity: this.state.SortDefault ? '1' : '0.5',
									cursor: 'pointer',
								}}
								onClick={() => this.defaultSort(true)}>
								Default
							</td>
							<td
								style={{
									backgroundColor: '#808080',
									padding: '8px',
									opacity: this.state.SortDefault ? '0.5' : '1',
									cursor: 'pointer',
								}}
								onClick={() => this.defaultSort(false)}>
								Department
							</td>
						</tr>
					</tbody>
				</Table>
			</Col>
		);
		const IndividualFilters = (
			<Fragment>
				<Col>
					<Input
						type='select'
						name='DepartmentSelector'
						onChange={this.onChange}
						value={this.state.DepartmentSelector}>
						<option value='' disabled>
							Select Department
						</option>
						{this.state.departments.map(({ name, id }) => (
							<option key={id} value={id}>
								{name}
							</option>
						))}
					</Input>
				</Col>
				<Col>
					<Input
						type='select'
						name='EmployeeSelector'
						onChange={this.onChange}
						value={this.state.EmployeeSelector}>
						<option value='' disabled>
							Select Employee
						</option>
						{this.state.employees.map(({ _id, name, user_id }) => (
							<option key={_id} value={user_id}>
								{name}
							</option>
						))}
					</Input>
				</Col>
			</Fragment>
		);

		return (
			<Container>
				<ProjectDetails />
				<TaskDetails />
				<AddReportModal
					modal={this.state.modal}
					toggle={this.toggle}
					currentDate={this.state.currentDate}
				/>
				<Table
					style={{
						width: '100%',
						marginBottom: '0.5rem',
						cursor: 'pointer',
					}}>
					<tbody>
						<tr onClick={this.changeTab}>
							<td style={this.state.Department ? this.state.SelectedTabStyle : this.state.TabStyle}>
								<center>Department</center>
							</td>
							<td style={this.state.Individual ? this.state.SelectedTabStyle : this.state.TabStyle}>
								<center>Individual</center>
							</td>
						</tr>
					</tbody>
				</Table>
				<Row>
					<Col xs='2'>
						<Input type='select' name='Month' onChange={this.onChange} value={this.state.Month}>
							<option value={1}>January</option>
							<option value={2}>February</option>
							<option value={3}>March</option>
							<option value={4}>April</option>
							<option value={5}>May</option>
							<option value={6}>June</option>
							<option value={7}>July</option>
							<option value={8}>August</option>
							<option value={9}>September</option>
							<option value={10}>October</option>
							<option value={11}>November</option>
							<option value={12}>December</option>
						</Input>
					</Col>
					<div
						style={{
							margin: '0 0 12px -0.5rem',
							padding: '.3rem .3rem 0 .3rem',
							border: '.5px solid #D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ Month: new Date().getMonth() + 1 })}>
						X
					</div>
					<Col xs='2'>
						<Input type='select' name='Year' onChange={this.onChange} value={this.state.Year}>
							<option>2019</option>
							<option>2020</option>
							<option>2021</option>
							<option>2022</option>
							<option>2023</option>
						</Input>
					</Col>
					<div
						style={{
							margin: '0 1rem 12px -.5rem',
							padding: '.3rem .3rem 0 .3rem',
							border: '.5px solid 	#D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ Year: new Date().getFullYear() })}>
						X
					</div>
					<Col xs='0.5'>
						<p
							style={{
								color: 'red',
								fontSize: '40px',
								marginTop: '-10px',
								marginBottom: '0px',
								padding: '0px',
								opacity: this.state.Overdue ? '1' : '0.3',
								textAlign: 'center',
								cursor: 'pointer',
							}}
							onClick={this.overdueFilter}>
							!!
						</p>
					</Col>
					<Col xs='3'>
						<Input type='select' name='project' onChange={this.onChange} value={this.state.project}>
							<option value='' disabled>
								Project Filter
							</option>
							<option></option>
							{this.props.project.projects.map((project) => (
								<option key={project._id} value={project._id}>
									{project.name}
								</option>
							))}
						</Input>
					</Col>
					{this.state.Department ? DepartmentFilters : IndividualFilters}
				</Row>
				<Row>
					<Col>
						<Table>
							<tbody>
								<tr>
									{this.state.departments.map(({ name, filterState, color, index }) => (
										<td
											key={name}
											style={{
												opacity: filterState ? '1' : '0.3',
												backgroundColor: color,
												textAlign: 'center',
												cursor: 'pointer',
											}}
											onClick={this.departmentFilter.bind(this, index)}>
											{name}
										</td>
									))}
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				<a onClick={() => this.setState({ resetFilterRr: !this.state.resetFilterRr })} href='#'>
					Reset Filter?
				</a>
				<Table style={{ marginTop: '0.5rem' }}>
					<thead>
						<tr
							style={{
								textAlign: 'center',
							}}>
							{['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
								(day) => (
									<th
										key={day}
										style={{ border: '1px solid black', padding: '10px', width: '14.3%' }}>
										{day}
									</th>
								)
							)}
						</tr>
					</thead>
					<tbody>
						{[0, 1, 2, 3, 4, 5].map((index) => {
							//Index is the value of the array used to create each week
							return (
								<tr key={index} style={{ height: this.state['week' + index] ? '0px' : '3rem' }}>
									{this.state.dateRange.slice(index * 7, (index + 1) * 7).map((e) => {
										//this is getting all the dates in the first week
										var totalHours = 0;
										var dateLabel = (
											<p
												onClick={this.hideWeek.bind(this, index)}
												style={{
													margin: '0px',
													textAlign: 'center',
													backgroundColor: '#757575',
													color: 'white',
													cursor: 'pointer',
													marginBottom: '1px',
												}}>
												{this.checkDate(new Date(e))}
											</p>
										);
										if (this.state['week' + index]) {
											return (
												<td
													key={e}
													style={{
														padding: '0px',
														paddingBottom: '2px',
														border: '1px solid black',
													}}>
													{dateLabel}
												</td>
											);
										}
										return (
											<td
												key={e}
												style={{ padding: '0px', paddingBottom: '2px', border: '1px solid black' }}>
												{dateLabel}
												<ListGroup>
													{Array.isArray(this.props.report.filteredReports[e]) &&
														this.props.report.filteredReports[e].map(
															(
																{
																	_id,
																	task_id,
																	department,
																	description,
																	user_id,
																	overdue,
																	overdueReason,
																	numberOfHours,
																	detail_id,
																	project_id,
																	proposal_id,
																	name
																},
																i
															) => {
																totalHours = totalHours + numberOfHours;
																var projectName = this.props.project.projects
																	.filter((project) => project._id === project_id)
																	.map((project) => project.name)[0];
																return (
																	<Fragment key={i}>
																		<a
																			onClick={
																				task_id
																					? this.props.toggleTaskIndex.bind(this, task_id)
																					: this.props.toggleProjectIndex.bind(this, project_id)
																			}
																			style={{
																				position: 'relative',
																			}}>
																			<ListGroupItem
																				style={{
																					marginTop: '1px',
																					marginLeft: '2px',
																					marginRight: '2px',
																					padding: '0',
																					cursor: 'pointer',
																					paddingLeft: '3px',
																					backgroundColor: this.state.departments
																						.filter((e) => e.id === department)
																						.map((e) => e.color),
																					fontSize: '12px',
																					border: this.state[_id] ? '1px solid #757575' : null,
																				}}
																				onMouseOver={this.tooltipReveal.bind(this, _id, true)}
																				onMouseOut={this.tooltipReveal.bind(this, _id, false)}>
																				{/* listGroupItem end tag</> */}
																				{
																					project_id === '5f224a84b2fbde22f5fee090' ?

																						<div key={project_id._id}>
																							{this.props.proposal.proposals.find(
																								({ _id }) => _id === proposal_id
																							).proposalNumber} - {name}
																							{overdue ? (
																								<span style={{ color: 'red' }}>
																									<b>!!</b>
																								</span>
																							) : null}
																							<Tooltip
																								id={this.state[_id]}
																								name={
																									this.props.employee.employees
																										? this.props.employee.employees.find(
																											(employee) =>
																												employee.user_id === user_id
																										).name
																										: null
																								}
																								description={description}
																								numberOfHours={numberOfHours}
																								overdue={overdue}
																								overdueReason={overdueReason}
																							/>
																						</div>
																						:
																						//check if has task or details
																						task_id ? (
																							tasks.map((task, j) => {
																								// task.sort(this.sortIndex);
																								var taskName = task.name;
																								if (task_id === task._id) {
																									if (task.proposal_id) {
																										const proposalNumber = this.props.proposal.proposals.find(
																											({ _id }) => _id === task.proposal_id
																										).proposalNumber;
																										taskName = proposalNumber + ' - ' + task.name;
																									}
																									return (
																										<div key={task._id}>
																											{taskName}
																											{overdue ? (
																												<span style={{ color: 'red' }}>
																													<b>!!</b>
																												</span>
																											) : null}
																											<Tooltip
																												id={this.state[_id]}
																												name={
																													this.props.employee.employees
																														? this.props.employee.employees.find(
																															(employee) =>
																																employee.user_id === user_id
																														).name
																														: null
																												}
																												description={description}
																												numberOfHours={numberOfHours}
																												overdue={overdue}
																												overdueReason={overdueReason}
																											/>
																										</div>
																									);
																								}
																							})
																						) : (
																							<div>
																								<strong>{projectName}</strong>
																								<br />
																								{this.props.detail.details
																									.filter((detail) => detail._id === detail_id)
																									.map((detail) => detail.description)}
																								<Tooltip
																									id={this.state[_id]}
																									name={
																										this.props.employee.employees
																											? this.props.employee.employees.find(
																												(employee) => employee.user_id === user_id
																											)?.name || ''
																											: null
																									}
																									description={description}
																									numberOfHours={numberOfHours}
																									overdue={overdue}
																									overdueReason={overdueReason}
																								/>
																							</div>
																						)
																				}
																			</ListGroupItem>
																		</a>
																	</Fragment>
																);
															}
														)}
												</ListGroup>
												{totalHours > 0 ? (
													<p
														style={{
															marginTop: '1px',
															marginLeft: '2px',
															marginRight: '2px',
															marginBottom: '1px',
															padding: '0',
															paddingLeft: '3px',
															textAlign: 'center',
															backgroundColor: '#bfd8ff',
															fontSize: '12px',
															borderTop: '1px solid #757575',
														}}>
														{totalHours}
													</p>
												) : null}
												<div>
													<h1
														style={{
															cursor: 'pointer',
															opacity: '0.5',
															textAlign: 'center',
															backgroundColor: '#D3D3D3',
															borderRadius: '3px',
															fontSize: '20px',
															fontWeight: '900',
														}}
														onClick={() => {
															this.toggle();
															this.updateDate(e);
														}}>
														+
													</h1>
												</div>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Container>
		);
	}
}

TaskList.propTypes = {
	getReports: PropTypes.func.isRequired,
	deleteReport: PropTypes.func.isRequired,
	getProjects: PropTypes.func.isRequired,
	getTasks: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	toggleReportIndex: PropTypes.func.isRequired,
	toggleProjectIndex: PropTypes.func.isRequired,
	filterReports: PropTypes.func.isRequired,
	toggleTaskIndex: PropTypes.func.isRequired,
	report: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	report: state.reportR, // getting reducer from /reducers/index.js
	employee: state.employeeR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js
	task: state.taskR, // getting reducer from /reducers/index.js
	company: state.companyR,
	detail: state.detailR,
	proposal: state.proposalR,
});

export default connect(mapStateToProps, {
	getReports,
	deleteReport,
	toggleReportIndex,
	getEmployees,
	getProjects,
	getTasks,
	getCompany,
	filterReports,
	toggleTaskIndex,
	getDetails,
	toggleProjectIndex,
})(TaskList);
