import axios from 'axios';
import {
	GET_EMPLOYEES,
	GET_EMPLOYEE,
	ADD_EMPLOYEE,
	DELETE_EMPLOYEE,
	UPDATE_EMPLOYEE_POINTS,
	EMPLOYEE_LOADING,
	UPDATE_EMPLOYEE,
	UPDATE_EMPLOYEE_FAIL,
	FILTER_EMPLOYEE,
	GET_USERS,
	TOGGLE_EDIT_MODAL,
	PURCHASE_REWARD,
	UPDATE_EMPLOYEE_REWARD,
	REDEEM_ANNUAL_LEAVES,
	UPDATE_EMPLOYEE_LEAVE,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

export const getEmployees = () => (dispatch, getState) => {
	dispatch(setEmployeeLoading());
	axios
		.get(`/api/employees`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_EMPLOYEES,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getEmployee = () => (dispatch, getState) => {
	dispatch(setEmployeeLoading());
	axios
		.get(`/api/employees/employee`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_EMPLOYEE,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const addEmployee = (newEmployee, name, points) => (dispatch, getState) => {
	newEmployee.name = name;
	newEmployee.points = points;
	axios
		.post(`/api/employees`, newEmployee, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: ADD_EMPLOYEE,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const updateEmployee = (employee, id) => (dispatch, getState) => {
	dispatch(setEmployeeLoading());
	axios
		.post(`/api/employees/update/${id}`, employee, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_EMPLOYEE,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_EMPLOYEE_FAIL,
			});
		});
};

export const executiveUpdateEmployee = (employee) => (dispatch, getState) => {
	dispatch(setEmployeeLoading());
	axios
		.post(`/api/employees/executive/update`, employee, tokenConfig(getState))
		.then((res) => {
			axios.post(`/api/users/executive/update`, employee, tokenConfig(getState)).then(() => {
				var obj = res.data;
				obj.message = 'Successfully Updated';
				dispatch({
					type: UPDATE_EMPLOYEE,
					payload: res.data,
				});
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_EMPLOYEE_FAIL,
			});
		});
};

export const updateEmployeePoints = (id, points) => (dispatch, getState) => {
	dispatch({
		type: DELETE_EMPLOYEE,
		payload: id,
	});
	axios
		.post(`api/employees/points/${id}`, points, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_EMPLOYEE_POINTS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_POINTS_FAIL'));
			dispatch({
				type: UPDATE_EMPLOYEE_FAIL,
			});
		});
};

export const uploadProfilePhoto = (object) => (dispatch) => {
	dispatch(setEmployeeLoading());
	object.key = 'nnnlwe19219n1b2--u0qjasca';
	var image = JSON.stringify(object);
	//Upload photo to google drive

	axios
		.post(
			'https://script.google.com/macros/s/AKfycbw2smMZXthCF_XAHvF2XzrKKwobh-kC0afqf3wbGYRhHvx8xWDL0j3Fyt5QNvtq2D0-HQ/exec',
			image
		)
		.then((res) => {
			const uploadLink = {
				photo: res.data,
				_id: object._id,
			};
			dispatch(photoUpload(uploadLink));
		})
		.catch((err) => {
			dispatch({
				type: UPDATE_EMPLOYEE_FAIL,
			});
		});
};

export const purchaseReward = (employee_id, newReward) => (dispatch, getState) => {
	axios
		.post(`/api/employees/purchaseRewards/${employee_id}`, newReward, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: PURCHASE_REWARD,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const updateEmployeeReward = (employee_id, updatedEmployee) => (dispatch, getState) => {
	axios
		.post(`/api/employees/updateRewards/${employee_id}`, updatedEmployee, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_EMPLOYEE,
				payload: [res.data],
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const employeeRewardStatus = (employee_id, updatedEmployee) => (dispatch, getState) => {
	axios
		.post(`/api/employees/updateRewards/${employee_id}`, updatedEmployee, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_EMPLOYEE_REWARD,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
//Requires an object with employee ID and Image link
export const photoUpload = (object) => (dispatch, getState) => {
	axios
		.post(`api/employees/photoUpload`, object, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_EMPLOYEE,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_POINTS_FAIL'));
			dispatch({
				type: UPDATE_EMPLOYEE_FAIL,
			});
		});
};

export const filterEmployees = (filters) => (dispatch) => {
	dispatch({
		type: FILTER_EMPLOYEE,
		payload: filters,
	});
};

export const getUsersByCompany = () => (dispatch, getState) => {
	axios
		.get(`api/users/companyusers`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_USERS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const toggleEditModal = (_id) => (dispatch) => {
	dispatch({
		type: TOGGLE_EDIT_MODAL,
		payload: _id,
	});
};

// export const deleteEmployee = (id) => (dispatch, getState) => {
//     axios
//     .delete(`/api/employees/${id}`, tokenConfig(getState))
//     .then(
//         res => dispatch({
//             typeDELETE_EMPLOYEE,
//             payload: id
//     }))
//     .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
// };

export const updateLeaveHistory = (employee) => (dispatch, getState) => {
	axios
		.patch(`/api/employees/update/takenLeaves`, employee, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_EMPLOYEE_LEAVE,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_EMPLOYEE_FAIL,
			});
		});
};

export const setEmployeeLoading = () => {
	return {
		type: EMPLOYEE_LOADING,
	};
};
