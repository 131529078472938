import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getSpecificDetails,
	createDetail,
	deleteDetail,
	updateDetail,
	expandInputBoxSize,
	reduceInputBoxSize,
} from '../../actions/detailActions';
import { getCompanyTimeInputs } from '../../actions/companyActions';
import { updateDetailsOrder } from '../../actions/proposalActions';
import { Input, Row, Col, Button, Table } from 'reactstrap';
import FeesCalculatorTable from './FeesCalculatorTable';
import ConfirmButton from '../userComponents/ConfirmButton';
// This is a child component of the Update Proposal component.
const UpdateDetails = (props) => {
	const [index, setIndex] = useState(null); // Capture the index of element to be dragged.
	const [index2, setIndex2] = useState(null); // Used for styling purpose in drag functionlity.
	const [stageNumber, setStageNumber] = useState(''); // Used to store the stage number of the detail
	const [description, setDescription] = useState(''); // Used to store the description of the detail
	const [timeInputs, setTimeInputs] = useState([]); // Stores data of new details
	const [costInputs, setCostInputs] = useState([]); // Stores data of new details
	const [clearInputs, setClearInputs] = useState(false); // It clears the aboce states after adding the detail to backend.
	const [hideNewRow, toggleNewRow] = useState(true); // It controls the visibility of the table row for adding new detail.
	const [toggler, setToggler] = useState(0); // It toggles the table headers from time inputs to ext cost inputs and vice versa
	const [updatedDetails, setUpdatedDetails] = useState([]); // Stores The details which are updated.
	const [details, setDetails] = useState([]); // Lists of details coming from redux.
	const [detailsOrder, setDetailsOrder] = useState([]); // List of the proposal ids sorted based on the user preference.
	const [isUpdated, setIsUpdated] = useState(false); // It becomes true if the user updates the order of details table.

	// Data coming from redux
	const reduxDetails = useSelector((state) => state.detailR.details);
	const intTimeInputs = useSelector((state) => state.companyR.intTimeInputs);
	const extCostInputs = useSelector((state) => state.companyR.extTimeInputs);
	const user = useSelector((state) => state.authR.user);
	const reduxDetailsOrder = useSelector((state) => state.proposalR.proposal.detailsOrder);
	const stages = useSelector((state) =>
		state.stageR.stages.filter(
			({ proposalNumber }) => proposalNumber === props.proposal.proposalNumber
		)
	);
	const inputBox = useSelector((state) => state.detailR.inputBox); // The width of input box

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSpecificDetails(props.proposal_id));
		dispatch(getCompanyTimeInputs());
	}, []);
	// It will be fire if the update is cancelled. To get back the original data
	useEffect(() => {
		dispatch(getSpecificDetails(props.proposal_id));
	}, [props.cancelEdit]);

	useEffect(() => {
		setDetailsOrder(reduxDetailsOrder);
	}, [reduxDetailsOrder]);

	useEffect(() => {
		// The two arrays used to capture the data of new details.
		if (intTimeInputs && extCostInputs) {
			//
			setTimeInputs(() => {
				var array = [];
				intTimeInputs.map(({ id }) => array.push({ [id]: 0 }));
				return array;
			});
			setCostInputs(() => {
				var array = [];
				extCostInputs.map(({ id }) => array.push({ [id]: 0 }));
				return array;
			});
		}
		setDescription('');
		setStageNumber(null);
	}, [intTimeInputs, extCostInputs, clearInputs]);

	// Storing all the details ids in a the state with the corresponding order.
	useEffect(() => {
		var arr = [];
		detailsOrder.forEach((id) => {
			if (reduxDetails.find((e) => e._id === id)) {
				arr.push(id);
			}
		});
		setDetailsOrder(arr); //
	}, [reduxDetails]);

	useEffect(() => {
		var detailsArray = []; // Temporary array to store details.
		if (detailsOrder) {
			// For not getting error.
			detailsOrder.map((id, index) => {
				// detail order array is coming from proposal.
				const detail = reduxDetails.find((e) => e._id === id);
				if (!detail) {
					console.log(id);
					return null;
				}
				if (detail.revisionNumber === props.currentRevisionNum) {
					// The revision number is checked here.
					var object = {
						// This is the data about each detail in the array
						_id: detail._id,
						stageNumber: detail.stageNumber,
						description: detail.description,
						intTimeInputs: detail.intTimeInputs,
						extCostInputs: detail.extCostInputs,
						revisionNumber: detail.revisionNumber,
					};
					detailsArray.push(object);
				} else {
					// The previous revision number
					detail.revision
						.filter(({ revisionNumber }) => revisionNumber === props.currentRevisionNum)
						.map((e) => {
							var object = {
								_id: detail._id,
								stageNumber: e.stageNumber,
								description: e.description,
								intTimeInputs: e.intTimeInputs,
								extCostInputs: e.extCostInputs,
								revisionNumber: e.revisionNumber,
							};
							detailsArray.push(object);
						});
				}
			});
		}
		setDetails(detailsArray);
	}, [reduxDetails, props.currentRevisionNum, detailsOrder]);

	useEffect(() => {
		// To fire the update function.
		if (updatedDetails.length) {
			updatedDetails.forEach((e) => {
				if (e) {
					dispatch(updateDetail(e, e._id));
				}
			});
		}
		if (isUpdated) {
			// TO check if the details order is updated.
			const body = {
				id: props.proposal_id,
				detailsOrder: detailsOrder,
			};
			dispatch(updateDetailsOrder(body)); // Saving the details order in proposal side.
			setIsUpdated(false);
		}
	}, [props.fireUpdateFunction]);

	const getNewDetailsInput = (index, { target }) => {
		if (toggler === 0) {
			timeInputs[index][target.name] = Number(target.value);
			setTimeInputs([...timeInputs]);
		} else {
			costInputs[index][target.name] = Number(target.value);
			setCostInputs([...costInputs]);
		}
	};
	const addDetail = () => {
		if (stageNumber && description) {
			const newDetail = {
				proposal_id: props.proposal_id,
				description: description,
				stageNumber: Number(stageNumber),
				intTimeInputs: timeInputs,
				extCostInputs: costInputs,
				revisionNumber: props.proposal.revisionNumber, // The revision Number will be correspond to proposal revision Number
			};
			const body = {
				// To add the detail's id in to details order array in proposal.
				id: props.proposal_id,
				detailsOrder: detailsOrder,
			};
			dispatch(createDetail(newDetail, body));
			setClearInputs((current) => !current);
			toggleNewRow((current) => !current);
		} else {
			alert('Information is not complete');
		}
	};

	const removeDetail = (_id) => {
		const body = {
			// It removes the detail's id from the details order array in proposal.
			id: props.proposal_id,
			detailsOrder: reduxDetailsOrder.filter((id) => id !== _id),
		};
		dispatch(updateDetailsOrder(body)); // Removing the id of the details from the details order.
		dispatch(deleteDetail(_id)); // Deleting the detail from backend.
		setDetailsOrder([...reduxDetailsOrder.filter((id) => id !== _id)]);
	};
	const getUpdatedDetailsInputs = (index, index2, { target }) => {
		if (target.name.substring(7, 13) === 'Inputs') {
			if (!index2 && index2 !== 0) {
				// When the index2 is undefined ( The key does not exist in details )
				details[index][target.name.substring(0, 13)].push({
					[target.name.substring(13)]: Number(target.value),
				});
			} else {
				details[index][target.name.substring(0, 13)][index2][target.name.substring(13)] = Number(
					target.value
				);
			}
		} else {
			details[index][target.name] =
				target.name === 'description' ? target.value : Number(target.value);
		}
		setDetails([...details]);
		updatedDetails[index] = details[index];
		setUpdatedDetails([...updatedDetails]);
	};
	// The drag and drop functions
	const onDragStart = (e, index) => {
		setIndex(index);
	};
	const onDragOver = (e) => {
		e.preventDefault();
	};
	const onDrop = (e, ind) => {
		e.preventDefault();
		setIsUpdated(true);
		var arr = [...detailsOrder];
		arr.splice(ind, 0, arr.splice(index, 1)[0]);
		setDetailsOrder(arr);
	};
	// The add details button.
	const addButton = (
		<span
			style={{
				position: 'relative',
				bottom: '0.5rem',
				left: '3rem',
				cursor: 'pointer',
				fontSize: '1.5rem',
			}}
			onClick={() => toggleNewRow((current) => !current)}>
			&#x271A;
		</span>
	);
	return (
		<Fragment>
			{details[0] ? (
				user.permissionLevel === 'Executive' ? (
					<FeesCalculatorTable
						details={details}
						proposalNumber={props.proposal.proposalNumber}
						currentRevisionNum={props.currentRevisionNum}
					/>
				) : null
			) : null}
			<Row onClick={() => dispatch(reduceInputBoxSize())}>
				<Col xs='3' style={{ textAlign: 'center' }}>
					Task Details
				</Col>
				<Col xs='7' style={{ textAlign: 'center' }}>
					<span style={{ position: 'relative', left: '3rem' }}>
						{toggler === 0 ? 'Time Inputs' : 'Other Costs'}
					</span>
				</Col>
				<Col xs='1'>
					{props.proposal.status !== 'Completed'
						? details[0]
							? details[0].revisionNumber === props.currentRevisionNum
								? addButton // The add button is defined above.
								: null
							: addButton
						: null}
				</Col>
				<Col xs='1' style={{ textAlign: 'center' }}>
					<Button
						color='primary'
						onClick={() => setToggler((current) => (current === 1 ? 0 : 1))}
						style={{ position: 'relative', bottom: '0.5rem', fontSize: '15px' }}>
						Toggle
					</Button>
				</Col>
			</Row>
			<Table>
				<thead onClick={() => dispatch(reduceInputBoxSize())}>
					<tr>
						<th width='5%'>Stage</th>
						<th width='20%'>Details</th>
						{toggler === 0
							? intTimeInputs.map((e) => (
									<th key={e.id} width={`${75 / intTimeInputs.length}%`}>
										{e.name}
									</th>
							  ))
							: extCostInputs.map((e) => (
									<th key={e.id} width={`${75 / extCostInputs.length}%`}>
										{e.name}
									</th>
							  ))}
					</tr>
				</thead>
				<tbody>
					<Fragment>
						{/*    The Row for adding a new detail       */}
						<tr hidden={hideNewRow} className='table-active' width='100%'>
							<td style={{ paddingRight: '0px', paddingLeft: '0px' }}>
								<Input
									type='select'
									name='stageNumber'
									value={Number(stageNumber)}
									onChange={({ target }) => setStageNumber(target.value)}>
									<option></option>
									{stages.map((e, i) => {
										return <option key={i}>{e.stage}</option>;
									})}
								</Input>
							</td>
							<td>
								<Input
									type='textarea'
									name='description'
									value={description}
									onChange={({ target }) => setDescription(target.value)}
								/>
							</td>
							{toggler === 0
								? timeInputs.map((e, index) => (
										<td key={index} style={{ paddingRight: '0px', paddingLeft: '5px' }}>
											<Input
												style={{
													width:
														intTimeInputs.length > 8
															? index === inputBox.inputIndex
																? inputBox.inputWidth
																: null
															: null,
												}}
												type='number'
												onClick={() => dispatch(expandInputBoxSize(index))}
												name={Object.keys(e)[0]}
												defaultValue={Object.values(e)[0] ? Number(Object.values(e)[0]) : ''}
												onChange={(e) => getNewDetailsInput(index, e)}
											/>
										</td>
								  ))
								: costInputs.map((e, index) => (
										<td key={index} style={{ paddingRight: '0px', paddingLeft: '5px' }}>
											<Input
												type='number'
												name={Object.keys(e)[0]}
												defaultValue={Object.values(e)[0] ? Number(Object.values(e)[0]) : ''}
												onChange={(e) => getNewDetailsInput(index, e)}
											/>
										</td>
								  ))}
							<td></td>
						</tr>
						<div
							hidden={hideNewRow}
							style={{
								position: 'absolute',
								right: '20px',
								marginTop: '10px',
								display: 'flex',
								flexDirection: 'row',
							}}>
							<Button size='sm' style={{ marginRight: '10px' }} color='success' onClick={addDetail}>
								&#10003;
							</Button>
							<Button size='sm' color='danger' onClick={() => toggleNewRow((current) => !current)}>
								&#10005;
							</Button>
						</div>
					</Fragment>
					{!hideNewRow ? <div style={{ height: '55px' }}></div> : ''}
					{
						// The details List
						details.map((detail, index) => {
							return (
								<tr
									key={detail._id}
									draggable={props.editMode ? true : false}
									style={{
										width: '100%',
										cursor: props.editMode ? 'move' : null,
										borderStyle: index === index2 ? 'solid' : null,
										borderColor: index === index2 ? 'gray' : null,
										borderWidth: index === index2 ? 2 : null,
										opacity: index === index2 ? 0.8 : null,
									}}
									// The 2 functions in below is for styling purpose.
									onDragEnter={() => setIndex2(index)}
									onDragEnd={() => setIndex2(null)}
									// The functions below are the drag and drop implementation.
									onDragStart={(e) => {
										onDragStart(e, index);
									}}
									onDragOver={(e) => {
										onDragOver(e);
									}}
									onDrop={(e) => {
										onDrop(e, index);
									}}>
									<td
										style={{
											paddingRight: props.editMode ? '0px' : 'auto',
											paddingLeft: props.editMode ? '0px' : 'auto',
										}}>
										{props.editMode ? (
											<Input
												type='select'
												name='stageNumber'
												value={detail.stageNumber}
												onChange={(e) => getUpdatedDetailsInputs(index, '', e)}>
												<option></option>
												{stages.map((e, i) => {
													return <option key={i}>{e.stage}</option>;
												})}
											</Input>
										) : (
											detail.stageNumber
										)}
									</td>
									<td
										style={{
											borderBottom:
												detail.revisionNumber !== props.currentRevisionNum ? '0px white none' : '',
										}}>
										{props.editMode ? (
											<Input
												type='textarea'
												name='description'
												defaultValue={detail.description}
												onChange={(e) => getUpdatedDetailsInputs(index, '', e)}
											/>
										) : (
											detail.description
										)}
									</td>
									{toggler === 0
										? intTimeInputs.map((x, i) => {
												// The intTimeInputs is coming from company side
												var value = '',
													ind = '';
												detail.intTimeInputs.map((e, index) => {
													if (Object.keys(e)[0] === x.id) {
														value = Object.values(e)[0];
														ind = index;
													}
												});
												return (
													<td
														key={i}
														style={{
															paddingRight: props.editMode ? '0px' : 'auto',
															paddingLeft: props.editMode ? '5px' : 'auto',
														}}>
														{props.editMode ? (
															<Input
																style={{
																	width:
																		intTimeInputs.length > 8
																			? i === inputBox.inputIndex
																				? inputBox.inputWidth
																				: null
																			: null,
																}}
																onClick={() => dispatch(expandInputBoxSize(i))}
																type='number'
																value={value}
																name={`intTimeInputs${x.id}`}
																onChange={(e) => getUpdatedDetailsInputs(index, ind, e)}
															/>
														) : (
															value
														)}
													</td>
												);
										  })
										: extCostInputs.map((x, i) => {
												// The extCostInputs is coming from company side
												var value = '',
													ind = '';
												detail.extCostInputs.map((e, index) => {
													if (Object.keys(e)[0] === x.id) {
														value = Object.values(e)[0];
														ind = index;
													}
												});
												return (
													<td key={i}>
														{props.editMode ? (
															<Input
																type='number'
																value={value}
																name={`extCostInputs${x.id}`}
																onChange={(e) => getUpdatedDetailsInputs(index, ind, e)}
															/>
														) : (
															value
														)}
													</td>
												);
										  })}
									<td width='2%'>
										{props.editMode ? (
											props.currentRevisionNum === detail.revisionNumber ? (
												<ConfirmButton
													style={{ cursor: 'pointer', position: 'absolute', right: '10px' }}
													alertTitle={'Remove this Details?'}
													onClick={() => removeDetail(detail._id)}
													size='sm'
													color='danger'>
													&#10005;
												</ConfirmButton>
											) : null
										) : null}
									</td>
								</tr>
							);
						})
					}
				</tbody>
			</Table>
		</Fragment>
	);
};
export default UpdateDetails;
