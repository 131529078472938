import React, { Component, Fragment } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	ListGroup,
	ListGroupItem,
	Form,
	Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
	toggleDepartmentIndex,
	deleteDepartment,
	addDepartment,
	updateDepartment,
} from '../../actions/companyActions';
import ConfirmButton from '../userComponents/ConfirmButton';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

class DepartmentModal extends Component {
	state = {
		addDepartment: true,
		newDepartment: '',
		editMode: false,
	};
	onDeleteClick = (_id, i) => {
		this.props.deleteDepartment(_id, i);
		this.props.toggleDepartmentIndex('');
	};

	onEditClick = (_id, i) => {
		this.setState({
			editMode: true,
			departments: this.props.company.company.departments.filter(({ active }) => active)
		})
	}

	toggle = () => {
		this.setState({
			addDepartment: !this.state.addDepartment,
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	updateDepartment = (e, i) => {
		var departments = this.state.departments
		departments[i].name = e.target.value
		this.setState({ departments });
	}

	onAddDepartment = (_id) => {
		const objectDepartment = {
			id: uuidv4(),
			name: this.state.newDepartment,
			active: true,
		};
		this.props.addDepartment(objectDepartment, _id);
		this.props.toggleDepartmentIndex('');
	};

	setNewDepartments = (_id) => {
		const departments = this.state.departments
		var object = {
			departments,
			_id
		}
		this.setState({
			editMode: false,
		})
		this.props.updateDepartment(object)
	}

	render() {
		return this.props.company.company.departments ? (
			<Fragment>
				<Modal
					isOpen={this.props.company.modal}
					toggle={this.props.toggleDepartmentIndex.bind(this, '')}>
					<ModalHeader toggle={this.props.toggleDepartmentIndex.bind(this, '')}>
						<strong>{this.props.company.company.companyname} Departments</strong>
					</ModalHeader>
					<ModalBody>
						<ListGroup>
							{this.props.company.company.departments
								.filter(({ active }) => active)
								.map((e, i) => (
									<ListGroupItem key={'department' + i}>
										{this.state.editMode ?
											<div>
												<Input name={e.id} defaultValue={e.name} onChange={(e) => this.updateDepartment(e, i)} />
											</div>
											:
											<div>
												{e.name}
												<ConfirmButton
													className='remove-btn'
													color='danger'
													size='sm'
													style={{
														float: 'right',
													}}
													alertTitle={'Delete this Department?'}
													onClick={() => this.onDeleteClick(this.props.company.company._id, i)}>
													<FontAwesomeIcon color='white' style={{ fontSize: '14x' }} icon={faTrash} />
												</ConfirmButton>
												<Button
													className='remove-btn'
													color='primary'
													size='sm'
													style={{
														float: 'right',
													}}
													onClick={this.onEditClick.bind(this, this.props.company.company._id, i)}>
													<FontAwesomeIcon color='white' style={{ fontSize: '14x' }} icon={faEdit} />
												</Button>
											</div>}
									</ListGroupItem>
								))}
						</ListGroup>
						{this.state.editMode ? <div
							style={{ margin: "0.5rem", display: "block" }}
						><Button
							className='remove-btn'
							color='success'
							style={{
								color: 'black',
								float: "right"
							}}
							size='sm'
							onClick={() => this.setNewDepartments(this.props.company.company._id)}>
								&#10003;
						</Button>
							<Button
								className='remove-btn'
								color='danger'
								style={{
									color: 'black',
									float: "right"
								}}
								size='sm'
								onClick={() => {
									this.setState({ editMode: false })
								}}>
								&#10005;
						</Button><br /></div> : null}
						{this.state.addDepartment ? (
							<Button color='dark' style={{ marginTop: '2rem' }} block onClick={this.toggle}>
								Add Department
							</Button>
						) : (
								<div>
									<Input
										type='text'
										name='newDepartment'
										placeholder='New Department Name'
										onChange={this.onChange}
										style={{
											marginTop: '0.5rem',
										}}
										required
									/>
									<Button
										onClick={this.toggle}
										className='remove-btn'
										color='danger'
										style={{
											color: 'black',
											marginTop: '2rem',
										}}
										size='sm'>
										&#10005;
								</Button>
									<Button
										onClick={this.onAddDepartment.bind(this, this.props.company.company._id)}
										className='remove-btn'
										color='success'
										style={{
											color: 'black',
											marginTop: '2rem',
										}}
										size='sm'>
										&#10003;
								</Button>
								</div>
							)}
					</ModalBody>
				</Modal>
			</Fragment>
		) : null;
	}
}

DepartmentModal.propTypes = {
	toggleDepartmentIndex: PropTypes.func.isRequired,
	deleteDepartment: PropTypes.func.isRequired,
	addDepartment: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	company: state.companyR, // getting reducer from /reducers/index.js
	auth: state.authR, // getting reducer from /reducers/index.js
});

export default connect(mapStateToProps, { toggleDepartmentIndex, deleteDepartment, addDepartment, updateDepartment })(
	DepartmentModal
);
