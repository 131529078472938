import React, { Component } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	NavLink,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';

class RegisterModal extends Component {
	state = {
		modal: false,
		company_id: '',
		email: '',
		name: '',
		password: '',
		password2: '',
		message: null,
	};

	componentDidUpdate(prevProps) {
		const { error, isAuthenticated } = this.props;
		if (error !== prevProps.error) {
			//Check for register error
			if (error.id === 'REGISTER_FAIL') {
				this.setState({ message: error.message.message });
			} else {
				this.setState({ message: null });
			}
		}

		if (this.state.modal) {
			if (isAuthenticated) {
				this.toggle();
			}
		}
	}

	toggle = () => {
		//Clear errors
		this.props.clearErrors();

		this.setState({
			modal: !this.state.modal,
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmit = (e) => {
		e.preventDefault();

		const { company_id, email, password, password2, name } = this.state;

		// create a user object
		const newUser = {
			company_id,
			email,
			password,
			password2,
			permissionLevel: 'Disabled',
			points: 0,
		};
		//Attemp to Register
		this.props.register(newUser, name);
	};

	render() {
		return (
			<div>
				<NavLink onClick={this.toggle} href='#'>
					Register
				</NavLink>

				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Register</ModalHeader>
					<ModalBody>
						{this.state.message ? <Alert color='danger'>{this.state.message}</Alert> : null}
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label for='company_id'>Company ID</Label>
								<Input
									type='text'
									name='company_id'
									id='company_id'
									placeholder='Add Company ID'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label>Full Name</Label>
								<Input
									type='text'
									name='name'
									placeholder='Full Name'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for='email'>Email</Label>
								<Input
									type='email'
									name='email'
									id='email'
									placeholder='Requires Unique Email'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for='password'>Password</Label>
								<Input
									type='password'
									name='password'
									id='password'
									placeholder='Password'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for='password2'>Confirm Password</Label>
								<Input
									type='password'
									name='password2'
									id='password2'
									placeholder='Confirm Password'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<Button color='dark' style={{ marginTop: '2rem' }} block>
								Register
							</Button>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

RegisterModal.propTypes = {
	isAuthenticated: PropTypes.bool,
	error: PropTypes.object.isRequired,
	register: PropTypes.func.isRequired,
	clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.authR.isAuthenticated, // getting reducer from /reducers/index.js
	error: state.errorR,
});

export default connect(mapStateToProps, {
	register,
	clearErrors,
})(RegisterModal);
