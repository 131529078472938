import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Table, Col, ListGroup, ListGroupItem, Row, Button, Input } from 'reactstrap';
import { getCompanyTimeInputs, updateCompanyTimeInputs } from '../../actions/companyActions';
import { v4 as uuidv4 } from 'uuid';
import ConfirmButton from '../userComponents/ConfirmButton.js'


const ProposalRates = props => {
	const [editMode, toggleEditMode] = useState(false);
	const [intNewRow, toggleIntNewRow] = useState(false); // It toggle the new row for adding intTimeInputs.
	const [extNewRow, toggleExtNewRow] = useState(false); // It toggle the new row for adding extTimeInputs.
	const [newIntTimeInputs, setNewIntTimeInputs] = useState({ name: '', rate: '', id: uuidv4() }); //For adding a new input
	const [newExtTimeInput, setNewExtTimeInput] = useState({ name: '', id: uuidv4() }); // For adding a new input.
	const [intTimeInputs, setIntTimeInputs] = useState([]); // The array of all internal time inputs.
	const [extTimeInputs, setExtTimeInputs] = useState([]); // The array of all external time inputs.
	const [cancel, setCancel] = useState(false); // It toggles if the user cancel the update operation.
	// Data coming from redux
	const reduxIntTimeInputs = useSelector(state => state.companyR.intTimeInputs)
	const reduxExtTimeInputs = useSelector(state => state.companyR.extTimeInputs)
	const user = useSelector(state => state.authR.user)

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompanyTimeInputs());
	}, []);

	useEffect(() => {
		setIntTimeInputs(reduxIntTimeInputs);
		setExtTimeInputs(reduxExtTimeInputs);
	}, [reduxIntTimeInputs, reduxExtTimeInputs]);

	useEffect(() => {
		// If the user cancel the update function then all the data will goes back to its original format. 
		dispatch(getCompanyTimeInputs());
	}, [cancel]);

	const enterEditMode = () => {
		toggleEditMode(true);
	};

	const cancelEditMode = () => {
		toggleEditMode(false);
		setCancel((state) => !state);
	};

	const updateIntTimeInputs = (target, index) => {
		intTimeInputs[index][target.name] = target.value === "rate" ? Number(target.value) : target.value;
		setIntTimeInputs([...intTimeInputs]);
	};
	const updateExtTimeInputs = (target, index) => {
		extTimeInputs[index].name = target.value;
		setExtTimeInputs([...extTimeInputs]);
	};
	const addNewInputs = (input) => {
		if (input === 'intTimeInputs') {
			if (!newIntTimeInputs.name || !newIntTimeInputs.rate) return alert('Information not Complete!!')
			intTimeInputs.push(newIntTimeInputs)
			setIntTimeInputs([...intTimeInputs])
			setNewIntTimeInputs({ name: '', rate: '', id: uuidv4() });
			toggleIntNewRow((state) => !state);
		} else {
			if (!newExtTimeInput.name) return alert('Information not Complete!!')
			extTimeInputs.push(newExtTimeInput) //It should be pushed for some reason.
			setExtTimeInputs([...extTimeInputs])
			toggleExtNewRow(false)
			setNewExtTimeInput({ name: '', id: uuidv4() })
		}
		const body = {
			intTimeInputs: intTimeInputs,
			extTimeInputs: extTimeInputs,
		};
		return dispatch(updateCompanyTimeInputs(body));
	};

	const deleteInput = (id, inputType) => {
		inputType === 'internal'
			? setIntTimeInputs([...intTimeInputs.filter(e => e.id !== id)])
			: setExtTimeInputs(extTimeInputs.filter(e => e.id !== id));
		const body = {
			intTimeInputs: intTimeInputs.filter(e => e.id !== id),
			extTimeInputs: extTimeInputs.filter(e => e.id !== id),
		};
		dispatch(updateCompanyTimeInputs(body));
	};

	const submit = () => {
		toggleEditMode((state) => !state);
		const body = {
			intTimeInputs: intTimeInputs,
			extTimeInputs: extTimeInputs,
		};
		dispatch(updateCompanyTimeInputs(body));
	};
	const addIntTimeButton = ( // The add buttton  ( + )
		<span
			style={{ fontSize: '1rem', cursor: 'pointer', float: 'right' }}
			onClick={() => toggleIntNewRow((hideNewRow) => !hideNewRow)}>
			&#x271A;
		</span>
	);

	if (user.permissionLevel !== "Executive") {
		return null
	}

	return (
		<Container>
			<div style={{ marginBottom: '0.8rem', marginTop: '-1rem' }}>
				{editMode ? (
					<div>
						<Button onClick={() => submit()} color='success'>
							Submit Changes
						</Button>
						<Button
							onClick={() => cancelEditMode()}
							color='danger'
							style={{ marginLeft: '0.2rem' }}>
							Cancel
						</Button>
					</div>
				) : (
						<Button color='primary' onClick={() => enterEditMode()}>
							Edit Mode
						</Button>
					)}
			</div>
			<Row>
				<Col>
					<Table>
						<thead>
							<tr>
								<th width={editMode ? '50%' : '80%'}>Identifier</th>
								<th width={editMode ? '50%' : '10%'}>Rate</th>
								{// Limiting the max number of int time inputs to 10.
									intTimeInputs && intTimeInputs.length >= 10 ? null : (
										<th width='10%' colSpan="2">
											{addIntTimeButton}
										</th>
									)
								}
							</tr>
						</thead>
						<tbody>
							{
								// New row
								intNewRow ? (
									<tr>
										<td width="50%">
											<Input
												type='text'
												placeholder="Keep it Short!  (AD, MN)"
												value={newIntTimeInputs.name}
												onChange={({ target }) => setNewIntTimeInputs({ ...newIntTimeInputs, 'name': target.value })}
											/>
										</td>
										<td width="30%">
											<Input
												type='number'
												placeholder="Number"
												value={newIntTimeInputs.rate}
												onChange={({ target }) => setNewIntTimeInputs({ ...newIntTimeInputs, 'rate': Number(target.value) })}
											/>
										</td>
										<td>
											<Button
												style={{
													position: 'relative',
													left: '1rem',
												}}
												onClick={() => addNewInputs('intTimeInputs')}
												size="sm" color="success"
											>
												&#10003;
											</Button>
										</td>
										<td>
											<Button
												style={{
													position: 'relative',
												}}
												onClick={() => toggleIntNewRow((state) => !state)}
												size="sm" color="danger"
											>
												&#10005;
											</Button>
										</td>
									</tr>
								) : null
							}
							{intTimeInputs
								? intTimeInputs.map(({ name, rate, id }, index) => {
									return (
										<tr key={index}>
											<td>
												{
													editMode ? (
														<Input
															type='text'
															name="name"
															defaultValue={name}
															onChange={({ target }) => updateIntTimeInputs(target, index)}
														/>
													) : name
												}
											</td>
											<td colSpan={editMode ? '1' : '2'}>
												{editMode ? (
													<Input
														type='number'
														name="rate"
														defaultValue={rate}
														onChange={({ target }) => updateIntTimeInputs(target, index)}
													/>
												) : (
														rate
													)}
											</td>
											<td colSpan={intNewRow ? '2' : '1'}>
												{
													!editMode ?
														<ConfirmButton
															style={{ cursor: "pointer", position: "relative", left: "1rem" }}
															onClick={() => deleteInput(id, 'internal')}
															color='danger'
															size="sm"
															alertTitle="Remove this item?"
														>
															&#10005;
														</ConfirmButton>
														: null
												}
											</td>
										</tr>
									);
								})
								: null}
						</tbody>
					</Table>
				</Col>
				<Col>
					<ListGroup>
						<ListGroupItem>
							<strong>Non-Time Inputs</strong>
							{ // Limiting the max number of external time inputs to 5
								editMode || (extTimeInputs && extTimeInputs.length >= 5) ? null : (
									<span
										style={{ fontSize: '1rem', cursor: 'pointer', float: 'right' }}
										onClick={() => toggleExtNewRow((hideNewRow) => !hideNewRow)}>
										&#x271A;
									</span>
								)
							}
						</ListGroupItem>
						{
							// Adding new External time inputs
							extNewRow ? (
								<ListGroupItem style={{ display: 'flex', flexDirection: 'row' }}>
									<Input
										type='text'
										style={{ width: '80%' }}
										value={newExtTimeInput.name}
										onChange={({ target }) => setNewExtTimeInput({ ...newExtTimeInput, 'name': target.value })}
									/>
									<Button
										size="sm"
										color="success"
										style={{
											position: 'relative', left: '3rem'
										}}
										onClick={() => addNewInputs('extTimeInputs')}>
										&#10003;
									</Button>
									<Button
										size="sm"
										color="danger"
										style={{
											position: 'relative',
											left: '3.5rem',
										}}
										onClick={() => toggleExtNewRow((state) => !state)}>
										&#10005;
									</Button>
								</ListGroupItem>
							) : ''
						}
						{extTimeInputs
							? extTimeInputs.map(({ name, id }, index) => (
								<ListGroupItem key={index}>
									{editMode ? ( // if edit mode is on then it shows the input box else show the e value
										<Input
											type='text'
											style={{ width: '100%', display: 'inline' }}
											defaultValue={name}
											onChange={({ target }) => updateExtTimeInputs(target, index)}
										/>
									) : (
											name
										)}
									{
										// The delete button section
										!editMode ?
											<ConfirmButton
												style={{ cursor: "pointer", float: "right" }}
												onClick={() => deleteInput(id, 'external')}
												color='danger'
												size="sm"
												alertTitle="Remove this item?"
											>
												&#10005;
											</ConfirmButton> :
											null
									}
								</ListGroupItem>
							))
							: null}
					</ListGroup>
				</Col>
			</Row>
		</Container>
	);
};

export default ProposalRates;
