import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux'

import { updateUserFee } from '../../actions/companyActions'


const UserFeeModal = (props) => {
    const dispatch = useDispatch()
    const [userFee, setUserFee] = useState('')
    const auth = useSelector((state) => state.authR.user);
    const company = useSelector((state) => state.companyR.company)

    useEffect(() => {
        setUserFee(props.userFee)
    }, [props.userFee])

    const UpdateUserFee = e => {
        e.preventDefault()
        dispatch(updateUserFee({
            userFee,
            _id: props.company_id
        }))
        props.toggleUserFeeModal()
    }

    return (
        <Modal isOpen={props.userFeeModal} toggle={props.toggleUserFeeModal}>
            <ModalHeader toggle={props.toggleUserFeeModal}>Modal title</ModalHeader>
            <Form onSubmit={e => UpdateUserFee(e)}>
                <ModalBody>
                    <FormGroup>
                        <Input
                            value={userFee}
                            onChange={e => setUserFee(e.target.value)}
                            required
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type='submit'>Update Fee</Button>{' '}
                    <Button color="secondary" onClick={props.toggleUserFeeModal}>Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

export default UserFeeModal;