import React, { Fragment, useEffect, useState } from 'react'
import { Form } from 'reactstrap'
import Avacado from './avacado.png'
import Jumper from './jumper.png'
import Tshirt from './tshirt.png'

import './JumperRegistration.css'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTshirt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Alert } from 'reactstrap'
const sizeLables = ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"]
const tshirtPrice = 10
const jumperPrice = 20
const tshirtPrice2 = 15
const jumperPrice2 = 25

const JumperRegistration = props => {
    const [sizes, setSizes] = useState({
        XS: 0,
        S: 0,
        M: 0,
        L: 0,
        XL: 0,
        "2XL": 0,
        "3XL": 0,
        "4XL": 0,
        "5XL": 0,
    })
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [comment, setComment] = useState('')
    const [type, setType] = useState('')
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalPrice2, setTotalPrice2] = useState(0)
    const [submited, setSubmited] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const updateSize = (e) => {
        const { value, name } = e.target
        if (value > 0 || value == 0) {
            const updatedSizes = {
                ...sizes,
                [name]: Number(value)
            }
            setSizes(updatedSizes)
            setTotalPrice(Object.values(updatedSizes).reduce((a, b) => a + b, 0) * (type === 'tshirt' ? tshirtPrice : jumperPrice))
            setTotalPrice2(Object.values(updatedSizes).reduce((a, b) => a + b, 0) * (type === 'tshirt' ? tshirtPrice2 : jumperPrice2))
        }
    }

    useEffect(() => {
        if (props.request) {
            setSizes(props.request.sizes)
            setName(props.request.name)
            setEmail(props.request.email)
            setPhone(props.request.phone)
            setComment(props.request.comment)
            setType(props.request.type)
            setTotalPrice(Object.values(props.request.sizes).reduce((a, b) => a + b, 0) * (props.request.type === 'tshirt' ? tshirtPrice : jumperPrice))
            setTotalPrice2(Object.values(props.request.sizes).reduce((a, b) => a + b, 0) * (props.request.type === 'tshirt' ? tshirtPrice2 : jumperPrice2))
        } else {
            setSizes({
                XS: 0,
                S: 0,
                M: 0,
                L: 0,
                XL: 0,
                "2XL": 0,
                "3XL": 0,
                "4XL": 0,
                "5XL": 0,
            })
            setName('')
            setEmail('')
            setPhone('')
            setComment('')
            setType('')
            setTotalPrice(0)
            setTotalPrice2(0)
        }
    }, [props.request])

    const sizeInput = (size) => {
        return <div className='jumper-size-wrapper'>
            <label className='jumper-size-label'>{size}</label>
            <input
                value={sizes[size]}
                name={size}
                step={1}
                onChange={e => updateSize(e)}
                className='jumper-size-input'
                type='Number'
            />
        </div>
    }

    const submitRequest = async (e) => {
        e.preventDefault()
        setDisabled(true)
        const requestBody = {
            name,
            email,
            phone,
            comment,
            totalPrice,
            sizes,
            type,
        }
        try {
            if (props.request) {
                requestBody._id = props.request._id
                await props.updateRequest(requestBody)

            } else {
                const { data } = await axios.post('api/jumpers', requestBody)
                setSubmited(true)
                setTimeout(() => { setSubmited(false) }, 5000)
            }
            setSizes({
                XS: 0,
                S: 0,
                M: 0,
                L: 0,
                XL: 0,
                "2XL": 0,
                "3XL": 0,
                "4XL": 0,
                "5XL": 0,
            })
            setName('')
            setEmail('')
            setPhone('')
            setComment('')
            setType('')
            setTotalPrice(0)
            setTotalPrice2(0)
            setDisabled(false)

        } catch (err) {
            console.log(err)
        }
    }
    console.log(type)
    return (
        <Form onSubmit={submitRequest}>
            <div className='jumper-card-wrapper'>
                <div className='jumper-card'>
                    <div className='jumper-header-wrapper'>
                        <div className='jumper-header'>
                            <span>Pré-Commandes PROMÔ</span>
                        </div>
                        <div className='jumper-image-wrapper'>
                            <img
                                style={{ width: '100%' }}
                                src={Avacado} />
                        </div>
                    </div>
                    <div className='jumper-form'>
                        <input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            className='jumper-input'
                            placeholder="Nom et prénom"
                            required
                        />
                        <input
                            type='email'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className='jumper-input'
                            placeholder="E-mail"
                            required
                        />
                        <input
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            className='jumper-input'
                            placeholder="Téléphone"
                        />
                        <div>Sweat ou T-shirt ?</div>
                        <div className='jumper-icons-wrapper'>
                            <div
                                className={`jumper-icons-selector ${type === 'jumper' && "jumper-icons-selected"}`}
                                onClick={() => {
                                    setType('jumper');
                                    setTotalPrice(Object.values(sizes).reduce((a, b) => a + b, 0) * (jumperPrice))
                                    setTotalPrice2(Object.values(sizes).reduce((a, b) => a + b, 0) * (jumperPrice2))
                                }}
                            >
                                <img className='jumper-icons' src={Jumper} />
                            </div>
                            <div
                                className={`jumper-icons-selector ${type === 'tshirt' && "jumper-icons-selected"}`}
                                onClick={() => {
                                    setType('tshirt');
                                    setTotalPrice(Object.values(sizes).reduce((a, b) => a + b, 0) * (tshirtPrice))
                                    setTotalPrice2(Object.values(sizes).reduce((a, b) => a + b, 0) * (tshirtPrice2))
                                }}
                            >
                                <img className='jumper-icons' src={Tshirt} />
                            </div>
                        </div>
                        <div className='jumper-estimate'>
                            <span className='jumper-info'><FontAwesomeIcon icon={faInfoCircle} />
                                <span className='jumper-info-arrow'></span>
                                <span className='jumper-info-tooltip'>Le prix évolue en fonction du nombre de commandes. <br />Une fois la phase de pré-commandes terminée, vous recevrez le prix final par e-mail.</span>
                            </span>
                            <span>Prix approximatif entre: {new Number(totalPrice).toLocaleString('fr-FR', { style: "currency", currency: "EUR" })} - {new Number(totalPrice2).toLocaleString('fr-FR', { style: "currency", currency: "EUR" })}</span>
                        </div>
                        <div className='jumper-sizes'>
                            {sizeLables.map(e => sizeInput(e))}
                        </div>
                        <textarea
                            value={comment}
                            onChange={e => setComment(e.target.value)}
                            className='jumper-input'
                            type='textarea'
                            placeholder="Commentaire..."
                        ></textarea>
                    </div>
                    {submited && <Alert >Votre demande est bien reçue !</Alert>}
                    <div className='jumper-button-wrapper'>
                        <button className='jumper-button' type='submit' disabled={disabled}>Pré-commander</button>
                    </div>
                </div>
            </div>
        </Form >
    )
}

export default JumperRegistration