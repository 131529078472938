import React, { Component } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Container,
	Button,
	Row,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEmployees } from '../../actions/employeeActions';
import { getTasks } from '../../actions/taskActions';
import { updateReport, getReport } from '../../actions/reportActions';
import ChangeTaskModal from './ChangeTaskModal';

class UpdateReport extends Component {
	state = {
		description: '', // It is used to take input from user
		numberOfHours: '', // It is used to take input from users
		msg: '', // It will show the respond from the backend to user.
		reason: '',
		reportdate: null,
		taskModal: false,
	};
	componentDidMount() {
		this.props.getReport(this.props.match.params.id);
		this.props.getEmployees();
		this.props.getTasks();
	}
	onChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};
	componentDidUpdate(prevProps) {
		const { report } = this.props;
		if (report.message !== prevProps.report.message) {
			this.setState({ msg: report.message });
		}
		if (report.report !== prevProps.report.report) {
			this.setState({
				description: report.report.description,
				numberOfHours: report.report.numberOfHours,
				reportdate: report.report.reportdate,
			});
		}
	}
	onSubmit = (e) => {
		e.preventDefault();
		const { report } = this.props.report; // It is the report that we get from getReport function for editing.
		const updates = {
			// added to capture changes
			description: report.description,
			numberOfHours: report.numberOfHours,
			reason: this.state.reason,
			date: new Date(),
			updatedBy: this.props.auth.user.id,
		};
		report.reportdate = this.state.reportdate
		report.description = this.state.description; // Here it just change the description to the new input.
		report.numberOfHours = Number(this.state.numberOfHours);
		report.reportHistory = [...report.reportHistory, updates]; //changes logged in reportHistory for future access
		this.props.updateReport(report._id, report);
	};

	toggleChangeTaskModal = () =>
		this.setState({ taskModal: !this.state.taskModal });

	// onChangeProject = (e) => {
	// 	const project = this.props.project.projects.find((project) => project._id === e.target.value);
	// 	// const proposal_id = project ? project.proposal_id : '';
	// 	// const proposalProject = project ? project.proposal : false; //used to indentify the special proposal project
	// 	console.log('name', e.target.name)
	// 	console.log('value', e.target.value)
	// 	this.setState({
	// 		[e.target.name]: e.target.value,
	// 		// proposal_id,
	// 		// proposalProject,
	// 	});
	// }


	render() {
		const { employees } = this.props.employee;
		const { tasks } = this.props.task;
		const { report } = this.props.report;
		const taskName = tasks.filter((task) => task._id === report.task_id).map((task) => task.name);
		const curEmployee = employees
			.filter((employee) => employee.user_id === report.user_id)
		const pic = curEmployee.map((employee) => employee.name);
		// TODO: set correcly
		const isExecutive = true

		return (
			<Container>
				{this.state.taskModal && <ChangeTaskModal
					modal={this.state.taskModal}
					toggle={this.toggleChangeTaskModal}
					report={report}
					tasks={tasks}
					pic={curEmployee[0]}
				/>}
				<Card
					style={{
						width: '70%',
						margin: 'Auto',
						marginBottom: '2rem',
					}}>
					<CardHeader>
						<strong>Report ID: {report._id}</strong>
					</CardHeader>
					<CardBody>
						<Form onSubmit={this.onSubmit}>
							<Row xs='2'>
								<Col>
									<FormGroup>
										<Label>Report Date</Label>
										{this.props.auth.user.permissionLevel === "Executive" ?
											<Input
												type='date'
												name="reportdate"
												onChange={this.onChange.bind(this)}
												defaultValue={this.state.reportdate ? this.state.reportdate.substring(0, 10) : ''}
											/> :
											<Input
												type='date'
												name="reportdate"
												onChange={this.onChange.bind(this)}
												defaultValue={this.state.reportdate ? this.state.reportdate.substring(0, 10) : ''}
												disabled
											/>
										}
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label>PIC</Label>
										<Input type='text' placeholder={pic} disabled />
									</FormGroup>
								</Col>
							</Row>
							<FormGroup>
								<Label>Task</Label>
								<Input type='text' placeholder={taskName} onClick={this.toggleChangeTaskModal} disabled={!isExecutive} />
							</FormGroup>
							<FormGroup>
								<Label>Description</Label>
								<Input
									id='descriptionBox'
									type='textarea'
									name='description'
									defaultValue={this.state.description}
									onChange={this.onChange.bind(this)}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label>Number Of Hours</Label>
								<Input
									step={0.5}
									type='number'
									name='numberOfHours'
									defaultValue={this.state.numberOfHours}
									onChange={this.onChange.bind(this)}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label>Change Reason</Label>
								<Input
									type='textarea'
									name='reason'
									placeholder='Describe the Reason for Change'
									defaultValue={this.state.reason}
									onChange={this.onChange.bind(this)}
									required
								/>
							</FormGroup>
							<Button
								color='dark'
								style={{
									marginTop: '2rem',
								}}
								block>
								Update Report
							</Button>
						</Form>
						<br />
						{this.state.msg ? (
							<Alert color='primary' style={{ textAlign: 'center' }}>
								{this.state.msg}
							</Alert>
						) : null}
					</CardBody>
				</Card>
			</Container>
		);
	}
}
const mapStateToProps = (state) => ({
	report: state.reportR,
	employee: state.employeeR,
	task: state.taskR,
	auth: state.authR,
});
export default connect(mapStateToProps, { updateReport, getReport, getEmployees, getTasks })(
	UpdateReport
);
