import React, { Component } from 'react';
import { Container, Row, Col, Table, Button, Progress, Tooltip, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { getTasks, toggleTaskIndex, toggleReviewIndex } from '../../actions/taskActions';
import { getProjects, toggleProjectIndex } from '../../actions/projectActions';
import { getReports } from '../../actions/reportActions';
import { getEmployee, getEmployees, uploadProfilePhoto } from '../../actions/employeeActions';
import { getCompany } from '../../actions/companyActions';
import PropTypes from 'prop-types';
import SendForReview from '../taskComponents/SendForReview';
import ProjectCalendar from '../projectComponents/ProjectCalendar';
import ReportModal from '../reportComponents/reportModal';
import ProjectDetails from '../projectComponents/ProjectDetails';
import UserProposal from '../proposalComponent/UserProposal';
import DetailsCard from './DetailsCard';
import ProgressChart from './ProgressChart';
import UserReward from '../rewardComponents/UserReward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import AnnouncementMessage from './announcement/AnnoucementMessage';

class UserPage extends Component {
	state = {
		tooltip: false,
		showRewards: false,
		employee: [],
	};
	componentDidMount() {
		this.props.getCompany();
		this.props.getTasks();
		this.props.getReports();
		this.props.getEmployees();
		this.props.getEmployee();
		this.props.getProjects();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.employee.employee !== this.props.employee.employee) {
			this.setState({ employee: this.props.employee.employee[0] });
		}
	}

	toggle = () =>
		this.setState({
			tooltip: !this.state.tooltip,
		});
	toggleReward = () => {
		this.setState({
			showRewards: !this.state.showRewards,
		});
	};

	render() {
		const id = this.props.match.params.id ? this.props.match.params.id : this.props.auth.user.id;
		const { employees, loading } = this.props.employee;
		const { projects } = this.props.project;
		const { tasks } = this.props.task;
		const completedTasks = tasks.filter(
			({ status, user_id }) => status === 'Completed' && user_id === id
		);
		var userPoints = 0;
		completedTasks.forEach((task) => (userPoints = userPoints + task.points));
		const userGolds = String(
			employees.filter((employee) => employee.user_id === id).map((employee) => employee.golds)
		);
		return projects ? (
			<Container>
				<ProjectDetails />
				<SendForReview />
				<Row style={{ marginBottom: '2rem', position: 'relative' }}>
					<div
						style={{
							position: 'absolute',
							zIndex: 1000,
							right: '20px',
							marginTop: '-25px',
						}}>
						{loading ? <Spinner color='primary' style={{ width: '3rem', height: '3rem' }} /> : null}
					</div>

					<Col md='12'>
						<AnnouncementMessage />
					</Col>

					<Col xs='4'>
						<DetailsCard employees={employees} match={this.props.match} id={id} />
					</Col>
					<Col xs='8'>
						<h4 style={{ marginTop: '0.5rem' }}>
							<strong>
								{employees.map((employee) => {
									if (employee.user_id === id) {
										return employee.name;
									}
								})}
							</strong>
						</h4>
						<span>
							<Progress id='Tooltip' animated color='primary' value={userPoints / 10} max={100}>
								<span>{userPoints / 100}</span>
							</Progress>
							<Tooltip
								placement='bottom'
								isOpen={this.state.tooltip}
								target='Tooltip'
								toggle={this.toggle}>
								<span>Level: {Math.floor(userPoints / 10)}</span>
								<br /> {/* Each Level will be equal to 100 EXP or in other words 10 UserPoints */}
								<span>Points: {userPoints}</span>
								<br /> {/* Each point worth 10 EXP   */}
								<span>Golds: {userGolds ? userGolds : 0}</span>
							</Tooltip>
						</span>
						<br />
						<ProgressChart id={id} completedTasks={completedTasks} />
					</Col>
				</Row>
				<br />
				{this.props.match.params.id ? null : <ReportModal />}
				<Button
					onClick={this.toggleReward}
					color='primary'
					style={{
						padding: '2px',
						paddingRight: '6px',
						paddingLeft: '6px',
						marginLeft: '10px',
					}}>
					My Rewards
					<FontAwesomeIcon size='lg' style={{ marginLeft: '5px' }} icon={faAward}></FontAwesomeIcon>
				</Button>
				{this.state.showRewards ? <UserReward employee={this.state.employee} /> : null}
				<h5 style={{ marginTop: '0.5rem' }}>
					<center>
						<b>My Tasks</b>
					</center>
				</h5>
				<Table size='sm'>
					<thead>
						<tr>
							<th>Name</th>
							<th>Project</th>
							<th>Department</th>
							<th>Status</th>
							<th>Deadline</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{tasks
							.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
							.map(
								(
									{ _id, name, department, status, deadline, user_id, project_id, proposal_id },
									index
								) => {
									if (user_id === id && status !== 'Completed' && status !== 'Cancelled') {
										const projectName = projects
											.filter((project) => project._id === project_id)
											.map((project) => {
												if (proposal_id) {
													return this.props.proposal.proposals
														.filter((proposal) => proposal._id === proposal_id)
														.map((proposal) => proposal.proposalNumber);
												}
												return project.name;
											});
										return (
											<tr key={_id}>
												<td>
													<a href='#' onClick={this.props.toggleTaskIndex.bind(this, _id)}>
														{name}
													</a>
												</td>
												<td>{projectName}</td>
												<td>
													{this.props.company.company.departments
														? this.props.company.company.departments.find(
																({ id }) => id === department
														  ).name
														: null}
												</td>
												<td>{status}</td>
												<td>{deadline ? deadline.substring(0, 10) : ''}</td>
												{this.props.match.params.id ? null : (
													<td>
														{status !== 'Review' ? (
															<Button
																className='remove-btn'
																color='primary'
																size='sm'
																onClick={this.props.toggleReviewIndex.bind(
																	this,
																	index,
																	this.props.task.reviewModal
																)}>
																Review
															</Button>
														) : (
															'Pending'
														)}
													</td>
												)}
											</tr>
										);
									} else {
										return null;
									}
								}
							)}
					</tbody>
				</Table>
				{this.props.auth.user.proposal ? <UserProposal /> : null}
				<h5 style={{ marginTop: '0.5rem' }}>
					<center>
						<b>Project Calendar</b>
					</center>
				</h5>
				<ProjectCalendar user_id={id} />
			</Container>
		) : null;
	}
}

UserPage.propTypes = {
	getTasks: PropTypes.func.isRequired,
	getEmployee: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	getReports: PropTypes.func.isRequired,
	getEmployees: PropTypes.func.isRequired,
	getProjects: PropTypes.func.isRequired,
	uploadProfilePhoto: PropTypes.func.isRequired,
	employee: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	toggleTaskIndex: PropTypes.func.isRequired,
	toggleReviewIndex: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR,
	report: state.reportR,
	employee: state.employeeR,
	auth: state.authR,
	company: state.companyR,
	project: state.projectR,
	proposal: state.proposalR,
});

export default connect(mapStateToProps, {
	toggleTaskIndex,
	toggleReviewIndex,
	getTasks,
	getReports,
	getEmployee,
	getEmployees,
	getCompany,
	getProjects,
	uploadProfilePhoto,
	toggleProjectIndex,
})(UserPage);
