import {
    GET_DETAILS, CREATE_DETAIL, DELETE_DETAIL, UPDATE_DETAIL, GET_SPECIFIC_DETAILS,
    REDUCE_INPUT_BOX_SIZE, EXPAND_INPUT_BOX_SIZE, DELETE_DETAILS, STORE_DETAIL_IN_REVISION,
}
    from '../actions/types'
const initailState = {
    details: [],
    detail: [],
    inputBox: { "inputWidth": null, "inputIndex": null }
}
const detailReducer = function (state = initailState, action) {
    switch (action.type) {
        case GET_DETAILS:
            return {
                ...state,
                details: action.payload.sort((a, b) => a.stageNumber - b.stageNumber) //To sort based on the stage Number
            }
        case GET_SPECIFIC_DETAILS:
            return {
                ...state,
                details: action.payload.sort((a, b) => a.stageNumber - b.stageNumber)
            }
        case CREATE_DETAIL:
            return {
                ...state,
                details: [...state.details, action.payload]
            }
        case DELETE_DETAIL:
            return {
                ...state,
                details: state.details.filter(({ _id }) => _id !== action.payload)
            }
        case DELETE_DETAILS:
            return {
                ...state,
                details: state.details.filter(({ proposal_id }) => proposal_id !== action.payload)
            }
        case UPDATE_DETAIL:
        case STORE_DETAIL_IN_REVISION:
            return {
                ...state,
                details: state.details.map((e) => e._id === action.payload._id ? action.payload : e)
            }
        case REDUCE_INPUT_BOX_SIZE:
            return {
                ...state,
                inputBox: { "inputWidth": null, "inputIndex": null }
            }
        case EXPAND_INPUT_BOX_SIZE:
            return {
                ...state,
                inputBox: { "inputWidth": "100px", "inputIndex": action.payload }
            }

        default:
            return state;
    }
}
export default detailReducer