import React, { Fragment } from 'react';
import { Card, CardBody, CardFooter, ListGroup, ListGroupItem, CardImg } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import ProfilePhoto from './profilePhoto';
import UserModal from './UserModal';

const DetailsCard = (props) => {
	return (
		<Fragment>
			<Card>
				<div
					style={{
						position: 'relative',
						borderBottom: '1px solid rgba(0,0,0,.125)',
					}}>
					<CardImg
						top
						className='round-img'
						src={props.employees
							.map((employee) => {
								if (employee.user_id === props.id) {
									return employee.photo;
								}
							})
							.join('')}
					/>
					<div style={{ position: 'absolute', right: '24px', bottom: '-20px' }}>
						{props.match.params.id ? null : <ProfilePhoto />}
					</div>
				</div>
				<CardBody>
					<ListGroup flush>
						<center>
							<ListGroupItem>
								<strong>Employee ID</strong>
								<br />
								{props.id}
							</ListGroupItem>
							<ListGroupItem>
								<strong>Email</strong>
								<br />
								{props.employees.map((employee) => {
									if (employee.user_id === props.id) {
										return employee.email;
									}
								})}
							</ListGroupItem>
							<ListGroupItem>
								<strong>Phone</strong>
								<br />
								{props.employees.map((employee) => {
									if (employee.user_id === props.id) {
										return employee.phonenumber;
									}
								})}
							</ListGroupItem>
							<ListGroupItem>
								<strong>Department</strong>
								<br />
								{props.employees.map((employee) => {
									if (employee.user_id === props.id) {
										return employee.department;
									}
								})}
							</ListGroupItem>
						</center>
					</ListGroup>
				</CardBody>
				<a href='/rewardShop' style={{ margin: '0 auto' }}>
					<b
						style={{
							fontSize: '1.1rem',
						}}>
						Reward Shop
					</b>
					<FontAwesomeIcon style={{ fontSize: '1.5rem', color: '#42adf5' }} icon={faCartPlus} />
				</a>
				<CardFooter>{props.match.params.id ? null : <UserModal />}</CardFooter>
			</Card>
		</Fragment>
	);
};

export default DetailsCard;
