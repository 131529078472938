import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { commentFeedback, deleteFeedbackComment } from '../../actions/feedbacksActions';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Label,
	Input,
	Row,
	Col,
	Collapse,
	CardBody,
	Card,
	CardHeader,
	Media,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const FeedbackDetails = ({
	reduxCompanies,
	reduxUsers,
	feedback,
	employees,
	user,
	openModal,
	closeModal,
}) => {
	const dispatch = useDispatch();

	const [comment, setComment] = useState('');
	const [collapse, setCollapse] = useState(false);

	const idGenerator = () => {
		// The function generates Id consist of numbers and letters
		return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2); // The ID length is 22 eg.. 8hfhals5fkd
	};
	const submitComment = (feedback_id, user_id) => {
		var uniqueID = idGenerator(); // comment ID
		if (comment) {
			var newComment = {
				id: uniqueID, // comment Id
				comment: comment, // Comment text
				user_id: user.id, // the commenter user_id
				isDeleted: false,
				date: new Date(),
			};

			dispatch(commentFeedback(newComment, feedback_id));
			setComment(''); // set to empty
		}
	};

	const deleteComment = (comment_id, comment, user_id, comment_date, feedback_id) => {
		const deleteComment = {
			id: comment_id, // Comment Id
			comment: comment, // The comment text
			user_id: user_id, // The commenter
			date: comment_date,
			isDeleted: true, // It deletes the comment
		};
		dispatch(deleteFeedbackComment(deleteComment, feedback_id));
	};

	const toggle = () => {
		setCollapse(!collapse);
	};

	return feedback ? (
		<Fragment>
			<Modal isOpen={openModal} toggle={closeModal} className='modal-lg'>
				<ModalHeader toggle={closeModal}>
					<strong>Edit Feedback & Bugs </strong>
				</ModalHeader>
				<ModalBody>
					<Row xs='2'>
						<Col>
							<FormGroup>
								<Label>Feedback ID</Label>
								<Input type='text' defaultValue={feedback._id} disabled></Input>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Company</Label>
								<Input
									type='text'
									defaultValue={reduxCompanies
										.filter((company) => company._id === feedback.company_id)
										.map((company) => company.companyname)}
									disabled></Input>
							</FormGroup>
						</Col>
					</Row>
					<Row xs='2'>
						<Col>
							<FormGroup>
								<Label>Submitted By</Label>
								<Input
									type='text'
									defaultValue={reduxUsers
										.filter(
											(user) =>
												user._id === feedback.user_id && user.company_id === feedback.company_id
										)
										.map((user) => user.email)}
									disabled
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Status</Label>
								<Input type='text' defaultValue={feedback.status && feedback.status} disabled />
							</FormGroup>
						</Col>
					</Row>
					<Row xs='2'>
						<Col>
							<FormGroup>
								<Label>Title</Label>
								<Input type='text' defaultValue={feedback.title && feedback.title} disabled />
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Submitted Date</Label>
								<Input
									type='text'
									defaultValue={feedback.createdAt ? feedback.createdAt.substring(0, 10) : null}
									disabled
								/>
							</FormGroup>
						</Col>
					</Row>

					<FormGroup>
						<Label>Description</Label>
						<Input
							type='textarea'
							id='descriptionBox'
							defaultValue={feedback.description && feedback.description}
							disabled></Input>
					</FormGroup>

					<Card>
						<CardHeader onClick={toggle}>
							Comments {collapse ? <Button close /> : <Button close>&#x2B;</Button>}
						</CardHeader>
						<Collapse isOpen={collapse}>
							<CardBody>
								{feedback.comments &&
									feedback.comments
										.filter((e) => !e.isDeleted)
										.map(({ comment, id, user_id, date }, index) => {
											return (
												<Card
													key={index}
													style={{
														marginBottom: '1rem',
														borderColor: '#6c757d',
													}}>
													<CardHeader
														style={{
															padding: '1px',
															background: '#6c757d',
														}}
														color='primary'>
														<Media style={{ alignItems: 'center' }}>
															<Media style={{ maxWidth: '5%', verticalAlign: 'center' }}>
																<Media
																	style={{ width: '100%', height: 'auto' }}
																	src={employees
																		.filter((employee) => employee.user_id === user_id)
																		.map((employee) => employee.photo && employee.photo)}
																	alt='Profile Photo'
																/>
															</Media>
															<Media
																body
																style={{
																	paddingLeft: '1rem',
																	color: 'white',
																}}>
																<strong>
																	{employees
																		.filter((employee) => employee.user_id === user_id)
																		.map((employee) => employee.name)}
																</strong>
															</Media>
															<p
																style={{
																	color: '#ededed',
																	fontSize: 'small',
																	paddingRight: '0.5rem',
																}}>
																{date ? date.substring(0, 10) : null}
															</p>
														</Media>
													</CardHeader>
													<CardBody>
														<p style={{ whiteSpace: 'pre-wrap' }}>{comment}</p>
														{user.id === user_id ? (
															<a href='#'>
																<FontAwesomeIcon
																	style={{
																		color: 'red',
																		fontSize: '1.3rem',
																		position: 'absolute',
																		right: '10px',
																		bottom: '7px',
																	}}
																	onClick={() => {
																		deleteComment(id, comment, user_id, date, feedback._id);
																	}}
																	icon={faTrashAlt}
																/>
															</a>
														) : null}
													</CardBody>
												</Card>
											);
										})}
								<Input
									type='textarea'
									name='comment'
									onChange={({ target }) => setComment(target.value)}
									placeholder='Comment here'
									value={comment}
								/>
								<Button
									color='secondary'
									size='sm'
									style={{
										float: 'right',
										marginTop: '0.5rem',
										marginBottom: '0.5rem',
									}}
									onClick={submitComment.bind(this, feedback._id, feedback.user_id)}>
									Submit
								</Button>
							</CardBody>
						</Collapse>
					</Card>
				</ModalBody>
			</Modal>
		</Fragment>
	) : null;
};

export default FeedbackDetails;
