import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input, Button } from 'reactstrap';
import { getStages, createStage, deleteStage, updateStage } from '../../actions/stageActions';
import ConfirmButton from '../userComponents/ConfirmButton';

const UpdateStages = props => {
	const [stages, setStages] = useState([]);
	const [updatedStages, setUpdatedStages] = useState([]); // THe updated stages will be stored here.
	const [description, setDescription] = useState(''); // The description of the new stage.
	const [hideNewRow, toggleRow] = useState(true); // It controls the visiblity of the row for adding a new stage. 

	const reduxStages = useSelector((state) => state.stageR.stages);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getStages());
	}, []);
	// It will be fired if the edit is cancelled. To get back the initial data.
	useEffect(() => {
		dispatch(getStages());
	}, [props.cancelEdit])

	useEffect(() => {
		setStages(
			[...reduxStages.filter(({ proposalNumber }) => proposalNumber === props.proposal.proposalNumber)]
		);
	}, [reduxStages, props.proposal]);

	useEffect(() => {  // firing the update stages function.  
		updatedStages.forEach((stage) => dispatch(updateStage(stage, stage._id)));
	}, [props.fireUpdateFunction]);

	const updateStages = (index, { target }) => {
		stages[index][target.name] = target.name === 'stage' ? Number(target.value) : target.value;
		updatedStages[index] = stages[index]; // Pushing the updated stage to the new array.
		setUpdatedStages(updatedStages);
		setStages(stages);
	};

	const removeStage = id => {
		dispatch(deleteStage(id));
	};
	const addStage = () => {
		if (description) {
			const newStage = {
				stage: stages[stages.length - 1] ? stages[stages.length - 1].stage + 1 : 1,
				description: description,
				proposalNumber: props.proposal.proposalNumber,
				revisionNumber: props.proposal.revisionNumber, // The revision number will corresponds to the proposal current revision number.
			};
			dispatch(createStage(newStage));
			setDescription('');
			return toggleRow(true);
		}
		return alert('Description is required!');
	};
	const addStageButton = (
		<span
			style={{
				fontSize: '1.5rem',
				position: 'relative',
				top: '0.2rem',
				left: props.editMode ? '20px' : '6px',
				cursor: 'pointer',
			}}
			onClick={() => toggleRow((hideNewRow) => !hideNewRow)}>
			&#x271A;
		</span>
	);
	return (
		<Table>
			<thead>
				<tr>
					<th width='10%'>Stage</th>
					<th width='80'>Description</th>
					<th width='5%'></th>
					<th width='5%'>
						{props.proposal.status !== 'Completed'
							? stages.length // For preventing error
								? stages[0].revisionNumber === props.currentRevisionNum
									? addStageButton
									: null
								: addStageButton
							: null}
					</th>
				</tr>
			</thead>
			<tbody>
				{
					stages.map((stage, index) => {
						return (
							<tr key={stage._id}>
								<td width='15%'>
									{props.editMode ? (
										<Input
											type='number'
											defaultValue={stage.stage}
											name='stage'
											onChange={(e) => updateStages(index, e)}
										/>
									) : stage.revisionNumber === props.currentRevisionNum ? (
										stage.stage
									) : (
												stage.revision
													.filter(({ revisionNumber }) => revisionNumber === props.currentRevisionNum)
													.map((e) => e.stage)
											)}
								</td>
								<td width='75%'>
									{props.editMode ? (
										<Input
											type='text'
											defaultValue={stage.description}
											name='description'
											onChange={(e) => updateStages(index, e)}
										/>
									) : props.currentRevisionNum === stage.revisionNumber ? (
										stage.description
									) : (
												stage.revision
													.filter(({ revisionNumber }) => revisionNumber === props.currentRevisionNum)
													.map((e) => e.description)
											)}
								</td>
								<td width='5%'></td>
								<td width='5%'>
									{props.editMode ? (
										props.currentRevisionNum === stage.revisionNumber ? (
											<ConfirmButton
												alertTitle={'Remove this Stage?'}
												size='sm'
												color='danger'
												onClick={() => removeStage(stage._id)}
											>
												&#10005;
											</ConfirmButton>
										) : null
									) : null}
								</td>
							</tr>
						);
					})}
				{
					/*        The table row for adding a new stage        */

					<Fragment>
						<tr className='table-active' hidden={hideNewRow}>
							<td width='10%'>
								{stages[stages.length - 1] ? stages[stages.length - 1].stage + 1 : 1}
							</td>
							<td width='80%'>
								<Input
									style={{ marginRight: '0px', position: 'relative', right: '1rem' }}
									type='textarea'
									name='stageDescription'
									value={description}
									onChange={({ target }) => setDescription(target.value)}
									required
								/>
							</td>
							<td width='5%'>
								<Button
									size='sm'
									color='success'
									style={{
										marginTop: '30px',
									}}
									onClick={addStage}>
									&#10003;
								</Button>
								{/* It is the thick sign (✔) in new stage row   */}
							</td>
							<td width='5%'>
								<Button
									color='danger'
									size='sm'
									style={{
										marginTop: '30px',
									}}
									onClick={() => toggleRow((hideNewRow) => !hideNewRow)}>
									&#10005; {/* It is the cross sign (x) in new stage row   */}
								</Button>
							</td>
						</tr>
					</Fragment>
				}
			</tbody>
		</Table>
	);
};
export default UpdateStages;
