import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Container,
	Form,
	FormGroup,
	Label,
	Input,
	CardBody,
	Card,
	Button,
	Alert,
	Col,
} from 'reactstrap';
import Image from 'react-bootstrap/Image';
import { createFeedback } from '../../actions/feedbacksActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

class FeedbacksAndBugs extends Component {
	state = {
		title: '',
		description: '',
		msg: '',
		file: '',
		photo: '',
		dragFocus: false,
		email: '',
		name: '',
		company: '',
		feedbackdate: new Date(),
		status: '',
		company_shortname: '',
	};

	componentDidUpdate(prevProps) {
		if (this.props.feedback.feedbacks !== prevProps.feedback.feedbacks) {
			this.setState({
				msg: 'Feedback Added',
			});
			this.setState({
				title: '',
				description: '',
				photo: '',
			});
		} else if (this.props.error.message !== prevProps.error.message) {
			this.setState({ msg: 'Adding feedback failed' });
			this.setState({
				title: '',
				description: '',
				photo: '',
			});
		}
	}

	onChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			if (file.size > 2097152 || file.type.substr(0, 5) !== 'image') {
				return this.setState({
					msg: 'Requires Image File below 2mb',
				});
			}
			this.setState({
				photo: URL.createObjectURL(file),
				file: file,
			});
		}
	};

	focus = (e) => {
		this.setState({
			dragFocus: true,
		});
	};

	unfocus = (e) => {
		this.setState({
			dragFocus: false,
		});
	};

	onSubmit = (e) => {
		e.preventDefault();
		if (!this.state.title || !this.state.description)
			return this.setState({ msg: 'Please enter all fields' });
		const newFeedback = {
			title: this.state.title,
			description: this.state.description,
			company_id: this.props.auth.user.company_id,
			user_id: this.props.auth.user.id,
			feedbackdate: this.state.feedbackdate,
			status: 'Pending',
			email: this.props.auth.user.email,
		};

		if (this.state.file) {
			var reader = new FileReader();
			reader.readAsDataURL(this.state.file);
			reader.onloadend = (e) => {
				newFeedback.photo = e.target.result;
				this.props.createFeedback(newFeedback);
			};
		} else {
			this.props.createFeedback(newFeedback);
		}
		this.setState({
			email: '',
			name: '',
			company: '',
			status: '',
			company_shortname: '',
		});
	};

	onChangeText = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	render() {
		return (
			<Container>
				<Col xs='6' md='6' style={{ margin: '0 auto' }}>
					<h3 style={{ textAlign: 'center', paddingBottom: '2rem', color: '#558DC4' }}>
						Feedback and Bugs Portal
					</h3>
					<Card>
						<CardBody>
							{this.state.msg ? (
								<Alert color='info' style={{ textAlign: 'center' }}>
									{this.state.msg}
								</Alert>
							) : null}

							<Form onSubmit={this.onSubmit}>
								<FormGroup>
									<Label>Add Image</Label>
									<Label
										htmlFor='photo'
										onMouseOver={this.focus}
										onMouseOut={this.unfocus}
										onDragEnter={this.focus}
										onDragLeave={this.unfocus}
										className='focusFile'
										style={{
											width: '100%',
											minHeight: '8px',
											maxHeight: '50%',
											height: 'auto',
											display: 'block',
											lineHeight: '4rem',
											border: '1px solid rgba(0,0,0,.125)',
											boxShadow: this.state.dragFocus ? '0 0 0 0.2rem rgba(38,143,255,.5)' : 'none',
											borderRadius: '5px',
											left: 0,
											top: 0,
											textAlign: 'center',
											cursor: 'pointer',
											position: 'relative',
										}}>
										<Input
											id='photo'
											type='file'
											name='photo'
											accept='image/*'
											onChange={this.onChange}
											style={{
												width: '100%',
												maxHeight: '200px',
												opacity: '0',
												position: 'absolute',
												top: 0,
												left: 0,
												overflow: 'hidden',
											}}
										/>
										{this.state.photo ? (
											<Image top='true' width='100%' src={this.state.photo} fluid />
										) : (
											<FontAwesomeIcon
												color='rgba(0,0,0,.125)'
												style={{
													fontSize: this.state.dragFocus ? '3rem' : '2rem',
													verticalAlign: 'middle',
												}}
												icon={faUpload}
											/>
										)}
									</Label>

									<Label>About</Label>
									<Input
										type='text'
										value={this.state.title}
										name='title'
										onChange={(e) => this.onChangeText(e)}
										required
									/>
								</FormGroup>
								<FormGroup>
									<Label>Description</Label>
									<Input
										rows={7}
										type='textarea'
										value={this.state.description}
										name='description'
										onChange={(e) => this.onChangeText(e)}
										required
									/>
								</FormGroup>
								<Button color='dark' block>
									Submit
								</Button>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Container>
		);
	}
}

FeedbacksAndBugs.propTypes = {
	createFeedback: PropTypes.func.isRequired,
	feedback: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.authR,
	company: state.companyR,
	feedback: state.feedbackR,
	error: state.errorR,
	companies: state.companiesR,
});
export default connect(mapStateToProps, { createFeedback })(FeedbacksAndBugs);
