import {
    CREATE_STAGE, GET_STAGE, GET_STAGES, DELETE_STAGE, UPDATE_STAGE,
    DELETE_STAGES, STORE_STAGE_IN_REVISION, UPDATE_STAGE_FEE
}
    from "../actions/types";

const initailState = {
    stages: [],
    stage: {},
    message: null,
}

const proposalReducer = function (state = initailState, action) {
    switch (action.type) {
        case GET_STAGES:
            return {
                stages: action.payload.sort((a, b) => a.stage - b.stage)
            }
        case CREATE_STAGE:
            return {
                ...state,
                stages: [...state.stages, action.payload]
            }
        case DELETE_STAGE:
            return {
                ...state,
                stages: state.stages.filter(({ _id }) => _id !== action.payload)
            }
        case DELETE_STAGES:
            return {
                ...state,
                stages: state.stages.filter(({ proposalNumber }) => proposalNumber !== action.payload)
            }
        case UPDATE_STAGE:
        case UPDATE_STAGE_FEE:
        case STORE_STAGE_IN_REVISION:
            return {
                ...state,
                stages: state.stages.map((e) => e._id === action.payload._id ? action.payload : e)
            }
        default:
            return state;
    }
}
export default proposalReducer