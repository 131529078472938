import {
	CREATE_PROPOSAL,
	GET_PROPOSALS,
	GET_PROPOSAL,
	TOGGLE_PROPOSAL_MODAL,
	CREATE_CLIENT,
	GET_PROPOSAL_IN_MODAL,
	UPDATE_PROPOSAL,
	SEND_FOR_REVIEW,
	DELETE_PROPOSAL,
	APPROVE_PROPOSAL,
	REJECT_PROPOSAL,
} from './types';
import axios from 'axios';
import { returnErrors } from './errorActions';
import { tokenConfig } from './authActions';

export const getProposals = () => (dispatch, getState) => {
	axios
		.get('/api/proposals', tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_PROPOSALS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const getProposalByNumber = proposalNumber => (dispatch, getState) => {
	axios
		.get(`/api/proposals/${proposalNumber}`, tokenConfig(getState))
		.then(res =>
			dispatch({
				type: GET_PROPOSAL,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};


export const createProposal = (newProposal) => (dispatch, getState) => {
	axios
		.post('/api/proposals', newProposal, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: CREATE_PROPOSAL,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const updateProposal = (updatedProposal, id) => (dispatch, getState) => {
	axios
		.post(`/api/proposals/update/${id}`, updatedProposal, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_PROPOSAL,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const updateProposalNote = (newNote, id) => (dispatch, getState) => {
	axios
		.post(`/api/proposals/addNote/${id}`, newNote, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_PROPOSAL,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
// The details order array in proposal.
export const updateDetailsOrder = body => (dispatch, getState) => {
	console.log('IS fired')
	axios
		.post(`/api/proposals/detailsOrder`, body, tokenConfig(getState))
		.then(res =>
			dispatch({
				type: UPDATE_PROPOSAL, // No types are required.
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const sendProposalForReview = (proposal) => (dispatch, getState) => {
	axios
		.post(`/api/proposals/review/${proposal._id}`, proposal, tokenConfig(getState))
		.then(() =>
			dispatch({
				type: SEND_FOR_REVIEW,
				payload: proposal,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const approveProposal = proposal => (dispatch, getState) => {
	axios
		.post(`/api/proposals/approve/${proposal._id}`, proposal, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: APPROVE_PROPOSAL,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const rejectProposal = (proposal) => (dispatch, getState) => {
	axios
		.post(`/api/proposals/reject/${proposal._id}`, proposal, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: REJECT_PROPOSAL,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const deleteProposal = (id) => (dispatch, getState) => {
	axios
		.delete(`/api/proposals/${id}`, tokenConfig(getState))
		.then(() =>
			dispatch({
				type: DELETE_PROPOSAL,
				payload: id,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const getProposal = (id) => (dispatch, getState) => {
	axios
		.get(`/api/proposals/${id}`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_PROPOSAL,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const createProposalAndClient = (newClient, newProposal) => (dispatch, getState) => {
	axios
		.post(`/api/clients`, newClient, tokenConfig(getState))
		.then((res) => {
			dispatch({
				type: CREATE_CLIENT,
				payload: res.data,
			});
			newProposal.client_id = res.data._id;
			axios
				.post('/api/proposals', newProposal, tokenConfig(getState))
				.then((res) =>
					dispatch({
						type: CREATE_PROPOSAL,
						payload: res.data,
					})
				)
				.catch((err) => {
					dispatch(returnErrors(err.response.data, err.response.status));
				});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const addReporttoProposal = (proposal_id, report) => (dispatch, getState) => {
	axios
		.post(`/api/proposals/addReport/${proposal_id}`, report, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_PROPOSAL,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getProposalInModal = (id) => (dispatch) => {
	dispatch({
		type: GET_PROPOSAL_IN_MODAL,
		payload: id,
	});
};
export const toggleProposalModal = (input) => (dispatch) => {
	dispatch({
		type: TOGGLE_PROPOSAL_MODAL,
		payload: input,
	});
};
