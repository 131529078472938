import React, { Component } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { addProject } from '../../actions/projectActions'

class ProjectModal extends Component {
    state = {
        modal: false,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = e => {
        e.preventDefault();

        const newProject = {
            name: this.state.name,
            deadline: this.state.deadline,
            description: this.state.description,
            status: "In Progress",
            startdate: this.state.startdate,
            user_id: this.state.user_id,
        }
        // Add project via addProject action
        this.props.addProject(newProject);
        this.setState({
            name: '',
            deadline: '',
            description: '',
            user_id:'',
        })
        //close modal
        this.toggle();
    }

    render() {
        const { employees } = this.props.employee;
        return (
            <div>
                <Button
                    color="dark"
                    style={{ marginBottom: "2rem" }}
                    onClick={this.toggle}
                >
                    Add Project
                </Button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader
                        toggle={this.toggle}
                    >
                        Create New Project
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Label for="ProjectName" >Name</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="ProjectName"
                                    placeholder="Add Project Name"
                                    defaultValue={this.state.name}
                                    onChange={this.onChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="ProjectDescription" >Description</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="descriptionBox"
                                    placeholder="Add Project Description"
                                    defaultValue={this.state.description}
                                    onChange={this.onChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Start Date</Label>
                                <Input
                                    type="date"
                                    name="startdate"
                                    onChange={this.onChange}
                                    defaultValue={this.state.startdate}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Project Deadline</Label>
                                <Input
                                    type="date"
                                    name="deadline"
                                    id="ProjectName"
                                    defaultValue={this.state.deadline}
                                    onChange={this.onChange}
                                />
                                <Label style={{ fontSize: '12px', color: 'grey' }}>Not Required</Label>
                            </FormGroup>
                            <FormGroup>
								<Label>Assign To?</Label>
                                <Input 
                                    type='select' 
                                    name='user_id' 
                                    id='user_id' 
                                    defaultValue={this.state.user_id}
                                    onChange={this.onChange}
                                >
									<option value=''>Select PIC</option>
									{employees.filter(employee => employee.permissionLevel !== "Disabled")
									.map(({ _id, user_id, name }) => (
										<option key={_id} value={user_id}>
											{name}
										</option>
									))}
								</Input>
                                <Label style={{ fontSize: '12px', color: 'grey' }}>Not Required</Label>
							</FormGroup>
                            <Button
                                color="dark"
                                style={{ marginTop: "2rem" }}
                                block
                            >
                                Add Project
                            </Button>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

ProjectModal.propTypes = {
    addProject: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    project: state.projectR, // getting reducer from /reducers/index.js
    employee: state.employeeR,
})

export default connect(
    mapStateToProps,
    { addProject }
)(ProjectModal);