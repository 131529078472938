import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector} from 'react-redux'

import {
    Container,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Card,
    CardBody,
    CardHeader
} from 'reactstrap'
import {registerCompany} from '../../actions/companyActions'

const RegisterCompany = props => {
    const [departments, setDepartments] = useState([""])
    const [companyname, setCompanyname] = useState('')
    const [shortname, setShortname] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [userFee, setUserFee] = useState(25)
    const [task , setTask] = useState(true)
    const [proposal , setProposal] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [registered, setRegistered] = useState(false)

    const dispatch = useDispatch()
    const company = useSelector(state => state.companyR)

    useEffect(() => {
        setErrorMessage(company.errors)
        setRegistered(company.registerSuccess)
    }, [company])

    const addRow = () => {
        var arr = [...departments, ""]
        setDepartments(arr)
    }
    
    const removeRow = () => {
        var arr = [...departments]
        arr.pop()
        setDepartments(arr)
    }

    const updateDepartment = (e,i) => {
        departments[i] = e.target.value
        setDepartments([...departments])
    }

    const onSubmit = e => {
        e.preventDefault();
        setErrorMessage([])
        if (userFee > 0){
            const newCompany = {
                companyname,
                shortname,
                name,
                email,
                password,
                password2,
                userFee,
                proposal,
                task,
            }
            dispatch(registerCompany(newCompany))
        } else {
            setErrorMessage([...errorMessage, "Requires at least one app"])
        }
    }
    if (registered) {
        return <Redirect />
    }
    return (
        <Container>
            <div
                style = {{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Card
                    style = {{
                        marginBottom: "1rem",
                        width: "60%",
                    }}
                >
                    <CardHeader>
                        New Company Registration
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={onSubmit}>
                            <FormGroup>
                                <Label>Company Name</Label>
                                <Input 
                                    type = "text"
                                    placeholder = "Add Company Name"
                                    onChange = {e => setCompanyname(e.target.value)}
                                    defaultValue = {companyname}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Short Name</Label>
                                <Input 
                                    type = "text"
                                    placeholder = "Spaces will be removed"
                                    onChange = {e => setShortname(e.target.value.replace(/\s/g, "").toLowerCase())}
                                    value = {shortname}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input 
                                    type = "text"
                                    placeholder = "Full Name for Executive User"
                                    onChange = {e => setName(e.target.value)}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for = "email" >Executive Email</Label>
                                <Input 
                                    type = "email"
                                    placeholder = "Requires Unique Email"
                                    onChange = {e => setEmail(e.target.value.toLowerCase())}
                                    value = {email}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for = "password" >Password</Label>
                                <Input 
                                    type = "password"
                                    placeholder = "Password"
                                    onChange = {e => setPassword(e.target.value)}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for = "password2" >Confirm Password</Label>
                                <Input 
                                    type = "password"
                                    placeholder = "Confirm Password"
                                    onChange = {e => setPassword2(e.target.value)}
                                    required
                                />
                            </FormGroup>
                            <Label>Select Apps</Label>
                            <FormGroup check>
                                <Label check>
                                <Input type="checkbox" 
                                    onChange = {e => {
                                        setProposal(e.target.checked)
                                        if(e.target.checked||task){
                                            setUserFee(25)
                                        } else {
                                            setUserFee(0)
                                        }
                                        if(e.target.checked&&task){
                                            setUserFee(35)
                                        }
                                    }}
                                    checked = {proposal}
                                    disabled
                                />{' '}
                                    Proposal Tracker (Registration Temporarily Closed)
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                <Input type="checkbox" 
                                    onChange = {e => {
                                        setTask(e.target.checked)
                                        if(e.target.checked||proposal){
                                            setUserFee(25)
                                        } else {
                                            setUserFee(0)
                                        }
                                        if(e.target.checked&&proposal){
                                            setUserFee(35)
                                        }
                                    }}
                                    checked = {task}
                                />{' '}
                                    Project Management
                                </Label>
                            </FormGroup>
                            <br />
                            <FormGroup>
                                <Label>Monthly User Fee</Label>
                                <Input 
                                    type = "number"
                                    value = {userFee}
                                    disabled
                                    required
                                />
                            </FormGroup>
                            {errorMessage.map( message => {
                            return <Alert color="danger">{ message }</Alert>
                            })}
                            {userFee > 0?<Button
                                color= "primary"
                                style = {{marginTop: "2rem"}}
                                block
                            >
                                Register Company
                            </Button>:<Button
                                color= "primary"
                                style = {{marginTop: "2rem"}}
                                block
                                disabled
                            >
                                Register Company
                            </Button>}
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Container>
    )
}

export default RegisterCompany