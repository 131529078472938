import { CREATE_REWARD, UPDATE_REWARD, DELETE_REWARD, GET_REWARDS, REDUCE_REWARD_AVAILABLE_NUMBER } from '../actions/types'

const initialState = {
    rewards: []
}
const rewardReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_REWARDS:
            return {
                ...state,
                rewards: action.payload,
            }
        case CREATE_REWARD:
            return {
                ...state,
                rewards: [...state.rewards, action.payload]
            }
        case REDUCE_REWARD_AVAILABLE_NUMBER: {
            return {
                ...state,
                rewards: state.rewards.map(reward => reward._id === action.payload._id ? action.payload : reward)
            }
        }
        case DELETE_REWARD:
            return {
                ...state,
                rewards: state.rewards.filter(e => e._id !== action.payload)
            }
        default: return state;
    }

}
export default rewardReducer