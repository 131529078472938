import React, {Component, Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, Input} from 'reactstrap'
import {connect} from 'react-redux'
import { togglePermissionIndex,updatePermissionLevel } from '../../actions/companyActions'
import PropTypes from 'prop-types';

class PermissionModal extends Component {
    state = {
        permissionLevel: "",
        _id: "",
    }

    componentDidUpdate(prevProps){
        if(this.props.company.user !== prevProps.company.user && this.props.company.user) {
            this.setState({
                permissionLevel: this.props.company.user.permissionLevel,
                _id: this.props.company.user._id
            })
        }
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    updatePermission = () => {
        this.props.updatePermissionLevel(this.state, this.props.company.shortname)
    }

    render() {
        const {user , permissionModal} = this.props.company
        return (
            permissionModal?<Fragment>
                    <Modal
                        isOpen={this.props.company.permissionModal}
                        toggle={this.props.togglePermissionIndex.bind(this, "")}
                    >
                        <ModalBody>
                            <Input 
                                type = "select"
                                name = "permissionLevel"
                                onChange = {this.onChange}
                                required
                                defaultValue = {this.state.permissionLevel}
                            >
                                <option>Disabled</option>
                                <option>Employee</option>
                                <option>Manager</option>
                                <option>Executive</option>
                                <option>Secretary</option>
                            </Input>
                            <Button
                                color= "dark"
                                style = {{marginTop: "2rem"}}
                                block
                                onClick = {this.updatePermission}
                            >
                                Update Permission Level
                            </Button>
                        </ModalBody>
                    </Modal>
            </Fragment>:null
        )
    }
}

PermissionModal.propTypes = {
    togglePermissionIndex: PropTypes.func.isRequired,
    updatePermissionLevel: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    company: state.companyR, // getting reducer from /reducers/index.js
    auth: state.authR // getting reducer from /reducers/index.js
})

export default connect(
    mapStateToProps,
    { togglePermissionIndex, updatePermissionLevel }
)(PermissionModal);