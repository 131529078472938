import React from 'react';
import calenedarCalculator from '../../utils/calenedarCalculator';

export function changeToPrevMonth(month, months, year, setMonth, setYear, setDates, setWeeks) {
	// 1. if not Jan
	if (month > 0) {
		setMonth(month - 1);
		setDates(calenedarCalculator(new Date(`1 ${months[month - 1]} ${year}`)));
		setWeeks([
			...Array(calenedarCalculator(new Date(`1 ${months[month]} ${year}`)).length / 7).keys(),
		]);
	} else {
		// 2. if Jan
		setMonth(11);
		setYear(year - 1);
		setDates(calenedarCalculator(new Date(`1 ${months[11]} ${year - 1}`)));
		setWeeks([
			...Array(calenedarCalculator(new Date(`1 ${months[11]} ${year - 1}`)).length / 7).keys(),
		]);
	}
}

export function changeToNextMonth(month, months, year, setMonth, setYear, setDates, setWeeks) {
	// Switching the date in the calendar to next month.
	if (month > 10) {
		// If the month is December it switches the date to next year January.
		setMonth(0);
		setYear(year + 1); // To switch to next year.
		setDates(calenedarCalculator(new Date(`1 ${months[0]} ${year + 1}`)));
		setWeeks([
			...Array(calenedarCalculator(new Date(`1 ${months[0]} ${year + 1}`)).length / 7).keys(),
		]);
	} else {
		// If the month is not December then we will just change to next month.
		setMonth(month + 1);
		setDates(calenedarCalculator(new Date(`1 ${months[month + 1]} ${year}`)));
		setWeeks([
			...Array(calenedarCalculator(new Date(`1 ${months[month + 1]} ${year}`)).length / 7).keys(),
		]);
	}
}

export function changeToCurrentMonth(months, date, setDates, setWeeks, setMonth, setYear) {
	setDates(calenedarCalculator(new Date(`1 ${months[date.getMonth()]} ${date.getFullYear()}`))); //  The whole months dates in an array form.
	setWeeks([
		...Array(
			calenedarCalculator(new Date(`1 ${months[date.getMonth()]} ${date.getFullYear()}`)).length / 7
		).keys(),
	]); // The number of weeks in th month.
	setMonth(date.getMonth());
	setYear(date.getFullYear());
}

export function renderStatus(reduxLeaves, _id, status, reduxEmployees) {
	return reduxLeaves.map((leave, j) => {
		if (_id === leave._id) {
			return (
				<div key={leave._id}>
					<div>{status !== 'Rejected' && status !== 'Cancelled' && leave.type}</div>
					<div>
						{status !== 'Rejected' &&
							status !== 'Cancelled' &&
							reduxEmployees
								.filter((e) => e.user_id === leave.user_id)
								.map((e) => e.name.split(' ')[0])}
					</div>
				</div>
			);
		}
	});
}

export function statusxAdmin(reduxLeaves, _id, status, user_id) {
	return reduxLeaves.map((leave, j) => {
		// console.log('leave', leave);
		if (_id === leave._id && user_id === leave.user_id) {
			return (
				<div key={leave._id}>
					<div>{status !== 'Rejected' && status !== 'Cancelled' && leave.type}</div>
				</div>
			);
		}
	});
}

export function renderYearList() {
	return (
		<>
			<option>2019</option>
			<option>2020</option>
			<option>2021</option>
			<option>2022</option>
			<option>2023</option>
			<option>2024</option>
			<option>2025</option>
			<option>2026</option>
		</>
	);
}

export function remainingLeaveCal(annualLeaves, takenLeaves) {
	const annualL = annualLeaves === undefined ? 0 : annualLeaves;
	const takenL = takenLeaves === undefined ? 0 : takenLeaves;
	const remainingLeaves = annualL - takenL;
	return remainingLeaves;
}
