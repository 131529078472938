import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Container, Table } from 'reactstrap';

// Import Actions
import { getInvoices } from '../actions/invoiceActions'
import { getStages } from '../actions/stageActions'

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

//Import Components 
import ConfirmButton from '../components/userComponents/ConfirmButton'
import EditInvoiceModal from '../components/invoiceComponents/EditInvoiceModal'


const InvoiceList = props => {
    const invoices = useSelector(state => state.invoiceR.invoices)
    const stages = useSelector(state => state.stageR.stages)
    const errorMessage = useSelector(state => state.errorR.message.message)
    const successMessage = useSelector(state => state.invoiceR.successMessage)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getInvoices())
        dispatch(getStages())
    }, [])

    return (
        <Container>
            {successMessage && <Alert color='success'>{successMessage}</Alert>}
            {errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
            <Table>
                <thead>
                    <tr>
                        <td width='10%' className='rowAlign'>Invoice No.</td>
                        <td width='10%' className='rowAlign'>Proposal No.</td>
                        <td width='10%' className='rowAlign'>Stage</td>
                        <td width='20%' className='rowAlign'>Step</td>
                        <td width='10%' className='rowAlign'>Amount</td>
                        <td width='10%' className='rowAlign'>Status</td>
                        <td width='10%' className='rowAlign'>Actions</td>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((invoice) => {
                        const stage = stages.find(e => e.payBreakdown.some(e => e.invoice_id === invoice._id))
                        if (!stage) { return null }
                        const payBreakdown = stage.payBreakdown.find(e => e.invoice_id === invoice._id)
                        return (
                            <tr key={invoice._id}>
                                <td className='rowAlign'>{invoice.invoiceNumber}</td>
                                <td className='rowAlign'>{stage.proposalNumber}</td>
                                <td className='rowAlign'>{stage.stage}</td>
                                <td className='rowAlign'>{payBreakdown.step}</td>
                                <td className='rowAlign'>{payBreakdown.percentage * stage.fees / 100}</td>
                                <td className='rowAlign'>{invoice.status}</td>
                                <td className='rowAlign'>
                                    <ConfirmButton
                                        className='remove-btn'
                                        color='danger'
                                        size='sm'
                                        style={{
                                            float: 'right',
                                        }}
                                        alertTitle={'Delete this Invoice?'}
                                        onClick={() => { }}>
                                        <FontAwesomeIcon color='white' style={{ fontSize: '14x' }} icon={faTrash} />
                                    </ConfirmButton>
                                    <EditInvoiceModal invoice={invoice} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Container>
    )
}

export default InvoiceList