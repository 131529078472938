import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row,Col, Button } from 'reactstrap';
import { toggleVendorModal } from '../../actions/vendorActions';

export class VendorDetails extends Component {
    render() {
        const { vendor } = this.props.vendor
        return (
            <Modal isOpen={this.props.vendor.detailsModal} toggle={this.props.toggleVendorModal.bind(this,"detailsModal")}>
                <ModalHeader toggle={this.props.toggleVendorModal.bind(this,"detailsModal")}>{vendor.name}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Hourly Rate</Label>
                                <Input value={vendor.rate} disabled /> 
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label>Contact Person</Label>
                                <Input value={vendor.PIC_name} disabled /> 
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input value={vendor.email} disabled /> 
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Contact Number</Label>
                                <Input value={vendor.contactNo} disabled /> 
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button href={`/updateVendor/${vendor._id}`} block >Update Vendor</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    vendor: state.vendorR
})

export default connect(mapStateToProps, {
toggleVendorModal,
})(VendorDetails)
