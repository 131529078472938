import React, { Component } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	NavLink,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import { Redirect, withRouter } from 'react-router-dom';

class LoginModal extends Component {
	state = {
		modal: false,
		email: '',
		password: '',
		message: null,
		redirect: false,
	};

	componentDidUpdate(prevProps) {
		const { error, isAuthenticated } = this.props;
		if (error !== prevProps.error) {
			//Check for register error
			if (error.status === 404 && error.message.message !== 'Email not found') {
				this.setState({ modal: false, redirect: true });
			}
			if (error.id === 'LOGIN_FAIL') {
				this.setState({ message: error.message.message });
			} else {
				this.setState({ message: null });
			}
		}

		if (this.state.modal) {
			if (isAuthenticated) {
				this.toggle();
			}
		}
	}

	toggle = () => {
		//Clear errors
		this.props.clearErrors();

		this.setState({
			modal: !this.state.modal,
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmit = (e) => {
		e.preventDefault();

		const { email, password } = this.state;

		// create a user object
		const user = {
			email,
			password,
		};

		//Attemp to Register
		this.props.login(user);
	};

	render() {
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: '/',
						state: { requireRefresh: true },
					}}
				/>
			);
		}
		return (
			<div>
				<NavLink onClick={this.toggle} href='#'>
					Login
				</NavLink>

				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Login</ModalHeader>
					<ModalBody>
						{this.state.message ? <Alert color='danger'>{this.state.message}</Alert> : null}
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label for='email'>Email</Label>
								<Input
									type='email'
									name='email'
									id='email'
									placeholder='Email'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for='password'>Password</Label>
								<Input
									type='password'
									name='password'
									id='password'
									placeholder='Password'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<a href='/resetpassword'>Forgot Password</a>
							<Button color='dark' style={{ marginTop: '2rem' }} block>
								Login
							</Button>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

LoginModal.propTypes = {
	isAuthenticated: PropTypes.bool,
	error: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	clearErrors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.authR.isAuthenticated, // getting reducer from /reducers/index.js
	error: state.errorR,
});

export default withRouter(
	connect(mapStateToProps, {
		login,
		clearErrors,
	})(LoginModal)
);
