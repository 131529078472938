import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import AddReportModal from './AddReportModal';

class ReportModal extends Component {
	state = {
		modal: false,
	};

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	render() {
		return (
			<Fragment>
				<Button
					color='dark'
					style={{ padding: '2px', paddingRight: '6px', paddingLeft: '6px' }}
					onClick={this.toggle}>
						
					Add Report
				</Button>
				
				<AddReportModal
					modal={this.state.modal}
					toggle={this.toggle}
					currentDate={this.state.currentDate}
				/>
			</Fragment>
		);
	}
}


const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {  })(ReportModal);
