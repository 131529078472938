import { GET_CLIENTS, GET_CLIENT_IN_MODAL, DELETE_CLIENT, CREATE_CLIENT, UPDATE_CLIENT, TOGGLE_CLIENT_MODAL, GET_CLIENT } from '../actions/types';

const initialState = {
    clients: [],
    detailsModal: false,
    addModal: false,
    client: {},
    message: null
}
const clientReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CLIENTS:
            return {
                ...state,
                clients: action.payload
            }
        case CREATE_CLIENT:
            return {
                ...state,
                clients: [...state.clients, action.payload]
            }
        case DELETE_CLIENT:
            return {
                ...state,
                clients: state.clients.filter(({ _id }) => _id !== action.payload)
            }
        case UPDATE_CLIENT:
            return {
                ...state,
                message: action.payload
            }
        case GET_CLIENT_IN_MODAL:
            return {
                ...state,
                client: state.clients.filter(({ _id }) => _id === action.payload)[0]
            }
        case GET_CLIENT:
            return {
                ...state,
                client: action.payload
            }
        case TOGGLE_CLIENT_MODAL:
            if (action.payload === "detailsModal") {
                return {
                    ...state,
                    detailsModal: !state.detailsModal
                }
            } else {
                return {
                    ...state,
                    addModal: !state.addModal
                }
            }
        default:
            return state;
    }
}
export default clientReducer