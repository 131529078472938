export const readFile = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.onloadend = (f) => {
            resolve(f.target.result)
        };
    });
}

export const isSameObject = (object1, object2) => {

    var string1 = JSON.stringify(object1);
    var string2 = JSON.stringify(object2);

    return string1 === string2
}