import {
	USER_LOADED,
	USER_LOADING,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_SUCCESS,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	RESET_SUCCESS,
	RESET_FAIL,
	CREATE_NOTIFICATION,
	REMOVE_SEEN_NOTIFICATION,
	HIDE_NOTIFICATIONS,
	SHOW_NOTIFICATIONS,
	CHANGE_PASSWORD,
	GET_USERS,
} from '../actions/types';

const initialState = {
	token: localStorage.getItem('token'),
	isAuthenticated: false,
	isLoading: false,
	user: null,
	message: null,
	redirect: false,
	notifications: [],
	hideNotifications: true,
	users: [], // All The users
};

const authReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_USERS:
			return {
				...state,
				users: action.payload,
			};
		case USER_LOADING:
			return {
				...state,
				isLoading: true,
			};
		case USER_LOADED:
		case LOGIN_SUCCESS:
		case REGISTER_SUCCESS:
			if (action.payload.token) {
				localStorage.setItem('token', action.payload.token);
			}
			return {
				...state,
				token: action.payload.token ? action.payload.token : state.token,
				user: action.payload.user,
				notifications: action.payload.notifications,
				isAuthenticated: true,
				isLoading: false,
				hideNotifications: true,
				isDevEnv: action.payload.isDevEnv
			};
		case AUTH_ERROR:
		case LOGIN_FAIL:
		case REGISTER_FAIL:
		case LOGOUT_SUCCESS:
			localStorage.clear();
			return {
				...state,
				token: null,
				notifications: [],
				isAuthenticated: false,
				isLoading: false,
				user: null,
				hideNotifications: true,
			};
		case REMOVE_SEEN_NOTIFICATION:
			return {
				...state,
				notifications: action.payload,
			};
		case HIDE_NOTIFICATIONS:
			return {
				...state,
				hideNotifications: true,
			};
		case SHOW_NOTIFICATIONS:
			return {
				...state,
				hideNotifications: false,
			};
		case CHANGE_PASSWORD:
			return {
				...state,
				message: action.payload,
			};
		case RESET_SUCCESS:
			return {
				...state,
				...action.payload,
				redirect: true,
			};
		case RESET_FAIL:
			return {
				...state,
				...action.payload,
				redirect: false,
			};
		default:
			return state;
	}
};

export default authReducer;
