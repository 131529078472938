import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from 'reactstrap';
import {
  toggleProposalModal,
  updateProposal,
} from '../../actions/proposalActions';
import { getStages } from '../../actions/stageActions';
import DetailsTable from './DetailsTable';

export class ProposalDetails extends Component {
  state = {
    stageNumber: '',
    stages: [],
  };
  componentDidMount() {
    this.props.getStages();
  }
  toggleInputsHeader = () => {
    this.setState({ index: !this.state.index });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.proposal !== this.props.proposal) {
      const proposal = this.props.proposal;
      if (proposal) {
        // This condational is for preventing error
        this.setState({
          stages: this.props.stages.filter(
            ({ proposalNumber }) => proposalNumber === proposal.proposalNumber
          ),
        });
      }
    }
  }

  render() {
    const { user } = this.props.auth;
    const { proposal } = this.props.proposals;
    const creationDate = proposal ? proposal.creation_date : null;
    const isNotExecutive = user.permissionLevel !== 'Executive';

    return (
      <Fragment>
        <Modal
          isOpen={this.props.proposals.detailsModal}
          toggle={this.props.toggleProposalModal.bind(this, '')}
          size="xl"
        >
          <ModalHeader toggle={this.props.toggleProposalModal.bind(this, 's')}>
            Proposal Details
            {(user.permissionLevel === 'Executive' ||
              user.permissionLevel === 'Manager') && (
              <Button
                style={{ position: 'absolute', right: 40, top: 10 }}
                color="secondary"
                href={`/proposalSummary/${proposal ? proposal._id : ''}`}
              >
                Proposal Summary
              </Button>
            )}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Proposal Number</Label>
                  <Input
                    placeholder={proposal ? proposal.proposalNumber : null}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>PIC</Label>
                  <Input
                    placeholder={
                      proposal
                        ? this.props.employees
                            .filter(
                              ({ user_id }) => user_id === proposal.PIC_id
                            )
                            .map((e) => e.name)
                        : null
                    }
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Created By</Label>
                  <Input
                    placeholder={
                      proposal
                        ? this.props.employees
                            .filter(
                              ({ user_id }) => user_id === proposal.createdBy_id
                            )
                            .map((e) => e.name)
                        : null
                    }
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Creation Date</Label>
                  <Input
                    placeholder={
                      creationDate ? creationDate.substring(0, 10) : null
                    }
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>Description</Label>
              <Input
                type="textarea"
                placeholder={proposal ? proposal.description : null}
                disabled
              />
            </FormGroup>
            {this.state.stages.length ? (
              <Table>
                <thead>
                  <tr>
                    <th width="8%">Stage</th>
                    <th width="82%">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.stages.map((e) => {
                    return (
                      <tr key={e._id}>
                        <td>{e.stage}</td>
                        <td>{e.description}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : null}
            {/* The DetailsTable component is being called */}
            <DetailsTable proposal_id={proposal ? proposal._id : null} />
          </ModalBody>
          <ModalFooter>
            {proposal ? (
              proposal.PIC_id === user.id ||
              user.permissionLevel !== 'Employee' ? (
                <>
                  <Button
                    color="dark"
                    href={`/updateProposal/${proposal ? proposal._id : null}`}
                    block
                  >
                    Preview Proposal
                  </Button>
                  {/* {proposal.status !== 'Canceled' && <Button
										color='danger'
										onClick={() => {
											if (window.confirm("Do you want to Cancel Proposal?")) {
												this.props.updateProposal({ ...proposal, status: 'Canceled' }, proposal._id)
											}
										}}
										block>
										Cancel Proposal
										</Button>} */}
                </>
              ) : null
            ) : null}
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  proposals: state.proposalR,
  proposal: state.proposalR.proposal,
  employees: state.employeeR.employees,
  stages: state.stageR.stages,
  timeInputs: state.companyR.timeInputs,
  details: state.detailR.details,
  auth: state.authR,
});
export default connect(mapStateToProps, {
  updateProposal,
  toggleProposalModal,
  getStages,
})(ProposalDetails);
