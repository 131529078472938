import { combineReducers } from 'redux';
import projectReducer from './projectReducer';
import taskReducer from './taskReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import employeeReducer from './employeeReducer';
import reportReducer from './reportReducer';
import companyReducer from './companyReducer';
import feedbackReducer from './feedbacksReducer';
import clientReducer from './clientReducer';
import vendorReducer from './vendorReducer';
import proposalReducer from './proposalReducer';
import stageReducer from './stageReducer';
import detailReducer from './detailReducer';
import jobReducer from './jobReducer';
import rewardReducer from './rewardReducer';
import leaveTrackerReducer from './leaveTrackerReducer';
import InvoiceReducer from './invoiceReducer';
import paymentReducer from './paymentReducer';
import expenseReducer from './expenseReducer';
import announcementReducer from './announcementReducer';

const reducers = combineReducers({
	projectR: projectReducer,
	taskR: taskReducer,
	errorR: errorReducer,
	authR: authReducer,
	employeeR: employeeReducer,
	reportR: reportReducer,
	companyR: companyReducer,
	feedbackR: feedbackReducer,
	clientR: clientReducer,
	vendorR: vendorReducer,
	proposalR: proposalReducer,
	stageR: stageReducer,
	detailR: detailReducer,
	jobR: jobReducer,
	rewardR: rewardReducer,
	leaveR: leaveTrackerReducer,
	invoiceR: InvoiceReducer,
	paymentR: paymentReducer,
	expenseR: expenseReducer,
	announcementR: announcementReducer,
});

export default reducers;
