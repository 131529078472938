import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Row, Col, Button } from 'reactstrap'
import { toggleProposalModal, sendProposalForReview } from '../../actions/proposalActions'

const SendProposalForReview = () => {
    const clients = useSelector(state => state.clientR.clients)
    const displayModal = useSelector(state => state.proposalR.reviewModal)
    const proposal = useSelector(state => state.proposalR.proposal)
    const dispatch = useDispatch()
    const reviewProposal = () => {
        proposal.status = 'Pending Review'
        dispatch(sendProposalForReview(proposal))
        dispatch(toggleProposalModal('reviewModal'))
    }
    if (proposal) {
        return (
            <Fragment>
                <Modal isOpen={displayModal} toggle={() => dispatch(toggleProposalModal('reviewModal'))}>
                    <ModalHeader toggle={() => dispatch(toggleProposalModal('reviewModal'))}>
                        {proposal.proposalNumber}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Client</Label>
                                    <Input placeholder={clients.filter(({ _id }) => _id === proposal.client_id).map(e => e.name)} disabled />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Creation Date</Label>
                                    <Input placeholder={proposal.creation_date ? proposal.creation_date.substring(0, 10) : null} disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="textarea" placeholder={proposal.description} disabled />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button block onClick={() => reviewProposal()}>Send For Review</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    } else {
        return (
            <div></div>
        )
    }
}

export default SendProposalForReview
