import {
	CREATE_ANNOUNCEMENT,
	UPDATE_ANNOUNCEMENT,
	UPDATE_ANNOUNCEMENT_FAIL,
	DELETE_ANNOUNCEMENT,
	GET_ANNOUNCEMENT,
	GET_ANNOUNCEMENTS,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import axios from 'axios';

export const getAnnouncements = () => (dispatch, getState) => {
	axios
		.get('/api/announcements', tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_ANNOUNCEMENTS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const getAnnouncement = (id) => (dispatch, getState) => {
	axios
		.get(`/api/announcements/${id}`, tokenConfig(getState))
		.then((res) => {
			dispatch({
				type: GET_ANNOUNCEMENT,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const createAnnouncement = (announcement) => (dispatch, getState) => {
	axios
		.post('/api/announcements', announcement, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: CREATE_ANNOUNCEMENT,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const updateAnnouncement = (announcement) => (dispatch, getState) => {
	axios
		.patch(`/api/announcements/update`, announcement, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_ANNOUNCEMENT,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_ANNOUNCEMENT_FAIL'));
			dispatch({
				type: UPDATE_ANNOUNCEMENT_FAIL,
			});
		});
};

export const deleteAnnouncement = (id) => (dispatch, getState) => {
	axios
		.delete(`/api/announcements/${id}`, tokenConfig(getState))
		.then(() =>
			dispatch({
				type: DELETE_ANNOUNCEMENT,
				payload: id,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
