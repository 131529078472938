import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter } from 'reactstrap';
import { getProjects } from '../../actions/projectActions';
import { getProposals } from '../../actions/proposalActions';
import { updateReport } from '../../actions/reportActions';
import { patchTask, patchTwoTask, updateTask, updateTwoTask } from '../../actions/taskActions';

const ChangeTaskModal = ({ modal = false, toggle, report, tasks, pic }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProposals())
        dispatch(getProjects())
    }, [dispatch])

    // const { proposalTasks, departments } = company
    const [reportObj, setReportObj] = useState()

    useEffect(() => {
        if (!report) return
        setReportObj({ ...report })
    }, [report])

    const proposals = useSelector(state => state.proposalR.proposals)
    const projects = useSelector(state => state.projectR.projects)

    if (!reportObj) return <></>

    const { project_id, task_id, name, proposal_id, department } = reportObj;

    const onChange = ({ target }) => {
        var newReportObj = {
            ...reportObj,
            [target.name]: target.value
        }

        if (target.name === 'project_id') {
            newReportObj.task_id = ''
        }

        setReportObj(newReportObj)
    }


    function onSubmit(e) {
        e.preventDefault()

        const prevTask = tasks.find(({ _id }) => _id === report.task_id)
        const newTask = tasks.find(({ _id }) => _id === task_id)
        const newReport = { ...reportObj }

        const updatePrevTask = {
            _id: prevTask._id,
            reports: prevTask.reports.filter((_id) => _id !== reportObj._id)
        }
        const updateNewTask = {
            _id: newTask._id,
            reports: [...newTask.reports, reportObj._id]
        }


        dispatch(updateReport(newReport._id, newReport))
        // remove report's id from prev task and add on new task
        dispatch(patchTwoTask(updatePrevTask, updateNewTask))

        toggle()
    }

    return modal ? <Modal isOpen={modal} toggle={() => toggle()}>
        <ModalHeader toggle={() => toggle()}>Change Task</ModalHeader>
        <Form onSubmit={e => onSubmit(e)}>
            <ModalBody>
                <FormGroup>
                    <Label>Project</Label>
                    <Input
                        type='select'
                        name='project_id'
                        value={project_id}
                        onChange={(e) => onChange(e)}
                        required>
                        <option value=''>Select Project</option>
                        {projects
                            .filter((project) => !project.proposal_id)
                            .sort((a, b) => {
                                if (a.name > b.name) {
                                    return -1;
                                }
                                if (b.name > a.name) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map(({ _id, name, status }) =>
                                status === 'In Progress' ? (
                                    <option key={_id} value={_id}>
                                        {name}
                                    </option>
                                ) : (
                                    ''
                                )
                            )}
                    </Input>
                </FormGroup>

                {/* {
                project_id === '5f224a84b2fbde22f5fee090' ?
                    <>
                        <FormGroup>
                            <Label>Proposal Number</Label>
                            <Input
                                type='select'
                                name='proposal_id'
                                value={proposal_id}
                                onChange={e => onChange(e)}
                                required>
                                <option value=''>Select Proposal Number</option>
                                {proposals
                                    .filter(({ status }) => {
                                        // filtering proposal by particulars status
                                        const statusFilterList = ['Completed', 'Canceled']
                                        return !statusFilterList.includes(status)
                                    })
                                    .sort(function (a, b) {
                                        if (
                                            Number(a.proposalNumber.substring(2, 4)) <
                                            Number(b.proposalNumber.substring(2, 4))
                                        ) {
                                            return -1;
                                        }
                                        if (
                                            Number(a.proposalNumber.substring(2, 4)) >
                                            Number(b.proposalNumber.substring(2, 4))
                                        ) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .sort(function (a, b) {
                                        if (
                                            Number(a.proposalNumber.substring(4, 6)) <
                                            Number(b.proposalNumber.substring(4, 6))
                                        ) {
                                            return -1;
                                        }
                                        if (
                                            Number(a.proposalNumber.substring(4, 6)) >
                                            Number(b.proposalNumber.substring(4, 6))
                                        ) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .map(({ _id, proposalNumber, description }) =>
                                        <option key={_id} value={_id}>
                                            {`${proposalNumber} - ${description}`}
                                        </option>
                                    )}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for='TaskName'>Name</Label>
                            <Input
                                type='select'
                                name='name'
                                id='TaskName'
                                placeholder='Add Task Name'
                                value={name}
                                onChange={e => onChange(e)}
                                required>
                                <option></option>
                                {proposalTasks.map((proposalTask) => (
                                    <option key={proposalTask.id}>{proposalTask.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for='Department'>Department</Label>
                            <Input
                                type='select'
                                name='department'
                                id='Department'
                                value={department}
                                onChange={e => onChange(e)}
                                required>
                                <option value=''>Select Department</option>
                                {departments
                                    ? departments.map((department, index) => (
                                        <option key={department.id} value={department.id}>
                                            {department.name}
                                        </option>
                                    ))
                                    : null}
                            </Input>
                        </FormGroup>
                    </> : */}
                <FormGroup>
                    <Label>Task</Label>
                    <Input type='select' name='task_id' value={task_id} onChange={e => onChange(e)} required>
                        <option value=''>Select Task</option>
                        {project_id ? tasks
                            .filter((task) => task.project_id === project_id)
                            .map(({ user_id, _id, name, status }) => {
                                if (status === 'In Progress' && user_id === pic.user_id) {
                                    return (
                                        <option key={_id} value={_id}>
                                            {name}
                                        </option>
                                    );
                                } else {
                                    return null;
                                }
                            }) : null}
                    </Input>
                </FormGroup>
                {/* } */}
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => toggle()}>Close</Button>
                <Button type='submit'>Confirm Task</Button>
            </ModalFooter>
        </Form>
    </Modal> : <></>
}

export default ChangeTaskModal;