import {
	GET_EMPLOYEES,
	GET_EMPLOYEE,
	ADD_EMPLOYEE,
	DELETE_EMPLOYEE,
	EMPLOYEE_LOADING,
	UPDATE_EMPLOYEE,
	UPDATE_EMPLOYEE_FAIL,
	UPDATE_EMPLOYEE_POINTS,
	PURCHASE_REWARD,
	FILTER_EMPLOYEE,
	GET_USERS,
	TOGGLE_EDIT_MODAL,
	UPDATE_EMPLOYEE_REWARD,
	UPDATE_EMPLOYEE_LEAVE,
} from '../actions/types';

const initialState = {
	employee: [],
	employees: [],
	users: [],
	user: [],
	modal: false,
	filteredEmployees: [],
	loading: false,
	message: '',
};

const employeeReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_EMPLOYEE:
		case PURCHASE_REWARD:
			return {
				...state,
				employee: action.payload,
				loading: false,
			};
		case GET_EMPLOYEES:
			return {
				...state,
				employees: action.payload.sort(function (a, b) {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				}),
				loading: false,
			};
		case GET_USERS:
			return {
				...state,
				users: action.payload,
			};
		case DELETE_EMPLOYEE:
			return {
				...state,
				employees: state.employees.filter((employee) => employee._id !== action.payload),
			};
		case ADD_EMPLOYEE:
		case UPDATE_EMPLOYEE_POINTS:
			return {
				...state,
				employees: [...state.employees, action.payload],
			};
		case EMPLOYEE_LOADING:
			return {
				...state,
				loading: true,
			};
		case UPDATE_EMPLOYEE:
		case UPDATE_EMPLOYEE_LEAVE:
		case UPDATE_EMPLOYEE_REWARD:
			return {
				...state,
				employees: state.employees.map((employee) => {
					if (action.payload._id === employee._id) {
						return action.payload;
					} else {
						return employee;
					}
				}),
				loading: false,
				message: action.payload.message ? action.payload.message : '',
			};

		case UPDATE_EMPLOYEE_POINTS:
			return {
				...state,
				employees: [...state.employees, action.payload],
			};
		case FILTER_EMPLOYEE:
			// Filter companies based on criteria provieded
			var arr = state.employees.filter((employee) =>
				Object.keys(action.payload).every((key) => {
					//check if the filter is an array of elements
					if (Array.isArray(action.payload[key])) {
						if (key === 'dateRange') {
							var filter1 = true;
							var filter2 = true;
							if (action.payload.dateRange[0] !== '') {
								filter1 = employee.createdAt > action.payload.dateRange[0] ? true : false;
							}
							if (action.payload.dateRange[1] !== '') {
								filter2 = employee.createdAt < action.payload.dateRange[1] ? true : false;
							}
							var filter = filter1 && filter2 ? true : false;
							return filter;
						}

						return action.payload[key].some((keyEle) =>
							employee[key] ? employee[key].includes(keyEle) : true
						);
					}
					return employee[key] ? employee[key].includes(action.payload[key]) : null;
				})
			);
			return {
				...state,
				filteredEmployees: arr,
			};

		case TOGGLE_EDIT_MODAL:
			return {
				...state,
				modal: !state.modal,
				user: state.users.filter(({ _id }) => _id === action.payload)[0],
				employee: state.employees.filter(({ user_id }) => user_id === action.payload)[0],
				message: '',
			};
		case UPDATE_EMPLOYEE_FAIL:
		default:
			return state;
	}
};

export default employeeReducer;
