import axios from 'axios';

import {
	GET_LEAVES,
	ADD_LEAVE,
	DELETE_LEAVE,
	GET_LEAVE,
	UPDATE_LEAVE,
	UPDATE_LEAVE_FAIL,
	LEAVE_LOADING,
	TOGGLE_LEAVE_MODAL,
	FILTER_LEAVE,
	UPDATE_EMPLOYEE_LEAVE,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

export const getLeaves = () => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.get(`/api/leaves`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_LEAVES,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getLeave = (id) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.get(`/api/leaves/${id}`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_LEAVE,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const createLeave = (newLeave) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	newLeave.key = 'nnnlwe19219n1b2--u0qjasca';
	var image = JSON.stringify(newLeave);

	if (newLeave.photo) {
		axios
			.post(
				'https://script.google.com/macros/s/AKfycbw2smMZXthCF_XAHvF2XzrKKwobh-kC0afqf3wbGYRhHvx8xWDL0j3Fyt5QNvtq2D0-HQ/exec',
				image
			)
			.then((res) => {
				newLeave.photo = res.data;
				// create a leave
				axios
					.post(`/api/leaves`, newLeave, tokenConfig(getState))
					.then((res) =>
						dispatch({
							type: ADD_LEAVE,
							payload: res.data,
						})
					)
					.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
			});
	} else {
		// create a leave without photo
		axios
			.post(`/api/leaves`, newLeave, tokenConfig(getState))
			.then((res) =>
				dispatch({
					type: ADD_LEAVE,
					payload: res.data,
				})
			)
			.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
	}
};

export const deleteLeave = (id) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.delete(`/api/leaves/${id}`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: DELETE_LEAVE,
				payload: id,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const updateLeaveStatus = (id, leave) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.post(`/api/leaves/update/${id}`, leave, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_LEAVE,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_LEAVE_FAIL'));
			dispatch({
				type: UPDATE_LEAVE_FAIL,
			});
		});
};

export const updateLeave = (id, leave) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.post(`/api/leaves/updateLeave/${id}`, leave, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_LEAVE,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_LEAVE_FAIL'));
			dispatch({
				type: UPDATE_LEAVE_FAIL,
			});
		});
};

export const leavePhotoUpdate = (id, leave) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	leave.key = 'nnnlwe19219n1b2--u0qjasca';
	var image = JSON.stringify(leave);

	if (leave.photo) {
		axios
			.post(
				'https://script.google.com/macros/s/AKfycbw2smMZXthCF_XAHvF2XzrKKwobh-kC0afqf3wbGYRhHvx8xWDL0j3Fyt5QNvtq2D0-HQ/exec',
				image
			)
			.then((res) => {
				leave.photo = res.data;
				// update a leave
				axios
					.post(`/api/leaves/updateLeave/${id}`, leave, tokenConfig(getState))
					.then((res) =>
						dispatch({
							type: UPDATE_LEAVE,
							payload: res.data,
						})
					)
					.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
			});
	} else {
		// update a leave without photo
		axios
			.post(`/api/leaves/updateLeave/${id}`, leave, tokenConfig(getState))
			.then((res) =>
				dispatch({
					type: UPDATE_LEAVE,
					payload: res.data,
				})
			)
			.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
	}
};

export const setItemsLoading = () => {
	return {
		type: LEAVE_LOADING,
	};
};

export const toggleLeaveModal = (id) => (dispatch) => {
	dispatch({
		type: TOGGLE_LEAVE_MODAL,
		payload: id,
	});
};

export const filterLeaves = (filters, calendarState) => (dispatch) => {
	dispatch({
		type: FILTER_LEAVE,
		payload: {
			filters: filters,
			calendarState: calendarState,
		},
	});
};
