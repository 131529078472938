import React, { Fragment, useState, useEffect, useRef } from 'react';
import { ListGroupItem, ListGroup, Button, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { updateProposalNote } from '../../actions/proposalActions'
import ConfirmButton from '../userComponents/ConfirmButton';

// This is a child component of Proposal Summary Component.
const ProposalNotes = props => {

    const [displayNoteRow, toggleNoteRow] = useState(false)
    const [newNote, setNewNote] = useState('')
    const [notes, setNotes] = useState([])
    const [isUpdated, setIsUpdated] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        setNotes(props.notes)
    }, [props.notes])

    useEffect(() => {
        if (isUpdated) {
            const body = {
                notes: notes
            }
            dispatch(updateProposalNote(body, props.proposal_id))
            setIsUpdated(false)
        }
    }, [props.fireUpdateFunctions])

    const onChangeHandler = (index, value) => {
        notes[index] = value
        setNotes([...notes])
        setIsUpdated(true)
    }
    
    const postProposalNote = () => {
        toggleNoteRow(false)
        const body = {
            notes: [...props.notes, newNote]
        }
        setNewNote('')
        dispatch(updateProposalNote(body, props.proposal_id))
    }
    const removeNote = note => {
        var updatedNotes = notes.filter(e => e !== note)
        setNotes([...updatedNotes])
        const body = {
            notes: updatedNotes
        }
        dispatch(updateProposalNote(body, props.proposal_id))
    }
    if (notes) {
        return (
            <Fragment>
                <ListGroup>
                    <ListGroupItem style={{ display: 'flex', justifyContent: "space-between", paddingRight: "35px" }}>
                        <b>Notes</b>
                        <span
                            style={{ fontSize: "1.5rem", cursor: "pointer" }}
                            onClick={() => toggleNoteRow(!displayNoteRow)}
                        >
                            &#x271A;
                        </span>
                    </ListGroupItem>
                    {
                        displayNoteRow ?
                            <ListGroupItem style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <Input style={{ width: "85%" }}
                                    value={newNote}
                                    onChange={({ target }) => setNewNote(target.value)}
                                />
                                <div style={{ width: "10%", display: "flex", justifyContent: 'space-around', marginTop: "5px" }}>
                                    <Button
                                        className='remove-btn'
                                        color='success'
                                        style={{
                                            color: 'black',
                                        }}
                                        size='sm'
                                        onClick={postProposalNote}
                                    >
                                        &#10003;
                                    </Button>
                                    <Button
                                        className='remove-btn'
                                        color='danger'
                                        style={{
                                            color: 'black',
                                        }}
                                        size='sm'
                                        onClick={() => toggleNoteRow(false)}
                                    >
                                        &#10005;
                                    </Button>
                                </div>
                            </ListGroupItem> : null
                    }
                    {
                        notes.map((note, index) =>
                            <ListGroupItem key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between"
                                }}
                            >
                                {
                                    props.editMode ?
                                        <Input
                                            style={{ width: "85%" }}
                                            value={note}
                                            onChange={({ target }) => onChangeHandler(index, target.value)}
                                        /> :
                                        note
                                }
                                {
                                    props.editMode ?
                                        <ConfirmButton
                                            alertTitle={'Delete this Note?'}
                                            onClick={() => removeNote(note)}
                                            color='danger'
                                            size="sm"
                                        >
                                            &#10005;
                                        </ConfirmButton>
                                        : ''
                                }
                            </ListGroupItem>
                        )
                    }
                </ListGroup>
            </Fragment>
        )
    } else {
        return null
    }

}

export default ProposalNotes
