import React, { Component, Fragment } from 'react';
import { Container, Table, Button, Input, Row, Col, Label } from 'reactstrap';
import { connect } from 'react-redux';
import {
	getProjects,
	deleteProject,
	toggleProjectIndex,
	filterProjects,
} from '../../actions/projectActions';
import { getTasks } from '../../actions/taskActions';
import { getReports } from '../../actions/reportActions';
import { getEmployees, getEmployee } from '../../actions/employeeActions';
import { getCompany } from '../../actions/companyActions';
import ProjectModal from './projectModal';
import PropTypes from 'prop-types';
import ProjectDetails from './ProjectDetails';
import ConfirmButton from '../userComponents/ConfirmButton';
import SortComponent from '../SortComponent';

class ProjectList extends Component {
	state = {
		Overdue: localStorage.getItem('OverduePl') ? localStorage.getItem('OverduePl') : false,
		DepartmentSelector: localStorage.getItem('DepartmentSelectorPl')
			? localStorage.getItem('DepartmentSelectorPl')
			: '',
		EmployeeSelector: localStorage.getItem('EmployeeSelectorPl')
			? localStorage.getItem('EmployeeSelectorPl')
			: '', // fiter related
		status: localStorage.getItem('statusPl')
			? JSON.parse(localStorage.getItem('statusPl'))
			: ['In Progress', 'Completed', 'Hold', 'Review'], // fiter related
		employees: [],
		departments: localStorage.getItem('departmentsPl')
			? JSON.parse(localStorage.getItem('departmentsPl'))
			: [], // fiter related
		RangeStart: localStorage.getItem('RangeStartPl') ? localStorage.getItem('RangeStartPl') : '', // fiter related
		RangeEnd: localStorage.getItem('RangeEndPl') ? localStorage.getItem('RangeEndPl') : '', // fiter related
		resetRangeStartPl: false, // reset fiter related
		resetRangeEndPl: false, // reset fiter related
		resetFilterPl: false, // reset fiter related
		sortCriteria: localStorage.getItem('sortCriteriaPl') ? localStorage.getItem('sortCriteriaPl') : "name",
		sortDirection: localStorage.getItem('sortDirectionPl') ? localStorage.getItem('sortDirectionPl') : 1,
	};
	componentDidMount() {
		this.props.getCompany();
		this.props.getEmployee();
		this.props.getEmployees();
		this.props.getProjects();
		this.props.getTasks();
		this.props.getReports();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.company.company !== this.props.company.company) {
			this.setState({
				status: this.state.status[0].name
					? this.state.status
					: this.state.status.map((e, index) => {
						return {
							name: e,
							filterState: true,
							color: '#b3b3b3',
							index: index,
						};
					}),
				departments: this.props.company.company.departments,
				employees: this.props.employee.employees,
			});
		}
		if (
			prevProps.project.projects !== this.props.project.projects ||
			prevState.status !== this.state.status ||
			prevState.EmployeeSelector !== this.state.EmployeeSelector ||
			prevState.RangeEnd !== this.state.RangeEnd ||
			prevState.RangeStart !== this.state.RangeStart
		) {
			localStorage.setItem('EmployeeSelectorPl', this.state.EmployeeSelector);
			localStorage.setItem('DepartmentSelectorPl', this.state.DepartmentSelector);
			localStorage.setItem('statusPl', JSON.stringify(this.state.status));
			localStorage.setItem('RangeEndPl', this.state.RangeEnd);
			localStorage.setItem('RangeStartPl', this.state.RangeStart);

			var filters = {};
			filters.status = this.state.status.filter((e) => e.filterState).map((e) => e.name);
			if (this.state.EmployeeSelector) {
				filters.user_id = this.state.EmployeeSelector;
			}
			var d = '';
			if (this.state.RangeEnd) {
				d = new Date(this.state.RangeEnd);
				d = new Date(d.setDate(d.getDate() + 1)).toISOString().substr(0, 10);
			}
			// filters.dateRange = [this.state.RangeStart, d];
			this.props.filterProjects(filters, false);
		}

		if (prevState.DepartmentSelector !== this.state.DepartmentSelector) {
			this.setState({
				employees: this.state.DepartmentSelector
					? this.props.employee.employees.filter(
						({ department }) => department === this.state.DepartmentSelector
					)
					: this.props.employee.employees,
			});
		}
		// Resetting the task filtering
		if (prevState.resetRangeStartPl !== this.state.resetRangeStartPl) {
			this.setState({ RangeStart: '' });
			localStorage.setItem('RangeStartPl', '');
		}
		if (prevState.resetRangeEndPl !== this.state.resetRangeEndPl) {
			this.setState({ RangeEnd: '' });
			localStorage.setItem('RangeEndPl', '');
		}
		if (prevState.resetFilterPl !== this.state.resetFilterPl) {
			this.setState({
				RangeEnd: '',
				RangeStart: '',
				EmployeeSelector: '',
				DepartmentSelector: '',
			});
			var status = ['In Progress', 'Completed', 'Hold', 'Review'];
			this.setState({
				status: status.map((e, index) => {
					return {
						name: e,
						filterState: true,
						color: '#b3b3b3',
						index: index,
					};
				}),
			});
			// Removing the user filter preferences from the local storage.
			localStorage.setItem('RangeStartPl', '');
			localStorage.setItem('RangeEndPl', '');
			localStorage.setItem('EmployeeSelectorPl', '');
			localStorage.setItem('statusPl', '');
			localStorage.setItem('DepartmentSelectorPl', '');
		}

		// Local Storage for sortCriteria and Direction
		if (
			prevState.sortCriteria !== this.state.sortCriteria ||
			prevState.sortDirection !== this.state.sortDirection
		) {
			localStorage.setItem('sortCriteriaPl', this.state.sortCriteria);
			localStorage.setItem('sortDirectionPl', this.state.sortDirection);
		}
	}

	overdueFilter = () => {
		this.setState({
			Overdue: !this.state.Overdue,
		});
	};

	statusFilter = (i) => {
		this.setState({
			status: this.state.status.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onDeleteClick = (id) => {
		this.props.deleteProject(id);
	};

	sortFunction = (sortCriteria) => {
		this.setState({
			sortCriteria,
			sortDirection: -this.state.sortDirection,
		});
	};

	render() {
		const { projects, filteredProjects } = this.props.project;
		const { user } = this.props.auth;
		return (
			<Container>
				<ProjectDetails />
				<Row style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline After</Label>
						<Input
							type='date'
							name='RangeStart'
							onChange={this.onChange}
							value={this.state.RangeStart}
						/>
					</Col>
					<div
						style={{
							margin: '2rem 0 0 -0.5rem',
							padding: '.3rem .3rem .2rem .3rem',
							border: '.5px solid #D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ resetRangeStartPl: !this.state.resetRangeStartPl })}>
						X
					</div>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline Before</Label>
						<Input
							type='date'
							name='RangeEnd'
							onChange={this.onChange}
							value={this.state.RangeEnd}
						/>
					</Col>
					<div
						style={{
							margin: '2rem 1rem 0 -0.5rem',
							padding: '.3rem .3rem .2rem .3rem',
							border: '.5px solid 	#D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ resetRangeEndPl: !this.state.resetRangeEndPl })}>
						X
					</div>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>PIC Department</Label>
						<Input
							type='select'
							name='DepartmentSelector'
							onChange={this.onChange}
							value={this.state.DepartmentSelector}>
							<option value=''>Select to Filter PIC</option>
							{this.state.departments.map((e) => (
								<option key={e.id}>{e.name}</option>
							))}
						</Input>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>PIC</Label>
						<Input
							type='select'
							name='EmployeeSelector'
							onChange={this.onChange}
							value={this.state.EmployeeSelector}>
							<option></option>
							{this.state.employees.map(({ _id, name, user_id }) => (
								<option key={_id} value={user_id}>
									{name}
								</option>
							))}
						</Input>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table>
							<tbody>
								<tr>
									{this.state.status.map(({ name, filterState, color, index }) => (
										<td
											key={index}
											width='25%'
											style={{
												opacity: filterState ? '1' : '0.3',
												backgroundColor: color,
												textAlign: 'center',
												border: '1px solid #969696',
												cursor: 'pointer',
											}}
											onClick={this.statusFilter.bind(this, index)}>
											{name}
										</td>
									))}
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				<Fragment>
					<a onClick={() => this.setState({ resetFilterPl: !this.state.resetFilterPl })} href='#'>
						Reset Filter?
					</a>
				</Fragment>

				{user.permissionLevel === 'Manager' || user.permissionLevel === 'Executive' ? (
					<ProjectModal />
				) : null}
				<Table hover>
					<thead style={{ fontSize: '14px' }}>
						<tr>
							<th className='rowAlign' style={{ width: '15%' }}>
								Name
								<SortComponent onClick={() => this.sortFunction('name')} />
							</th>
							<th className='rowAlign' style={{ width: '25%' }}>
								Description
							</th>
							<th className='rowAlign' style={{ width: '10%' }}>
								Status
							</th>
							<th className='rowAlign' style={{ width: '12.5%' }}>
								PIC
							</th>
							<th className='rowAlign' style={{ width: '12.5%' }}>
								Started
								<SortComponent onClick={() => this.sortFunction('startdate')} />
							</th>
							<th className='rowAlign' style={{ width: '12.5%' }}>
								Completed
								<SortComponent onClick={() => this.sortFunction('enddate')} />
							</th>
							<th className='rowAlign' style={{ width: '12.5%' }}>
								Deadline
								<SortComponent onClick={() => this.sortFunction('deadline')} />
							</th>
						</tr>
					</thead>
					<tbody style={{ fontSize: '14px' }}>
						{filteredProjects
							.sort((a, b) => {
								if (a[this.state.sortCriteria] > b[this.state.sortCriteria]) {
									return this.state.sortDirection;
								}
								if (a[this.state.sortCriteria] < b[this.state.sortCriteria]) {
									return -this.state.sortDirection;
								}
								if (a.name > b.name) {
									return 1;
								}
								if (a.name < b.name) {
									return -1;
								}
								return 0;
							})
							.map(({ _id, description, name, status, startdate, enddate, deadline, user_id }) => (
								<tr key={_id}>
									<td className='rowAlign'>
										{projects.map((project) => {
											if (_id === project._id) {
												return (
													<a
														href='#'
														onClick={this.props.toggleProjectIndex.bind(this, _id)}
														key={_id}>
														{name}
													</a>
												);
											}
										})}
									</td>
									<td className='rowAlign' style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>
										{description}
									</td>
									<td className='rowAlign'>{status}</td>
									<td className='rowAlign'>
										{this.state.employees.map((employee) => {
											if (employee.user_id === user_id) {
												return employee.name;
											}
										})}
									</td>
									<td className='rowAlign'>{startdate ? startdate.substring(0, 10) : ''}</td>
									<td className='rowAlign'>{enddate ? enddate.substring(0, 10) : ''}</td>
									<td className='rowAlign'>{deadline ? deadline.substring(0, 10) : ''}</td>
									{user.permissionLevel === 'Executive' ? (
										<td className='rowAlign'>
											<ConfirmButton
												className='remove-btn'
												color='danger'
												size='sm'
												alertTitle={'Remove this Project?'}
												onClick={() => this.onDeleteClick(_id)}>
												&times;
											</ConfirmButton>
										</td>
									) : null}
								</tr>
							))}
					</tbody>
				</Table>
			</Container>
		);
	}
}

ProjectList.propTypes = {
	getProjects: PropTypes.func.isRequired,
	getReports: PropTypes.func.isRequired,
	getEmployees: PropTypes.func.isRequired,
	getEmployee: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	deleteProject: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	getTasks: PropTypes.func.isRequired,
	toggleProjectIndex: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	project: state.projectR, // getting reducer from /reducers/index.js
	task: state.taskR,
	report: state.reportR,
	employee: state.employeeR,
	auth: state.authR,
	company: state.companyR,
});

export default connect(mapStateToProps, {
	getProjects,
	deleteProject,
	toggleProjectIndex,
	getTasks,
	getReports,
	getEmployees,
	getEmployee,
	getCompany,
	filterProjects,
})(ProjectList);
