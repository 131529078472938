import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input,Row,Col,Form, FormGroup,Button } from 'reactstrap'
import { toggleClientModal,createClient } from '../../actions/clientAction';


export class clientModal extends Component {
    state = {
        name:'',
        email:'',
        PIC:'',
        contactNo:'',
        adderess:''
    }
    onChangeInput = ({ target }) => {
        this.setState({ [target.name]: target.value })
    } 
    onSubmit = (e) => {
        e.preventDefault()
        const newClient = {
            name: this.state.name,
            email: this.state.email,
            PIC_name: this.state.PIC,
            contactNo: this.state.contactNo,
            adderess: this.state.adderess 
        }
        this.props.createClient(newClient)
        this.props.toggleClientModal('')
    }
    render() {
        return (
            <Modal isOpen={this.props.client.addModal} toggle={this.props.toggleClientModal.bind(this,'')}>
            <ModalHeader toggle={this.props.toggleClientModal.bind(this,'')}>New Client</ModalHeader>
            <Form onSubmit={this.onSubmit}>
            <ModalBody>
                <Row>
                     <Col>
                         <FormGroup>
                             <Label>Name</Label>
                             <Input type="text" name="name" value={this.state.name} onChange={this.onChangeInput} required /> 
                         </FormGroup>
                     </Col>
                    <Col>
                        <FormGroup>
                            <Label>PIC</Label>
                            <Input type="text" name="PIC" value={this.state.PIC} onChange={this.onChangeInput} required/>
                        </FormGroup>
                    </Col>
                </Row>
                 <Row>
                     <Col>
                        <FormGroup>
                            <Label>Contact Number</Label>
                            <Input type="text" name="contactNo" value={this.state.contactNo} onChange={this.onChangeInput} required />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                            <Label>Adderess</Label>
                            <Input type="text" name="adderess" value={this.state.adderess} onChange={this.onChangeInput} required />
                        </FormGroup>
                     </Col>
                 </Row>
                 <Row>
                     <Col>
                     <FormGroup>
                         <Label>Email</Label>
                         <Input type="email" name="email" value={this.state.email} onChange={this.onChangeInput} required />
                     </FormGroup> 
                     </Col>
                 </Row>
            </ModalBody>
            <ModalFooter>
                <Button type="submit" block>Submit</Button>
            </ModalFooter>
            </Form>
        </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
  client: state.clientR  
})

export default connect(mapStateToProps,{
toggleClientModal, createClient
})(clientModal)
