import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
    Card,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    Button,
    Col,
    Row,
    Alert,
} from "reactstrap";
import { updateEmployeeReward } from '../../actions/employeeActions'
// This is a child component of user page
const UserReward = props => {
    const [myRewards, setMyRewards] = useState([])
    const [displayedIndex, setDisplayIndex] = useState(0)

    const dispatch = useDispatch()

    useEffect(() => {
        if (props.employee.myRewards) {
            setMyRewards(() => {
                var tempArray = [], index = -1
                props.employee.myRewards.map((reward, i) => {
                    index = (i % 4 === 0 ? index + 1 : index)
                    tempArray[index] = tempArray[index] ? [...tempArray[index], reward] : [reward]
                })
                return tempArray
            })
        }
    }, [props.employee])

    const redemReward = (uniqueID) => {
        props.employee.myRewards.map(e => e.uniqueID === uniqueID ? e.status = "Requested" : e)
        console.log(props.employee.myRewards)
        dispatch(updateEmployeeReward(props.employee._id, props.employee.myRewards))
    }
    // It is commented for now but if it is used the gold of th reward needs to be refunded.
    // const deleteReward = (reward_id) => {
    //     props.employee.myRewards.map(e => e.reward_id === reward_id ? e.status = "Deleted" : e)
    //     dispatch(updateEmployeeReward(props.employee._id, props.employee.myRewards))
    // }
    const arrowButtonStyle = {
        cursor: 'pointer',
        fontSize: '1.2rem',
    }
    if (myRewards.length) {
        return (
            <div>
                <br></br>
                <Row>
                    {
                        myRewards[displayedIndex].map((reward, index) => {
                            if (reward) {
                                return (
                                    <Col xs="3" className="mb-4" key={index}>
                                        <Card
                                            style={{
                                                height: "330px",
                                                paddingBottom: '0px'
                                            }}
                                        >
                                            <CardImg
                                                top
                                                style={{
                                                    height: "160px",
                                                    width: "100%",
                                                }}
                                                src={reward.photo}
                                                alt="Reward Image"
                                            />
                                            <CardBody>
                                                <CardTitle style={{ marginTop: "0px", marginBottom: "0px" }}>
                                                    {reward.name}

                                                    {/*         The delete button is commented for now*/}
                                                    {/* <span
                                                //     onClick={() => deleteReward(reward._id)}
                                                //     style={{
                                                //         position: "absolute",
                                                //         bottom: '290px',
                                                //         right: '5px',
                                                //         color: "red",
                                                //         cursor: "pointer",
                                                //         fontSize: "1.5rem",
                                                //         margin: "0px",
                                                //         padding: '0px',
                                                //     }}
                                                // >
                                                //     &#10008;
                                                // </span> */}
                                                </CardTitle>
                                                <CardText style={{ marginBottom: "0px", marginTop: '0px' }}>
                                                    <span style={{ fontSize: "15px" }}>
                                                        {reward.description}
                                                    </span>
                                                    <p style={{ marginBottom: "0px" }}>
                                                        Purchased Date: {reward.purchasedDate.substring(0, 10)}
                                                    </p>
                                                    <p style={{ marginBottom: "0px", paddingBottom: "0px" }}>
                                                        Price: {reward.price} Gold
                                                </p>
                                                </CardText>
                                                {
                                                    reward.status === "Purchased" ?
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            style={{ marginTop: "0px" }}
                                                            onClick={() => (redemReward(reward.uniqueID))}
                                                        >
                                                            Redeem
                                                    </Button> :
                                                        reward.status === "Requested" ?
                                                            < Alert style={{
                                                                backgroundColor: '#6da832',
                                                                paddingTop: '0px',
                                                                textAlign: "center",
                                                            }}>
                                                                <span style={{ position: 'relative', top: '5px' }}>Requested</span>
                                                            </Alert>
                                                            :
                                                            < Alert style={{
                                                                backgroundColor: '#a8eb34',
                                                                paddingTop: '0px',
                                                                textAlign: "center",
                                                            }}>
                                                                <span style={{ position: 'relative', top: '5px' }}>Redeemed</span>
                                                            </Alert>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )

                            }
                            // console.log(reward)



                        })

                    }
                </Row>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <p className="mr-4"
                        onClick={() => setDisplayIndex(i => i > 0 ? i - 1 : i)}
                        style={arrowButtonStyle}
                    >
                        &#10094;
                </p>
                    <p className="mt-1">{displayedIndex + 1} / {myRewards.length}</p>
                    <p className="ml-4"
                        onClick={() => setDisplayIndex(i => i < (myRewards.length - 1) ? i + 1 : i)}
                        style={arrowButtonStyle}

                    >
                        &#10095;
                </p>
                </div>
            </div >
        )
    } else {
        return null
    }

}

export default UserReward;
