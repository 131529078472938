import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addTask } from '../../actions/taskActions';
import { addTasktoProject } from '../../actions/projectActions';

class TaskModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			estimatedTime: {
				estimate: { weeks: 0, days: 0, hours: 0, minutes: 0 },
			},
		};
		this.onChange = this.onChange.bind(this);
		this.onChangeEstimatedTime = this.onChangeEstimatedTime.bind(this);
	}

	componentDidMount() {
		var date = new Date(); // It provide a default value for the dates in input boxes
		date = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
			2,
			'0'
		)}-${date.getDate()}`;
		this.setState({
			creationDate: date,
		});
	}
	componentDidUpdate(prevProps) {
		if (prevProps.company.company.departments !== this.props.company.company.departments) {
			this.setState({
				departments: this.props.company.company.departments,
			});
		}
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onChangeEstimatedTime = (e) => {
		const { name, value } = e.target;
		const timeSpread = { ...this.state.estimatedTime };
		timeSpread['estimate'][name] = value;
		this.setState({ estimatedTime: timeSpread });
	};

	updateProject = (e) => {
		const project = this.props.project.projects.find((project) => project._id === e.target.value);
		const proposal_id = project ? project.proposal_id : '';
		const proposalProject = project ? project.proposal : false; //used to indentify the special proposal project

		this.setState({
			[e.target.name]: e.target.value,
			proposal_id,
			proposalProject,
		});
	};

	onSubmit = (e) => {
		e.preventDefault();

		const newTask = {
			name: this.state.name,
			deadline: this.state.deadline,
			creationDate: this.state.creationDate,
			project_id: this.state.project_id,
			reward: this.state.reward,
			department: this.state.department,
			description: this.state.description,
			user_id: this.state.user_id,
			status: 'In Progress',
			detail_id: this.state.detail_id,
			estimatedTime: [this.state.estimatedTime],
		};
		if (this.state.task_proposal_id) {
			newTask.proposal_id = this.state.task_proposal_id;
		}
		// Add task via addTask action
		this.props.addTask(newTask);
		//close modal
		this.setState({
			modal: false,
			name: '',
			project_id: '',
			reward: '',
			department: '',
			departments: [],
			deadline: '',
			description: '',
			user_id: '',
			proposal_id: '',
			estimatedTime: {
				estimate: { weeks: 0, days: 0, hours: 0, minutes: 0 },
			},
		});
	};

	render() {
		const { projects } = this.props.project;
		const { employees } = this.props.employee;
		return (
			<Fragment>
				<Button color='dark' style={{ marginBottom: '2rem' }} onClick={this.toggle}>
					Add Task
				</Button>

				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Create New Task</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label>Project</Label>
								<Input
									type='select'
									name='project_id'
									defaultValue={this.state.project_id}
									onChange={this.updateProject}
									required>
									<option value=''>Select Project</option>
									{projects
										.filter((project) => !project.proposal_id
											// hide proposal
											? project._id !== '5f224a84b2fbde22f5fee090'
											: false
										)
										.sort((a, b) => {
											if (a.name > b.name) {
												return -1;
											}
											if (b.name > a.name) {
												return 1;
											}
											return 0;
										})
										.map(({ _id, name, status }) =>
											status === 'In Progress' ? (
												<option key={_id} value={_id}>
													{name}
												</option>
											) : (
												''
											)
										)}
								</Input>
							</FormGroup>

							<FormGroup>
								<Label for='TaskName'>Name</Label>
								<Input
									type='text'
									name='name'
									id='TaskName'
									placeholder='Add Task Name'
									defaultValue={this.state.name}
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for='TaskDescription'>Description</Label>
								<Input
									type='textarea'
									name='description'
									id='descriptionBox'
									placeholder='Add Task Description'
									defaultValue={this.state.description}
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for='Department'>Department</Label>
								<Input
									type='select'
									name='department'
									id='Department'
									defaultValue={this.state.department}
									onChange={this.onChange}
									required>
									<option value=''>Select Department</option>
									{this.props.company.company.departments
										? this.props.company.company.departments.map((department, index) => (
											<option key={department.id} value={department.id}>
												{department.name}
											</option>
										))
										: null}
								</Input>
							</FormGroup>
							<FormGroup>
								<Label>Assign To?</Label>
								<Input
									type='select'
									name='user_id'
									defaultValue={this.state.user_id}
									id='user_id'
									onChange={this.onChange}>
									<option value=''>Select PIC</option>
									{employees
										.filter((employee) => employee.permissionLevel !== 'Disabled')
										.map(({ _id, user_id, name }) => (
											<option key={_id} value={user_id}>
												{name}
											</option>
										))}
								</Input>
								<Label style={{ fontSize: '12px', color: 'grey' }}>Not Required</Label>
							</FormGroup>

							<FormGroup>
								<Label for='estimatedTime' style={{ display: 'flex' }}>
									Estimated Time{' '}
									<div
										style={{
											color: '#888',
											marginLeft: '1rem',
											fontStyle: 'italic',
										}}>
										(all fields are not required!)
									</div>
								</Label>
								<div style={{ display: 'flex' }}>
									<div>
										<Label for='estimatedTime'>week</Label>
										<Input
											type='number'
											name='weeks'
											id='weeks'
											value={this.state.estimatedTime.estimate.weeks}
											onChange={this.onChangeEstimatedTime}></Input>
									</div>

									<div>
										<Label for='estimatedTime'>day</Label>
										<Input
											type='number'
											name='days'
											id='days'
											value={this.state.estimatedTime.estimate.days}
											onChange={this.onChangeEstimatedTime}></Input>
									</div>
									<div>
										<Label for='estimatedTime'>hour</Label>
										<Input
											type='number'
											name='hours'
											id='estimatedTime'
											value={this.state.estimatedTime.estimate.hours}
											onChange={this.onChangeEstimatedTime}></Input>
									</div>

									<div>
										<Label for='estimatedTime'>min</Label>
										<Input
											type='number'
											name='minutes'
											id='minutes'
											value={this.state.estimatedTime.estimate.minutes}
											onChange={this.onChangeEstimatedTime}></Input>
									</div>
								</div>
							</FormGroup>
							{/* {  // TODO this part is commented out for now as we are not using it!} */}
							{/* <FormGroup>
								<Label for='Reward'>Difficulty</Label>
								<Input
									type='select'
									name='reward'
									id='Reward'
									defaultValue={this.state.reward}
									onChange={this.onChange}
									required>
									<option value=''>Select Difficulty Level</option>
									<option value={1}>1 (1-2 Day Task)</option>
									<option value={2}>2 (1 week or below)</option>
									<option value={3}>3 (Above 1 week)</option>
								</Input>
							</FormGroup> */}
							<FormGroup>
								<Label for='deadline'>Task Deadline</Label>
								<Input
									type='date'
									name='deadline'
									id='deadline'
									placeholder='Add Task Deadline'
									defaultValue={this.state.deadline}
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<Button color='dark' style={{ marginTop: '2rem' }} block>
								Add Task
							</Button>
						</Form>
					</ModalBody>
				</Modal>
			</Fragment>
		);
	}
}

TaskModal.propTypes = {
	addTask: PropTypes.func.isRequired,
	addTasktoProject: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js
	company: state.companyR,
	employee: state.employeeR,
	detail: state.detailR,
	proposal: state.proposalR,
});

export default connect(mapStateToProps, { addTask, addTasktoProject })(TaskModal);
