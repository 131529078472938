import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Alert } from 'reactstrap';

const CommentModal = ({
	toggle,
	modal,
	comment,
	setComment,
	updateStatusApprove,
	updateStatusReject,
	leaveStatus,
	message,
	alertMessage,
}) => {
	const cancel = leaveStatus === 'cancel';
	const error = message === 'Error: Email could not be sent!';

	return (
		<div>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader style={{ border: 'none' }} toggle={toggle}>
					Add Comment
				</ModalHeader>

				<ModalBody>
					{alertMessage ? (
						<Alert style={{ textAlign: 'center' }} color='danger'>
							{alertMessage}
						</Alert>
					) : null}
					{message ? <Alert color={error ? 'danger' : 'info'}>{message}</Alert> : null}
					<Input
						type='textarea'
						rows={8}
						value={comment}
						placeholder='Add reason ...'
						onChange={(e) => setComment(e.target.value)}
					/>
				</ModalBody>
				<ModalFooter style={{ border: 'none' }}>
					<Button
						size='sm'
						color={cancel ? 'danger' : 'success'}
						onClick={() => (cancel ? updateStatusReject() : updateStatusApprove())}>
						{cancel ? 'Reject' : 'Approve'}
					</Button>
					<Button size='sm' color='secondary' onClick={toggle}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default CommentModal;
