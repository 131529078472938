import {
    GET_EXPENSES,
    CREATE_EXPENSE,
    DELETE_EXPENSE,
    GET_EXPENSE,
    UPDATE_EXPENSE,
    APPROVE_EXPENSE,
    EXPENSE_LOADING,
    CREATE_EXPENSE_FAIL,
    UPDATE_EXPENSE_FAIL,
    APPROVAL_EXPENSE_FAIL,
    DELETE_EXPENSE_FAIL
} from '../actions/types';

const initialState = {
    expenses: [],
    expense: '',
    loading: false,
    message: null,
    errorMessage: null,
}

const expenseReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_EXPENSES: return {
            ...state, expenses: action.payload,
            loading: false,
        }
        case GET_EXPENSE: return {
            ...state,
            expense: action.payload,
            loading: false,
        };
        case CREATE_EXPENSE: return {
            ...state,
            expense: action.payload,
            expenses: [action.payload, ...state.expenses],
            message: 'Expense Requested ',
            loading: false,

        };
        case DELETE_EXPENSE: return {
            ...state,
            expenses: state.expenses.filter((expense) => expense._id !== action.payload),
            message: 'Expense Request Deleted ',
            loading: false,

        };
        case UPDATE_EXPENSE: return {
            ...state,
            expense: action.payload,
            expenses: state.expenses.map((expense) => {
                if (expense._id === action.payload._id) {
                    return action.payload;
                } else {
                    return expense;
                }
            }),
            message: 'Expense Updated Successfully',
            loading: false,
        }
        case APPROVE_EXPENSE: return {
            ...state,
            expense: action.payload,
            expenses: state.expenses.map((expense) => {
                if (expense._id === action.payload._id) {
                    return action.payload;
                } else {
                    return expense;
                }
            }),
            message: 'Expense Approval Submitted',
            loading: false,
        }
        case EXPENSE_LOADING: return {
            ...state,
            message: null,
            loading: true,
        };
        case CREATE_EXPENSE_FAIL: return {
            ...state,
            errorMessage: 'Expense Request Failed',
            loading: false,
        };
        case UPDATE_EXPENSE_FAIL: return {
            ...state,
            errorMessage: 'Expense Update Failed',
            loading: false,
        };
        case APPROVAL_EXPENSE_FAIL: return {
            ...state,
            errorMessage: 'Expense Approval Failed',
            loading: false,
        };
        case DELETE_EXPENSE_FAIL: return {
            ...state,
            errorMessage: 'Expense Delete Failed',
            loading: false,
        };
        default: return state;
    }
}
export default expenseReducer;
