//              This Component is called as a child in ProposalSummary Component   \\
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Table, Card, CardHeader, CardBody, Input, FormGroup, Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import { createPayBreakDown, updatePayBreakDown } from '../../actions/stageActions'
import ConfirmButton from '../userComponents/ConfirmButton'

const BillingSchedule = props => {
    const [displayNewRow, toggleNewRow] = useState(false)  // To display the new pay breakdown row.  
    const [step, setStep] = useState(''); // Used for adding a new paybreak down
    const [stage_id, setStage_id] = useState(''); // Used for adding a new paybreak down
    const [percentage, setPercentage] = useState(''); // Used for adding a paybreak down
    const [totalAmounts, setTotalAmounts] = useState(0)
    const [totalTaxes, setTotalTaxes] = useState(0)
    const [stages, setStages] = useState([]) // The stages array coming from Proposal Summary component.
    const user = useSelector(state => state.authR.user)

    const updatedStages = useRef([])

    const dispatch = useDispatch()

    useEffect(() => {
        setStages([...props.stages])
    }, [props.stages])

    useEffect(() => { // To calculate Total Amounts and Total Taxes. 
        var tempTaxes = 0, tempAmounts = 0;
        stages.map(stage => stage.payBreakdown.map(pay => {
            tempAmounts += (pay.percentage / 100) * stage.fees
            tempTaxes += (pay.percentage / 100) * stage.fees * 0.06
        }))
        setTotalAmounts(tempAmounts);
        setTotalTaxes(tempTaxes);
    }, [stages])

    useEffect(() => {
        if (updatedStages.current.length) {
            updatedStages.current.map(e => {
                var body = {
                    payBreakdown: e.payBreakdown
                }
                dispatch(updatePayBreakDown(body, e._id))
            })
            updatedStages.current = []
        }
    }, [props.fireUpdateFunctions])

    const onChangeHandler = (index, ind, { name, value }) => {
        setStages(stages => {
            stages[index].payBreakdown[ind][name] = name === 'step' ? value : Number(value)
            return [...stages]
        })
        updatedStages.current[index] = stages[index]
    }
    const addStep = () => {
        if (!stage_id || !step || !percentage) {
            return alert('Information is not complete')
        }
        const newStep = {
            step: step,
            percentage: percentage,
            invoice_id: null
        }
        dispatch(createPayBreakDown(newStep, stage_id))
        toggleNewRow(false)
    }

    const deleteStep = (stageIndex, stepIndex, stage_id) => {
        var body = {}
        body.payBreakdown = stages[stageIndex].payBreakdown.filter((e, i) => i !== stepIndex)
        dispatch(updatePayBreakDown(body, stage_id))

    }
    return (
        <Card>
            <CardHeader style={{ textAlign: 'center' }}>Billing Schedule</CardHeader>
            <CardBody>
                <Table>
                    <thead>
                        <tr>
                            <th width="15">Stage</th>
                            <th width="20%">Step</th>
                            <th width="13%" style={{ textAlign: displayNewRow ? 'center' : "start" }}>%</th>
                            <th width="20%">Amount (excl tax)</th>
                            <th width="10%">SST (6%)</th>
                            <th width="20%" style={{ textAlign: 'center' }}>Total</th>
                            <th width="2%" onClick={() => toggleNewRow(!displayNewRow)}>
                                <span style={{ fontSize: '1.5rem', position: 'relative', top: "6px", cursor: 'pointer' }}>
                                    &#x271A;
                                </span>
                            </th>
                        </tr>

                    </thead>
                    <tbody>
                        {   // The row for adding a new pay breakdown .
                            displayNewRow ?
                                <tr>
                                    <td>
                                        <FormGroup>
                                            <Input
                                                name='stage' type="select" style={{ width: "80%" }}
                                                onChange={({ target }) => setStage_id(target.value)}
                                            >
                                                <option></option>
                                                {
                                                    stages.filter(({ fees }) => fees).map(stage => <option key={stage._id} value={stage._id}>{stage.stage}</option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup>
                                            <Input
                                                type="text"
                                                onChange={({ target }) => setStep(target.value)}

                                            />
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup>
                                            <Input
                                                name='percentage'
                                                type="number"
                                                style={{ marginRight: '0px', marginLeft: '0px' }}
                                                onChange={({ target }) => setPercentage(Number(target.value))}

                                            />
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <Button
                                            className='remove-btn'
                                            color='success'
                                            style={{
                                                position: "relative", left: "480px",
                                                color: 'black',
                                            }}
                                            size='sm'
                                            onClick={addStep}
                                        >
                                            &#10003;
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            className='remove-btn'
                                            color='danger'
                                            style={{
                                                position: "relative", left: "320px",
                                                color: 'black',
                                            }}
                                            onClick={() => toggleNewRow(false)}
                                            size='sm'
                                        >
                                            &#10005;
                                        </Button>

                                    </td>
                                </tr>
                                : null
                        }
                        {                           // Display the pay breakdowns array 
                            stages.map((stage, index) => {
                                if (stage.payBreakdown) {
                                    return stage.payBreakdown.map((pay, ind) => {
                                        return (
                                            <tr key={ind}>
                                                <td>{stage.stage}</td>
                                                <td>
                                                    {
                                                        props.editMode ?
                                                            <Input type="text" name='step'
                                                                defaultValue={pay.step} onChange={({ target }) => onChangeHandler(index, ind, target)}
                                                            /> :
                                                            pay.step
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        props.editMode ?
                                                            <Input type="number" name='percentage'
                                                                defaultValue={pay.percentage}
                                                                onChange={({ target }) => onChangeHandler(index, ind, target)}
                                                            /> :
                                                            pay.percentage
                                                    }
                                                </td>
                                                {user.permissionLevel === "Executive"
                                                    ? <Fragment>
                                                        <td>{((pay.percentage / 100) * stage.fees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td>{(((pay.percentage / 100) * stage.fees) * 0.06).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                                        <td>
                                                            <span style={{ marginLeft: "70px" }}>
                                                                {(((pay.percentage / 100) * stage.fees) * 1.06).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                                            </span>
                                                        </td>
                                                        <td style={{ paddingLeft: '0px' }}>
                                                            {
                                                                props.editMode ?
                                                                    <ConfirmButton
                                                                        alertTitle={'Remove the payment step?'}
                                                                        onClick={() => deleteStep(index, ind, stage._id)}
                                                                        color='danger'
                                                                        size="sm"
                                                                    >
                                                                        &#10005;
                                                            </ConfirmButton> :
                                                                    pay.invoice_id ?
                                                                        <Button
                                                                            style={{
                                                                                borderRadius: '10px',
                                                                                fontSize: 12,
                                                                                paddingTop: 0,
                                                                                paddingBottom: 0
                                                                            }}
                                                                            onClick={() => {
                                                                                props.togglePDF(true)
                                                                                props.setStage(stage)
                                                                                props.setStepIndex(ind)
                                                                            }}
                                                                            color="info"
                                                                        >
                                                                            View Invoice
                                                                </Button> :
                                                                        <ConfirmButton
                                                                            style={{
                                                                                borderRadius: '10px',
                                                                                fontSize: 12,
                                                                                paddingTop: 0,
                                                                                paddingBottom: 0
                                                                            }}
                                                                            onClick={() => {
                                                                                props.togglePDF(true)
                                                                                props.setStage(stage)
                                                                                props.setStepIndex(ind)
                                                                            }}
                                                                            alertTitle={'Create New Invoice'}
                                                                            color="primary"
                                                                        >
                                                                            Generate Invoice
                                                                </ConfirmButton>
                                                            }
                                                        </td>
                                                    </Fragment>
                                                    : <Fragment>
                                                        <td>N/A</td>
                                                        <td>N/A</td>
                                                        <td>N/A</td>
                                                        <td>N/A</td>
                                                    </Fragment>}

                                            </tr>)
                                    }
                                    )
                                }
                            })
                        }
                        {
                            user.permissionLevel === "Executive" && totalTaxes && totalAmounts ?
                                <tr>
                                    <td colSpan="3">Total</td>
                                    <td>{(totalAmounts).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    <td>{(totalTaxes).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                                    <td colSpan="2">
                                        <span style={{ marginLeft: "70px" }}>
                                            {(totalTaxes + totalAmounts).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                        </span>
                                    </td>
                                </tr> :
                                null
                        }
                    </tbody>
                </Table>
            </CardBody>
        </Card >
    )
}
export default BillingSchedule