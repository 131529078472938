import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Form, FormGroup, Label, Input, Row,
    Col, Table, Modal, ModalBody,
    ModalFooter, ModalHeader, Button
} from 'reactstrap'
import { toggleProposalModal, createProposal, createProposalAndClient } from "../../actions/proposalActions";
import { createStage } from '../../actions/stageActions'

class NewProposal extends Component {
    state = {
        client: '',
        newClient: '',
        proposalDescription: '',
        numberOfStages: [1], // It can be increased. 
        employeeId: '',
        stagesDescriptions: [],// All stages Descriptions

    }
    increaseRow = () => {
        const stageNumbers = this.state.numberOfStages
        stageNumbers.push(stageNumbers[stageNumbers.length - 1] + 1)
        this.setState({ numberOfStages: stageNumbers })
    }
    decreaseRow = () => {
        if (this.state.numberOfStages.length > 1) {
            const stageNumbers = this.state.numberOfStages
            stageNumbers.pop()
            this.setState({ numberOfStages: stageNumbers })
        }
    }
    getInput = ({ target }) => {
        this.setState({ [target.name]: target.value })
    }
    getStageInput = (rowNumber, e) => {
        const stagesDescription = this.state.stagesDescriptions
        stagesDescription[rowNumber - 1] = e.target.value
        this.setState({ stagesDescriptions: stagesDescription })
    }
    onSubmit = (e) => {
        e.preventDefault()
        // The below lines are getting the proposal Nmmber
        var proposalNumbers = [], date = new Date().getFullYear().toString().substring(2, 4) // The date will be the last 2 digits of current year (eg: 20)
        this.props.proposal.proposals.map(({ proposalNumber }) => proposalNumber.substring(proposalNumber.length - 2) === date ? proposalNumbers.push(proposalNumber) : null)
        if (proposalNumbers.length) { // 
            var proposalNumber = proposalNumbers.sort()[proposalNumbers.length - 1] // Getting the last element as it will be the greates one.
            proposalNumber = Number(proposalNumber.substring(2, proposalNumber.length - 2)) + 1
            proposalNumber = String(proposalNumber).length === 1 ? "JP0" + proposalNumber + date : "JP" + proposalNumber + date
        } else {
            var proposalNumber = "JP01" + date
        }
        /// At this point there is a proposal number stored in ProposalNumber variable.
        const newProposal = {
            proposalNumber: proposalNumber,
            description: this.state.proposalDescription,
            createdBy_id: this.props.auth.user.id,
            PIC_id: this.state.employeeId,
            status: "In Progress"
        }
        this.state.numberOfStages.forEach(stage => {
            const newStage = { // Creating the stage based on the number of stages.
                stage: stage,
                proposalNumber: newProposal.proposalNumber,
                description: this.state.stagesDescriptions[stage - 1],
                revisionNumber: 0
            }
            this.props.createStage(newStage) // Dispatching the action. 
        })
        if (this.state.newClient) {
            const newClient = {
                name: this.state.newClient
            }
            this.props.createProposalAndClient(newClient, newProposal) // Creating proposal and client
        } else {
            newProposal.client_id = this.state.client
            this.props.createProposal(newProposal) // Or just creating proposal.
        }
        this.setState({ // Cleaning up the state.
            client: '', newClient: '', proposalDescription: '',
            numberOfStages: [1], employeeId: '', stagesDescriptions: []
        })
        this.props.toggleProposalModal('addModal')   // Closing the proposal modal.
    }
    render() {
        return (
            <Modal isOpen={this.props.proposal.addModal} toggle={this.props.toggleProposalModal.bind(this, "addModal")}>
                <ModalHeader toggle={this.props.toggleProposalModal.bind(this, "addModal")} >New Proposal</ModalHeader>
                <Form onSubmit={this.onSubmit}>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Client</Label>
                                    <Input type="select" name="client" onChange={this.getInput} required >
                                        <option value="">Select Client</option>
                                        {
                                            this.props.clients.map(e => {
                                                return (
                                                    <option key={e._id} value={e._id}>{e.name}</option>
                                                )
                                            })
                                        }
                                        <option>New Client</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>New Client</Label>
                                    <Input type="text" name="newClient"
                                        disabled={this.state.client === "New Client" ? false : true}
                                        onChange={this.getInput}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Description</Label>
                            <Input type="textarea"
                                name="proposalDescription"
                                value={this.state.proposalDescription}
                                placeholder="Some Details.."
                                onChange={this.getInput}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Assign To?</Label>
                            <Input type="select" name="employeeId" value={this.state.employeeId} onChange={this.getInput} placeholder="Optional" >
                                <option value="" >Select Employee</option>
                                {
                                    this.props.employees.map(e => {
                                        return (
                                            <option key={e.user_id} value={e.user_id}>{e.name}</option>
                                        )
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <Table>
                            <thead>
                                <tr>
                                    <th width="8%">Stage</th>
                                    <th width="82%">Stage Description</th>
                                    <th width="2%" onClick={this.decreaseRow} style={{ cursor: "pointer" }}>&#9866;</th>
                                    <th width="2%" onClick={this.increaseRow} style={{ cursor: "pointer" }}>&#10010;</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state.numberOfStages.map(row => {
                                        return (<tr key={row}>
                                            <td>{row}</td>
                                            <td colSpan="3">
                                                <Input
                                                    type="text" name="stagesDescriptions"
                                                    onChange={this.getStageInput.bind(this, row)}
                                                    required={true}
                                                />
                                            </td>
                                        </tr>

                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" color="primary" block>Submit</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        )
    }
}
const mapStateToProps = state => ({
    proposal: state.proposalR,
    clients: state.clientR.clients,
    auth: state.authR,
    employees: state.employeeR.employees
})
export default connect(mapStateToProps, {
    toggleProposalModal, createProposal, createProposalAndClient, createStage
})(NewProposal)
