import axios from 'axios';
import { returnErrors } from './errorActions';

import {
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_SUCCESS,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	RESET_SUCCESS,
	RESET_FAIL,
} from './types';
import { addEmployee } from './employeeActions';

// Validate token and load user
export const loadUser = () => (dispatch, getState) => {
	//User Loading
	//dispatch({ type: USER_LOADING })

	axios
		.get('/api/auth/user', tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: USER_LOADED,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
			dispatch({
				type: AUTH_ERROR,
			});
		});
};

//Register User
export const register = (
	{ company_id, email, password, password2, permissionLevel, points },
	name,
	billingUser //This is used during initial registration to make the first accuont the main billing user
) => (dispatch) => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	//Request Body
	const body = JSON.stringify({
		company_id,
		email,
		password,
		password2,
		permissionLevel,
		points,
		billingUser: billingUser ? true : false,
	});

	axios
		.post('api/users/register', body, config)
		.then((res) => {
			dispatch({
				type: REGISTER_SUCCESS,
				payload: res.data,
			});
			var emailObject = {
				template: 'ResetPassword',
				email,
				title: 'Welcome to Qworky',
				description:
					'Thanks for joining our little community. We do look forward to helping make our tools work for you. Contact us to see how we can improve your experience',
				descriptiontitle: 'Welcome',
				buttonLink: 'https://qworky.herokuapp.com/',
				buttontitle: 'Get Started',
				key: 'nnnlwe19219n1b2--u0qjasca',
			};
			var object = JSON.stringify(emailObject);
			axios.post(
				'https://script.google.com/macros/s/AKfycbwaVATD7Fvhqs3xKzhr-dvgbSTXY52h_rbFxN3PRfRuC3U58VD8/exec',
				object
			);
			dispatch(addEmployee(res.data, name, points));
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL'));
			dispatch({
				type: REGISTER_FAIL,
			});
		});
};
//Login User
export const login = ({ email, password }) => (dispatch) => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	//Request Body
	const body = JSON.stringify({
		email,
		password,
	});

	axios
		.post('api/auth', body, config)
		.then((res) =>
			dispatch({
				type: LOGIN_SUCCESS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
			dispatch({
				type: LOGIN_FAIL,
			});
		});
};

//Logout User
export const logout = () => {
	return {
		type: LOGOUT_SUCCESS,
	};
};

//Reset Password
export const resetPassword = (user) => (dispatch) => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	axios
		.post('api/users/resetPassword/request', user, config)
		.then((res) => {
			dispatch({
				type: RESET_SUCCESS,
				payload: res.data,
			});
			user.key = 'nnnlwe19219n1b2--u0qjasca';
			user.template = 'ResetPassword';
			user.title = 'Link to Reset Password';
			user.descriptiontitle = 'Reset Password';
			user.description =
				'You are recieving this because you (or someone else) has requested the reset of the password for your account. Use the reset button below within one hour of recieving it.';
			user.buttonLink = `https://qworky.herokuapp.com/changepassword/${res.data.token}`;
			user.buttontitle = 'Reset';
			var object = JSON.stringify(user);
			axios.post(
				'https://script.google.com/macros/s/AKfycbwaVATD7Fvhqs3xKzhr-dvgbSTXY52h_rbFxN3PRfRuC3U58VD8/exec',
				object
			);
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'RESET_FAIL'));
			dispatch({
				type: RESET_FAIL,
			});
		});
};

//Change Password
export const changePassword = ({ token, password, password2 }) => (dispatch) => {
	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	config.headers['x-auth-token'] = token;

	//Request Body
	const body = JSON.stringify({
		password,
		password2,
	});
	axios
		.post('../api/users/resetPassword', body, config)
		.then((res) =>
			dispatch({
				type: RESET_SUCCESS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'RESET_FAIL'));
			dispatch({
				type: RESET_FAIL,
			});
		});
};

// Setup config/headers and token
export const tokenConfig = (getState) => {
	//Get token from localstorage.. refer to authR under src/reducers/index.js
	const token = getState().authR.token;

	// Headers
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	//if there is a token then add it to the header
	if (token) {
		config.headers['x-auth-token'] = token;
	}

	return config;
};
