//Company related types
export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANIES = 'GET_COMPANIES';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const COMPANY_LOADING = 'COMPANY_LOADING';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';
export const GET_USERS = 'GET_USERS';
export const ADD_COMPANY = 'ADD_COMPANY';
export const TOGGLE_DEPARTMENT_MODAL = 'TOGGLE_DEPARTMENT_MODAL';
export const TOGGLE_USER_MODAL = 'TOGGLE_USER_MODAL';
export const FILTER_COMPANY = 'FILTER_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const TOGGLE_PERMISSION_MODAL = 'TOGGLE_PERMISSION_MODAL';
export const OPEN_DEPARTMENT_MODAL = 'OPEN_DEPARTMENT_MODAL';
export const TOGGLE_MODULE_MODAL = 'TOGGLE_MODULE_MODAL';
export const GET_COMPANY_TIME_INPUTS = 'GET_COMPANY_TIME_INPUTS';
export const UPDATE_COMPANY_TIME_INPUTS = 'UPDATE_COMPANY_TIME_INPUTS';
export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const ADD_PROPOSAL_TASK = 'ADD_PROPOSAL_TASK';
export const UPDATE_PROPOSAL_TASK = 'UPDATE_PROPOSAL_TASK';

// Reward related types
export const GET_REWARDS = 'GET_REWARDS';
export const CREATE_REWARD = 'CREATE_REWARD';
export const DELETE_REWARD = 'DELETE_REWARD';
export const UPDATE_REWARD = 'UPDATE_REWARD';
export const REDUCE_REWARD_AVAILABLE_NUMBER = 'REDUCE_REWARD_AVAILABLE_NUMBER';

//Employee related types
export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_POINTS = 'UPDATE_EMPLOYEE_POINTS';
export const EMPLOYEE_LOADING = 'EMPLOYEE_LOADING';
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL';
export const ADD_PHOTO = 'ADD_PHOTO';
export const PURCHASE_REWARD = 'PURCHASE_REWARD';
export const UPDATE_EMPLOYEE_REWARD = 'EMPLOYEE_REWARD_REWARD';
export const FILTER_EMPLOYEE = 'FILTER_EMPLOYEE';
export const TOGGLE_EDIT_MODAL = 'TOGGLE_EDIT_MODAL';
export const REDEEM_ANNUAL_LEAVES = 'REDEEM_ANNUAL_LEAVES';
export const UPDATE_EMPLOYEE_LEAVE = 'UPDATE_EMPLOYEE_LEAVE';

//Project related types
export const GET_PROJECTS = 'GET_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_PROJECT_PROPOSAL = 'ADD_PROJECT_PROPOSAL'; //Creating a project from proposal information.
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const GET_PROJECT = 'GET_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';
export const PROJECT_LOADING = 'PROJECT_LOADING';
export const TOGGLE_PROJECT_MODAL = 'TOGGLE_PROJECT_MODAL';
export const FILTER_PROJECT = 'FILTER_PROJECT';
export const ASSIGN_PROJECT = 'ASSIGN_PROJECT';
export const UNASSIGN_PROJECT = 'UNASSIGN_PROJECT';

//Task related types
export const GET_TASKS = 'GET_TASKS';
export const ADD_TASK = 'ADD_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const GET_TASK = 'GET_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL';
export const TASK_LOADING = 'TASK_LOADING';
export const TOGGLE_TASK_MODAL = 'TOGGLE_TASK_MODAL';
export const REQUEST_TASK = 'REQUEST_TASK';
export const APPROVE_TASK = 'APPROVE_TASK';
export const TOGGLE_REVIEW_MODAL = 'TOGGLE_REVIEW_MODAL';
export const REVIEW_TASK = 'REVIEW_TASK';
export const REMOVE_TASK = 'REMOVE_TASK'; // It remove the task from tasks array based on its ID.
export const COMMENT_TASK = 'COMMENT_TASK';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const CANCEL_TASK = 'CANCEL_TASK';
export const FILTER_TASK = 'FILTER_TASK';
export const UPDATE_TASK_REPORT = 'UPDATE_TASK_REPORT';

//Report related types
export const GET_REPORTS = 'GET_REPORTS';
export const ADD_REPORT = 'ADD_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';
export const GET_REPORT = 'GET_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const UPDATE_REPORT_FAIL = 'UPDATE_REPORT_FAIL';
export const REPORT_LOADING = 'REPORT_LOADING';
export const TOGGLE_REPORT_MODAL = 'TOGGLE_REPORT_MODAL';
export const FILTER_REPORT = 'FILTER_REPORT';

// Authentication/Login/Password Resetion and Registration related types
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
// User Notifications types
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const REMOVE_SEEN_NOTIFICATION = 'REMOVE_SEEN_NOTIFICATION';
export const HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS';
export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS';
// Job related types
export const GET_JOBS = 'GET_JOBS';
export const CREATE_JOB = 'CREATE_JOB';
// Proposal related types
export const GET_PROPOSALS = 'GET_PROPOSALS';
export const GET_PROPOSAL = 'GET_PROPOSAL';
export const CREATE_PROPOSAL = 'CREATE_PROPOSAL';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const TOGGLE_PROPOSAL_MODAL = 'TOGGLE_PROPOSAL_MODAL';
export const GET_PROPOSAL_IN_MODAL = 'GET_PROPOSAL_IN_MODAL';
export const SEND_FOR_REVIEW = 'SEND_FOR_REVIEW';
export const APPROVE_PROPOSAL = 'APPROVE_PROPOSAL';
export const REJECT_PROPOSAL = 'REJECT_PROPOSAL';
export const DELETE_PROPOSAL = 'DELETE_PROPOSAL';
// Stages related types
export const GET_STAGES = 'GET_STAGES';
export const UPDATE_STAGE = 'UPDATE_STAGE';
export const CREATE_STAGE = 'CREATE_STAGE';
export const DELETE_STAGE = 'DELETE_STAGE';
export const DELETE_STAGES = 'DELETE_STAGES';
export const STORE_STAGE_IN_REVISION = 'STORE_STAGE_IN_REVISION';
export const UPDATE_STAGE_FEE = 'UPDATE_STAGE_FEE';
// Invoice Number related types
export const CREATE_INVOICE_NUMBER = 'CREATE_INVOICE_NUMBER'
export const GET_INVOICE_NUMBER = 'GET_INVOICE_NUMBER'
export const GET_INTERNAL_INVOICES = 'GET_INTERNAL_INVOICES'
export const UPDATE_INTERNAL_INVOICE = 'UPDATE_INTERNAL_INVOICE'

// Details related types
export const GET_DETAILS = 'GET_DETAILS';
export const GET_SPECIFIC_DETAILS = 'GET_SPECIFIC_DETAILS';
export const CREATE_DETAIL = 'CREATE_DETAIL';
export const DELETE_DETAIL = 'DELETE_DETAIL';
export const DELETE_DETAILS = 'DELETE_DETAILS';
export const UPDATE_DETAIL = 'UPDATE_DETAIL';
export const REDUCE_INPUT_BOX_SIZE = 'REDUCE_INPUT_BOX_SIZE';
export const EXPAND_INPUT_BOX_SIZE = 'EXPAND_INPUT_BOX_SIZE';
export const STORE_DETAIL_IN_REVISION = 'STORE_DETAIL_IN_REVISION';
// Client related types
export const GET_CLIENTS = 'GET_CLIENTS';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const GET_CLIENT_IN_MODAL = 'GET_CLIENT_IN_MODAL';
export const GET_CLIENT = 'GET_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const TOGGLE_CLIENT_MODAL = 'TOGGLE_MODAL';
// Vendor related types
export const GET_VENDORS = 'GET_VENDORS';
export const GET_VENDOR = 'GET_VENDOR';
export const CREATE_VENDOR = 'CREATE_VENDOR';
export const UPDATE_VENDOR = 'UPDATE_VENDOR';
export const GET_VENDOR_IN_MODAL = 'GET_VENDOR_IN_MODAL';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const TOGGLE_VENDOR_MODAL = 'TOGGLE_VENDOR_MODAL';
// Error related types
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// FeedbacksAndBugs types
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK';
export const GET_FEEDBACKS = 'GET_FEEDBACKS';
export const GET_FEEDBACK = 'GET_FEEDBACK';
export const FILTER_FEEDBACK = 'FILTER_FEEDBACK';
export const CANCEL_FEEDBACK = 'CANCEL_FEEDBACK';
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK';
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK';
export const TOGGLE_FEEDBACK_MODAL = 'TOGGLE_FEEDBACK_MODAL';
export const SEND_FEEDBACK_FOR_REVIEW = 'SEND_FEEDBACK_FOR_REVIEW';
export const APPROVE_FEEDBACK = 'APPROVE_FEEDBACK';
export const REJECT_FEEDBACK = 'REJECT_FEEDBACK';
export const UPDATE_FEEDBACK_FAIL = 'UPDATE_FEEDBACK_FAIL';
export const COMMENT_FEEDBACK = 'COMMENT_FEEDBACK';
export const DELETE_FEEDBACK_COMMENT = 'DELETE_FEEDBACK_COMMENT';
export const UPDATE_FEEDBACK_STATUS = 'UPDATE_FEEDBACK_STATUS';

// Confirmation custom alert types
export const CONFIRM_ALERT = 'CONFIRM_ALERT';
export const CANCEL_ALERT = 'CANCEL_ALERT';
export const ALERT_OPENER = 'ALERT_OPENER';

//Project related types
export const GET_LEAVES = 'GET_LEAVES';
export const ADD_LEAVE = 'ADD_LEAVE';
export const DELETE_LEAVE = 'DELETE_LEAVE';
export const GET_LEAVE = 'GET_LEAVE';
export const UPDATE_LEAVE = 'UPDATE_LEAVE';
export const UPDATE_LEAVE_FAIL = 'UPDATE_LEAVE_FAIL';
export const LEAVE_LOADING = 'LEAVE_LOADING';
export const TOGGLE_LEAVE_MODAL = 'TOGGLE_LEAVE_MODAL';
export const FILTER_LEAVE = 'FILTER_LEAVE';
export const APPROVE_LEAVE = 'APPROVE_LEAVE';
export const DISAPPROVE_LEAVE = 'DISAPPROVE_LEAVE';
// EXPENSE TYPES
export const GET_EXPENSES = 'GET_EXPENSES';
export const GET_EXPENSE = 'GET_EXPENSE';
export const CREATE_EXPENSE = 'CREATE_EXPENSE';
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE';
export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const APPROVE_EXPENSE = 'APPROVE_EXPENSE';
export const EXPENSE_LOADING = 'EXPENSE_LOADING';
export const CREATE_EXPENSE_FAIL = 'CREATE_EXPENSE_FAIL';
export const UPDATE_EXPENSE_FAIL = 'UPDATE_EXPENSE_FAIL';
export const APPROVAL_EXPENSE_FAIL = 'APPROVAL_EXPENSE_FAIL';
export const DELETE_EXPENSE_FAIL = 'DELETE_EXPENSE_FAIL';

//Payment related types
export const GET_INVOICES = 'GET_INVOICES';
export const CREATE_INVOICES = 'CREATE_INVOICES';
export const INVOICE_LOADING = 'INVOICE_LOADING';
export const SEND_BILLING_EMAIL = 'SEND_BILLING_EMAIL';
export const BILLING_STARTED = 'BILLING_STARTED';

// Announcement related types
export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT_FAIL = 'UPDATE_ANNOUNCEMENT_FAIL';
