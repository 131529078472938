import React from "react";
import { Table } from 'reactstrap';

/*
Requires the props below to be passed
Reports={array of all reports}
ReportIds={array containing all report ids}
Employees={array containing all employees}
toggleReportIndex={this.props.toggleReportIndex}
*/
const ReportSummary = props => {
    return <Table size='sm'>
        <thead>
            <tr>
                <th style={{width: "15%"}}>Report Date</th>
                <th style={{width: "15%"}}>PIC</th>
                <th style={{width: "10%"}}>Time Spent</th>
                <th style={{width: "60%"}}>Details</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {props.Reports.sort((a,b) => {
                if(a.reportdate > b.reportdate){return 1}
                if(a.reportdate < b.reportdate){return -1}
                return 0
            }).map(({ _id, reportdate, description, user_id, numberOfHours }, index) => {
                if (props.ReportIds.includes(_id)) {
                    return (
                        <tr key={_id}>
                            <td>
                                <a href='#' onClick={() => props.toggleReportIndex(_id)}>
                                    {reportdate.substring(0, 10)}
                                </a>
                            </td>
                            <td>
                                {props.Employees
                                    .filter((employee) => employee.user_id === user_id)
                                    .map((employee) => employee.name)}
                            </td>
                            <td style={{textAlign:'center'}}>
                                {numberOfHours}
                            </td>
                            <td style={{ whiteSpace: 'pre-wrap' }}>{description}</td>
                        </tr>
                    );
                } else {
                    return null;
                }
            })}
        </tbody>
    </Table>
}

export default ReportSummary