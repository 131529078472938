import React, { Fragment } from 'react'
import { Container, Alert } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import ConfirmButton from '../components/userComponents/ConfirmButton'
import { startBilling } from '../actions/companyActions'

const RegisterBilling = props => {
    const dispatch = useDispatch()
    const company_id = props.match.params.company_id
    const paypalImage = 'https://pbs.twimg.com/profile_images/1278352097688182784/Bn7-mEJ9.jpg'
    const message = useSelector(state => state.companyR.message)
    return (
        <Container>
            <center>
                {message && <Alert color='success'>{message}</Alert>}
                <p>We bill monthly using PayPal. By agreeing to this, you will be billed monthly based on your number of users. If payment is not fulfilled for 2 months in a row, your account access will be blocked. Billing will commence on your next cycle</p>
                <ConfirmButton
                    alertTitle='Confirm Billing'
                    color='primary'
                    onClick={() => dispatch(startBilling({ company_id }))}
                >
                    Start Billing
            </ConfirmButton>
                <img style={{ width: '40px' }} src={paypalImage} />
            </center>
        </Container>
    )
}

export default RegisterBilling