import React from 'react'

const Tooltip = props => {
    return (
        <span
            style={{
                visibility: props.id
                    ? 'visible'
                    : 'hidden',
                padding: '8px',
                fontSize: '12px',
                zIndex: '1',
                borderRadius: '2px',
                color: '#fff',
                lineHeight: '130%',
                position: 'absolute',
                width: '200%',
                overflow: 'hidden',
                left: '102%',
                top: '0',
                backgroundColor: '#323232',
                whiteSpace: 'pre-wrap',
            }}>
            <b style={{ lineHeight: '150%' }}>
                {props.name}
            </b>
            <br />
            {props.description}
            <span
                style={{
                    display: 'block',
                    marginTop: '0.5rem',
                }}>
                Time Spent: {props.numberOfHours}
            </span>
            {props.overdue ? (
                <span
                    style={{
                        display: 'block',
                        marginTop: '0.5rem',
                    }}>
                    Overdue Reason: {props.overdueReason}
                </span>
            ) : null}
        </span>
    )
}

export default Tooltip