import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Table, Button } from 'reactstrap';
import {
	getVendors,
	toggleVendorModal,
	getVendorInModal,
	deleteVendor,
} from '../../actions/vendorActions';
import VendorDetails from './VendorDetails';
import VendorModal from './vendorModal';
import ConfirmButton from '../userComponents/ConfirmButton';
export class Vendor extends Component {
	componentDidMount() {
		this.props.getVendors();
	}
	toggleModal = (id, input) => {
		this.props.getVendorInModal(id);
		this.props.toggleVendorModal(input);
	};
	deleteVendor = (id) => {
		this.props.deleteVendor(id);
	};
	render() {
		const { vendors } = this.props.vendor;
		return (
			<Container>
				<VendorDetails />
				<VendorModal />
				<Button color='dark' onClick={this.props.toggleVendorModal.bind(this, '')}>
					Add Vendor
				</Button>
				<br />
				<br />
				<Table>
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Hourly rate</th>
							<th>Contact Person</th>
							<th colSpan='2'>Contact Number</th>
						</tr>
					</thead>
					<tbody>
						{vendors.map((e) => {
							return (
								<tr>
									<td>
										<a href='#' onClick={this.toggleModal.bind(this, e._id, 'detailsModal')}>
											{e.name}
										</a>
									</td>
									<td>{e.email}</td>
									<td>{e.rate}</td>
									<td>{e.PIC_name}</td>
									<td>{e.contactNo}</td>
									{this.props.auth.user.permissionLevel === 'Manager' ? (
										<td>
											<ConfirmButton
												className='remove-btn'
												color='danger'
												size='sm'
												alertTitle={'Delete This Vendor?'}
												onClick={() => this.deleteVendor(e._id)}>
												&times;
											</ConfirmButton>
										</td>
									) : null}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	vendor: state.vendorR,
});

export default connect(mapStateToProps, {
	getVendors,
	toggleVendorModal,
	getVendorInModal,
	deleteVendor,
})(Vendor);
