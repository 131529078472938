import {
	CREATE_ANNOUNCEMENT,
	UPDATE_ANNOUNCEMENT,
	UPDATE_ANNOUNCEMENT_FAIL,
	DELETE_ANNOUNCEMENT,
	GET_ANNOUNCEMENT,
	GET_ANNOUNCEMENTS,
} from '../actions/types';

const initialState = {
	announcements: [],
	loading: true,
	announcement: '',
	message: '',
	success: '',
};

const announcementReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_ANNOUNCEMENTS:
			return {
				...state,
				announcements: action.payload.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
				loading: false,
			};
		case GET_ANNOUNCEMENT:
			return {
				...state,
				announcement: action.payload,
				success: 'Announcement Updated Successfully',
				loading: false,
			};
		case UPDATE_ANNOUNCEMENT:
			return {
				...state,
				announcement: action.payload,
				announcements: state.announcements.map((announcement) => {
					if (announcement._id === action.payload._id) {
						return action.payload;
					} else {
						return announcement;
					}
				}),
				success: 'Announcement Updated Successfully',
				loading: false,
			};

		case UPDATE_ANNOUNCEMENT_FAIL:
			return {
				...state,
				message: 'Update Announcement Failed',
				loading: false,
			};
		case CREATE_ANNOUNCEMENT:
			return {
				...state,
				announcement: action.payload,
				announcements: [...state.announcements, action.payload],
			};
		case DELETE_ANNOUNCEMENT:
			return {
				...state,
				announcements: state.announcements.filter((e) => e._id !== action.payload),
				loading: false,
			};
		default:
			return state;
	}
};

export default announcementReducer;
