import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container,Card, CardHeader, CardBody, CardFooter, FormGroup, Label, Input, Row,Form,Col, Button, Alert } from 'reactstrap';
import { getClient, updateClient } from '../../actions/clientAction';

export class UpdateClient extends Component {
    state = {
        name:'',
        email:'',
        contactNo:'',
        PIC:'',
        adderess:'',
        message: ''
    }
    componentDidMount() {
        this.props.getClient(this.props.match.params.id)
    }
    onChangeInput = ({ target }) => {
        this.setState({ [target.name]: target.value })
    }
    componentDidUpdate(prevProps,prevState) {
        if(prevProps.client.message !== this.props.client.message){
            this.setState({ message:this.props.client.message })
        }
    }
    onSubmit = (e) => {
        e.preventDefault()
        const { client } = this.props.client
        const updatedClient = {
            name: this.state.name?this.state.name:client.name, 
            email: this.state.email?this.state.email:client.email, 
            contactNo: this.state.contactNo?this.state.contactNo:client.contactNo, 
            PIC_name: this.state.PIC?this.state.PIC:client.PIC_name, 
            adderess: this.state.adderess?this.state.adderess:client.adderess 
        }
        this.props.updateClient(updatedClient,client._id)
    }
    render() {
        const { client } = this.props.client
        return (
            <Container>
                <Card>
                <Form onSubmit={this.onSubmit}>
                    <CardHeader>Updating Client Details</CardHeader>
                    <CardBody>
                      
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input defaultValue={client.name} name="name" onChange={this.onChangeInput} required />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>PIC</Label>
                                        <Input defaultValue={client.PIC_name} name="PIC" onChange={this.onChangeInput} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Contact Number</Label>
                                        <Input defaultValue={client.contactNo} name="contactNo" onChange={this.onChangeInput} required />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>Adderess</Label>
                                        <Input defaultValue={client.adderess} name="adderess" onChange={this.onChangeInput} required />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input defaultValue={client.email} name="email" onChange={this.onChangeInput} required />
                            </FormGroup>
                     
                    </CardBody>
                    <CardFooter>
                        <Button type="submit" block>Submit Changes</Button>
                        {
                            this.state.message?<Alert style={{textAlign:"center"}}>{this.state.message}</Alert>:null
                        }
                        
                    </CardFooter>
                    </Form>
                </Card>
            </Container>
        )
    }
}

const mapStateToProps = (state) => ({
    client: state.clientR
})

export default connect(mapStateToProps, { getClient,updateClient })(UpdateClient)
