import axios from 'axios';
import {
	GET_PROJECTS,
	ADD_PROJECT,
	DELETE_PROJECT,
	PROJECT_LOADING,
	UPDATE_PROJECT,
	UPDATE_PROJECT_FAIL,
	TOGGLE_PROJECT_MODAL,
	GET_PROJECT,
	FILTER_PROJECT,
	ASSIGN_PROJECT,
	UNASSIGN_PROJECT,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

export const getProjects = () => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.get(`/api/projects`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_PROJECTS,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const addProject = (newProject) => (dispatch, getState) => {
	axios
		.post(`/api/projects`, newProject, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: ADD_PROJECT,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const addTasktoProject = (project_id, project) => (dispatch, getState) => {
	axios
		.post(`/api/projects/addTask/${project_id}`, project, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_PROJECT,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const addReporttoProject = (project_id, report) => (dispatch, getState) => {
	axios
		.post(`/api/projects/addReport/${project_id}`, report, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_PROJECT,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const deleteProject = (id) => (dispatch, getState) => {
	axios
		.delete(`/api/projects/${id}`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: DELETE_PROJECT,
				payload: id,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const updateProject = (id, project) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.post(`/api/projects/update/${id}`, project, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_PROJECT,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_PROJECT_FAIL'));
			dispatch({
				type: UPDATE_PROJECT_FAIL,
			});
		});
};

export const getProject = (id) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.get(`/api/projects/${id}`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_PROJECT,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
export const assignProject = (id, body) => (dispatch, getState) => {
	axios
		.post(`/api/projects/assignEmployee/${id}`, body, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: ASSIGN_PROJECT,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
export const unAssignProject = (id, body) => (dispatch, getState) => {
	axios
		.post(`/api/projects/unassignEmployee/${id}`, body, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UNASSIGN_PROJECT,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
export const setItemsLoading = () => {
	return {
		type: PROJECT_LOADING,
	};
};

export const filterProjects = (filters, calendarState) => (dispatch) => {
	dispatch({
		type: FILTER_PROJECT,
		payload: {
			filters: filters,
			calendarState: calendarState,
		},
	});
};

export const toggleProjectIndex = (_id) => (dispatch) => {
	dispatch({
		type: TOGGLE_PROJECT_MODAL,
		payload: _id,
	});
};
