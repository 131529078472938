import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// import actions
import {
	getAnnouncements,
	updateAnnouncement,
	deleteAnnouncement,
	getAnnouncement,
} from '../../../actions/announcementActions';
import ConfirmButton from '../ConfirmButton';
import UpdateAnnouncement from './UpdateAnnouncement';

const AnnouncementList = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAnnouncements());
	}, [dispatch]);

	const [currentAnn, setCurrentAnn] = useState('');

	useEffect(() => {
		dispatch(getAnnouncement(currentAnn));
	}, [currentAnn]);

	const { message } = useSelector((state) => state.announcementR);
	const authUser = useSelector((state) => state.authR.user);
	const announcements = useSelector((state) => state.announcementR.announcements);
	const announcement = announcements.find((ann) => ann._id === currentAnn);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const updateStatus = (_id, status) => {
		const requestBody = {
			_id,
			status: status === 'active' ? 'inactive' : 'active',
		};
		dispatch(updateAnnouncement(requestBody));
	};

	const deletedAnnouncement = (_id) => {
		dispatch(deleteAnnouncement(_id));
	};

	const updateAnn = (value, _id) => {
		const {
			description,
			title,
			status,
			schedule_date,
			schedule_time,
			deadline_date,
			deadline_time,
		} = value;
		const requestBody = {
			_id,
			status,
			description,
			title,
			schedule_date,
			schedule_time,
			deadline_date,
			deadline_time,
		};
		dispatch(updateAnnouncement(requestBody));
	};
	// console.log('announcements', announcements);

	return (
		<div>
			<div>
				<a href='#' style={{ fontSize: '0.8rem', marginRight: '1rem' }} onClick={toggle}>
					Announcement List
				</a>
			</div>

			<Modal size='lg' isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Announcement List</ModalHeader>
				<ModalBody>
					<Table hover responsive className='table-sm'>
						<thead style={{ fontSize: '14px' }}>
							<tr>
								<th>No.</th>
								<th>Date</th>
								<th>Title</th>
								<th style={{ maxWidth: '50%' }}>Description</th>
								<th>Status</th>
								<th style={{ width: '10%' }}></th>
							</tr>
						</thead>
						<tbody style={{ fontSize: '14px' }}>
							{announcements &&
								announcements.map((ann, index) => {
									return (
										<tr key={ann._id}>
											<td>{index + 1}</td>
											<td>{ann.createdAt.substr(0, 10)}</td>
											<td>{ann.title}</td>
											<td>{ann.description}</td>
											{authUser.permissionLevel === 'Executive' ||
											authUser.permissionLevel === 'Manager' ? (
												<Fragment>
													<td>
														<ConfirmButton
															alertTitle='Change Quiz Status?'
															color={ann.status !== 'active' ? 'danger' : 'success'}
															size='sm'
															onClick={() => updateStatus(ann._id, ann.status)}>
															{ann.status}
														</ConfirmButton>
													</td>
													<td onClick={() => setCurrentAnn(ann._id)}>
														<ConfirmButton
															size='sm'
															style={{
																float: 'left',
															}}
															color='none'
															alertTitle={'Delete this Announcement?'}
															onClick={() => deletedAnnouncement(ann._id)}>
															<FontAwesomeIcon
																color='#CD5C5C'
																style={{ fontSize: '14x' }}
																icon={faTrash}
															/>
														</ConfirmButton>
														<UpdateAnnouncement
															message={message}
															announcement={announcement}
															description={ann.description}
															status={ann.status}
															title={ann.title}
															schedule_date={ann.schedule_date}
															schedule_time={ann.schedule_time}
															deadline_time={ann.deadline_time}
															deadline_date={ann.deadline_date}
															onChange={(e) => updateAnn(e, ann._id)}
														/>
													</td>
												</Fragment>
											) : null}
										</tr>
									);
								})}
						</tbody>
					</Table>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default AnnouncementList;
