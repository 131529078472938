import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, Table } from 'reactstrap'
import { connect } from 'react-redux'
import { toggleProjectIndex } from '../../actions/projectActions'
import { toggleTaskIndex } from '../../actions/taskActions'
import { toggleReportIndex } from "../../actions/reportActions";
import TaskDetails from "../taskComponents/TaskDetails";
import ReportDetails from '../reportComponents/ReportDetails'
import PropTypes from 'prop-types';
import ReportSummary from '../reportComponents/ReportSummary';

class ProjectDetails extends Component {

    render() {
        const Reports = this.props.report.reports;
        const { project } = this.props.project
        const { employees } = this.props.employee
        const { user } = this.props.auth
        var Tasks = this.props.task.tasks

        return project ? (
            <Fragment>
                <ReportDetails />
                <TaskDetails />
                <Modal
                    key={project._id}
                    isOpen={this.props.project.modal}
                    toggle={this.props.toggleProjectIndex.bind(this, project._id)}
                    className="modal-xl"
                >
                    <ModalHeader
                        toggle={this.props.toggleProjectIndex.bind(this, project._id)}
                    >
                        <strong>{project.name}</strong>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Project ID</Label>
                                    <Input
                                        type="text"
                                        defaultValue={project._id}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row xs="2">
                            <Col>
                                <FormGroup>
                                    <Label>Status</Label>
                                    <Input
                                        type="text"
                                        defaultValue={project.status}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Deadline</Label>
                                    <Input
                                        type="text"
                                        defaultValue={project.deadline ? project.deadline.substring(0, 10) : null}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row xs="2">
                            <Col>
                                <FormGroup>
                                    <Label>Start Date</Label>
                                    <Input
                                        type="text"
                                        defaultValue={project.startdate ? project.startdate.substring(0, 10) : ""}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Completion Date</Label>
                                    <Input
                                        type="text"
                                        defaultValue={project.enddate ? project.enddate.substring(0, 10) : ""}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Description</Label>
                            <Input
                                type="textarea"
                                defaultValue={project.description}
                                disabled
                            />
                        </FormGroup>
                        {project.tasks && project.tasks.length > 0 ? <Table size="sm">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Department</th>
                                    <th>PIC</th>
                                    <th>Status</th>
                                    <th>Deadline</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Tasks.map(({ _id, name, department, status, deadline, user_id }, index) => {
                                    if (project.tasks.includes(_id)) {
                                        return (
                                            <tr
                                                key={_id}
                                            >
                                                <td><a href="#" onClick={this.props.toggleTaskIndex.bind(this, _id)}>{name}</a></td>
                                                <td>{department}</td>
                                                <td>{employees.filter(employee => employee.user_id === user_id).map(employee => employee.name)}</td>
                                                <td>{status}</td>
                                                <td>{deadline ? deadline.substring(0, 10) : ""}</td>
                                            </tr>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </tbody>
                        </Table>
                            : <ReportSummary
                                Reports={Reports}
                                ReportIds={project.reports}
                                Employees={employees}
                                toggleReportIndex={this.props.toggleReportIndex}
                            />}
                        {
                            user.permissionLevel === "Manager" || user.permissionLevel === "Executive" ?
                                <Button
                                    color="dark"
                                    style={{ marginTop: "2rem" }}
                                    href={`/editProject/${project._id}`}
                                    block
                                >
                                    Edit Project
                                </Button> : null
                        }
                    </ModalBody>
                </Modal>
            </Fragment>
        ) : null
    }
}

ProjectDetails.propTypes = {
    toggleProjectIndex: PropTypes.func.isRequired,
    toggleTaskIndex: PropTypes.func.isRequired,
    toggleReportIndex: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    employee: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    task: state.taskR, // getting reducer from /reducers/index.js
    employee: state.employeeR, // getting reducer from /reducers/index.js
    project: state.projectR, // getting reducer from /reducers/index.js 
    report: state.reportR,
    auth: state.authR  // getting reducer from /reducers/index.js 
})

export default connect(
    mapStateToProps,
    { toggleProjectIndex, toggleTaskIndex, toggleReportIndex }
)(ProjectDetails);