import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'
import { getTasks,toggleTaskIndex } from '../actions/taskActions' 
import { getEmployees } from '../actions/employeeActions'
import TaskDetails from "./taskComponents/TaskDetails";


class NotificationsPage extends Component {
    componentDidMount(){
        this.props.getTasks()
        this.props.getEmployees()
    }
    render() {
        const { tasks } = this.props.task
        const  { employees } = this.props.employee
        const { notifications } = this.props.auth
        return (
            <Container>
                 <TaskDetails />   
                <table className="table">
                    <thead>
                        <tr>
                            <th>Commented By</th>
                            <th>Task</th>
                            <th>Comment</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                       {
                           notifications.map((e,index) => {
                            return (   
                               <tr key={index}>
                                    <th>{employees.filter(i => i.user_id === e.commentedBy_id).map(i => i.name)}</th>
                                    <th><a href='#' onClick={this.props.toggleTaskIndex.bind(this,e.task_id)}>{tasks.filter(i => i._id === e.task_id).map(i => i.name)} </a></th>
                                    <th>{e.comment}</th>
                                    <th>{e.date.substring(0,10)}</th>
                               </tr>)
                           })
                       }
                    </tbody>
                </table>
            </Container>          
        )
    }
}
const mapStateToProps = state => ({
    auth: state.authR,
    task: state.taskR,
    employee: state.employeeR
})
export default connect(mapStateToProps,{ getTasks,getEmployees,toggleTaskIndex })(NotificationsPage)