import axios from 'axios';
import { returnErrors } from "./errorActions";
import { tokenConfig } from './authActions';
import { GET_JOBS, CREATE_JOB } from './types'; 

export const getJobs = () => (dispatch,getState) => {
    axios.get
    ('/api/jobs',tokenConfig(getState))
    .then(res => dispatch({
        type: GET_JOBS,
        payload: res.data
    }))
    .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status))
    })
}
export const createJob = newJob =>  (dispatch,getState) => {
    axios.post
    ('/api/jobs',newJob,tokenConfig(getState))
    .then(res => dispatch({
        type: CREATE_JOB,
        payload: res.data
    }))
    .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status))
    })
}