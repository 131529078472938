import {
	GET_TASKS,
	ADD_TASK,
	DELETE_TASK,
	TASK_LOADING,
	TOGGLE_TASK_MODAL,
	GET_TASK,
	UPDATE_TASK_FAIL,
	UPDATE_TASK,
	REQUEST_TASK,
	APPROVE_TASK,
	TOGGLE_REVIEW_MODAL,
	REVIEW_TASK,
	CANCEL_TASK,
	REMOVE_TASK,
	COMMENT_TASK,
	DELETE_COMMENT,
	FILTER_TASK,
	UPDATE_TASK_REPORT,
} from '../actions/types';

const initialState = {
	tasks: [],
	task: '', // used for update Task page
	filteredTasks: [],
	loading: false,
	modal: false,
	reviewModal: [],
	message: null,
};

const taskReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_TASKS:
			return {
				...state,
				tasks: action.payload,
				reviewModal: action.payload.map(() => {
					return false;
				}),
				loading: false,
			};
		case APPROVE_TASK:
		case REQUEST_TASK:
		case REMOVE_TASK:
		case DELETE_TASK:
			return {
				...state,
				tasks: state.tasks.filter((task) => task._id !== action.payload),
				filteredTasks: state.filteredTasks.filter((task) => task._id !== action.payload),
			};
		case REVIEW_TASK:
			return {
				...state,
				tasks: [action.payload, ...state.tasks],
			};
		case ADD_TASK:
			return {
				...state,
				tasks: [...state.tasks, action.payload],
				filteredTasks: [...state.filteredTasks, action.payload],
			};
		case TASK_LOADING:
			return {
				...state,
				loading: true,
				message: null,
			};
		case TOGGLE_TASK_MODAL:
			return {
				...state,
				task: state.tasks.filter(({ _id }) => _id === action.payload)[0],
				modal: !state.modal,
			};
		case TOGGLE_REVIEW_MODAL:
			return {
				...state,
				reviewModal: action.payload,
			};
		case UPDATE_TASK:
			return {
				...state,
				task: action.payload,
				message: 'Task Updated Successfully',
				loading: false,
			};
		case CANCEL_TASK:
			return {
				...state,
				message: 'Task Cancelled Successfully',
				loading: false,
			};
		case GET_TASK:
			return {
				...state,
				task: action.payload,
				loading: false,
			};
		case UPDATE_TASK_FAIL:
			return {
				...state,
				message: null,
				loading: false,
			};
		case DELETE_COMMENT:
		case COMMENT_TASK:
			return {
				...state,
				tasks: state.tasks.map((e) => (e._id === action.payload._id ? action.payload : e)),
				task: action.payload,
				loading: false,
			};
		case FILTER_TASK:
			// Filter tasks based on criteria provieded
			var arr = state.tasks.filter((task) =>
				Object.keys(action.payload.filters).every((key) => {
					//check if the filter is an array of elements
					if (Array.isArray(action.payload.filters[key])) {
						if (key === 'dateRange') {
							var filter1 = true;
							var filter2 = true;
							if (action.payload.filters.dateRange[0] !== '') {
								filter1 = task.deadline > action.payload.filters.dateRange[0] ? true : false;
							}
							if (action.payload.filters.dateRange[1] !== '') {
								filter2 = task.deadline < action.payload.filters.dateRange[1] ? true : false;
							}
							var filter = filter1 && filter2 ? true : false;
							return filter;
						}
						return action.payload.filters[key].some((keyEle) => task[key].includes(keyEle));
					}
					if (key === 'overdue') {
						var d = new Date();
						d.setHours(0, 0, 0, 0);
						d.setDate(d.getDate() + 1);
						return new Date(task.deadline) < d && task.status !== 'Completed';
					}
					return task[key] ? task[key].includes(action.payload.filters[key]) : null;
				})
			);

			if (action.payload.calendarState) {
				//Create an object for each date to hold the values of the daily entries
				var dates = {};
				console.log(action.payload.filters.deadline);
				for (var i = 0; i < action.payload.filters.deadline.length; i++) {
					dates[action.payload.filters.deadline[i].substr(0, 10)] = [];
				}

				//Push the entries for the day into respective object element
				for (var j = 0; j < arr.length; j++) {
					dates[arr[j].deadline.substr(0, 10)].push(arr[j]);
				}
			}
			return {
				...state,
				filteredTasks: action.payload.calendarState ? dates : arr,
			};
		case UPDATE_TASK_REPORT:
		default:
			return state;
	}
};

export default taskReducer;
