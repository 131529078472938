import React, { Fragment, useEffect, useState } from 'react';
import {
    Form,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    CustomInput,
    Alert,
} from 'reactstrap';

import { useDispatch } from 'react-redux';
import { deleteExpense, updateExpense } from '../../actions/expenseTracker';
import { readFile, isSameObject } from './ExpenseHelper'

const ExpenseUpdateModal = ({ projects, propModalFormData, propToggleModalForm }) => {

    const dispatch = useDispatch();

    // DATA
    const currency = ['RM', '$'];

    // FUNCTION
    const originalData = { ...propModalFormData, file: '' }

    // STATES
    const [toggleModalForm, setToggleModalForm] = useState(false);
    const [modalFormData, setModalFormData] = useState({});

    const [errorMessage, setErrorMessage] = useState('');

    const { file, _id } = modalFormData;

    useEffect(() => {
        setModalFormData(originalData)
        setToggleModalForm(propToggleModalForm);
    }, []);

    const onToggleModalForm = () => {
        setToggleModalForm(!toggleModalForm);
    };

    const onChangeModalForm = (e) => setModalFormData({
        ...modalFormData,
        [e.target.name]: e.target.value,
    });

    const onUploadPhoto = (e) => {
        var file = e.target.files[0];
        if (file) {
            if (file.size > 2097152 || file.type.substr(0, 5) !== 'image') {
                return setErrorMessage(
                    'Requires Image File below 2mb'
                );
            }
            setModalFormData({
                ...modalFormData,
                photo: URL.createObjectURL(file),
                file: file,
            });
        }
    };

    const onClickDelete = () => {

        dispatch(deleteExpense(_id));

        onToggleModalForm();
    };

    const onSubmitModalForm = async (e) => {
        e.preventDefault();
        if (!isSameObject(modalFormData, originalData)) {
            var newExpense = modalFormData;
            var isNewPhoto = false;

            if (file) {
                newExpense.photo = await readFile(file);
                isNewPhoto = true;
            }

            dispatch(updateExpense(newExpense, isNewPhoto));
        }
        onToggleModalForm();
    };

    return (
        <Fragment>
            <Modal isOpen={toggleModalForm} toggle={onToggleModalForm} centered>
                <Form onSubmit={onSubmitModalForm}>
                    <ModalHeader toggle={onToggleModalForm}>
                        Update Expense Request
          </ModalHeader>
                    <ModalBody>
                        {/* NAME */}
                        <FormGroup>
                            <Label for="name">Expense Name</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                value={modalFormData.name}
                                placeholder="Enter name"
                                onChange={onChangeModalForm}
                                required
                            />
                        </FormGroup>

                        {/* PROJECT */}
                        <FormGroup>
                            <Label for="project_id">Project</Label>
                            <Input
                                type="select"
                                name="project_id"
                                id="project_id"
                                value=
                                {modalFormData.project_id}

                                onChange={onChangeModalForm}
                                required
                            >
                                <option value="">Select Project</option>
                                {projects.map((p, index) => (
                                    <option key={p._id} value={p._id}>{p.name}</option>
                                ))}
                            </Input>
                        </FormGroup>

                        {/* AMOUNT */}
                        <FormGroup>
                            <Label for="amount"> Amount</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <Input
                                        type="select"
                                        name="currency"
                                        id="currency"
                                        value={modalFormData.currency}
                                        onChange={onChangeModalForm}
                                        required
                                    >
                                        {currency.map((c) => (
                                            <option key={c}>{c}</option>
                                        ))}
                                    </Input>
                                </InputGroupAddon>
                                <Input
                                    name="amount"
                                    id="amount"
                                    placeholder="Amount"
                                    min={0}
                                    type="number"
                                    value={modalFormData.amount}
                                    onChange={onChangeModalForm}
                                />
                            </InputGroup>
                        </FormGroup>

                        {/* DESCRIPTION */}
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                placeholder="Description"
                                value={modalFormData.description}
                                onChange={onChangeModalForm}
                            />
                        </FormGroup>

                        {/* UPLOAD PHOTO */}
                        <FormGroup>
                            <Label for="photo">Receipt Photo</Label>
                            <li >{modalFormData.photo}</li>
                            <CustomInput
                                type="file"
                                name="photo"
                                id="photo"
                                accept="image/*"
                                htmlFor="photo"
                                onChange={onUploadPhoto}
                            />
                            {errorMessage ? (
                                <Alert style={{ textAlign: 'center' }} color="warning">
                                    {errorMessage}
                                </Alert>
                            ) : null}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={onClickDelete}>
                            Delete Request
            </Button>
                        <Button color="primary" type='submit'> Request Expense</Button>
                    </ModalFooter>
                </Form>

            </Modal>
        </Fragment>
    );
};

export default ExpenseUpdateModal;
