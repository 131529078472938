import React, { Component, Fragment } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addCompany } from '../../actions/companyActions';
import { v4 as uuidv4 } from 'uuid';
class CompanyModal extends Component {
	state = {
		modal: false,
		companyname: '',
		userFee: '',
		departmentCount: 1,
		message: '',
	};

	componentDidUpdate(prevProps) {
		const { error, company } = this.props;
		if (error !== prevProps.error) {
			//Check for register error
			if (error.id === 'ADD_COMPANY_FAIL') {
				this.setState({ message: error.message.message });
			} else {
				this.setState({ message: null });
			}
		}

		if (this.state.modal) {
			if (company !== prevProps.company) {
				this.toggle();
			}
		}
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	addRow = () => {
		this.setState({
			departmentCount: this.state.departmentCount + 1,
		});
	};

	removeRow = () => {
		this.setState({
			departmentCount: this.state.departmentCount !== 1 ? this.state.departmentCount - 1 : 1,
		});
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ message: null });
		var arr = [...Array(this.state.departmentCount)];
		var departments = [];
		arr.forEach((e, i) => {
			departments.push({
				id: uuidv4(),
				name: this.state['department' + i],
				active: true,
			});
		});
		// const objectCompany = {
		// 	id: uuidv4(),
		// 	companyname: this.state.companyname,
		// 	active: true,
		// };
		const newCompany = {
			companyname: this.state.companyname,
			userFee: this.state.userFee,
			departments: departments,
			shortname: this.state.shortname,
		};
		// Add via addCompany action
		this.props.addCompany(newCompany);

		//close modal
		this.toggle();
	};

	render() {
		var arr = [...Array(this.state.departmentCount)];
		return (
			<Fragment>
				<Button color='dark' onClick={this.toggle}>
					Add Company
				</Button>

				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Create New Company</ModalHeader>
					<ModalBody>
						{this.state.message ? <Alert color='danger'>{this.state.message}</Alert> : null}
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label>Company Name</Label>
								<Input
									type='text'
									name='companyname'
									placeholder='Add Company Name'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label>Short Name</Label>
								<Input
									type='text'
									name='shortname'
									placeholder='Name for DB'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<Label>Departments</Label>
							<span className='addRow' onClick={this.removeRow}>
								-
							</span>
							<span className='addRow' onClick={this.addRow}>
								+
							</span>
							{arr.map((e, i) => (
								<FormGroup>
									<Input
										key={'department' + i}
										type='text'
										name={'department' + i}
										onChange={this.onChange}
										required
									/>
								</FormGroup>
							))}
							<FormGroup>
								<Label>User Fee</Label>
								<Input
									type='number'
									name='userFee'
									defaultValue={this.state.userFee}
									onChange={this.onChange}
								/>
							</FormGroup>
							<Button color='dark' style={{ marginTop: '2rem' }} block>
								Add Company
							</Button>
						</Form>
					</ModalBody>
				</Modal>
			</Fragment>
		);
	}
}

CompanyModal.propTypes = {
	addCompany: PropTypes.func.isRequired,
	error: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	error: state.errorR,
	company: state.companyR,
});

export default connect(mapStateToProps, { addCompany })(CompanyModal);
