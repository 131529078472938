import React, {Component, Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col,Table} from 'reactstrap'
import {connect} from 'react-redux'
import { toggleReportIndex } from '../../actions/reportActions'
import PropTypes from 'prop-types';

class ReportDetails extends Component {
    state = {
        _id:"",
        reportdate: "",
        user_id:"",
        task_id:"",
        description:"",
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        const { tasks } = this.props.task
        const { employees } = this.props.employee
        const { user } = this.props.auth
        return (
            this.props.report.report?<Fragment>
                    <Modal
                        isOpen={this.props.report.modal}
                        toggle={this.props.toggleReportIndex.bind(this, "")}
                    >
                        <ModalHeader
                            toggle={this.props.toggleReportIndex.bind(this, "")}
                        >
                            <strong>{this.props.report.report._id}</strong>
                        </ModalHeader>
                        <ModalBody>
                            <Row xs = "2">
                                <Col>
                                    <FormGroup>
                                        <Label>reportdate</Label>
                                        <Input 
                                            type = "text"
                                            defaultValue = {this.props.report.report.reportdate?this.props.report.report.reportdate.substring(0,10):""}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>PIC</Label>
                                        <Input 
                                            type = "text"
                                            defaultValue = {employees.filter(employee => employee.user_id === this.props.report.report.user_id).map(employee => employee.name)}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="8">
                                    <FormGroup >
                                        <Label>Task</Label>
                                        <Input 
                                            type = "text"
                                            defaultValue = {tasks.filter(task => task._id === this.props.report.report.task_id).map(task => task.name)}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs="4" style={{paddingLeft:"0px"}}>
                                    <FormGroup >
                                        <Label>Number of Hours</Label>
                                        <Input 
                                            type="number"
                                            defaultValue={this.props.report.report.numberOfHours}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Description</Label>
                                <Input 
                                    type = "textarea"
                                    id="descriptionBox" 
                                    defaultValue = {this.props.report.report.description}
                                    disabled
                                />
                            </FormGroup>
                            {
                                user.permissionLevel === "Manager"||user.permissionLevel === "Executive" ? 
                                <Button
                                color= "dark"
                                style = {{marginTop: "2rem"}}
                                href = {`/editReport/${this.props.report.report._id}`}
                                block
                                >
                                    Edit Report
                                </Button> : null
                            }
                        </ModalBody>
                    </Modal>
            </Fragment>:null
        )
    }
}

ReportDetails.propTypes = {
    toggleReportIndex: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    employee: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    task: state.taskR, // getting reducer from /reducers/index.js
    employee: state.employeeR, // getting reducer from /reducers/index.js
    report: state.reportR, // getting reducer from /reducers/index.js
    auth: state.authR // getting reducer from /reducers/index.js
})

export default connect(
    mapStateToProps,
    { toggleReportIndex }
)(ReportDetails);