import {
	CREATE_FEEDBACK,
	GET_FEEDBACKS,
	TOGGLE_FEEDBACK_MODAL,
	FILTER_FEEDBACK,
	UPDATE_FEEDBACK_STATUS,
	DELETE_FEEDBACK,
	CANCEL_FEEDBACK,
	GET_FEEDBACK,
	UPDATE_FEEDBACK,
	UPDATE_FEEDBACK_FAIL,
	COMMENT_FEEDBACK,
	DELETE_FEEDBACK_COMMENT,
} from '../actions/types';

const initialState = {
	feedbacks: [],
	loading: true,
	feedback: [], // used for update feedback
	filterFeedbacks: [],
	modal: false,
	message: '',
};

const feedbackReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_FEEDBACKS:
			return {
				...state,
				feedbacks: action.payload,
				loading: false,
			};
		case GET_FEEDBACK:
			return {
				...state,
				feedback: action.payload,
				loading: false,
			};
		case UPDATE_FEEDBACK:
			return {
				...state,
				feedback: action.payload,
				feedbacks: state.feedbacks.map((feedback) => {
					if (feedback._id === action.payload._id) {
						return action.payload;
					} else {
						return feedback;
					}
				}),
				message: 'Feedback Updated Successfully',
				loading: false,
			};
		case UPDATE_FEEDBACK_STATUS:
			return {
				...state,
				feedback: action.payload,
				feedbacks: state.feedbacks.map((feedback) => {
					if (feedback._id === action.payload._id) {
						return action.payload;
					} else {
						return feedback;
					}
				}),
				message: 'Feedback Updated Successfully',
				loading: false,
			};
		case UPDATE_FEEDBACK_FAIL:
			return {
				...state,
				message: 'Update Feedback Failed',
				loading: false,
			};
		case CREATE_FEEDBACK:
			return {
				...state,
				feedbacks: [...state.feedbacks, action.payload],
			};
		case TOGGLE_FEEDBACK_MODAL:
			return {
				...state,
				feedback: state.feedbacks.filter((e) => e._id === action.payload)[0],
				modal: !state.modal,
			};
		case CANCEL_FEEDBACK:
			return {
				...state,
				message: 'Feedback Cancelled Successfully',
				loading: false,
			};
		case DELETE_FEEDBACK:
			return {
				...state,
				feedbacks: state.feedbacks.filter((e) => e._id !== action.payload),
				loading: false,
			};
		case DELETE_FEEDBACK_COMMENT:
		case COMMENT_FEEDBACK:
			return {
				...state,
				feedbacks: state.feedbacks.map((e) => (e._id === action.payload._id ? action.payload : e)),
				feedback: action.payload,
				loading: false,
			};
		case FILTER_FEEDBACK:
			var arr = state.feedbacks.filter((feedback) =>
				Object.keys(action.payload.filters).every((key) => {
					if (Array.isArray(action.payload.filters[key])) {
						return action.payload.filters[key].some((keyEle) =>
							feedback[key] ? feedback[key].includes(keyEle) : false
						);
					}
					return feedback[key] ? feedback[key].includes(action.payload.filters[key]) : null;
				})
			);
			return {
				...state,
				filterFeedbacks: arr,
			};
		default:
			return state;
	}
};

export default feedbackReducer;
