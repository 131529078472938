import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Card, CardBody, CardHeader, Container, Button, Input, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { getProposal } from '../../actions/proposalActions'
import { getSpecificStages, updateStageFee } from '../../actions/stageActions'
import { getCompany } from '../../actions/companyActions';
import { getClient } from '../../actions/clientAction';
import { getInvoiceNumber, createInvoiceNumber } from '../../actions/invoiceActions'
import BillingSchedule from './BillingSchedule'
import ProposalNotes from './ProposalNotes'
import PdfDocument from './InvoicePdf'

const ProposalSummary = props => {
    const [stepIndex, setStepIndex] = useState('') // The index of payment step in stage.
    const [stage, setStage] = useState({}) // The value used in pdf document
    const [showPDF, togglePDF] = useState(false) // It renders the pdf document in the browser. 
    const [stages, setStages] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [resetData, fireResetData] = useState(false)
    const [fireUpdateFunctions, toggleFireFunctions] = useState(false)
    const updatedStages = useRef([]) // These are the stages that is going to be updated

    const proposal = useSelector(state => state.proposalR.proposal)
    const reduxStages = useSelector(state => state.stageR.stages)
    const client = useSelector(state => state.clientR.client)
    const user = useSelector(state => state.authR.user)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProposal(props.match.params.id))
        dispatch(getCompany())
    }, [])

    useEffect(() => { // Fetching data. 
        if (proposal.proposalNumber) {
            dispatch(getSpecificStages(proposal.proposalNumber))
            dispatch(getClient(proposal.client_id))
        }
    }, [proposal])

    useEffect(() => {
        setStages(reduxStages)  // Saving the stages coming from redux in state.
    }, [reduxStages])

    useEffect(() => {
        if (showPDF) { // Here we fetech the invoice number.
            if (stage.payBreakdown[stepIndex].invoice_id) { // If the payment step has an invoice number then we get the data.
                dispatch(getInvoiceNumber(stage.payBreakdown[stepIndex].invoice_id))
            } else { // if it doesnt have then we create a new one.
                dispatch(createInvoiceNumber({}, stage.payBreakdown, stepIndex, stage._id))
            }
        }
    }, [showPDF])

    useEffect(() => {
        dispatch(getSpecificStages(proposal.proposalNumber))
        dispatch(getProposal(props.match.params.id))
    }, [resetData])

    const updateStageFees = (index, newFee) => {
        setStages(stages => {
            stages[index].fees = Number(newFee)
            return [...stages]
        })
        updatedStages.current[index] = stages[index]
        updatedStages.current[index].fees = Number(newFee)
    }

    const updateData = () => {
        toggleFireFunctions(!fireUpdateFunctions)
        setEditMode(false)
        updatedStages.current.map(stage => dispatch(updateStageFee(stage, stage._id)))
    }
    if (showPDF) { // if true then the pdf will be rendered to the browser.
        return (<PdfDocument stage={stage} stepIndex={stepIndex} />)
    }

    return (
        <Container>
            <Card>
                <CardHeader style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                    <b>{client.name}</b>
                    <Button
                        style={{ marginBottom: "-5px", marginTop: "-5px" }}
                        color={editMode ? 'primary' : 'info'}
                        onClick={() => {
                            setEditMode(!editMode)
                            fireResetData(!resetData)
                        }}
                    >
                        {editMode ? 'Normal Mode' : 'Edit Mode'}
                    </Button>
                </CardHeader>
                <CardHeader style={{ textAlign: 'center' }}>Consultancy Services Fees</CardHeader>
                <CardBody>
                    <Table>
                        <thead>
                            <tr>
                                <th>Stages</th>
                                <th>Description</th>
                                <th>Fees(excl tax)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                stages.map((stage, index) => {
                                    return (<tr key={index}>
                                        <td>{stage.stage}</td>
                                        <td>{stage.description}</td>
                                        <td>
                                            {
                                                user.permissionLevel === "Executive" && editMode ?
                                                    <Input type="number" defaultValue={stage.fees}
                                                        onChange={e => updateStageFees(index, e.target.value)} /> :
                                                    user.permissionLevel === "Executive" ? stage.fees ? stage.fees.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 'TBA' : 'N/A'
                                            }
                                        </td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </Table>
                </CardBody>
                <BillingSchedule
                    setStepIndex={setStepIndex}
                    setStage={setStage}
                    togglePDF={togglePDF}
                    stages={stages}
                    editMode={editMode}
                    fireUpdateFunctions={fireUpdateFunctions}
                />
                <ProposalNotes
                    notes={proposal.summaryNotes}
                    proposal_id={proposal._id}
                    editMode={editMode}
                    fireUpdateFunctions={fireUpdateFunctions}
                    resetData={resetData}
                />
                {
                    editMode ?
                        <Button block onClick={updateData} color="primary" >Update</Button>
                        : ''
                }
            </Card>
            <br />
        </Container>

    )
}
export default ProposalSummary
