import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Table, Button } from 'reactstrap';
import {
	getClients,
	deleteClient,
	updateClient,
	toggleClientModal,
	getClientInModal,
} from '../../actions/clientAction';
import ClientDetails from './ClientDetails';
import ClientModal from './clientModal';
import ConfirmButton from '../userComponents/ConfirmButton';
class Client extends Component {
	componentDidMount() {
		this.props.getClients();
	}
	toggleClientModal = (id) => {
		this.props.toggleClientModal('detailsModal');
		this.props.getClientInModal(id);
	};
	deleteClient = (id) => {
		this.props.deleteClient(id);
	};
	render() {
		return (
			<Container>
				<ClientDetails />
				<ClientModal />
				<Button color='dark' onClick={this.props.toggleClientModal.bind(this, '')}>
					Add Client
				</Button>
				<br />
				<br />
				<Table>
					<thead>
						<tr>
							<th>Name</th>
							<th>PIC</th>
							<th>Contact</th>
							<th>Email</th>
							<th>address</th>
						</tr>
					</thead>
					<tbody>
						{this.props.client.clients.map((e, i) => {
							return (
								<tr key={i}>
									<td onClick={this.toggleClientModal.bind(this, e._id)}>
										<a href='#'>{e.name}</a>
									</td>
									<td>{e.PIC_name}</td>
									<td>{e.contactNo}</td>
									<td>{e.email}</td>
									<td>{e.adderess}</td>
									{this.props.auth.user.permissionLevel === 'Manager' ? (
										<td>
											<ConfirmButton
												alertTitle={'Remove this Client?'}
												className='remove-btn'
												color='danger'
												size='sm'
												onClick={() => this.deleteClient(e._id)}>
												&times;
											</ConfirmButton>
										</td>
									) : null}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	client: state.clientR,
});

export default connect(mapStateToProps, {
	getClients,
	deleteClient,
	updateClient,
	toggleClientModal,
	getClientInModal,
})(Client);
