import React, { Component } from 'react';
import { connect } from 'react-redux';
import {FormGroup, Label, Input, Row,Form,Col, Button, Alert,ModalFooter,ModalBody,ModalHeader,Modal} from 'reactstrap';
import { createVendor,toggleVendorModal } from '../../actions/vendorActions';

export class vendorModal extends Component {
    state = {
        name:'',
        PIC: '',
        contactNo: '',
        email: '',
        rate:null,
        message:null
    }
    onChangeInput = ({ target }) => {
        this.setState({
            [target.name]: target.value
        })
    }
    onSubmit = (e) => {
        e.preventDefault()
        const newVendor = {
            name: this.state.name,
            email: this.state.email,
            PIC_name: this.state.PIC,
            contactNo: this.state.contactNo,
            rate: this.state.rate
        }
       this.props.createVendor(newVendor)
       this.props.toggleVendorModal('')
    }
    render() {
        return (
            <Modal isOpen={this.props.vendor.addModal} toggle={this.props.toggleVendorModal.bind(this," ")}>
            <ModalHeader toggle={this.props.toggleVendorModal.bind(this," ")}></ModalHeader>
            <Form onSubmit={this.onSubmit}>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input name="name" type="text" value={this.state.name} onChange={this.onChangeInput} required /> 
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Contact Person</Label>
                            <Input  name="PIC" type="text" value={this.state.PIC} onChange={this.onChangeInput} required /> 
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Hourly Rate</Label>
                            <Input  name="rate" type="number" value={this.state.rate} onChange={this.onChangeInput} required /> 
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Contact Number</Label>
                            <Input  name="contactNo" type="text" value={this.state.contactNo} onChange={this.onChangeInput} required /> 
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input  name="email" type="email" value={this.state.email} onChange={this.onChangeInput} required /> 
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button  block >Submit</Button>
            </ModalFooter>
            </Form>
        </Modal>
        )
    }
}

const mapStateToProps = state => ({
    vendor: state.vendorR
})

export default connect(mapStateToProps, {
toggleVendorModal,createVendor
})(vendorModal)
