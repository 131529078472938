import {GET_ERRORS,CLEAR_ERRORS} from './types'

//RETURN ERRORS
export const returnErrors = (message, status, id = null) => {
    return {
        type: GET_ERRORS,
        payload: {
            message,
            status,
            id
        }
    }
}

//CLEAR ERRORS
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
}