import {
    GET_PROPOSALS, GET_PROPOSAL, CREATE_PROPOSAL, TOGGLE_PROPOSAL_MODAL,
    GET_PROPOSAL_IN_MODAL, UPDATE_PROPOSAL, SEND_FOR_REVIEW, DELETE_PROPOSAL,
    APPROVE_PROPOSAL, REJECT_PROPOSAL
}
    from "../actions/types";

const initailState = {
    proposals: [],
    proposal: {},
    message: null,
    addModal: false, //  Toggles the add modal
    detailsModal: false, // Toggles the details modal
    reviewModal: false  // Toggles the review modal

}
const proposalReducer = function (state = initailState, action) {
    switch (action.type) {
        case GET_PROPOSALS:
            return {
                proposals: action.payload
            }
        case CREATE_PROPOSAL:
            return {
                ...state,
                proposals: [...state.proposals, action.payload]
            }
        case SEND_FOR_REVIEW:
        case REJECT_PROPOSAL:
        case APPROVE_PROPOSAL:
            return {
                ...state,
                proposals: state.proposals.map(e => e._id === action.payload._id ? action.payload : e)
            }
        case GET_PROPOSAL:
        case UPDATE_PROPOSAL:
            return {
                ...state,
                proposals: state.proposals.map(e => e._id === action.payload._id ? action.payload : e),
                proposal: action.payload
            }
        case DELETE_PROPOSAL:
            return {
                ...state,
                proposals: state.proposals.filter(({ _id }) => _id !== action.payload)
            }
        case GET_PROPOSAL_IN_MODAL: {
            return {
                ...state,
                proposal: state.proposals.filter(e => e._id === action.payload)[0]
            }
        }
        case TOGGLE_PROPOSAL_MODAL: // Toggling the corresponding modal 
            if (action.payload === "addModal") {
                return {
                    ...state,
                    addModal: !state.addModal
                }
            } else if (action.payload === "reviewModal") {
                return {
                    ...state,
                    reviewModal: !state.reviewModal
                }
            } else {
                return {
                    ...state,
                    detailsModal: !state.detailsModal
                }
            }
        default:
            return state;
    }
}
export default proposalReducer