import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'reactstrap'
import { getEmployees, employeeRewardStatus, } from '../../actions/employeeActions'

const RewardsRequestTable = () => {
    const [showComponent, setShowComponent] = useState(false)// To show the component id there is any redeem request
    const employees = useSelector(state => state.employeeR.employees.filter(({ myRewards }) => myRewards.length))

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getEmployees())
    }, [])
    useEffect(() => {
        employees.map(e => e.myRewards.map(e => e.status === "Requested" ? setShowComponent(true) : e))
    }, [employees])
    const updateReward = (employee, index, action) => {
        employee.myRewards[index].status = action
        dispatch(employeeRewardStatus(employee._id, employee.myRewards))
    }
    if (showComponent) {
        return (
            <div>
                <h5 style={{ marginTop: '0.5rem' }}>
                    <center>
                        <b>Redeem Requests</b>
                    </center>
                </h5>
                <Table>
                    <thead>
                        <tr>
                            <th width="20%">Employee Name</th>
                            <th width="20%">Reward Name</th>
                            <th width="45%">Reward Description</th>
                            <th width="15%">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            employees.map(employee => employee.myRewards.map((reward, index) => {
                                if (reward.status === "Requested")
                                    return <tr key={index}>
                                        <td>{employee.name}</td>
                                        <td>{reward.name}</td>
                                        <td>{reward.description}</td>
                                        <td style={{ display: 'flex', flexDirection: "column" }}>
                                            <span
                                                style={{ color: "green", cursor: "pointer" }}
                                                onClick={() => updateReward(employee, index, "Redeemed")}
                                            >
                                                Approve
                                        </span>
                                            <span
                                                style={{ color: "red", cursor: "pointer" }}
                                                onClick={() => updateReward(employee, index, "Purchased")}
                                            >
                                                Reject
                                        </span>
                                        </td>
                                    </tr>
                            }))
                        }
                    </tbody>
                </Table>
            </div>
        )
    } else {
        return null
    }

}
export default RewardsRequestTable 
