import React, { Fragment, useState } from 'react'
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	ListGroup,
	ListGroupItem,
	Form,
	Input,
} from 'reactstrap';
import ConfirmButton from '../userComponents/ConfirmButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

const ProposalTaskOptions = props => {
    const [modal, setModal] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [addDepartment, setAddDepartment] = useState(false)

    if(!props.proposalTasks){
        return null
    }
    return (
        <Fragment>
            <a
                href='#'
                style={{
                    display: 'inline-block',
                    marginBottom: '1rem',
                    marginTop: '-0.5rem',
                    fontSize: '12px',
                }}
                onClick={() => setModal(true)}>
                Manage Proposal Tasks
            </a>

            <Modal
					isOpen={modal}
					toggle={() => setModal(!modal)}>
					<ModalHeader toggle={() => setModal(!modal)}>
						<strong>Manage Proposal Tasks</strong>
					</ModalHeader>
					<ModalBody>
						<ListGroup>
							{props.proposalTasks
								.filter(({ active }) => active)
								.map((e,i) => (
									<ListGroupItem key={e.id}>
										{editMode?
										<div>
											<Input name = {e.id} defaultValue = {e.name} onChange={(e) => props.updateProposalTask(e,i)}/>
										</div>
										:
										<div>
											{e.name}
											<ConfirmButton
												className='remove-btn'
												color='danger'
												size='sm'
												style={{
													float: 'right',
												}}
												alertTitle={'Delete this Task?'}
												onClick={() => props.onDeleteClick(props.company_id, i)}>
												<FontAwesomeIcon color='white' style={{ fontSize: '14x' }} icon={faTrash} />
											</ConfirmButton>
											<Button
												className='remove-btn'
												color='primary'
												size='sm'
												style={{
													float: 'right',
												}}
												onClick={() => {
                                                    props.onEditClick()
                                                    setEditMode(true)}
                                                    }>
												<FontAwesomeIcon color='white' style={{ fontSize: '14x' }} icon={faEdit} />
											</Button>
										</div>}
									</ListGroupItem>
								))}
						</ListGroup>
						{editMode?<div
							style = {{margin: "0.5rem", display: "block"}}
						><Button
							className='remove-btn'
							color='success'
							style={{
								color: 'black',
								float: "right"
							}}
							size='sm'
							onClick={() => {
                                props.setNewProposalTask(props.company_id)
                                setEditMode(false)
                            }}>
							&#10003;
						</Button>
						<Button
							className='remove-btn'
							color='danger'
							style={{
								color: 'black',
								float: "right"
							}}
							size='sm'
							onClick={() => setEditMode(false)}>
							&#10005;
						</Button><br /></div>: null}
						{!addDepartment? (
							<Button color='dark' style={{ marginTop: '2rem' }} block onClick={() => setAddDepartment(true)}>
								Add Task
							</Button>
						) : (
								<div>
									<Input
										type='text'
										name='newProposalTask'
										placeholder='New Task'
										onChange={(e) => props.onChange(e)}
										style={{
											marginTop: '0.5rem',
										}}
										required
									/>
									<Button
										onClick={() => setAddDepartment(false)}
										className='remove-btn'
										color='danger'
										style={{
											color: 'black',
											marginTop: '2rem',
										}}
										size='sm'>
										&#10005;
								</Button>
									<Button
										onClick={() => {
                                            props.onProposalTask(props.company_id)
                                            setAddDepartment(false)
                                        }}
										className='remove-btn'
										color='success'
										style={{
											color: 'black',
											marginTop: '2rem',
										}}
										size='sm'>
										&#10003;
								</Button>
								</div>
							)}
					</ModalBody>
				</Modal>
        </Fragment>
        
)
}

export default ProposalTaskOptions