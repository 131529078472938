import { GET_VENDORS, CREATE_VENDOR,UPDATE_VENDOR,GET_VENDOR_IN_MODAL,TOGGLE_VENDOR_MODAL,DELETE_VENDOR ,GET_VENDOR } from '../actions/types';

const initailState= {
    vendors:[],
    vendor: {},
    message:null,
    detailsModal: false,
    addModal:false
}
const vendorReducer = function(state = initailState,action) {
    switch(action.type){
        case GET_VENDORS:
            return {
                ...state,
                vendors: action.payload
            }
        case GET_VENDOR: 
            return {
                ...state,
                vendor: action.payload
            }
        case CREATE_VENDOR:
            return {
                ...state,
                vendors: [...state.vendors, action.payload]
            }
        case DELETE_VENDOR: 
            return {
                ...state,
                vendors: state.vendors.filter(({_id}) => _id !== action.payload)
            }
        case UPDATE_VENDOR:
            return {
                ...state,
                message: action.payload

            }
        case TOGGLE_VENDOR_MODAL:
            if(action.payload === "detailsModal"){
                return {
                    ...state,
                    detailsModal: !state.detailsModal
                }
            }else {
                return {
                    ...state,
                    addModal: !state.addModal
                }
            }
           
        case GET_VENDOR_IN_MODAL: 
            return {
                ...state,
                vendor: state.vendors.filter(({_id}) => _id === action.payload)[0]
            }
        default:
            return state;
    }
}
export default vendorReducer