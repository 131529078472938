import React, { Component } from 'react';
import { Container, Table, Button, Input, Alert, Row, Col, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { getTasks, toggleTaskIndex, approveTask, filterTasks } from '../../actions/taskActions';
import TaskDetails from './TaskDetails';
import ApprovalModal from './ApprovalModal'
import PropTypes from 'prop-types';
import { getEmployees, updateEmployeePoints } from '../../actions/employeeActions';
import { getProjects } from '../../actions/projectActions';
import { getReports } from '../../actions/reportActions';
import { getCompany } from '../../actions/companyActions';
import { createNotification } from '../../actions/userActions';
import SortComponent from '../SortComponent'
import { getProposals } from '../../actions/proposalActions';

class TaskApproval extends Component {
	state = {
		Overdue: false, // Start of filter related states
		RangeStart: '',
		RangeEnd: '',
		project: '',
		departments: [],
		DepartmentSelector: '',
		EmployeeSelector: '',
		employees: [],
		filtercolors: [
			'#e1bee7',
			'#64b5f6',
			'#c5e1a5',
			'#f48fb1',
			'#f06292',
			'#ffee58',
			'#ffab91',
			'#80cbc4',
		], // End of filter related states
		message: null,
		points: '', // storing the points input
		comment: '', // storing the comment input
		golds: 0, // storing the golds input
		sortCriteria: localStorage.getItem('sortCriteriaTa')?localStorage.getItem('sortCriteriaTa'):"name",
		sortDirection: localStorage.getItem('sortDirectionTa')?localStorage.getItem('sortDirectionTa'):1,
	};

	componentDidMount() {
		this.props.getCompany();
		this.props.getTasks();
		this.props.getReports();
		this.props.getEmployees();
		this.props.getProposals();
		this.props.getProjects();
	}
	componentDidUpdate(prevProps, prevState) {
		const { error } = this.props;
		if (error !== prevProps.error) {
			// Check for register error
			if (error.id === 'UPDATE_TASK_FAIL') {
				this.setState({ message: error.message.message });
			} else {
				this.setState({ message: null });
			}
		}
		// The below conditionals are related to tasks filter.
		if (prevProps.company.company !== this.props.company.company) {
			this.setState({
				departments: this.props.company.company.departments
					.filter(({ active }) => active)
					.map((e, index) => {
						return {
							name: e.name,
							id: e.id,
							filterState: true,
							color: this.state.filtercolors[index],
							index: index,
						};
					}),
				employees: this.props.employee.employees,
			});
		}
		if (
			prevProps.task.tasks !== this.props.task.tasks ||
			prevState.departments !== this.state.departments ||
			prevState.project !== this.state.project ||
			prevState.EmployeeSelector !== this.state.EmployeeSelector ||
			prevState.Overdue !== this.state.Overdue ||
			prevState.RangeEnd !== this.state.RangeEnd ||
			prevState.RangeStart !== this.state.RangeStart
		) {
			var filters = {};
			filters.department = this.state.departments.filter((e) => e.filterState).map((e) => e.id);
			if (this.state.EmployeeSelector) {
				filters.user_id = this.state.EmployeeSelector;
			}
			if (this.state.project) {
				filters.project_id = this.state.project;
			}
			var d = '';
			if (this.state.RangeEnd) {
				d = new Date(this.state.RangeEnd);
				d = new Date(d.setDate(d.getDate() + 1)).toISOString().substr(0, 10);
			}
			if (this.state.Overdue) {
				filters.overdue = this.state.Overdue;
			}
			filters.dateRange = [this.state.RangeStart, d];
			this.props.filterTasks(filters, false);
		}
		if (prevState.DepartmentSelector !== this.state.DepartmentSelector) {
			this.setState({
				employees: this.state.DepartmentSelector
					? this.props.employee.employees.filter(
						({ department }) => department === this.state.DepartmentSelector
					)
					: this.props.employee.employees,
			});
		}

		// Local Storage for sortCriteria and Direction
		if(
			prevState.sortCriteria!==this.state.sortCriteria||
			prevState.sortDirection!==this.state.sortDirection
		){
			localStorage.setItem('sortCriteriaTa', this.state.sortCriteria);
			localStorage.setItem('sortDirectionTa', this.state.sortDirection);
		}
	}
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	overdueFilter = () => {
		this.setState({
			Overdue: !this.state.Overdue,
		});
	};
	departmentFilter = (i) => {
		this.setState({
			departments: this.state.departments.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};

	ApprovalModal = (status, _id, user_id, points) => {
		this.setState({
			approvalModal: true,
			modalStatus: status,
			modal_id: _id,
			modal_user_id: user_id,
			modal_points: points,
			points: '',
			comment: '',
			modalMessage: '',
		})
	}

	ApproveRequest = (status, _id, user_id) => {
		this.setState({
			message: null,
			modalMessage: null,
		});
		const uniqueID = this.idGenerator(); // The unique id of the comment and it's notification.
		if (status === 'In Progress' && !this.state.comment) {
			this.setState({
				modalMessage: "Comment Cannot be blank",
			});
		} else if (status === 'Completed' && !this.state.points) {
			this.setState({
				modalMessage: "Points cannot be blank",
			});

		} else {
			if (this.state.comment && this.props.auth.user.id !== user_id) {
				// It checks for the comment and the commenter should be someone else not the user it self.
				const newNotifiation = {
					comment: this.state.comment, // THe comment text
					id: uniqueID, // THe comment unique Id which corresponds to it's notification
					task_id: _id, // task id
					commentedBy_id: this.props.auth.user.id, // The commenter Id,
					isSeen: false,
					date: new Date(),
				};
				this.props.createNotification(newNotifiation, user_id);
			} else if (this.state.points) {
				const pointsNumber = {
					points: Number(this.state.points),
				};
				const employeeID = String( // Just to get employee_id
					this.props.employee.employees
						.filter((employee) => employee.user_id === user_id)
						.map((employee) => employee._id)
				);
				this.props.updateEmployeePoints(employeeID, pointsNumber); //The employee's Golds and Points will be updated.
			}
			const object = {
				comment: this.state.comment,
				commentId: uniqueID, //  unique Id of the comment that will corresponds to the notification id.
				points: this.state.points,
				_id: _id, // Task id
				status: status,
			};
			this.props.approveTask(object);
			this.setState({
				points: '',
				comment: '',
			})
			this.toggle()
		}
	};
	idGenerator = () => {
		// The function generates Id consist of numbers and letters
		return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2); // The ID length is 22 eg.. 8hfhals5fkd..
	};
	handleInputChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};
	toggle = () => {
		this.setState({
			approvalModal: !this.state.approvalModal,
		});
	};

	sortFunction = (sortCriteria) => {
		this.setState({
			sortCriteria,
			sortDirection: -this.state.sortDirection
		})
	}

	render() {
		const { filteredTasks } = this.props.task;
		const { projects } = this.props.project;
		const { employees } = this.props.employee;
		return (
			<Container>
				<ApprovalModal
					approvalModal={this.state.approvalModal}
					toggle={this.toggle}
					onChange={this.onChange}
					ApproveRequest={this.ApproveRequest}
					modalStatus={this.state.modalStatus}
					modal_id={this.state.modal_id}
					modal_user_id={this.state.modal_user_id}
					modal_points={this.state.modal_points}
					message={this.state.modalMessage}
				/>
				<TaskDetails />
				<Row style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
					{' '}
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline After</Label>
						<Input
							type='date'
							name='RangeStart'
							onChange={this.onChange}
							defaultValue={this.state.RangeStart}
						/>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline Before</Label>
						<Input
							type='date'
							name='RangeEnd'
							onChange={this.onChange}
							defaultValue={this.state.RangeEnd}
						/>
					</Col>
					<Col xs='0.5'>
						<p
							style={{
								color: 'red',
								fontSize: '40px',
								marginTop: '17px',
								marginBottom: '-17px',
								padding: '0px',
								opacity: this.state.Overdue ? '1' : '0.3',
								textAlign: 'center',
								cursor: 'pointer',
							}}
							onClick={this.overdueFilter}>
							!!
						</p>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Project</Label>
						<Input
							type='select'
							name='project'
							onChange={this.onChange}
							defaultValue={this.state.project}>
							<option></option>
							{this.props.project.projects.map((project) => (
								<option key={project._id} value={project._id}>
									{project.name}
								</option>
							))}
						</Input>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>PIC Department</Label>
						<Input
							type='select'
							name='DepartmentSelector'
							onChange={this.onChange}
							defaultValue={this.state.DepartmentSelector}>
							<option value="">Select to Filter PIC</option>
							{this.state.departments.map(({ name, id }) => (
								<option key={id} value={id}>{name}</option>
							))}
						</Input>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>PIC</Label>
						<Input
							type='select'
							name='EmployeeSelector'
							onChange={this.onChange}
							defaultValue={this.state.EmployeeSelector}>
							<option></option>
							{this.state.employees.map(({ _id, name, user_id }) => (
								<option key={_id} value={user_id}>
									{name}
								</option>
							))}
						</Input>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table>
							<tbody>
								<tr>
									{this.state.departments.map(({ name, filterState, color, index }) => (
										<td
											key={name}
											style={{
												opacity: filterState ? '1' : '0.3',
												backgroundColor: color,
												textAlign: 'center',
												cursor: 'pointer',
											}}
											onClick={this.departmentFilter.bind(this, index)}>
											{name}
										</td>
									))}
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				{this.state.message ? (
					<Alert style={{ textAlign: 'center' }} color='danger'>
						{this.state.message}
					</Alert>
				) : null}
				<Table hover>
					<thead>
						<tr>
							<th>Name<SortComponent onClick={() => this.sortFunction("name")} /></th>
							<th>Project</th>
							<th>Department</th>
							<th>PIC</th>
							<th>Difficulty<SortComponent onClick={() => this.sortFunction("reward")} /></th>
							{/* <th>Points</th>
							<th>Comments</th> */}
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{filteredTasks.sort((a, b) => {
							if (!b[this.state.sortCriteria]) { return -1 }
							if (!a[this.state.sortCriteria]) { return 1 }
							if (a[this.state.sortCriteria] > b[this.state.sortCriteria]) { return this.state.sortDirection }
							if (a[this.state.sortCriteria] < b[this.state.sortCriteria]) { return -this.state.sortDirection }
							if (a.name > b.name) { return 1 }
							if (a.name < b.name) { return -1 }
							return 0
						}).map(
							({ _id, name, department, user_id, deadline, status, project_id, reward, proposal_id }, index) => {
								if (status === 'Review') {
									const projectName = projects
										.filter((project) => project._id === project_id)
										.map((project) => {
											if (proposal_id) {
												return this.props.proposal.proposals.filter(proposal => proposal._id === proposal_id).map(proposal => proposal.proposalNumber)
											}
											return project.name
										});
									const PIC = employees
										.filter((employee) => employee.user_id === user_id)
										.map((employee) => employee.name);
									const relatedReports = this.props.report.reports.filter(report => report.task_id === _id)
									var points = 0
									relatedReports.forEach(({ numberOfHours }) => points = points + numberOfHours);
									return (
										<tr key={_id}>
											<td>
												<a href='#' onClick={this.props.toggleTaskIndex.bind(this, _id)}>
													{name}
												</a>
											</td>
											<td>{projectName}</td>
											<td>{this.props.company.company.departments ? this.props.company.company.departments.find(({ id }) => id === department).name : null}</td>
											<td>{PIC}</td>
											<td>{reward}</td>
											{/* <td>
												<Input type='number' name='points' onChange={this.handleInputChange} 
													placeholder = {points}
												/>
											</td>
											<td>
												<Input type='textarea' name='comment' onChange={this.handleInputChange} />
											</td> */}
											<td style={{ whiteSpace: 'nowrap' }}>
												<Button
													className='remove-btn'
													color='success'
													style={{
														color: 'black',
													}}
													size='sm'
													onClick={() => this.ApprovalModal('Completed', _id, user_id, points)}>
													&#10003;
												</Button>
												<Button
													className='remove-btn'
													color='danger'
													style={{
														color: 'black',
													}}
													size='sm'
													onClick={() => this.ApprovalModal('In Progress', _id, user_id, points)}>
													&#10005;
												</Button>
											</td>
										</tr>
									);
								} else {
									return null;
								}
							}
						)}
					</tbody>
				</Table>
			</Container>
		);
	}
}

TaskApproval.propTypes = {
	getTasks: PropTypes.func.isRequired,
	approveTask: PropTypes.func.isRequired,
	getProjects: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	toggleTaskIndex: PropTypes.func.isRequired,
	getProposals: PropTypes.func.isRequired,
	task: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR, // getting reducer from /reducers/index.js
	employee: state.employeeR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js
	report: state.reportR,
	auth: state.authR,
	company: state.companyR,
	proposal: state.proposalR,
});

export default connect(mapStateToProps, {
	getTasks,
	toggleTaskIndex,
	getEmployees,
	updateEmployeePoints,
	getProjects,
	getReports,
	getCompany,
	approveTask,
	createNotification,
	getProposals,
	filterTasks,
})(TaskApproval);
