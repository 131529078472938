import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import "./JumperManagement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faComment,
  faDownload,
  faEdit,
  faEnvelope,
  faPhone,
  faTrash,
  faTrashAlt,
  faTshirt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import UpdateRequest from "./UpdateRequest/UpdateRequest";

import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const sizeLables = ["XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"];

const JumperManagement = (props) => {
  const [jumperRequests, setJumperRequests] = useState([]);
  const [filteredJumperRequests, setFilteredJumperRequests] = useState([]);
  const [request, setRequest] = useState(null);
  useEffect(() => {
    async function getData() {
      const { data } = await axios.get("api/jumpers");
      setJumperRequests(data);
      setFilteredJumperRequests(data);
    }
    getData();
  }, []);

  const deleteRequest = async (user) => {
    const confirm = window.confirm(`Delete ${user.name}'s request`);
    if (confirm) {
      try {
        const { data } = await axios.delete(`api/jumpers/${user._id}`);
        setJumperRequests(jumperRequests.filter((e) => e._id !== user._id));
        setFilteredJumperRequests(
          filteredJumperRequests.filter((e) => e._id !== user._id)
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const updateRequest = async (requestBody) => {
    const confirm = window.confirm(`Update ${requestBody.name}'s request`);
    if (confirm) {
      try {
        const { data } = await axios.put(`api/jumpers`, requestBody);
        setJumperRequests(
          jumperRequests.map((e) => {
            if (e._id === data._id) {
              return data;
            } else {
              return e;
            }
          })
        );
        setFilteredJumperRequests(
          filteredJumperRequests.map((e) => {
            if (e._id === data._id) {
              return data;
            } else {
              return e;
            }
          })
        );
        setRequest(null);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const filterRequests = (e) => {
    setFilteredJumperRequests(
      jumperRequests.filter(
        (request) =>
          request.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          request.email.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const editRequest = (e) => {
    setRequest(e);
  };

  const ExportCSV = (csvData, fileName) => {
    var parsedData = csvData.map((x) => {
      var order = x;
      for (const key in x["sizes"]) {
        order[key] = x["sizes"][key];
      }
      delete order["sizes"];
      return order;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(parsedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Fragment>
      <UpdateRequest
        request={request}
        modal={request ? true : false}
        toggle={() => setRequest(null)}
        updateRequest={updateRequest}
      />

      <div className="jumper-page-wrapper">
        <div className="jumper-filter-wrapper">
          <input
            className="jumper-page-filter-wrapper"
            placeholder="Filter"
            onChange={(e) => filterRequests(e)}
          />
          <div
            className="export-button"
            onClick={() => ExportCSV(filteredJumperRequests, `order-list (${(new Date().toISOString()).substr(0,10)})`)}
          >
            <FontAwesomeIcon icon={faDownload} />
          </div>
        </div>
        <div className="jumper-page-details-wrapper">
          {filteredJumperRequests.map((e) => {
            return (
              <div className="jumper-table-wrapper" key={e._id}>
                <div className="jumper-table-actions">
                  <div
                    className="jumper-table-edit"
                    onClick={() => editRequest(e)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </div>
                  <div
                    className="jumper-table-delete"
                    onClick={() => deleteRequest(e)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                </div>
                <div className="jumper-table-personal-info-wrapper">
                  <div className="jumper-table-personal-info">
                    <FontAwesomeIcon icon={faUser} /> {e.name}
                  </div>
                  <div className="jumper-table-personal-info">
                    <FontAwesomeIcon icon={faCalendar} />{" "}
                    {new Date(e.createdAt).toLocaleDateString()}
                  </div>
                </div>
                <div className="jumper-table-personal-info-wrapper">
                  <div className="jumper-table-personal-info">
                    <FontAwesomeIcon icon={faEnvelope} /> {e.email}
                  </div>
                  <div className="jumper-table-personal-info">
                    <FontAwesomeIcon icon={faPhone} /> {e.phone}
                  </div>
                </div>
                <div className="jumper-table-personal-info-wrapper">
                  <div className="jumper-table-personal-info">
                    <FontAwesomeIcon icon={faTshirt} /> {e.type}
                  </div>
                </div>
                <div className="jumper-table-comment-wrapper">
                  <span>
                    <FontAwesomeIcon icon={faComment} /> {e.comment}
                  </span>
                </div>
                <div className="jumper-table-size-wrapper">
                  {sizeLables.map((size) => (
                    <div className="jumper-table-size">
                      <div className="jumper-table-size-label">{size}</div>
                      <div>{e.sizes[size]}</div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default JumperManagement;
