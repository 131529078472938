import axios from 'axios'
import {
    GET_TASKS,
    ADD_TASK,
    DELETE_TASK,
    TASK_LOADING,
    TOGGLE_TASK_MODAL,
    GET_TASK,
    UPDATE_TASK,
    UPDATE_TASK_FAIL,
    REQUEST_TASK,
    APPROVE_TASK,
    TOGGLE_REVIEW_MODAL,
    REVIEW_TASK,
    REMOVE_TASK,
    CANCEL_TASK,
    COMMENT_TASK,
    DELETE_COMMENT,
    FILTER_TASK,
    UPDATE_TASK_REPORT,
    GET_COMPANY
} from './types';
import { tokenConfig } from './authActions'
import { returnErrors } from './errorActions'
import { addTasktoProject, getProject, getProjects } from "./projectActions";


export const getTasks = () => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios
        .get(
            `/api/tasks`, tokenConfig(getState)
        )
        .then(
            res => dispatch({
                type: GET_TASKS,
                payload: res.data
            }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
};

export const addTask = (newTask) => (dispatch, getState) => {
    axios
        .post(`/api/tasks`, newTask, tokenConfig(getState))
        .then(res => {
            dispatch(addTasktoProject(newTask.project_id, res.data))
            dispatch({
                type: ADD_TASK,
                payload: res.data
            })
        })
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const addReporttoTask = (task_id, report) => (dispatch, getState) => {
    axios
        .post(`/api/tasks/addReport/${task_id}`, report, tokenConfig(getState))
        .then(
            res => dispatch({
                type: UPDATE_TASK_REPORT,
                payload: res.data
            }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const deleteTask = (id) => (dispatch, getState) => {
    axios
        .delete(`/api/tasks/${id}`, tokenConfig(getState))
        .then(
            res => dispatch({
                type: DELETE_TASK,
                payload: id
            }))
        .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};

export const cancelTask = (task) => (dispatch, getState) => {
    axios
        .post('/api/tasks/cancelTask', task, tokenConfig(getState))
        .then(
            () => dispatch({
                type: CANCEL_TASK,
                payload: task
            }))
        .catch(
            err => {
                dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
                dispatch({
                    type: UPDATE_TASK_FAIL
                })
            })
}

export const requestTask = (id, task) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios
        .post(`/api/tasks/requestTask/${id}`, task, tokenConfig(getState))
        .then(
            res => dispatch({
                type: REQUEST_TASK,
                payload: id
            }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
            dispatch({
                type: UPDATE_TASK_FAIL
            })
        })
}

export const reviewTask = (task) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    dispatch(removeTask(task._id));
    axios
        .post(`/api/tasks/reviewTask`, task, tokenConfig(getState))
        .then(
            res => dispatch({
                type: REVIEW_TASK,
                payload: res.data
            }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
            dispatch({
                type: UPDATE_TASK_FAIL
            })
        })
}

export const approveTask = (task) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios
        .post(`/api/tasks/approveTask`, task, tokenConfig(getState))
        .then(
            () => dispatch({
                type: APPROVE_TASK,
                payload: task._id
            }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
            dispatch({
                type: UPDATE_TASK_FAIL
            })
        })
}

export const commentTask = (newComment, taskID) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios
        .post(`/api/tasks/comment/${taskID}`, newComment, tokenConfig(getState))
        .then(res => dispatch({
            type: COMMENT_TASK,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
            dispatch({
                type: UPDATE_TASK_FAIL
            })
        })
}

export const deleteComment = (deletedComment, taskID) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios.
        post(`/api/tasks/deletingComment/${taskID}`, deletedComment, tokenConfig(getState))
        .then(res => dispatch({
            type: DELETE_COMMENT,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
            dispatch({
                type: UPDATE_TASK_FAIL
            })
        })

}
export const updateTask = (id, task) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios
        .post(`/api/tasks/update/${id}`, task, tokenConfig(getState))
        .then(
            (res) => {
                dispatch({
                    type: UPDATE_TASK,
                    payload: res.data
                })
                console.log('updated', res)

            })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
            dispatch({
                type: UPDATE_TASK_FAIL
            })
        })
}
export const patchTask = (task) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios
        .patch(`/api/tasks/patch`, task, tokenConfig(getState))
        .then(
            (res) => {
                dispatch({
                    type: UPDATE_TASK,
                    payload: res.data
                })
            })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
            dispatch({
                type: UPDATE_TASK_FAIL
            })
        })
}

export const patchTwoTask = (firstTask, secondTask) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios
        .patch(`/api/tasks/patch`, firstTask, tokenConfig(getState))
        .then(
            (res) => {
                dispatch({
                    type: UPDATE_TASK,
                    payload: res.data
                })
                dispatch(patchTask(secondTask))
            })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, "UPDATE_TASK_FAIL"))
            dispatch({
                type: UPDATE_TASK_FAIL
            })
        })
}


export const getTask = (id) => (dispatch, getState) => {
    dispatch(setItemsLoading());
    axios
        .get(`/api/companies/company`, tokenConfig(getState))
        .then((res) => {
            axios
                .get(
                    `/api/tasks/${id}`, tokenConfig(getState)
                )
                .then(
                    res => {
                        dispatch({
                            type: GET_TASK,
                            payload: res.data
                        })
                        dispatch(getProjects())
                    })
                .catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
            dispatch({
                type: GET_COMPANY,
                payload: res.data,
            })
        })
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const filterTasks = (filters, calendarState) => (dispatch) => {
    dispatch({
        type: FILTER_TASK,
        payload: {
            filters: filters,
            calendarState: calendarState,
        }
    })
}

export const toggleTaskIndex = (_id) => (dispatch) => {
    dispatch({
        type: TOGGLE_TASK_MODAL,
        payload: _id
    })
}

export const toggleReviewIndex = (i, modals) => (dispatch) => {
    const modal = modals.map((item, j) => {
        if (j === i) {
            return !item
        } else {
            return item
        }
    })
    dispatch({
        type: TOGGLE_REVIEW_MODAL,
        payload: modal
    })
}

export const setItemsLoading = () => {
    return {
        type: TASK_LOADING
    }
}
export const removeTask = (id) => { // It just remove the tasks from the tasks array based on ID
    return {
        type: REMOVE_TASK,
        payload: id
    }
}
