import React, {Component} from 'react';
import { Redirect } from 'react-router-dom'
import {
    Card,
    CardBody,
    CardHeader,
    Container,
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Alert
} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import { updateProject, getProject } from '../../actions/projectActions'
import { getEmployees } from '../../actions/employeeActions'
import { clearErrors } from '../../actions/errorActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';


class UpdateProject extends Component {
    state = {
        _id: "",
        name: '',
        deadline: '',
        PIC:'',
        description: '',
        enddate: '',
        startdate: '',
        reason:'',
        message: null,
        messageSuccess: null,
    }

    componentDidMount(){
        this.props.getProject(this.props.match.params.id);
        this.props.getEmployees()
    }

    componentDidUpdate(prevProps){

        if(prevProps.project.project !== this.props.project.project){
            const {name , status, deadline, startdate, enddate, description, _id, user_id} = this.props.project.project
            this.setState({
                name: name,
                status: status,
                deadline: deadline?deadline.substring(0,10):"",
                startdate: startdate? startdate.substring(0,10):"",
                enddate: enddate? enddate.substring(0,10):"",
                description: description,
                PIC: user_id,
                _id: _id
            })
        }

        if(prevProps.project.message !== this.props.project.message){
            this.setState({
                messageSuccess: this.props.project.message
            })
        }

        const { error } = this.props
        if (error!== prevProps.error) {
            //Check for register error
            if(error.id === 'UPDATE_PROJECT_FAIL') {
                this.setState({message: error.message})
            } else {
                this.setState({ message: null })
            }
        }
    }
    
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = e => {
        e.preventDefault();
        if(!this.state.reason){
            return this.setState({message:"Reason is Required"})
        }
        this.props.clearErrors()
        var projectHistory = {
            reason: this.state.reason,
            date: new Date(),
            updatedBy: this.props.auth.user.id
        }
         
        const arr = ["name" , "status", "deadline", "startdate", "enddate", "description", "user_id"]
        arr.forEach(element => {
            if(element ==="deadline"||element ==="startdate"||element ==="enddate"){
                if(this.props.project.project[element]){
                    if(this.props.project.project[element].substring(0,10) !== this.state[element]){projectHistory[element] = this.props.project.project[element]}
                } else {
                    if(this.state[element]){projectHistory[element] = this.props.project.project[element]}
                }
            } else {
                if(this.props.project.project[element] !== this.state[element]){ projectHistory[element] = this.props.project.project[element]}
            }
        })
        const updateProject = {
            name: this.state.name,
            deadline: this.state.deadline.substring(0,10),
            startdate: this.state.startdate? this.state.startdate.substring(0,10):"",
            enddate: this.state.enddate? this.state.enddate.substring(0,10):"",
            description: this.state.description,
            status: this.state.status,
            user_id: this.state.PIC,
            projectHistory
        }
        // Add project via updateProject action
        this.props.updateProject( this.state._id, updateProject);
    }

    render() {
        const { employees } = this.props.employee
        if(this.state.redirect){
            //return this.props.history.goBack()
            return <Redirect to={this.state.redirect} />
		}
        return this.props.project.project?(
            <Container>
                <Card
                    style = {{
                        width: "70%",
                        margin: "Auto",
                        marginBottom: "2rem"
                    }}
                >
                    <CardHeader>
                        <strong>Project ID: {this.state._id}</strong>
                        <div
							style={{
								display: "inline-block",
								float: "right",
								position: 'relative'
							}}
						>
							<div>
								<FontAwesomeIcon 
									style={{ fontSize: '1.5rem', cursor: "pointer" }} 
									icon={faArrowAltCircleLeft} 
									onClick={() => {
										this.setState({ redirect: "/projectlist" });
									}}
									onMouseOver={() => {
										this.setState({
											returnTooltip: true,
										})
									}}
									onMouseOut={() => {
										this.setState({
											returnTooltip: false,
										})
									}}
								/>
							</div>
							<span
								style={{
									visibility: this.state.returnTooltip
										? 'visible'
										: 'hidden',
									color: '#323232',
									position: 'absolute',
									left: '0',
									top: '55%',
									fontSize: 20,
								}}>
								&#9650;
							</span>
							<span
								style={{
									visibility: this.state.returnTooltip
										? 'visible'
										: 'hidden',
									padding: '8px',
									fontSize: '12px',
									zIndex: '1',
									borderRadius: '2px',
									color: '#fff',
									lineHeight: '130%',
									position: 'absolute',
									textAlign: 'center',
									overflow: 'hidden',
									width: "10rem",
									left: '0%',
									top: '130%',
									backgroundColor: '#323232',
									whiteSpace: 'pre-wrap',
							}}>
								Return to Project List
							</span>
						</div>
                    </CardHeader>
                    <CardBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input 
                                    type = "text"
                                    name = "name"
                                    onChange = {this.onChange}
                                    defaultValue = {this.state.name}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Description</Label>
                                <Input 
                                    id="descriptionBox"
                                    type = "textarea"
                                    name = "description"
                                    onChange = {this.onChange}
                                    defaultValue = {this.state.description}
                                    required
                                />
                            </FormGroup>
                            <Row xs = "2">
                                <Col>
                                    <FormGroup>
                                        <Label for = "StartDate" >Start Date</Label>
                                        <Input 
                                            type = "date"
                                            name = "startdate"
                                            id = "StartDate"
                                            onChange = {this.onChange}
                                            value = {this.state.startdate}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for = "EndDate" >End Date</Label>
                                        <Input 
                                            type = "date"
                                            name = "enddate"
                                            id = "EndDate"
                                            onChange = {this.onChange}
                                            value = {this.state.enddate}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row xs = "2">
                                <Col>
                                    <FormGroup>
                                        <Label for = "TaskDeadline" >Task Deadline</Label>
                                        <Input 
                                            type = "date"
                                            name = "deadline"
                                            id = "TaskDeadline"
                                            placeholder = "Add Task Deadline"
                                            onChange = {this.onChange}
                                            value = {this.state.deadline}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for = "Status" >Status</Label>
                                        <Input 
                                            type = "select"
                                            name = "status"
                                            id = "Status"
                                            onChange = {this.onChange}
                                            required
                                        >
                                            <option>{this.state.status}</option>
                                            <option>In Progress</option>
                                            <option>Completed</option>
                                            <option>Hold</option>
                                            <option>Review</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for = "PIC" >PIC</Label>
                                        <Input 
                                            type = "select"
                                            name = "PIC"
                                            id = "PIC"
                                            onChange = {this.onChange}
                                            defaultValue = {this.state.PIC}
                                        >
                                            <option value = {this.state.PIC}>{employees.map(({user_id, name }) => {if(user_id === this.state.PIC){return name}})}</option>
                                            {employees.filter(employee => employee.permissionLevel !== "Disabled")
                                            .map(({ _id, name, employementstatus,user_id }) => 
                                                employementstatus !== "Inactive"? 
                                                (
                                                    <option 
                                                        key = {_id}
                                                        value = {user_id}
                                                    >
                                                        {name}
                                                    </option>
                                                ): null
                                            )}
                                            <option></option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Change Reason</Label>
                                <Input 
                                    type = "textarea"
                                    name = "reason"
                                    id = "descriptionBox"
                                    placeholder = "Describe the Reason for Change"
                                    onChange = {this.onChange}
                                    defaultValue = {this.state.reason}
                                    required
                                />
                            </FormGroup>
                            { this.state.message? <Alert color="danger" style={{textAlign:"center"}}>{ this.state.message }</Alert>: null }
                            { this.state.messageSuccess? <Alert color="success" style={{textAlign:"center"}}>{ this.state.messageSuccess }</Alert>: null }
                            <Button
                                color= "dark"
                                style = {{
                                            marginTop: "2rem",
                                        }}
                                block
                            >
                                Update Project
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        ):null
    }
}

UpdateProject.propTypes = {
    updateProject: PropTypes.func.isRequired,
    getProject: PropTypes.func.isRequired,
    getEmployees: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    clearErrors: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    project: state.projectR, // getting reducer from /reducers/index.js
    error: state.errorR,
    employee: state.employeeR,
})

export default connect(
    mapStateToProps, 
        { updateProject, getProject, clearErrors,getEmployees}
)(UpdateProject);