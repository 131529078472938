import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import {
	Card,
	CardBody,
	CardHeader,
	Container,
	Button,
	Row,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateTask, getTask, cancelTask } from '../../actions/taskActions';
import { getProjects } from '../../actions/projectActions';
import { clearErrors } from '../../actions/errorActions';
import { getEmployees } from '../../actions/employeeActions';
import { getCompany } from '../../actions/companyActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

class UpdateTask extends Component {
	state = {
		_id: '',
		modal: false,
		name: '',
		project_id: '',
		project_name: '',
		reward: '',
		department: '',
		departments: [],
		deadline: '',
		description: '',
		enddate: '',
		startdate: '',
		user_id: '',
		status: '',
		points: '',
		reason: '',
		message: null,
		messageSuccess: null,
		relatedtasks: '',
	};

	componentDidMount() {
		// this.props.getCompany(); moved all the calls into get Task
		this.props.getTask(this.props.match.params.id);
		this.props.getEmployees();
		// this.props.getProjects();moved all the calls into get Task
	}

	componentDidUpdate(prevProps) {
		if (prevProps.task.task !== this.props.task.task) {
			this.setState({
				name: this.props.task.task.name,
				project_id: this.props.task.task.project_id,
				user_id: this.props.task.task.user_id,
				user_name: this.props.employee.employees
					.filter((employee) => employee.user_id === this.props.task.task.user_id)
					.map((employee) => employee.name),
				points: this.props.task.task.points,
				status: this.props.task.task.status,
				reward: this.props.task.task.reward,
				department: this.props.task.task.department,
				departments: this.props.company.company.departments,
				deadline: this.props.task.task.deadline.substring(0, 10),
				startdate: this.props.task.task.startdate
					? this.props.task.task.startdate.substring(0, 10)
					: '',
				enddate: this.props.task.task.enddate ? this.props.task.task.enddate.substring(0, 10) : '',
				description: this.props.task.task.description,
				_id: this.props.task.task._id,
			});
		}

		if (prevProps.project.projects !== this.props.project.projects) {
			if (this.props.task.task) {
				this.setState({
					project_name: this.props.project.projects
						? this.props.project.projects.find(
							(project) => project._id === this.props.task.task.project_id
						).name
						: null,
				});
			}
		}

		if (prevProps.task.message !== this.props.task.message) {
			this.setState({
				messageSuccess: this.props.task.message,
			});
		}

		const { error } = this.props;
		if (error !== prevProps.error) {
			//Check for register error
			if (error.id === 'UPDATE_TASK_FAIL') {
				this.setState({ message: error.message });
			} else {
				this.setState({ message: null });
			}
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onSubmit = (e) => {
		e.preventDefault();
		if (!this.state.reason) {
			return this.setState({ message: 'Reason is Required' });
		}
		this.props.clearErrors();
		var taskHistory = {
			reason: this.state.reason,
			date: new Date(),
			updatedBy: this.props.auth.user.id,
		};

		const arr = [
			'name',
			'project_id',
			'user_id',
			'points',
			'status',
			'reward',
			'department',
			'deadline',
			'startdate',
			'enddate',
			'description',
		];
		arr.forEach((element) => {
			if (element === 'deadline' || element === 'startdate' || element === 'enddate') {
				if (this.props.task.task[element]) {
					if (this.props.task.task[element].substring(0, 10) !== this.state[element]) {
						taskHistory[element] = this.props.task.task[element];
					}
				} else {
					if (this.state[element]) {
						taskHistory[element] = this.props.task.task[element];
					}
				}
			} else {
				if (this.props.task.task[element] !== this.state[element]) {
					taskHistory[element] = this.props.task.task[element];
				}
			}
		});
		const updateTask = {
			name: this.state.name,
			project_id: this.state.project_id,
			reward: this.state.reward,
			points: this.state.points,
			user_id: this.state.user_id,
			department: this.state.department,
			deadline: this.state.deadline.substring(0, 10),
			startdate: this.state.startdate ? this.state.startdate.substring(0, 10) : '',
			enddate: this.state.enddate ? this.state.enddate.substring(0, 10) : '',
			description: this.state.description,
			relatedtasks: this.state.relatedtasks,
			status: this.state.status,
			taskHistory,
		};
		// Add task via updateTask action
		this.props.updateTask(this.state._id, updateTask);
	};
	cancelTask = () => {
		const task = {
			_id: this.state._id,
			status: 'Cancelled',
		};
		this.props.cancelTask(task);
	};
	render() {
		const { employees } = this.props.employee;
		if (this.state.redirect) {
			//return this.props.history.goBack(); 
			return <Redirect to={this.state.redirect} />
		}

		// TODO: set correctly
		const isExecutive = true

		return this.props.task ? (
			<Container>
				<Card
					style={{
						width: '70%',
						margin: 'Auto',
						marginBottom: '2rem',
					}}>
					<CardHeader>
						<strong>Task ID: {this.state._id}</strong>
						<div
							style={{
								display: 'inline-block',
								float: 'right',
								position: 'relative',
							}}>
							<div>
								<FontAwesomeIcon
									style={{ fontSize: '1.5rem', cursor: 'pointer' }}
									icon={faArrowAltCircleLeft}
									onClick={() => {
										this.setState({ redirect: '/tasklist' });
									}}
									onMouseOver={() => {
										this.setState({
											returnTooltip: true,
										});
									}}
									onMouseOut={() => {
										this.setState({
											returnTooltip: false,
										});
									}}
								/>
							</div>
							<span
								style={{
									visibility: this.state.returnTooltip ? 'visible' : 'hidden',
									color: '#323232',
									position: 'absolute',
									left: '0',
									top: '55%',
									fontSize: 20,
								}}>
								&#9650;
							</span>

							<span
								style={{
									visibility: this.state.returnTooltip ? 'visible' : 'hidden',
									padding: '8px',
									fontSize: '12px',
									zIndex: '1',
									borderRadius: '2px',
									color: '#fff',
									lineHeight: '130%',
									position: 'absolute',
									textAlign: 'center',
									overflow: 'hidden',
									width: '10rem',
									left: '0%',
									top: '130%',
									backgroundColor: '#323232',
									whiteSpace: 'pre-wrap',
								}}>
								Return to Task List
							</span>
						</div>
					</CardHeader>
					<CardBody>
						<Form>
							<FormGroup>
								<Label>Project</Label>
								<Input
									type='text'
									name='project_id'
									onChange={this.onChange}
									defaultValue={this.state.project_name}
									disabled={isExecutive}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label>Name</Label>
								<Input
									type='text'
									name='name'
									onChange={this.onChange}
									defaultValue={this.state.name}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label for='TaskDescription'>Description</Label>
								<Input
									type='textarea'
									name='description'
									id='descriptionBox'
									placeholder='Add Task Description'
									onChange={this.onChange}
									defaultValue={this.state.description}
									required
								/>
							</FormGroup>
							<Row xs='2'>
								<Col>
									<FormGroup>
										<Label>PIC</Label>
										<Input type='select' name='user_id' onChange={this.onChange}>
											<option value={this.state.user_id}>{this.state.user_name}</option>
											{employees
												.filter((employee) => employee.permissionLevel !== 'Disabled')
												.map(({ _id, name, employementstatus, user_id }) =>
													employementstatus !== 'Inactive' ? (
														<option key={_id} value={user_id}>
															{name}
														</option>
													) : null
												)}
											<option></option>
										</Input>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='Department'>Department</Label>
										<Input
											type='select'
											name='department'
											id='Department'
											onChange={this.onChange}
											required>
											{this.state.departments.length > 0 ? (
												<option value={this.state.department}>
													{this.state.departments.find((e) => e.id === this.state.department).name}
												</option>
											) : null}
											{this.state.departments.length > 0
												? this.state.departments.map((department) => (
													<option key={department.id} value={department.id}>
														{department.name}
													</option>
												))
												: null}
										</Input>
									</FormGroup>
								</Col>
							</Row>
							<Row xs='2'>
								<Col>
									<FormGroup>
										<Label for='Points'>Points</Label>
										<Input
											type='number'
											name='points'
											id='Points'
											onChange={this.onChange}
											defaultValue={this.state.points}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label>Difficulty</Label>
										<Input type='select' name='reward' onChange={this.onChange} required>
											<option>{this.state.reward}</option>
											<option value={1}>1 (1-2 Day Task)</option>
											<option value={2}>2 (1 week or below)</option>
											<option value={3}>3 (Above 1 week)</option>
										</Input>
									</FormGroup>
								</Col>
							</Row>
							<Row xs='2'>
								<Col>
									<FormGroup>
										<Label for='StartDate'>Start Date</Label>
										<Input
											type='date'
											name='startdate'
											id='StartDate'
											placeholder='Add Task Deadline'
											onChange={this.onChange}
											value={this.state.startdate}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='EndDate'>End Date</Label>
										<Input
											type='date'
											name='enddate'
											id='EndDate'
											placeholder='Add Task Deadline'
											onChange={this.onChange}
											value={this.state.enddate}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row xs='2'>
								<Col>
									<FormGroup>
										<Label for='TaskDeadline'>Task Deadline</Label>
										<Input
											type='date'
											name='deadline'
											id='TaskDeadline'
											placeholder='Add Task Deadline'
											onChange={this.onChange}
											value={this.state.deadline}
											required
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='Status'>Status</Label>
										<Input
											type='select'
											name='status'
											id='Status'
											onChange={this.onChange}
											required>
											<option>{this.state.status}</option>
											<option>In Progress</option>
											<option>Completed</option>
											<option>Hold</option>
											<option>Review</option>
										</Input>
									</FormGroup>
								</Col>
							</Row>
							<FormGroup>
								<Label>Change Reason</Label>
								<Input
									type='textarea'
									name='reason'
									id='descriptionBox'
									placeholder='Describe the Reason for Change'
									onChange={this.onChange}
									defaultValue={this.state.reason}
									required
								/>
							</FormGroup>
							{this.state.message ? (
								<Alert style={{ textAlign: 'center' }} color='danger'>
									{this.state.message}
								</Alert>
							) : null}
							{this.state.messageSuccess ? (
								<Alert style={{ textAlign: 'center' }} color='success'>
									{this.state.messageSuccess}
								</Alert>
							) : null}
							<Row xs='2'>
								<Button
									color='danger'
									style={{
										marginTop: '2rem',
									}}
									onClick={this.cancelTask}
									block>
									Cancel Task
								</Button>
								<Button
									color='dark'
									style={{
										marginTop: '2rem',
									}}
									onClick={this.onSubmit}
									block>
									Update Task
								</Button>
							</Row>
						</Form>
					</CardBody>
				</Card>
			</Container>
		) : null;
	}
}

UpdateTask.propTypes = {
	updateTask: PropTypes.func.isRequired,
	getTask: PropTypes.func.isRequired,
	getProjects: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	error: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	clearErrors: PropTypes.func.isRequired,
	getEmployees: PropTypes.func.isRequired,
	employee: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js
	error: state.errorR,
	employee: state.employeeR,
	company: state.companyR,
});

export default connect(mapStateToProps, {
	updateTask,
	cancelTask,
	getTask,
	clearErrors,
	getProjects,
	getEmployees,
	getCompany,
})(UpdateTask);
