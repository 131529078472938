import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createHashHistory } from 'history';
import PropTypes from 'prop-types';
import Qworky_Banner from '../images/Qworky_Banner.jpg';
import AppNavbar from '../components/AppNavbar';

function requireAuth(TheComponent, module) {
	class Authenticate extends Component {
		render() {
			if (this.props.auth.token === null) {
				return (
					<Fragment>
						<div style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
							<AppNavbar />
						</div>
						<img style={{ width: '100%', marginTop: '-48px' }} top='true' src={Qworky_Banner} />
					</Fragment>
				);
			}
			var { isAuthenticated, user } = this.props.auth;
			const AuthPage = <TheComponent {...this.props} />;
			//Check to see if it is qworky staff
			if (module === 'qworky') {
				if (
					this.props.auth.user &&
					this.props.auth.user.company_id !== '5e6a0a21a15d6f17d2b221fb'
				) {
					return null;
				}
			}
			//Check to see if Company has task features
			if (module === 'task') {
				if (this.props.auth.user && !this.props.auth.user.task) {
					return null;
				}
			}
			// Check to see if Company has proposal features
			if (module === 'proposal') {
				if (this.props.auth.user && !this.props.auth.user.proposal) {
					return null;
				}
			}
			if (this.props.location.state) {
				if (this.props.location.state.requireRefresh) {
					const history = createHashHistory();
					const state = { ...history.location.state };
					state.requireRefresh = false;
					history.replace({ ...history.location, state });
					window.location.reload();
				}
			}

			return (
				<Fragment>
					<div style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
						<AppNavbar />
					</div>
					{
						isAuthenticated && user.permissionLevel !== 'Disabled' ? AuthPage : null
						// <div>
						//     <Container>
						//         <Spinner type="grow" color="primary" />
						//         <Spinner type="grow" color="success" />
						//         <Spinner type="grow" color="danger" />
						//     </Container>
						// </div>
					}
				</Fragment>
			);
		}
	}

	Authenticate.propTypes = {
		auth: PropTypes.object.isRequired,
	};

	const mapStateToProps = (state) => ({
		auth: state.authR,
	});

	return connect(mapStateToProps)(Authenticate);
}

export default requireAuth;
