import { CREATE_INVOICE_NUMBER, GET_INVOICE_NUMBER, GET_INTERNAL_INVOICES, UPDATE_INTERNAL_INVOICE } from './types'
import axios from 'axios'
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import { getStages, updatePayBreakDown } from './stageActions'

export const createInvoiceNumber = (newInvoice, payBreakdown, stepIndex, stage_id) => (dispatch, getState) => {
    // The new invoice is empty {}. the invoice number will be generated dynamically.
    axios.post('/api/invoices/create', newInvoice, tokenConfig(getState))
        .then(res => {
            const body = {
                payBreakdown: payBreakdown
            }
            body.payBreakdown[stepIndex].invoice_id = res.data._id
            // Saving the invoice _id in the payment step of the corresponding stage.
            dispatch(updatePayBreakDown(body, stage_id))
            dispatch({
                type: CREATE_INVOICE_NUMBER,
                payload: res.data
            })
        })

        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
}

export const getInvoiceNumber = stage_id => (dispatch, getState) => {
    axios.get(`/api/invoices/${stage_id}`, tokenConfig(getState))
        .then(res => dispatch({
            type: GET_INVOICE_NUMBER,
            payload: res.data
        }))
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));

}

export const getInvoices = () => (dispatch, getState) => {
    axios.get(`/api/invoices`, tokenConfig(getState))
        .then(res => dispatch({
            type: GET_INTERNAL_INVOICES,
            payload: res.data
        }))
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));

}

export const updateInvoice = requestBody => (dispatch, getState) => {
    axios.patch('/api/invoices', requestBody, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_INTERNAL_INVOICE,
                payload: res.data
            })
        })
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
}