import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateLeave,
	deleteLeave,
	getLeave,
	leavePhotoUpdate,
	updateLeaveStatus,
} from '../../actions/leaveTrackerActions';
import { getEmployees } from '../../actions/employeeActions';
import {
	Card,
	CardBody,
	CardHeader,
	Container,
	Button,
	Row,
	Col,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	Alert,
} from 'reactstrap';

const UpdateLeave = (props) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getEmployees());
		dispatch(getLeave(props.match.params.id));
	}, []);

	const [status, setStatus] = useState('');
	const [type, setType] = useState('');
	const [reason, setReason] = useState('');
	const [changeReason, setChangeReason] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [alertMessage, setAlertMessage] = useState('');
	const leave = useSelector((state) => state.leaveR.leave);
	const reduxEmployees = useSelector((state) => state.employeeR.employees);
	const executive = reduxEmployees.map((employee) => employee.permissionLevel === 'Executive');
	const [photo, setPhoto] = useState('');
	const [file, setFile] = useState('');
	const [message, setMessage] = useState('');
	const [checkPhoto, setCheckPhoto] = useState('');
	const [alertPhoto, setAlertPhoto] = useState('');

	useEffect(() => {
		setStatus(leave.status);
		setType(leave.type);
		setReason(leave.reason);
		setPhoto(leave.photo);
		setCheckPhoto(leave.photo);
	}, [leave]);

	useEffect(() => {
		if (checkPhoto !== photo) {
			setAlertPhoto('Photo Changed');
		}
	}, [photo]);

	const onDeleteClick = (id) => {
		dispatch(deleteLeave(id));
	};
	const updateStatusCancelled = (id) => {
		const requestLeave = {
			status: 'Cancelled',
		};
		dispatch(updateLeaveStatus(id, requestLeave));
	};

	const pictureChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			if (file.size > 2097152 || file.type.substr(0, 5) !== 'image') {
				return setMessage('Requires Image File below 2mb');
			}
			setFile(URL.createObjectURL(file));
			setPhoto(file);
		}
	};

	const updateLeaveAll = (id) => {
		if (!changeReason) {
			setAlertMessage('Change Reason is Required!');
		} else {
			const updatedLeave = {
				status: status,
				type: type,
				reason: reason,
				reasonOfChange: changeReason,
				photo: photo,
			};
			if (alertPhoto) {
				var reader = new FileReader();
				reader.readAsDataURL(photo);
				reader.onloadend = (e) => {
					updatedLeave.photo = e.target.result;
					dispatch(leavePhotoUpdate(id, updatedLeave));
				};
				dispatch(updateLeave(id, updatedLeave));
				setSuccessMessage('Successfully updated!');
				setAlertMessage('');
			} else {
			}
			dispatch(updateLeave(id, updatedLeave));
			setSuccessMessage('Successfully updated!');
			setAlertMessage('');
		}
	};

	return (
		<Container>
			<Card
				style={{
					width: '70%',
					margin: 'Auto',
					marginBottom: '2rem',
				}}>
				<CardHeader>
					<strong>Leave ID: {leave._id}</strong>
					<div
						style={{
							display: 'inline-block',
							float: 'right',
							position: 'relative',
						}}></div>
				</CardHeader>

				<CardBody>
					<FormGroup>
						<Col>
							{alertMessage ? (
								<Alert style={{ textAlign: 'center' }} color='danger'>
									{alertMessage}
								</Alert>
							) : null}
							{successMessage ? (
								<Alert style={{ textAlign: 'center' }} color='success'>
									{successMessage}
								</Alert>
							) : null}
							{message ? (
								<Alert style={{ textAlign: 'center' }} color='warning'>
									{message}
								</Alert>
							) : null}
						</Col>
					</FormGroup>
					<Form>
						<FormGroup>
							<Label>Type</Label>
							<Input
								type='select'
								name='type'
								id='type'
								value={type}
								onChange={(e) => setType(e.target.value)}
								disabled={leave.status === 'Approved'}
								required>
								<option>Annual</option>
								<option>Unpaid</option>
								<option>Advance</option>
								<option>Emergency</option>
								<option>Medical</option>
								<option>Replacement</option>
								<option>Other</option>
							</Input>
						</FormGroup>
						<Row xs='2'>
							<Col>
								<FormGroup>
									<Label>Upload Image</Label>
									<CustomInput
										type='file'
										name='photo'
										accept='image/*'
										htmlFor='photo'
										disabled={leave.status === 'Approved'}
										onChange={(e) => pictureChange(e)}
										id='photo'
									/>
								</FormGroup>
							</Col>
							{executive && (
								<Col>
									<FormGroup>
										<Label for='Status'>Status</Label>
										<Input
											type='select'
											name='status'
											id='Status'
											value={status}
											disabled={leave.status === 'Approved'}
											onChange={({ target }) => setStatus(target.value)}
											required>
											<option>Review</option>
											<option>Rejected</option>
											<option>Approved</option>
											<option>Cancelled</option>
										</Input>
									</FormGroup>
								</Col>
							)}
						</Row>
						<FormGroup>
							<Label>Reason</Label>
							<Input
								type='textarea'
								name='reason'
								rows='4'
								id='reasonBox'
								disabled={leave.status === 'Approved'}
								placeholder='Add a reason'
								onChange={({ target }) => setReason(target.value)}
								value={reason}
								required
							/>
						</FormGroup>

						<FormGroup>
							<Label>Change Reason</Label>
							<Input
								type='textarea'
								name='reason'
								rows='4'
								id='reasonBox'
								disabled={leave.status === 'Approved'}
								placeholder='Describe the Reason for Change'
								onChange={({ target }) => setChangeReason(target.value)}
								defaultValue={changeReason}
								required
							/>
						</FormGroup>
						<Row xs='2'>
							{
								<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button
										color='danger'
										className='remove-btn'
										style={{
											marginTop: '2rem',
										}}
										disabled={leave.status !== 'Review' && !executive}
										onClick={() => updateStatusCancelled(leave._id)}>
										Cancel Leave
									</Button>
								</Col>
							}

							<Col>
								<Button
									color='dark'
									style={{
										marginTop: '2rem',
									}}
									disabled={leave.status !== 'Review' && !executive}
									onClick={(e) => updateLeaveAll(leave._id)}>
									Update Leave
								</Button>
							</Col>
						</Row>
					</Form>
				</CardBody>
			</Card>
		</Container>
	);
};

export default UpdateLeave;
