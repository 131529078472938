import React, { Fragment, useState } from 'react'
import { Button } from 'reactstrap'
import { createInvoices, sendBillingEmail } from '../../actions/paymentActions'
import { useDispatch, useSelector } from 'react-redux'

const CreateInvoices = props => {
    const dispatch = useDispatch()
    const companies = useSelector(state => state.companyR.companies)
    const users = useSelector(state => state.companyR.users)
    const [disabled, setDisabled] = useState(false)

    const generateInvoices = () => {
        const list = companies.filter(company => !company.disabled && company.userFee > 0)
        var paymentDetails
        list.forEach(({companyname, userFee, createdAt, _id, billing}) => {
            setDisabled(true)
            const createdDate = new Date (createdAt)
            const currentDate = new Date()
            const userList = users.filter(element => element.company_id === _id && element.permissionLevel !== 'Disabled')
            if((currentDate-createdDate)/(1000*60*60*24)<31){
                if((currentDate-createdDate)/(1000*60*60*24) > 25 &&!billing){
                    dispatch(sendBillingEmail(userList.find(e => e.billingUser).email,_id))
                }
                return console.log('First month')
            }
            if(!billing) {
                return props.updateUserStatus(_id)
            }
            // get the users billing date for the month
            const tempdate = new Date(currentDate.setDate(createdDate.getDate()))
            // if we haven't reached the billing date, terminate operation
            if(tempdate> new Date()) {return null}
            const enddate = tempdate.toISOString().substr(0, 10)
            const startdate = new Date(tempdate.setMonth(tempdate.getMonth()-1)).toISOString().substr(0, 10)
            paymentDetails = {
                companyname,
                email: userList.find(e => e.billingUser).email,
                userFee,
                activeUsers: userList.length,
                enddate,
                startdate,
                company_id: _id,
            }
            dispatch(createInvoices(paymentDetails))
        });
    }
    
    return (
        <Fragment>
            <Button color='dark' disabled={disabled} onClick={() => generateInvoices()}>Create Invoices</Button>
        </Fragment>
    )
}

export default CreateInvoices