import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Container,
	Card,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
	Button,
	Col,
	Row,
	Alert,
} from 'reactstrap';
import RewardModal from './RewardModal';
import RewardsRequestTable from './RewardsRequestTable';
import { getRewards, deleteReward, reduceAvailbleNumber } from '../../actions/rewardActions';
import { purchaseReward, getEmployee } from '../../actions/employeeActions';
import { v4 as uuidv4 } from 'uuid';

const RewardShop = props => {
	const [showModal, toggleModal] = useState(false);
	const [removeMode, toggleRemoveMode] = useState(false);
	const [employee, setEmployee] = useState('');
	// Data coming from Redux.
	const rewards = useSelector((state) =>
		state.rewardR.rewards.filter(({ isDeleted }) => !isDeleted)
	);
	const reduxEmployee = useSelector((state) => state.employeeR.employee); //Employee from redux.
	const user = useSelector((state) => state.authR.user);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getRewards());
		dispatch(getEmployee());
	}, []);

	useEffect(() => {
		// It gets the employee directly from redux after a reward is purchased.
		if (reduxEmployee[0]) {
			return setEmployee(reduxEmployee[0]);
		}
		return setEmployee(reduxEmployee);
	}, [reduxEmployee]);

	const removeReward = (id) => {
		var confirm = window.confirm('Remove this Reward?');
		if (confirm) {
			dispatch(deleteReward(id));
		}
		return toggleRemoveMode(!removeMode);
	};

	const purchaseRewards = (reward) => {
		if (reward.price < employee.golds) {
			var confirm = window.confirm('Purchase Reward?');
			if (confirm) {
				const purchasedReward = {
					newReward: {
						uniqueID: uuidv4(),
						reward_id: reward._id,
						status: 'Purchased',
						name: reward.name,
						description: reward.description,
						purchasedDate: new Date(),
						photo: reward.photo,
					},
					price: reward.price, // THe price is used to deduct employee gold.
				};
				dispatch(purchaseReward(employee._id, purchasedReward));
				dispatch(reduceAvailbleNumber(reward));
			}
		} else {
			alert('Not Enough Golds');
		}
	};
	return (
		<Container>
			<RewardModal showModal={showModal} toggleModal={toggleModal} />
			<span
				style={{
					position: 'relative',
					bottom: '10px',
					fontSize: '20px',
					backgroundColor: '#bef2f7',
					padding: '10px',
					borderRadius: '10px',
				}}>
				<b>My Golds: {employee.golds}</b>
			</span>
			{user && user.permissionLevel === 'Executive' ? (
				<Fragment>
					<Button color='dark' className='mb-4 ml-4' onClick={() => toggleModal(true)}>
						Add Reward
					</Button>
					<Button
						color={removeMode ? 'primary' : 'danger'}
						className='mb-4 ml-4'
						onClick={() => toggleRemoveMode(!removeMode)}>
						{removeMode ? 'Normal Mode' : 'Delete Mode'}
					</Button>
				</Fragment>
			) : null}
			<Row>
				{rewards.map((reward) => {
					return (
						<Col lg='3' className='mb-4' key={reward._id}>
							<Card
								style={{
									height: '340px',
									paddingBottom: '0px',
								}}>
								<CardImg
									top
									style={{
										height: '170px',
										width: '100%',
									}}
									src={reward.photo}
									alt='Reward Image'
								/>
								{removeMode ? (
									<span
										onClick={() => removeReward(reward._id)}
										style={{
											position: 'absolute',
											top: '0px',
											right: '5px',
											color: 'red',
											cursor: 'pointer',
											fontSize: '1.5rem',
											marginTop: '0px',
										}}>
										&#10008;
									</span>
								) : null}
								<CardBody>
									<CardTitle style={{ marginBottom: '0px' }}>{reward.name}</CardTitle>
									<CardText style={{ marginBottom: '0px' }}>
										<span style={{ fontSize: '15px' }}>{reward.description}</span>
										<p style={{ marginBottom: '0px' }}>
											Available Numbers: {reward.availableNumbers}
										</p>
										<span
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'space-between',
												marginBottom: '-10px',
											}}>
											<p style={{ marginBottom: '0px', paddingBottom: '0px' }}>
												Price: {reward.price} Gold
											</p>
											<p style={{ marginRight: '-10px' }}>
												<b>Purchased</b>
											</p>
										</span>
									</CardText>
									<span style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div>
											{reward.availableNumbers > 0 ? (
												<Button
													size='sm'
													color='primary'
													style={{ height: '35px' }}
													onClick={() => purchaseRewards(reward)}>
													Purchase
												</Button>
											) : (
													<Alert
														style={{ width: '140px', height: '40px', alignItems: 'center' }}
														color='danger'>
														<span style={{ position: 'relative', bottom: '5px', left: '5px' }}>
															Unavailable
													</span>
													</Alert>
												)}
										</div>
										<p style={{ fontSize: '1.5rem' }}>
											{employee.myRewards
												? employee.myRewards.filter(({ reward_id }) => reward_id === reward._id)
													.length
												: 5}
										</p>
									</span>
								</CardBody>
							</Card>
						</Col>
					);
				})}
			</Row>
			{user && user.permissionLevel === 'Executive' ? (
				<RewardsRequestTable rewards={rewards} />
			) : null}
		</Container>
	);
};
export default RewardShop;
