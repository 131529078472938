import React, { Fragment, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import ProgressGraph from './ProgressGraph';
import styled from 'styled-components';
import '../../App.css';
const ProgressChart = (props) => {
	const [dateIndicator, setDateIndicator] = useState('week');
	const [activeButton, setActiveButton] = useState({ week: true, month: false, year: false });

	var date = new Date();
	date.setHours(0, 0, 0, 0);

	switch (dateIndicator) {
		case 'month':
			date.setDate(date.getDate() - 84);
			break;
		case 'year':
			date.setDate(date.getDate() - 336);
			break;
		default:
			date.setDate(date.getDate() - 7);
			break;
	}
	const progressTasks = props.completedTasks
		.filter(({ enddate }) => new Date(enddate) > date)
		.sort((a, b) => {
			if (new Date(a.enddate) < new Date(b.enddate)) {
				return 1;
			}
			if (new Date(a.enddate) > new Date(b.enddate)) {
				return -1;
			}
			return 0;
		});

	function mainToggler(input) {
		setDateIndicator(input);
		if (input === 'week') {
			setActiveButton({ week: true, month: false, year: false });
		} else if (input === 'month') {
			setActiveButton({ week: false, month: true, year: false });
		} else if (input === 'year') {
			setActiveButton({ week: false, month: false, year: true });
		} else {
			input = 'week';
		}
	}
	var week = activeButton.week === true;
	var month = activeButton.month === true;
	var year = activeButton.year === true;
	return (
		<Fragment>
			<Card>
				<CardBody>
					<StyledButton
						className={week ? 'active' : 'inactive'}
						onClick={() => mainToggler('week')}>
						{' '}
						1-Week
					</StyledButton>
					<StyledButton
						className={month ? 'active' : 'inactive'}
						onClick={() => mainToggler('month')}>
						3-Month
					</StyledButton>
					<StyledButton
						className={year ? 'active' : 'inactive'}
						onClick={() => mainToggler('year')}>
						12-Month
					</StyledButton>
					<GraphTitle>Progress Chart</GraphTitle>
					<ProgressGraph
						id={props.id}
						dateIndicator={dateIndicator}
						data={progressTasks}
						date={date}
					/>
				</CardBody>
			</Card>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	task: state.taskR,
	report: state.reportR,
	employee: state.employeeR,
	auth: state.authR,
	company: state.companyR,
	project: state.projectR,
});

// for three buttons week, month, year
const StyledButton = styled.div`
	display: inline-block;
	color: blue;
	margin: 5px;
	margin-top: -7px;
	margin-bottom: 10px;
	padding: 5px;
	border-radius: 3px;
	cursor: pointer;
`;

const GraphTitle = styled.div`
	display: inline-block;
	margin-left: 3rem;
	font-size: 1.5rem;
	padding-top: 16px;
	font-weight: 500;
	line-height: 1.5rem;
	font-family: 'Ubuntu', serif;
`;

export default connect(mapStateToProps, {})(ProgressChart);
