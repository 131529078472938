import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
	Container,
	Table,
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Label,
	Input,
	FormGroup,
	Form,
} from 'reactstrap';
import {
	getProposals,
	toggleProposalModal,
	getProposalInModal,
	deleteProposal,
	approveProposal,
	rejectProposal,
} from '../../actions/proposalActions';
import { deleteStages, getStages, storeStageInRevision } from '../../actions/stageActions';
import { deleteDetails, storeDetailInRevision, getDetails } from '../../actions/detailActions';
import { getClients } from '../../actions/clientAction';
import { getEmployees, getEmployee } from '../../actions/employeeActions';
import { getJobs, createJob } from '../../actions/jobActions';
import { addProject } from '../../actions/projectActions';
import ProposalModal from './proposalModal';
import ProposalDetails from './ProposalDetails';
import ConfirmButton from '../userComponents/ConfirmButton';
import { toggleProjectIndex } from '../../actions/projectActions';
import ProjectDetails from '../projectComponents/ProjectDetails';

export class ProposalList extends Component {
	state = {
		actualFee: '', // It will be used in modal once the proposal is completed.
		proposal: '', // It is used to store the proposal.
		clientName: '', //It will be used when creating a new project after proposal is approved. 
		feeModal: false,
		filteredStatus: ['In Progress', 'Completed', 'Pending Client', 'Pending Review',],
		statusList: ['Canceled']
	};
	componentDidMount() {
		this.props.getProposals();
		this.props.getClients();
		this.props.getEmployee();
		this.props.getEmployees();
		this.props.getDetails();
		this.props.getJobs();
	}

	toggleProposalModal = (proposal_id) => {
		this.props.getProposalInModal(proposal_id);
		this.props.toggleProposalModal('');
	};

	approveProposal = proposal => {
		this.props.approveProposal(proposal); // Approving a proposal;
		if (proposal.status === 'Pending Client') {
			const sortedJobs = this.props.jobs.sort((a, b) => a.jobNumber - b.jobNumber); // Sorting the existing jobs array.
			const jobNumber = sortedJobs[sortedJobs.length - 1].jobNumber + 1; // Assigning the new job number based on the sorted array.
			const newJob = {
				jobNumber: jobNumber,
				proposal_id: proposal._id,
			};
			this.props.createJob(newJob); // Creating new job
			const newProject = {  /// Creating a project from completed proposal.
				name: `${jobNumber} - ${this.state.clientName}`,
				description: proposal.description,
				status: 'In Progress',
				createdBy_id: proposal.createdBy_id,
				proposal_id: proposal._id,
				startdate: new Date(),
			};
			this.props.addProject(newProject); // Creting new Project
		}
	};
	onSubmitFee = (e) => {
		e.preventDefault();
		this.state.proposal.fees = Number(this.state.actualFee);
		this.approveProposal(this.state.proposal);
		this.toggleFeeModal();
	};
	toggleFeeModal = (proposal = '', client) => {
		this.setState({ feeModal: !this.state.feeModal, proposal, clientName: client });
	};
	onChange = ({ target }) => {
		// It stores the actual fee input from user.
		this.setState({ [target.name]: target.value });
	};
	rejectProposal = (proposal) => {
		if (proposal.status === 'Pending Client') {
			// Taking a snapshot of the details and stages
			const details = this.props.details.filter(({ proposal_id }) => proposal_id === proposal._id);
			details.forEach((detail) => {
				this.props.storeDetailInRevision(detail._id, detail);
			});
			const stages = this.props.stages.filter(
				({ proposalNumber }) => proposalNumber === proposal.proposalNumber
			);
			stages.forEach((stage) => {
				this.props.storeStageInRevision(stage, stage._id);
			});
		}
		this.props.rejectProposal(proposal);
	};

	onClickFilterSelection = (value) => {
		if (value === '') return
		this.setState({ filteredStatus: [...this.state.filteredStatus, value] });
		this.setState({ statusList: this.state.statusList.filter(s => s !== value) });
	}

	onRemoveFilterSelection = (value) => {
		this.setState({ filteredStatus: this.state.filteredStatus.filter(s => s !== value) });
		this.setState({ statusList: [...this.state.statusList, value] });
	}
	// deleteProposal = (proposal) => {
	// 	this.props.deleteProposal(proposal._id);
	// 	// Deleting all the proposal stages and details
	// 	this.props.deleteStages(proposal.proposalNumber);
	// 	this.props.deleteDetails(proposal._id);
	// };

	renderActionCol = (proposal, client) => {
		return proposal.status !== 'Completed' && proposal.status !== 'In Progress' && proposal.status !== 'Canceled' ? (
			<Fragment>
				<ConfirmButton
					onClick={
						proposal.status === 'Pending Client' ?
							this.toggleFeeModal.bind(this, proposal, client) :
							this.approveProposal.bind(this, proposal)
					}
					alertTitle={
						proposal.status === 'Pending Client' ?
							'Create Job' : 'Approve Proposal'
					}
					style={{
						width: 80,
						paddingRight: 2, paddingLeft: 2, fontSize: 12
					}}
					color="success"
				>
					{
						proposal.status === 'Pending Client' ? 'Create Job' : 'Approve'
					}
				</ConfirmButton>
				<ConfirmButton
					onClick={this.rejectProposal.bind(this, proposal)}
					alertTitle={
						proposal.status === 'Pending Client'
							? 'Make New Revision?'
							: 'Reject The proposal?'
					}
					style={{
						width: 80, marginTop: 4,
						paddingRight: 2, paddingLeft: 2, fontSize: 12
					}}
					color="danger"
				>
					{proposal.status === 'Pending Client' ? 'New Revision' : 'Reject'}
				</ConfirmButton>
			</Fragment>
		) : (
			'N/A'
		)
	}
	render() {
		const { user } = this.props;
		var propsalList = this.props.proposals;

		propsalList = propsalList.filter(pps => this.state.filteredStatus.includes(pps.status))

		return (
			<Container>
				<ProjectDetails />
				<ProposalModal />
				<ProposalDetails />
				<Button color='dark' onClick={this.props.toggleProposalModal.bind(this, 'addModal')}>
					Add Proposal
				</Button>
				<br />
				<br />
				<FormGroup>
					<Label>Filter Status:</Label>
					{this.state.statusList.length >= 1 && <Input
						type='select'
						value
						onChange={e => this.onClickFilterSelection(e.target.value)}
					>
						<option value=''>Filter Status</option>
						{React.Children.toArray(
							this.state.statusList.map(s => {
								return !this.state.filteredStatus.includes(s) && <option value={s}>{s}</option>
							}))
						}
					</Input>
					}
					<div style={{ padding: '10px 0px', height: '100%', width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
						{React.Children.toArray(
							this.state.filteredStatus.map(s => {
								return <div style={{ width: 'fit-content', padding: '5px 10px', margin: '5px 5px 5px 0px', border: '1px solid grey', borderRadius: '10px' }}>
									{s}
									<span onClick={() => this.onRemoveFilterSelection(s)} style={{ marginLeft: '5px', padding: '1px 6px', borderRadius: '40px', backgroundColor: '#ff000061', color: '#ff5555' }}>x</span></div>
							}))
						}

					</div>

				</FormGroup>
				<br />
				<br />
				<Table>
					<thead>
						<tr>
							<th width='10%'>Proposal Number</th>
							<th width='10%'>Client</th>
							<th width='10%'>PIC</th>
							<th width={user.permissionLevel === 'Executive' ? '38%' : '50%'}>Description</th>
							<th width='10%'>Status</th>
							<th width='10%'>Revision Number</th>
							{user.permissionLevel === 'Executive' ? <th width='12%'>Actions</th> : null}
						</tr>
					</thead>
					<tbody>
						{propsalList
							// sort by recent created proposal
							.sort((a, b) => {
								const aCreation = new Date(a.creation_date)
								const bCreation = new Date(b.creation_date)
								return bCreation - aCreation
							})
							.map((proposal) => {
								const client =
									this.props.clients.length > 0
										? this.props.clients.find(({ _id }) => _id === proposal.client_id).name
										: null;
								console.log('proposal.jobNumbe', proposal.jobNumbe)
								return (
									<tr key={proposal._id}>
										<td>
											<a href='#' onClick={this.toggleProposalModal.bind(this, proposal._id)}>
												{proposal.proposalNumber}
											</a>
										</td>
										<td>{client}</td>
										<td>
											{this.props.employees
												.filter(({ user_id }) => user_id === proposal.PIC_id)
												.map((e) => e.name)}
										</td>
										<td>{proposal.description}</td>
										<td>
											{proposal.status}
											<br />
											<br />
											{proposal.jobNumber && proposal.jobNumber !== '' && <>
												<i style={{
													textTransform: 'uppercase',
													fontSize: '14px',
													opacity: '0.5',
												}}>Job No.:</i>
												<br />

												{proposal.jobNumber}
											</>}
										</td>
										<td>
											<span style={{ marginLeft: '20px' }}>{proposal.revisionNumber}</span>
										</td>
										{user.permissionLevel === 'Executive' ? (
											<td style={{ display: 'flex', flexDirection: 'column' }}>
												{this.renderActionCol(proposal, client)}
											</td>
										) : null}
									</tr>
								);
							})}
					</tbody>
				</Table>
				<Modal isOpen={this.state.feeModal} toggle={this.toggleFeeModal.bind(this)}>
					<ModalHeader toggle={this.toggleFeeModal.bind(this)}>
						{this.state.proposal.proposalNumber}
					</ModalHeader>
					<Form onSubmit={this.onSubmitFee}>
						<ModalBody>
							<FormGroup>
								<Label>Final Fee</Label>
								<Input
									type='number'
									name='actualFee'
									value={this.state.actualFee}
									onChange={this.onChange}
									required
								/>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button type='submit' block color='primary'>
								Submit
							</Button>
						</ModalFooter>
					</Form>
				</Modal>
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	proposals: state.proposalR.proposals,
	clients: state.clientR.clients,
	employees: state.employeeR.employees,
	user: state.authR.user,
	details: state.detailR.details,
	stages: state.stageR.stages,
	jobs: state.jobR.jobs,
});

export default connect(mapStateToProps, {
	toggleProjectIndex,
	getProposals,
	getClients,
	getEmployees,
	getEmployee,
	toggleProposalModal,
	getProposalInModal,
	deleteProposal,
	approveProposal,
	rejectProposal,
	deleteStages,
	deleteDetails,
	storeDetailInRevision,
	getDetails,
	getStages,
	storeStageInRevision,
	getJobs,
	createJob,
	addProject,
})(ProposalList);
