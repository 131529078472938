import React, { Fragment, useEffect, useState } from 'react';
import {
    Container,

} from 'reactstrap';
import { useDispatch } from 'react-redux';
import ExpenseCreateModal from './ExpenseCreateModal';
import ExpenseList from './ExpenseList';

const Expense = (props) => {
    const dispatch = useDispatch();

    // DATA
    const user = props.auth.user;
    // STATE
    const [errorMessage, setErrorMessage] = useState('');

    // FUNCTION

    return (
        <Fragment>
            <Container>
                <ExpenseCreateModal user={user} />

                <h4>ExpenseTracker List</h4>
                <ExpenseList user={user} />
            </Container>
        </Fragment>
    );
};

export default Expense;