import React, { Component, Fragment } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	ListGroup,
	ListGroupItem,
	Form,
	Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import { toggleModuleIndex, toggleTask, toggleProposal } from '../../actions/companyActions';
import ConfirmButton from '../userComponents/ConfirmButton.js';
import PropTypes from 'prop-types';

class ModuleModal extends Component {
	toggleTask = () => {
		var confirm = window.confirm('Toggle Module?');
		if (confirm) {
			this.props.toggleTask(this.props.company.company._id);
		}
	};

	toggleProposal = () => {
		var confirm = window.confirm('Toggle Module?');
		if (confirm) {
			this.props.toggleProposal(this.props.company.company._id);
		}
	};

	render() {
		return this.props.company.company ? (
			<Fragment>
				<Modal
					isOpen={this.props.company.moduleModal}
					toggle={this.props.toggleModuleIndex.bind(this, '')}>
					<ModalHeader toggle={this.props.toggleModuleIndex.bind(this, '')}>
						<strong>{this.props.company.company.companyname} Modules</strong>
					</ModalHeader>
					<ModalBody>
						<ListGroup>
							<ListGroupItem
								style={{
									backgroundColor: this.props.company.company.task ? '#aeff9c' : '#ffa29c',
								}}
								onClick={this.toggleTask}>
								Task Management
							</ListGroupItem>
							<ListGroupItem
								style={{
									backgroundColor: this.props.company.company.proposal ? '#aeff9c' : '#ffa29c',
								}}
								onClick={this.toggleProposal}>
								Proposal Tracker
							</ListGroupItem>
						</ListGroup>
						{this.props.company.company.proposal}
						{this.props.company.company.task}
					</ModalBody>
				</Modal>
			</Fragment>
		) : null;
	}
}

ModuleModal.propTypes = {
	toggleModuleIndex: PropTypes.func.isRequired,
	toggleTask: PropTypes.func.isRequired,
	toggleProposal: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	company: state.companyR, // getting reducer from /reducers/index.js
	auth: state.authR, // getting reducer from /reducers/index.js
});

export default connect(mapStateToProps, { toggleModuleIndex, toggleTask, toggleProposal })(
	ModuleModal
);
