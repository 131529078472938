import React from 'react';

export const months = [
	'Jan',
	'Feb',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const filtercolors = [
	'#e1bee7',
	'#64b5f6',
	'#c5e1a5',
	'#f48fb1',
	'#f06292',
	'#ffee58',
	'#ffab91',
	'#80cbc4',
];

export const typeArr = [
	'Annual',
	'Unpaid',
	'Advance',
	'Emergency',
	'Medical',
	'Replacement',
	'Other',
];

export const daysOfWeek = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
];

export const monthsList = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026];
