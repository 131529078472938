import React, { useState, useEffect } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	FormGroup,
	ModalBody,
	Form,
	Label,
	Input,
	Alert,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const UpdateAnnouncement = (props) => {
	const [modal, setModal] = useState(false);
	const [title, setTitle] = useState('');
	const [status, setStatus] = useState('');
	const [successMsg, setSuccess] = useState('');
	const [description, setDescription] = useState('');
	const [schedule_date, setSchedule_date] = useState('');
	const [schedule_time, setSchedule_time] = useState('');
	const [deadline_date, setDeadline_date] = useState('');
	const [deadline_time, setDeadline_time] = useState('');

	useEffect(() => {
		setSchedule_date(props.schedule_date);
		setSchedule_time(props.schedule_time);
		setDeadline_date(props.deadline_date);
		setDeadline_time(props.deadline_time);
	}, [props.schedule_date, props.schedule_time, props.deadline_date, props.deadline_time]);

	useEffect(() => {
		setTitle(props.title);
		setDescription(props.description);
		setStatus(props.status);
	}, [props.description, props.title, props.status]);

	const toggle = () => setModal(!modal);
	const [visible, setVisible] = useState(true);
	const onDismiss = () => setVisible(false);

	const onSubmit = (e) => {
		e.preventDefault();
		props.onChange({
			description,
			title,
			status,
			schedule_date,
			schedule_time,
			deadline_date,
			deadline_time,
		});
		setSuccess('Updated Successfully');
		setTimeout(() => {
			setModal(!modal);
		}, 4000);
	};

	return (
		<div>
			<Button size='sm' color='none'>
				<FontAwesomeIcon
					color='#585858'
					style={{ fontSize: '14x', float: 'right', marginTop: '0.2rem' }}
					icon={faEdit}
					onClick={toggle}
				/>
			</Button>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Update Announcement</ModalHeader>
				<ModalBody>
					<Form onSubmit={(e) => onSubmit(e)}>
						{successMsg ? (
							<Alert color='success' isOpen={visible} toggle={onDismiss}>
								{successMsg}
							</Alert>
						) : props.message ? (
							<Alert color='danger' isOpen={visible} toggle={onDismiss}>
								{props.message}
							</Alert>
						) : null}

						<FormGroup>
							<Label for='status'>Status</Label>
							<Input
								type='select'
								name='status'
								id='status'
								value={status}
								onChange={(e) => setStatus(e.target.value)}
								required>
								<option>active</option>
								<option>inactive</option>
							</Input>
						</FormGroup>
						<FormGroup>
							<Label for='title'>Title</Label>
							<Input
								type='text'
								name='title'
								id='title'
								placeholder='Add Title'
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								required
							/>
						</FormGroup>
						<div style={{ display: 'flex' }}>
							<div style={{ width: '48%' }}>
								<FormGroup>
									<Label for='schedule_date'>Schedule Date</Label>
									<Input
										type='date'
										name='schedule_date'
										id='schedule_date'
										value={schedule_date && schedule_date.split('T')[0]}
										onChange={(e) => setSchedule_date(e.target.value)}
									/>
								</FormGroup>
							</div>
							<div style={{ width: '48%', marginLeft: '1rem' }}>
								<FormGroup>
									<Label for='schedule_time'>Schedule Time</Label>
									<Input
										type='time'
										name='schedule_time'
										id='schedule_time'
										value={schedule_time}
										onChange={(e) => setSchedule_time(e.target.value)}
									/>
								</FormGroup>
							</div>
						</div>
						<div style={{ display: 'flex' }}>
							<div style={{ width: '48%' }}>
								<FormGroup>
									<Label for='deadline_date'>Deadline Date</Label>
									<Input
										type='date'
										name='deadline_date'
										id='deadline_date'
										value={deadline_date && deadline_date.split('T')[0]}
										onChange={(e) => setDeadline_date(e.target.value)}
									/>
								</FormGroup>
							</div>
							<div style={{ width: '48%', marginLeft: '1rem' }}>
								<FormGroup>
									<Label for='deadline_time'>Deadline Time</Label>
									<Input
										type='time'
										name='deadline_time'
										id='deadline_time'
										value={deadline_time}
										onChange={(e) => setDeadline_time(e.target.value)}
									/>
								</FormGroup>
							</div>
						</div>
						<FormGroup>
							<Label for='description'>Description</Label>
							<Input
								type='textarea'
								name='description'
								id='description'
								rows='5'
								placeholder='Add Description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								required
							/>
						</FormGroup>
						<div style={{ float: 'right' }}>
							<Button type='submit' color='primary'>
								Update Announcement
							</Button>{' '}
							<Button color='secondary' onClick={toggle}>
								Cancel
							</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default UpdateAnnouncement;
