function calenedarCalculator(date) {
	switch (
		date ? date.getMonth() : null // Checks for the month
	) {
		case 0:
		case 7:
			var days = [...Array(32).keys()]; // To get the full Days in a month.
			days.shift(); // to delete th zero at the beginning of array
			days = days.map(String);
			for (var i = 0; i < days.length; i++) {
				days[i] = days[i] + `/${date.getMonth() + 1}/${date.getFullYear()}`;
			}
			days = endOfMonth(date, days.length, days); // The function adjust last week of the month
			return startOfMonth(days, date, 31); // The function adjust first week of the month.
		case 1: // Below conditional checks if the the the year is leap.
			if (isLeapYear(date)) {
				var days = [...Array(30).keys()]; // Days of the month array.
			} else {
				// if Leap year Feb will be 29 else 28 days.
				var days = [...Array(29).keys()]; // Days of the month array.
			}
			days.shift(); // to delete th zero at first of array
			days = days.map(String);
			for (var i = 0; i < days.length; i++) {
				days[i] = days[i] + `/${date.getMonth() + 1}/${date.getFullYear()}`;
			}
			days = endOfMonth(date, days.length, days);
			return startOfMonth(days, date, 31);
		case 2:
			var days = [...Array(32).keys()]; // Days of the month array.
			days.shift(); // to delete th zero at first of array
			days = days.map(String);
			for (var i = 0; i < days.length; i++) {
				days[i] = days[i] + `/${date.getMonth() + 1}/${date.getFullYear()}`;
			}
			days = endOfMonth(date, days.length, days);
			if (isLeapYear) return startOfMonth(days, date, 29);
			return startOfMonth(days, date, 28);
		case 3:
		case 5:
		case 8:
		case 10:
			var days = [...Array(31).keys()]; // Days of the month array.
			days.shift(); // to delete th zero at first of array
			days = days.map(String);
			for (var i = 0; i < days.length; i++) {
				days[i] = days[i] + `/${date.getMonth() + 1}/${date.getFullYear()}`;
			}
			days = endOfMonth(date, days.length, days);
			return startOfMonth(days, date, 31);
		case 4:
		case 6:
		case 9:
		case 11:
			var days = [...Array(32).keys()]; // Days of the month array.
			days.shift(); // to delete th zero at first of array
			days = days.map(String);
			for (var i = 0; i < days.length; i++) {
				days[i] = days[i] + `/${date.getMonth() + 1}/${date.getFullYear()}`;
			}
			days = endOfMonth(date, days.length, days);
			return startOfMonth(days, date, 30);
		default:
			return null;
	}
}
function startOfMonth(days, date, prevMonthDays) {
	var fullDate;
	date.getMonth() === 0
		? (fullDate = `/12/${date.getFullYear() - 1}`)
		: (fullDate = `/${date.getMonth()}/${date.getFullYear()}`);
	var startingDay = date.getDay(); // Gets the index of first week days in the month.
	if (startingDay) {
		// Will execute if the starting day is greater than zero.
		for (startingDay; startingDay > 1; startingDay--) {
			// This loop fill up the first week of the month .
			days.unshift(`${String(prevMonthDays)}${fullDate}`); // with the previous month dates.
			prevMonthDays -= 1;
		}
	} else {
		// Will execute if the startingDay is equal to zero.
		for (var i = 6; i > 0; i--) {
			days.unshift(`${String(prevMonthDays)}${fullDate}`);
			prevMonthDays -= 1;
		}
	}
	return days;
}
function endOfMonth(date, monthLength, days) {
	var fullDate;
	date.getMonth() === 11
		? (fullDate = `/1/${date.getFullYear() + 1}`)
		: (fullDate = `/${date.getMonth() + 2}/${date.getFullYear()}`);
	var endDay = new Date(`${monthLength} ${months[date.getMonth()]} ${date.getFullYear()}`).getDay(); // It takes the index of last day of the month in terms of week days.
	if (endDay) {
		// This loop fill up last week of the month with the next month dates if needed.
		var nextDay = 1;
		for (endDay; endDay < 7; endDay++) {
			days.push(`${String(nextDay)}${fullDate}`);
			nextDay += 1;
		}
	}
	return days;
}
function isLeapYear(date) {
	// It returns a boolean
	if (
		(date.getFullYear() % 4 === 0 && date.getFullYear() % 100 !== 0) ||
		(date.getFullYear() % 4 === 0 &&
			date.getFullYear() % 100 === 0 &&
			date.getFullYear() % 400 === 0)
	) {
		return true;
	}
	return false;
}
var months = [
	'Jan',
	'Feb',
	'Marc',
	'April',
	'May',
	'June',
	'July',
	'August',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
export default calenedarCalculator;


// The calenedarCalculator function expects to get the input in this format 
// console.log(calenedarCalculator(new Date('1 Jan 2025'))) 
