import axios from 'axios';
import { returnErrors } from "./errorActions";
import { tokenConfig } from './authActions'
import {
    GET_STAGES, CREATE_STAGE, DELETE_STAGE, UPDATE_STAGE,
    DELETE_STAGES, STORE_STAGE_IN_REVISION, UPDATE_STAGE_FEE
} from './types'

export const getStages = () => (dispatch, getState) => {
    axios.get
        (`/api/stages`, tokenConfig(getState))
        .then(res => dispatch({
            type: GET_STAGES,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const getSpecificStages = proposalNumber => (dispatch, getState) => {
    axios.get
        (`/api/stages/${proposalNumber}`, tokenConfig(getState))
        .then(res => dispatch({
            type: GET_STAGES,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const createStage = newStage => (dispatch, getState) => {
    axios.post
        (`/api/stages`, newStage, tokenConfig(getState))
        .then(res => dispatch({
            type: CREATE_STAGE,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const createPayBreakDown = (payBreakDown, id) => (dispatch, getState) => {
    axios.post
        (`/api/stages/payBreakdown/create/${id}`, payBreakDown, tokenConfig(getState))
        .then(res => dispatch({
            type: UPDATE_STAGE_FEE,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}

export const updatePayBreakDown = (payBreakDown, id) => (dispatch, getState) => {
    axios.post
        (`/api/stages/payBreakdown/update/${id}`, payBreakDown, tokenConfig(getState))
        .then(res => dispatch({
            type: UPDATE_STAGE_FEE,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}

export const updateStage = (updatedStage, id) => (dispatch, getState) => {
    axios.post
        (`/api/stages/update/${id}`, updatedStage, tokenConfig(getState))
        .then(res => dispatch({
            type: UPDATE_STAGE,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const updateStageFee = (updatedStage, id) => (dispatch, getState) => {
    axios.post
        (`/api/stages/updateFees/${id}`, updatedStage, tokenConfig(getState))
        .then(res => dispatch({
            type: UPDATE_STAGE_FEE,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const storeStageInRevision = (stage, id) => (dispatch, getState) => {
    axios.post
        (`/api/stages/updateStage/${id}`, stage, tokenConfig(getState))
        .then(res => dispatch({
            type: STORE_STAGE_IN_REVISION,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const deleteStage = id => (dispatch, getState) => {
    axios.delete
        (`/api/stages/${id}`, tokenConfig(getState))
        .then(() => dispatch({
            type: DELETE_STAGE,
            payload: id
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const deleteStages = proposalNumber => (dispatch, getState) => {
    axios.delete
        (`/api/stages/delete/${proposalNumber}`, tokenConfig(getState))
        .then(() => dispatch({
            type: DELETE_STAGES,
            payload: proposalNumber
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}