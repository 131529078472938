import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalBody, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { toggleUserIndex, deleteUser, togglePermissionIndex } from '../../actions/companyActions';
import PropTypes from 'prop-types';
import PermissionModal from './PermissionModal';
import ConfirmButton from '../userComponents/ConfirmButton';
class UserModal extends Component {
	state = {
		newUser: '',
	};
	onDeleteClick = (_id, company_id) => {
		this.props.deleteUser(_id, this.props.company.shortname);
		this.props.toggleUserIndex('');
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		return this.props.company.filteredUsers ? (
			<Fragment>
				<PermissionModal />
				<Modal
					isOpen={this.props.company.userModal}
					toggle={this.props.toggleUserIndex.bind(this, '')}
					className='modal-xl'>
					<ModalBody>
						<Table>
							<thead>
								<tr>
									<th className='rowAlign'>ID</th>
									<th className='rowAlign'>Email</th>
									<th className='rowAlign'>Access</th>
									<th className='rowAlign'>Actions</th>
								</tr>
							</thead>
							<tbody>
								{this.props.company.filteredUsers.map(
									({ email, _id, company_id, permissionLevel }) => (
										<tr key={_id}>
											<td className='rowAlign'>{_id}</td>
											<td className='rowAlign'>{email}</td>
											<td className='rowAlign'>
												<a href='#' onClick={this.props.togglePermissionIndex.bind(this, _id)}>
													{permissionLevel}
												</a>
											</td>
											<td className='rowAlign'>
												<ConfirmButton
													className='remove-btn'
													color='danger'
													size='sm'
													alertTitle={'Delete this User?'}
													onClick={() => this.onDeleteClick(_id, company_id)}>
													&times;
												</ConfirmButton>
											</td>
										</tr>
									)
								)}
							</tbody>
						</Table>
					</ModalBody>
				</Modal>
			</Fragment>
		) : null;
	}
}

UserModal.propTypes = {
	toggleUserIndex: PropTypes.func.isRequired,
	deleteUser: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	company: state.companyR, // getting reducer from /reducers/index.js
	auth: state.authR, // getting reducer from /reducers/index.js
});

export default connect(mapStateToProps, { toggleUserIndex, deleteUser, togglePermissionIndex })(
	UserModal
);
