import React, { Component } from 'react';
import { Container, Table, Button, Row, Col, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { getTasks, toggleTaskIndex, requestTask, filterTasks } from '../../actions/taskActions';
import TaskDetails from './TaskDetails';
import PropTypes from 'prop-types';
import { getEmployees } from '../../actions/employeeActions';
import { getProjects } from '../../actions/projectActions';
import { getReports } from '../../actions/reportActions';
import { getCompany } from '../../actions/companyActions';
import ConfirmButton from '../userComponents/ConfirmButton';
import SortComponent from '../SortComponent'
import { getProposals } from '../../actions/proposalActions';

class TaskDashboard extends Component {
	myRef = React.createRef();
	state = {
		Overdue: localStorage.getItem('OverdueTd')
			? JSON.parse(window.localStorage.getItem('OverdueTd'))
			: false, // fiter related
		RangeStart: localStorage.getItem('rangeStartTd') ? localStorage.getItem('rangeStartTd') : '', // fiter related
		RangeEnd: localStorage.getItem('rangeEndTd') ? localStorage.getItem('rangeEndTd') : '', // fiter related
		project: localStorage.getItem('projectTd') ? localStorage.getItem('projectTd') : '', // fiter related
		departments: localStorage.getItem('departmentsTd')
			? JSON.parse(window.localStorage.getItem('departmentsTd'))
			: [], // fiter related
		resetRangeStartTd: false, // reset fiter related
		resetRangeEndTd: false, // reset fiter related
		resetFilterTd: false, // reset fiter related
		filtercolors: [
			'#e1bee7',
			'#64b5f6',
			'#c5e1a5',
			'#f48fb1',
			'#f06292',
			'#ffee58',
			'#ffab91',
			'#80cbc4',
		],
		sortCriteria: localStorage.getItem('sortCriteriaTd')?localStorage.getItem('sortCriteriaRl'):"name",
		sortDirection: localStorage.getItem('sortDirectionTd')?localStorage.getItem('sortDirectionRl'):1,
	};

	componentDidMount() {
		this.props.getCompany();
		this.props.getTasks();
		this.props.getReports();
		this.props.getEmployees();
		this.props.getProjects();
		this.props.getProposals();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.company.company !== this.props.company.company) {
			this.setState({
				departments:
					this.state.departments.length > 0
						? this.state.departments
						: this.props.company.company.departments
								.filter(({ active }) => active)
								.map((e, index) => {
									return {
										name: e.name,
										id: e.id,
										filterState: true,
										color: this.state.filtercolors[index],
										index: index,
									};
								}),
			});
		}
		if (
			prevProps.task.tasks !== this.props.task.tasks ||
			prevState.departments !== this.state.departments ||
			prevState.project !== this.state.project ||
			prevState.Overdue !== this.state.Overdue ||
			prevState.RangeEnd !== this.state.RangeEnd ||
			prevState.RangeStart !== this.state.RangeStart
		) {
			localStorage.setItem('departmentsTd', JSON.stringify(this.state.departments));
			localStorage.setItem('projectTd', this.state.project);
			localStorage.setItem('OverdueTd', this.state.Overdue);
			localStorage.setItem('rangeEndTd', this.state.RangeEnd);
			localStorage.setItem('rangeStartTd', this.state.RangeStart);

			var filters = {};
			filters.department = this.state.departments.filter((e) => e.filterState).map((e) => e.id);
			if (this.state.project) {
				filters.project_id = this.state.project;
			}
			var d = '';
			if (this.state.RangeEnd) {
				d = new Date(this.state.RangeEnd);
				d = new Date(d.setDate(d.getDate() + 1)).toISOString().substr(0, 10);
			}
			if (this.state.Overdue) {
				filters.overdue = this.state.Overdue;
			}
			filters.dateRange = [this.state.RangeStart, d];
			this.props.filterTasks(filters, false);
		}
		// Resetting the task filtering
		if (prevState.resetRangeStartTd !== this.state.resetRangeStartTd) {
			this.setState({ RangeStart: '' });
			localStorage.setItem('rangeStartTd', '');
		}
		if (prevState.resetRangeEndTd !== this.state.resetRangeEndTd) {
			this.setState({ RangeEnd: '' });
			localStorage.setItem('rangeEndTd', '');
		}
		if (prevState.resetFilterTd !== this.state.resetFilterTd) {
			this.setState({
				RangeEnd: '',
				RangeStart: '',
				Overdue: false,
				project: '',
			});
			this.setState({
				departments: this.props.company.company.departments
					.filter(({ active }) => active)
					.map((e, index) => {
						return {
							name: e.name,
							id: e.id,
							filterState: true,
							color: this.state.filtercolors[index],
							index: index,
						};
					}),
			});
			// Removing the filter preferences from the local storage.
			localStorage.setItem('rangeStartTd', '');
			localStorage.setItem('rangeEndTd', '');
			localStorage.setItem('OverdueTd', '');
			localStorage.setItem('departmentsTd', '');
			localStorage.setItem('projectTd', '');
		}
		if(
			prevState.sortCriteria!==this.state.sortCriteria||
			prevState.sortDirection!==this.state.sortDirection
		){
			localStorage.setItem('sortCriteriaTd', this.state.sortCriteria);
			localStorage.setItem('sortDirectionTd', this.state.sortDirection);
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	overdueFilter = () => {
		this.setState({
			Overdue: !this.state.Overdue,
		});
	};
	departmentFilter = (i) => {
		this.setState({
			departments: this.state.departments.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};

	onRequestClick = (id) => {
		var date = new Date(); // It provide a default value for the dates in input boxes
		date = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
			2,
			'0'
		)}-${date.getDate()}`;
		const task = {
			startdate: date,
		};
		this.props.requestTask(id, task);
	};

	sortFunction = (sortCriteria) => {
		this.setState({
			sortCriteria,
			sortDirection: -this.state.sortDirection
		})
	}

	render() {
		const { filteredTasks } = this.props.task;
		const { projects } = this.props.project;

		return (
			<Container>
				<TaskDetails />

				<Row style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline After</Label>
						<Input
							type='date'
							name='RangeStart'
							onChange={this.onChange}
							value={this.state.RangeStart}
						/>
					</Col>
					<div
						style={{
							margin: '2rem 0 0 -0.5rem',
							padding: '.3rem .3rem .2rem .3rem',
							border: '.5px solid #D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ resetRangeStartTd: !this.state.resetRangeStartTd })}>
						X
					</div>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline Before</Label>
						<Input
							type='date'
							name='RangeEnd'
							onChange={this.onChange}
							value={this.state.RangeEnd}
						/>
					</Col>
					<div
						style={{
							margin: '2rem 1rem 0 -0.5rem',
							padding: '.3rem .3rem .2rem .3rem',
							border: '.5px solid 	#D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ resetRangeEndTd: !this.state.resetRangeEndTd })}>
						X
					</div>
					<Col xs='0.5'>
						<p
							style={{
								color: 'red',
								fontSize: '40px',
								marginTop: '17px',
								marginBottom: '-17px',
								padding: '0px',
								opacity: this.state.Overdue ? '1' : '0.3',
								textAlign: 'center',
								cursor: 'pointer',
							}}
							onClick={this.overdueFilter}>
							!!
						</p>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Project</Label>
						<Input type='select' name='project' onChange={this.onChange} value={this.state.project}>
							<option></option>
							{this.props.project.projects.map((project) => (
								<option key={project._id} value={project._id}>
									{project.name}
								</option>
							))}
						</Input>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table>
							<tbody>
								<tr>
									{this.state.departments.map(({ name, filterState, color, index }) => (
										<td
											key={name}
											style={{
												opacity: filterState ? '1' : '0.3',
												backgroundColor: color,
												textAlign: 'center',
												cursor: 'pointer',
											}}
											onClick={this.departmentFilter.bind(this, index)}>
											{name}
										</td>
									))}
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				<a onClick={() => this.setState({ resetFilterTd: !this.state.resetFilterTd })} href='#'>
					Reset Filter?
				</a>
				<Table hover>
					<thead>
						<tr>
							<th>Name<SortComponent onClick={() => this.sortFunction("name")}/></th>
							<th>Project</th>
							<th>Department</th>
							<th>Deadline<SortComponent onClick={() => this.sortFunction("deadline")}/></th>
							<th>Difficulty<SortComponent onClick={() => this.sortFunction("reward")}/></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{filteredTasks.sort((a,b) => {
							if(!b[this.state.sortCriteria]){return -1}
							if(!a[this.state.sortCriteria]){return 1}
							if(a[this.state.sortCriteria]>b[this.state.sortCriteria]){return this.state.sortDirection}
							if(a[this.state.sortCriteria]<b[this.state.sortCriteria]){return -this.state.sortDirection}
							if(a.name>b.name){return 1}
							if(a.name<b.name){return -1}
							return 0
						}).map(
							({ _id, name, department, user_id, deadline, reward, project_id, status, proposal_id }) => {
								if (!user_id && status !== 'Cancelled') {
									const projectName = projects
										.filter((project) => project._id === project_id)
										.map((project) => {
											if(proposal_id){
												return this.props.proposal.proposals.filter(proposal =>proposal._id === proposal_id).map(proposal => proposal.proposalNumber)
											}
											return project.name
										});
									return (
										<tr key={_id}>
											<td>
												<a href='#' onClick={this.props.toggleTaskIndex.bind(this, _id)}>
													{name}
												</a>
											</td>
											<td>{projectName}</td>
											<td>
												{this.props.company.company.departments
													? this.props.company.company.departments.find(
															({ id }) => id === department
													  ).name
													: ''}
											</td>
											<td>{deadline ? deadline.substring(0, 10) : ''}</td>
											<td>{reward}</td>
											<td>
												<ConfirmButton
													className='remove-btn'
													color='success'
													size='sm'
													alertTitle={'Request for this Task?'}
													onClick={() => {
														this.onRequestClick(_id);
													}}>
													Request
												</ConfirmButton>
											</td>
										</tr>
									);
								} else {
									return null;
								}
							}
						)}
					</tbody>
				</Table>
			</Container>
		);
	}
}

TaskDashboard.propTypes = {
	getTasks: PropTypes.func.isRequired,
	requestTask: PropTypes.func.isRequired,
	getProjects: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	getProposals: PropTypes.func.isRequired,
	toggleTaskIndex: PropTypes.func.isRequired,
	task: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR, // getting reducer from /reducers/index.js
	employee: state.employeeR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js
	report: state.reportR,
	company: state.companyR,
	proposal: state.proposalR,
});

export default connect(mapStateToProps, {
	getTasks,
	toggleTaskIndex,
	getEmployees,
	getProjects,
	getReports,
	getCompany,
	requestTask,
	filterTasks,
	getProposals,
})(TaskDashboard);
