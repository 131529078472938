import React, { Fragment, useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from 'react-redux'

//Import Actions
import { updateInvoice } from '../../actions/invoiceActions'

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ProposalTaskOptions from '../executiveComponents/ProposalTaskOptions';

const EditInvoiceModal = (props) => {
    const dispatch = useDispatch()
    const [modal, setModal] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [status, setStatus] = useState('')

    useEffect(() => {
        setInvoiceNumber(props.invoice.invoiceNumber)
        setStatus(props.invoice.status)
    }, [props.invoice])

    const toggle = () => setModal(!modal);
    const UpdateInvoice = e => {
        e.preventDefault()
        const requestBody = {
            _id: props.invoice._id,
            invoiceNumber,
            status
        }
        if(invoiceNumber != props.invoice.invoiceNumber){
            requestBody.oldInvoiceNo = props.invoice.invoiceNumber
        }
        dispatch(updateInvoice(requestBody))
        toggle()
    }

    return (
        <Fragment>
            <Button
                className='remove-btn'
                color='primary'
                size='sm'
                style={{
                    float: 'right',
                }}
                onClick={toggle}>
                <FontAwesomeIcon color='white' style={{ fontSize: '14x' }} icon={faEdit} />
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Update Invoice Details</ModalHeader>
                <Form onSubmit={e => UpdateInvoice(e)}>
                    <ModalBody>
                        <FormGroup>
                            <Label>Invoice Number</Label>
                            <Input
                                type='number'
                                value={invoiceNumber}
                                onChange={e => setInvoiceNumber(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Status</Label>
                            <Input
                                type='select'
                                value={status}
                                onChange={e => setStatus(e.target.value)}
                                required
                            >
                                <option></option>
                                <option>Pending</option>
                                <option>Paid</option>
                                <option>Cancelled</option>
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type='submit' color="primary">Update Invoice</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Cancel</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    );
}

export default EditInvoiceModal;