import React from 'react';
import { Button, Row, Col } from 'reactstrap';

const CreateButtons = ({
	chosenDates,
	setChosenDates,
	leaveRequestMode,
	toggle,
	setLeaveRequestMode,
}) => {
	return (
		<>
			<Row style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1.8rem' }}>
				<Col xs='3'>
					{chosenDates.length ? (
						<Button color='danger' onClick={() => setChosenDates([])}>
							Clear Selected
						</Button>
					) : null}
				</Col>
				{leaveRequestMode && (
					<Col xs='3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button color='dark' style={{ marginBottom: '2rem' }} onClick={toggle}>
							Create Leave
						</Button>
					</Col>
				)}
				{leaveRequestMode ? (
					<Col xs='3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							color='dark'
							style={{ marginBottom: '2rem' }}
							disabled={!leaveRequestMode}
							onClick={() => setLeaveRequestMode(!leaveRequestMode)}>
							Create Mode Off
						</Button>
					</Col>
				) : (
					<Col xs='3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							color='dark'
							style={{ marginBottom: '2rem' }}
							disabled={leaveRequestMode}
							onClick={() => setLeaveRequestMode(!leaveRequestMode)}>
							Create Mode On
						</Button>
					</Col>
				)}
			</Row>
		</>
	);
};

export default CreateButtons;
