import {
	GET_REPORTS,
	ADD_REPORT,
	DELETE_REPORT,
	REPORT_LOADING,
	TOGGLE_REPORT_MODAL,
	GET_REPORT,
	UPDATE_REPORT_FAIL,
	UPDATE_REPORT,
	FILTER_REPORT,
} from '../actions/types';

const initialState = {
	reports: [],
	filteredReports: [],
	report: [], //used for update Task page
	loading: false,
	modal: false,
	message: null,
};

const reportReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_REPORTS:
			return {
				...state,
				reports: action.payload.reverse(),
				loading: false,
			};
		case DELETE_REPORT:
			return {
				...state,
				reports: state.reports.filter((task) => task._id !== action.payload),
			};
		case ADD_REPORT:
			return {
				...state,
				reports: [action.payload, ...state.reports],
			};
		case REPORT_LOADING:
			return {
				...state,
				loading: true,
				message: null,
			};
		case TOGGLE_REPORT_MODAL:
			return {
				...state,
				report: state.reports.filter(({ _id }) => _id === action.payload)[0],
				modal: !state.modal,
			};
		case UPDATE_REPORT:
			return {
				...state,
				message: action.payload, // It takes the respond from database if the update was successful
				loading: false, // message will be shown to user after updating.
			};
		case GET_REPORT:
			return {
				...state,
				report: action.payload,
				loading: false,
			};
		case UPDATE_REPORT_FAIL:
			return {
				...state,
				message: null,
			};
		case FILTER_REPORT:
			// Filter reports based on criteria provided
			var arr = state.reports.filter((report) =>
				Object.keys(action.payload.filters).every((key) => {
					//check if the filter is an array of elements
					if (Array.isArray(action.payload.filters[key])) {
						if (key === 'dateRange') {
							var filter1 = true;
							var filter2 = true;
							if (action.payload.filters.dateRange[0] !== '') {
								filter1 = report.reportdate > action.payload.filters.dateRange[0] ? true : false;
							}
							if (action.payload.filters.dateRange[1] !== '') {
								filter2 = report.reportdate < action.payload.filters.dateRange[1] ? true : false;
							}
							var filter = filter1 && filter2 ? true : false;
							return filter;
						}
						return action.payload.filters[key].some((keyEle) => report[key].includes(keyEle));
					}

					if (key === 'overdue') {
						return report.overdue;
					}
					return report[key] ? report[key].includes(action.payload.filters[key]) : true;
				})
			);

			const sortReportTasks = (a, b) => {
				const indexA = a.department;
				const indexB = b.department;
				if (indexA > indexB) {
					return 1;
				} else if (indexA < indexB) {
					return -1;
				} else {
					return 0;
				}
			};

			if (action.payload.calendarState) {
				if (!action.payload.filters.SortDefault) {
					arr.sort(sortReportTasks);
				}

				//Create an object for each date to hold the values of the daily entries
				var dates = {};
				for (var i = 0; i < action.payload.filters.reportdate.length; i++) {
					dates[action.payload.filters.reportdate[i].substr(0, 10)] = [];
				}

				//Push the entries for the day into respective object element
				for (var j = 0; j < arr.length; j++) {
					dates[arr[j].reportdate.substr(0, 10)].push(arr[j]);
				}
			}

			return {
				...state,
				filteredReports: action.payload.calendarState ? dates : arr,
			};
		default:
			return state;
	}
};

export default reportReducer;
