import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

const SortComponent = props => {
    return (
        <FontAwesomeIcon 
        icon={faSort} 
        style={{cursor:'pointer', marginLeft:"5px"}} 
        onClick={() => props.onClick()}/>
    )
}

export default SortComponent

