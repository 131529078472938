import axios from 'axios';
import {
	GET_REWARDS,
	CREATE_REWARD,
	DELETE_REWARD,
	UPDATE_REWARD,
	REDUCE_REWARD_AVAILABLE_NUMBER,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

export const getRewards = () => (dispatch, getState) => {
	axios
		.get('/api/rewards', tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_REWARDS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const createReward = (newReward) => (dispatch, getState) => {
	newReward.key = 'nnnlwe19219n1b2--u0qjasca';
	// Updlaoding reward photo
	var image = JSON.stringify(newReward);
	axios
		.post(
			'https://script.google.com/macros/s/AKfycbw2smMZXthCF_XAHvF2XzrKKwobh-kC0afqf3wbGYRhHvx8xWDL0j3Fyt5QNvtq2D0-HQ/exec',
			image
		)
		.then((res) => {
			newReward.photo = res.data;
			// Creating the reward
			axios
				.post('/api/rewards/create', newReward, tokenConfig(getState))
				.then((res) =>
					dispatch({
						type: CREATE_REWARD,
						payload: res.data,
					})
				)
				.catch((err) => {
					dispatch(returnErrors(err.response.data, err.response.status));
				});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const reduceAvailbleNumber = (reward) => (dispatch, getState) => {
	axios
		.post('/api/rewards/reduceAvailableNumber', reward, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: REDUCE_REWARD_AVAILABLE_NUMBER,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
export const deleteReward = (id) => (dispatch, getState) => {
	axios
		.delete(`/api/rewards/${id}`, tokenConfig(getState))
		.then(() =>
			dispatch({
				type: DELETE_REWARD,
				payload: id,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};
