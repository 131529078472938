import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getProposal, updateProposal } from '../../actions/proposalActions';
import { getClients } from '../../actions/clientAction';
import { getEmployees } from '../../actions/employeeActions';
import { reduceInputBoxSize } from '../../actions/detailActions';
import UpdateStages from './UpdateStages';
import UpdateDetails from './UpdateDetails';
// This is the parent component for the proposal preview page.
const UpdateProposal = (props) => {
  const [cancelEdit, fireCancelFunction] = useState(false); // To retreive back the original data.
  const [editMode, toggleEditMode] = useState(false);
  const [currentRevisionNum, setCurrentRevisionNum] = useState('');
  const [revisionNumbers, setRevisionNumbers] = useState([]);
  const [fireUpdateFunction, toggleFireUpdateFunction] = useState(false); // Once it is true the update action will be called in stage and detail component.
  const [proposal, setProposal] = useState({}); // The proposal coming from redux will be stored here.
  const [updatedProposal, setUpdatedProposal] = useState(false); // If it is true the updateProposal function will be called.
  const statusArray = [
    'In Progress',
    'Pending Client',
    'Pending Review',
    'Completed',
    'Canceled',
  ]; // For display purpose only.

  const dispatch = useDispatch();

  const reduxProposal = useSelector((state) => state.proposalR.proposal);
  const clients = useSelector((state) => state.clientR.clients);
  const employees = useSelector((state) => state.employeeR.employees);
  const user = useSelector((state) => state.authR.user);

  useEffect(() => {
    dispatch(getClients());
    dispatch(getEmployees());
    dispatch(getProposal(props.match.params.id));
  }, []);

  useEffect(() => {
    // It will be fired if the edits are cancelled.
    dispatch(getProposal(props.match.params.id));
  }, [cancelEdit]);

  useEffect(() => {
    // Here we switch in between the revision history.
    const {
      _id,
      PIC_id,
      client_id,
      description,
      status,
      revisionNumber,
      proposalNumber,
      revision,
      detailsOrder,
    } = reduxProposal;
    if (currentRevisionNum === reduxProposal.revisionNumber) {
      // Taking the proposal data in current revision
      setProposal({
        _id,
        PIC_id,
        client_id,
        description,
        status,
        revisionNumber,
        proposalNumber,
        detailsOrder,
      });
    } else if (revision) {
      // Taking the proposal from previous revisions
      var proposal = revision.find(
        ({ revisionNumber }) => revisionNumber === currentRevisionNum
      );
      const { PIC_id, client_id, description, revisionNumber } = proposal
        ? proposal
        : '';
      setProposal({
        _id,
        PIC_id,
        client_id,
        description,
        revisionNumber,
        proposalNumber,
        status,
        detailsOrder,
      });
    }
  }, [currentRevisionNum, reduxProposal]);

  useEffect(() => {
    setCurrentRevisionNum(reduxProposal.revisionNumber);
    if (reduxProposal) {
      if (reduxProposal.revisionNumber) {
        setRevisionNumbers([
          ...Array(reduxProposal.revisionNumber).keys(),
          reduxProposal.revisionNumber,
        ]);
      } else {
        setRevisionNumbers([0]);
      }
    }
  }, [reduxProposal]);

  const toggleUpdateMode = () => {
    // Toggles the edit button.
    if (editMode) {
      fireCancelFunction((state) => !state);
    }
    toggleEditMode((state) => !state);
  };
  const getUpdatedPropsalInput = ({ target }) => {
    // Will be called to capture the updated details
    setProposal({
      ...proposal,
      [target.name]: target.value,
    });
    setUpdatedProposal(true); //
  };

  const updateAll = () => {
    // It fires the update function to save the new updated informations.
    toggleFireUpdateFunction((current) => !current);
    if (updatedProposal) {
      dispatch(updateProposal(proposal, proposal._id));
    }
    setUpdatedProposal(false);
    toggleEditMode(false);
  };
  const editButton = (
    <Button color="primary" onClick={toggleUpdateMode}>
      {editMode ? 'Normal Mode' : 'Edit Mode'}
    </Button>
  );
  if (!proposal) {
    return null;
  }

  return (
    <Container>
      <Card>
        <CardHeader
          style={{ display: 'flex', justifyContent: 'space-between' }}
          onClick={() => dispatch(reduceInputBoxSize())}
        >
          {proposal.proposalNumber}

          {
            // The edit button is visible to PIC in (in progress) mode.
            (user.id === proposal.PIC_id &&
              proposal.status === 'In Progress' &&
              proposal.revisionNumber === currentRevisionNum) ||
            //   Allow mangers to edit any time
            user.permissionLevel !== 'Employee'
              ? editButton
              : null
          }

          {/* {
		  previous logic
            proposal.revisionNumber === currentRevisionNum // Only in current revision number.
              ? proposal.status === 'In Progress'
                ? user.permissionLevel !== 'Employee' ||
                  user.id === proposal.PIC_id
                  ? editButton // Visible to managers only in Pending Review mode.
                  : proposal.status === 'Pending Review' &&
                    user.permissionLevel !== 'Employee'
                  ? editButton
                  : null
                : null
              : null
          } */}
        </CardHeader>
        {!editMode ? (
          <span style={{ display: 'flex', flexDirection: 'row' }}>
            {revisionNumbers.map((revision) => (
              <Button
                key={revision}
                style={{
                  backgroundColor:
                    revision === currentRevisionNum ? '#808080' : '#B0B0B0',
                  marginLeft: '10px',
                  marginTop: '10px',
                  borderRadius: '10px',
                }}
                onClick={() => {
                  setCurrentRevisionNum(revision);
                }}
              >
                Revision {revision}
              </Button>
            ))}
          </span>
        ) : null}
        <CardBody>
          <div onClick={() => dispatch(reduceInputBoxSize())}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Proposal Number</Label>
                  <Input
                    type="text"
                    name="proposalNumber"
                    value={proposal.proposalNumber}
                    onChange={getUpdatedPropsalInput}
                    disabled={!editMode && user.permissionLevel !== 'Employee'}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Client</Label>
                  <Input
                    type="select"
                    name="client_id"
                    value={proposal.client_id}
                    onChange={getUpdatedPropsalInput}
                    disabled={!editMode}
                  >
                    {clients.map((e) => (
                      <option key={e._id} value={e._id}>
                        {e.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>PIC</Label>
                  <Input
                    type="select"
                    name="PIC_id"
                    value={proposal.PIC_id}
                    onChange={getUpdatedPropsalInput}
                    disabled={!editMode}
                  >
                    {employees.map((e) => (
                      <option key={e._id} value={e.user_id}>
                        {e.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    value={proposal.description}
                    onChange={getUpdatedPropsalInput}
                    disabled={!editMode}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Status</Label>
                  <Input
                    type="select"
                    name="status"
                    value={proposal.status}
                    onChange={getUpdatedPropsalInput}
                    disabled={!editMode}
                  >
                    {statusArray.map((e, i) => {
                      return <option key={i}>{e}</option>;
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <UpdateStages
              currentRevisionNum={currentRevisionNum}
              revisionNumbers={revisionNumbers}
              editMode={editMode}
              fireUpdateFunction={fireUpdateFunction}
              proposal={proposal}
              cancelEdit={cancelEdit}
            />
            <br />
          </div>
          <UpdateDetails
            currentRevisionNum={currentRevisionNum}
            revisionNumbers={revisionNumbers}
            proposal={proposal}
            proposal_id={props.match.params.id}
            editMode={editMode}
            fireUpdateFunction={fireUpdateFunction}
            cancelEdit={cancelEdit}
          />
        </CardBody>
        <CardFooter>
          {editMode ? (
            <div>
              <Button color="primary" onClick={() => updateAll()} block>
                Update Changes
              </Button>
              <br />
            </div>
          ) : null}
        </CardFooter>
      </Card>
    </Container>
  );
};
export default UpdateProposal;
