import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Alert } from 'reactstrap';

const ApprovalModal = props => {
    return (
        <Modal isOpen={props.approvalModal} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>{props.modalStatus === "In Progress"?"Task Rejection":"Task Approval"}</ModalHeader>
            <ModalBody>
                {props.message ? (
					<Alert style={{ textAlign: 'center' }} color='danger'>
						{props.message}
					</Alert>
				) : null}
                <Form>
                    <FormGroup>
                        {props.modalStatus === "In Progress"?
                        <Label>Comment</Label>:
                        <Label>Points</Label>}
                        {props.modalStatus === "In Progress"?
                        <Input type='textarea' name='comment' onChange={props.onChange} required />:
                        <Input type='number' name='points' placeholder={props.modal_points} onChange={props.onChange} required />}
                    </FormGroup>
                    <div>
                        <Button 
                        color='danger'
                        onClick={() => props.toggle()}
                        style={{ marginTop: '2rem', width: "49.5%", display: 'inline-block', marginRight:"1%" }} 
                        block>
                            Cancel
                        </Button>
                        <Button 
                        color='primary' 
                        onClick={() => props.ApproveRequest(props.modalStatus, props.modal_id, props.modal_user_id)}
                        style={{ marginTop: '2rem', width: "49.5%", display: 'inline-block', }} 
                        block>
                            {props.modalStatus === "In Progress"?"Reject Task":"Approve Task"}
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default ApprovalModal