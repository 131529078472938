import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteLeave,
  getLeaves,
  filterLeaves,
  updateLeave,
  toggleLeaveModal,
} from '../../actions/leaveTrackerActions';
import { Table, Input, Row, Col, Container, Alert, Button } from 'reactstrap';
import {
  getEmployees,
  updateLeaveHistory,
} from '../../actions/employeeActions';
import ConfirmButton from '../userComponents/ConfirmButton';
import LeaveDetails from './LeaveDetails';
import CommentModal from './CommentModal';
import axios from 'axios';
import { renderYearList, remainingLeaveCal } from './HelperFunctions';
import { sendEmailWithScript } from '../BackUpEmailFunction';

const LeaveTrackerList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLeaves());
    dispatch(getEmployees());
  }, []);

  // Date format and time selection
  const reduxEmployees = useSelector((state) => state.employeeR.employees);
  const filteredLeaves = useSelector((state) => state.leaveR.filteredLeaves);
  const leave = useSelector((state) => state.leaveR.leave);
  const reduxLeaves = useSelector((state) => state.leaveR.leaves);
  var statusArr = ['Review', 'Approved', 'Rejected', 'Cancelled'];
  const [statusR, setStatusR] = useState([]);
  const auth = useSelector((state) => state.authR.user);

  var date = new Date();
  const [year, setYear] = useState(date.getFullYear());
  const [auth_user_id, setAuthUser_Id] = useState('');
  const [modal, setModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [pic, setPic] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [emailData, setEmailData] = useState({});
  const [approveData, setApproveData] = useState({});
  const [comment, setComment] = useState('');
  const [leaveStatus, setLeaveStatus] = useState('');
  const [message, setMessage] = useState('');
  const executive = reduxEmployees.map(
    (employee) => employee.permissionLevel === 'Executive'
  );

  const execEmailList =
    reduxEmployees &&
    reduxEmployees
      .filter((e) => e.permissionLevel === 'Executive')
      .map((each) => each.email);

  const onDeleteClick = (id) => {
    dispatch(deleteLeave(id));
  };

  // Effect for setting pic user for first render
  useEffect(() => {
    setAuthUser_Id(auth.id);
  }, [auth]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setCommentModal(!commentModal);
      }, 4000);
    }
  }, [message]);

  useEffect(() => {
    // mapping key and value for filter leave in redux
    const filters = { user_id: pic, year };
    // setting filterState to be leaves status
    filters.status =
      statusR.length &&
      statusR.filter((e) => e.filterState).map((each) => each.name);

    dispatch(filterLeaves(filters, false));
  }, [statusR, reduxLeaves, pic, year]);

  useEffect(() => {
    setStatusR(
      statusArr.map((e, index) => {
        return {
          name: e,
          filterState: true,
          color: '#b3b3b3',
          index: index,
        };
      })
    );
  }, []);

  const statusFilter = (i) => {
    setStatusR(
      statusR.map((e, j) => {
        if (i === j) {
          e.filterState = !e.filterState;
          return e;
        } else {
          return e;
        }
      })
    );
  };

  const getLeaveEmailObject = (
    reason,
    type,
    daysInNumber,
    selectedDaysArray,
    user_id
  ) => {
    const user = reduxEmployees.find((user) => user.user_id === user_id);
    const exist = execEmailList.includes('user.email');
    let allEmailList;

    if (!exist) {
      allEmailList = [...execEmailList, user.email];
    } else {
      allEmailList = execEmailList;
    }

    const data = {
      reason,
      type,
      chosenDates: selectedDaysArray,
      selectedDates: daysInNumber,
      emails: allEmailList,
      userName: user.name,
    };

    setEmailData(data);
    return data;
  };

  const getApproveLeaveObject = (
    leaveId,
    selectedDates,
    remainingLeaves,
    type,
    leave_user_id,
    employeeObj
  ) => {
    const data = {
      leaveId,
      selectedDates,
      remainingLeaves,
      type,
      leave_user_id,
      employeeObj,
    };
    setApproveData(data);
    return data;
  };

  const updateStatusReject = async () => {
    const dataEmail = { ...emailData, status: 'rejected', comment: comment };
    const { leaveId } = approveData; // leave Id

    const request = {
      status: 'Rejected',
      leave_comment: comment,
    };

    const sendEmailResponse = await axios.post(
      '/api/leaves/approval-status-email',
      dataEmail
    );

    if (!sendEmailResponse.data.accepted) {
      setMessage('Error: Email could not be sent!');
    } else {
      setMessage('Email has sent successfully!');
    }
    dispatch(updateLeave(leaveId, request));
  };

  const updateStatusApprove = async () => {
    const {
      leaveId,
      selectedDates,
      remainingLeaves,
      type,
      leave_user_id,
      employeeObj,
    } = approveData;
    const leaveHistory = employeeObj.leaveHistory;
    const dataEmail = { ...emailData, status: 'approved', comment: comment };

    const request = {
      status: 'Approved',
      leave_comment: comment,
    };

    // remainingLeaves = annualLeaves - takenLeaves + selectedDates
    if (
      selectedDates <= remainingLeaves &&
      (type === 'Annual' || type === 'Emergency')
    ) {
      const sendEmailResponse = await axios.post(
        '/api/leaves/approval-status-email',
        dataEmail
      );

      if (!sendEmailResponse.data.accepted) {
        setMessage('Error: Email could not be sent!');
      } else {
        setMessage('Email has sent successfully!');
      }
      // update to employeeObj once approve
      const taken = employeeObj.takenLeaves + selectedDates;
      const currentYear = new Date().getFullYear();

      const requestObj = {
        _id: employeeObj._id,
        status: 'Approved',
        leave_comment: comment,
        takenLeaves: taken,
      };

      dispatch(updateLeaveHistory(requestObj));
      dispatch(updateLeave(leaveId, request));
    } else if (type !== 'Annual' && type !== 'Emergency') {
      const sendEmailResponse = await axios.post(
        '/api/leaves/approval-status-email',
        dataEmail
      );
      if (!sendEmailResponse.data.accepted) {
        setMessage('Error: Email could not be sent!');
      } else {
        setMessage('Email has sent successfully!');
      }

      dispatch(updateLeave(leaveId, request));
    } else {
      setAlertMessage('Not enough annual leave days left.');
    }
  };

  const getRemainingLeaves = (user_id) => {
    const currentUser = reduxEmployees.find((e) => e.user_id === user_id);
    const { annualLeaves, takenLeaves } = currentUser;
    const remainingLeaves = remainingLeaveCal(annualLeaves, takenLeaves);
    return remainingLeaves;
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleCommentModal = (type) => {
    setLeaveStatus(type);
    setCommentModal(!commentModal);
    setMessage('');
    setComment('');
    setAlertMessage('');
  };

  return (
    <Fragment>
      <Container>
        <LeaveDetails
          employees={reduxEmployees}
          leave={leave}
          closeModal={toggleModal}
          openModal={modal}
          updateStatusApprove={updateStatusApprove}
          updateStatusReject={updateStatusReject}
          toggleCommentModal={toggleCommentModal}
          alertMessage={alertMessage}
          executive={executive}
        />
        <CommentModal
          comment={comment}
          setComment={setComment}
          toggle={toggleCommentModal}
          modal={commentModal}
          updateStatusApprove={updateStatusApprove}
          updateStatusReject={updateStatusReject}
          leaveStatus={leaveStatus}
          alertMessage={alertMessage}
          message={message}
        />
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  {statusR.length
                    ? statusR.map(({ name, filterState, color, index }) => (
                        <th
                          key={index}
                          className="statusR"
                          style={{
                            opacity: filterState ? '1' : '0.3',
                            backgroundColor: color,
                          }}
                          onClick={() => statusFilter(index)}
                        >
                          {name}
                        </th>
                      ))
                    : null}
                </tr>
              </thead>
            </Table>
          </Col>
        </Row>
        <Row style={{ marginBottom: '1rem' }}>
          <Col xs="2">
            <Input
              type="select"
              name="year"
              value={year}
              onChange={(e) => setYear(Number(e.target.value))}
            >
              {renderYearList()}
            </Input>
          </Col>
          <Col xs={2}>
            {executive ? (
              <Fragment>
                <Input
                  type="select"
                  value={pic}
                  onChange={({ target }) => setPic(target.value)}
                >
                  <option value="">Select PIC</option>
                  {reduxEmployees.map(({ name, user_id }) => (
                    <option key={name} value={user_id}>
                      {name}
                    </option>
                  ))}
                </Input>
              </Fragment>
            ) : null}
          </Col>
        </Row>
        <Table hover>
          <thead>
            <tr>
              <th style={{ width: '12.5%' }} className="rowAlign">
                Name
              </th>
              <th style={{ width: '12.5%' }} className="rowAlign">
                Type
              </th>
              <th style={{ width: '12.5%' }}>Reason</th>
              <th style={{ width: '12.5%' }} className="rowAlign">
                Leave Allocation
              </th>
              <th style={{ width: '12.5%' }} className="rowAlign">
                Leave Remaining
              </th>
              <th style={{ width: '12.5%' }} className="rowAlign">
                Selected Days
              </th>
              <th style={{ width: '12.5%' }} className="rowAlign">
                Status
              </th>
              <th style={{ width: '8%' }} className="rowAlign">
                Image
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredLeaves.map(
              ({
                _id,
                user_id,
                reason,
                photo,
                status,
                type,
                numberOfDays,
                selectedDays,
              }) => {
                const userCheck = reduxEmployees.map(
                  (employee) => employee.user_id === user_id
                );
                const remainingLeaves = getRemainingLeaves(user_id);
                const employeeObj = reduxEmployees.find(
                  (e) => e.user_id === user_id
                );

                var annualLeaves = reduxEmployees
                  .filter((e) => e.user_id === user_id)
                  .map((e) => e.annualLeaves);
                var name = reduxEmployees
                  .filter((employee) => employee.user_id === user_id)
                  .map((employee) => (
                    <a key={employee._id} href={'/user/' + employee.user_id}>
                      {employee.name}
                    </a>
                  ));
                if (userCheck || executive) {
                  return (
                    <tr key={_id}>
                      <td className="rowAlign">{name}</td>
                      <td className="rowAlign">
                        <a
                          href="#"
                          onClick={() => {
                            toggleModal();
                            dispatch(toggleLeaveModal(_id));
                            getLeaveEmailObject(
                              reason,
                              type,
                              numberOfDays,
                              selectedDays,
                              user_id
                            );
                            getApproveLeaveObject(
                              _id,
                              numberOfDays,
                              remainingLeaves,
                              type,
                              user_id,
                              employeeObj
                            );
                          }}
                        >
                          {type}
                        </a>
                      </td>

                      <td style={{ whiteSpace: 'pre-wrap', padding: '1rem' }}>
                        {reason}
                      </td>
                      <td className="rowAlign">{annualLeaves}</td>
                      <td className="rowAlign">{remainingLeaves}</td>
                      <td className="rowAlign">{numberOfDays}</td>
                      <td className="rowAlign">{status}</td>
                      <td style={{ width: '5%' }} className="rowAlign">
                        <a
                          href={photo}
                          target="_blank"
                          style={{ fontSize: '0.9rem' }}
                        >
                          {photo}
                        </a>
                      </td>
                      <td></td>
                      <td className="rowAlign">
                        {/* <ConfirmButton
												className='remove-btn'
												color='danger'
												size='sm'
												alertTitle={'Remove this leave?'}
												onClick={() => onDeleteClick(_id)}>
												&times;
											</ConfirmButton> */}
                      </td>

                      {/* {to show only after requested} */}
                      {status === 'Review' && executive ? (
                        <Fragment>
                          <td style={{ display: 'flex', paddingTop: '12px' }}>
                            <Button
                              onClick={() => {
                                getLeaveEmailObject(
                                  reason,
                                  type,
                                  numberOfDays,
                                  selectedDays,
                                  user_id
                                );
                                getApproveLeaveObject(_id);
                                toggleCommentModal('cancel');
                              }}
                              color="danger"
                              size="sm"
                            >
                              Reject
                            </Button>
                          </td>
                          <td>
                            <Button
                              onClick={() => {
                                getLeaveEmailObject(
                                  reason,
                                  type,
                                  numberOfDays,
                                  selectedDays,
                                  user_id
                                );
                                toggleCommentModal('approve');
                                getApproveLeaveObject(
                                  _id,
                                  numberOfDays,
                                  remainingLeaves,
                                  type,
                                  user_id,
                                  employeeObj
                                );
                              }}
                              color="success"
                              size="sm"
                            >
                              Approve
                            </Button>
                          </td>
                        </Fragment>
                      ) : null}
                    </tr>
                  );
                } else {
                  return null;
                }
              }
            )}
          </tbody>
        </Table>
      </Container>
    </Fragment>
  );
};

export default LeaveTrackerList;
