import React, { Component, Fragment } from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Container,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Card,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RegisterModal from './auth/RegisterModal';
import Logout from './auth/Logout';
import LoginModal from './auth/LoginModal';
import { getTasks, toggleTaskIndex } from '../actions/taskActions';
import {
	removeSeenNotifications,
	showNotifications,
	hideNotifications,
} from '../actions/userActions';
import { getEmployees } from '../actions/employeeActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import TaskDetails from './taskComponents/TaskDetails';
import Qworky_Logo from '../images/Qworky_Logo.png';

class AppNavbar extends Component {
	state = {
		isOpen: false,
		hideNotification: this.props.auth.hideNotifications,
		dropdownOpen: false,
	};

	componentWillMount() {
		this.props.getTasks();
		this.props.getEmployees();
	}
	static propTypes = {
		auth: PropTypes.object.isRequired,
	};
	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	closeDropdown = () => {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		});
	};

	showNotification = () => {
		//  This function control the visibility of notification div
		if (this.props.auth.hideNotifications) {
			// Notification is Hidden
			this.props.showNotifications(); // So we display by calling the actions
			if (this.props.notifications.length !== this.filterNotification.length) {
				const notifications = this.filterNotification();
				notifications.forEach(function (item) {
					// Here we change all the notifications to seen
					item.isSeen = true;
				});
				const body = {};
				body.notifications = notifications;
				this.props.removeSeenNotifications(body, this.props.auth.user.id);
			}
		} else {
			// Notifications is Visible
			this.props.hideNotifications(); // so we hide it by calling the actions.
		}
	};
	filterNotification = () => {
		//This function will not be needed if the notification is deleted along side the comment deletion.
		// This function exclude notifications related to deleted tasks and comments.
		var filteredNotifications = [];
		this.props.notifications.forEach((e, i) => {
			const task = this.props.task.tasks.filter((task) => task._id === e.task_id);
			if (task.length) {
				// if there is any task
				var comments = task
					.map((e) => e.comments)
					.flat()
					.filter((x) => !x.isDeleted);
				if (comments.filter((i) => i.id === e.id).length) {
					filteredNotifications.push(this.props.notifications[i]);
				}
			}
		});
		return filteredNotifications; // THe output is an array of notification objects.[{},{}]
	};
	render() {
		const { isAuthenticated, user, isDevEnv } = this.props.auth;
		const tasks =
			user && this.props.task.tasks
				? this.props.task.tasks.filter((e) => e.user_id === user.id)
				: null;
		const { employees } = this.props.employee;
		const seenNotif = { backgroundColor: 'white' };
		const unseenNotif = { backgroundColor: '#C0C0C0' };

		const devUser = employees.filter((user) => user.email === 'thespecialways@gmail.com');

		const authLinks = (
			<Fragment>
				<TaskDetails />
				{devUser.length ? (
					<NavItem>
						<NavLink href='/stafflist'>Development </NavLink>
					</NavItem>
				) : null}

				{user && (user.permissionLevel === 'Admin' || user.permissionLevel === 'Executive') ? (
					<NavItem>
						<NavLink href='/stafflist'>Admin</NavLink>
					</NavItem>
				) : null}
				{user && user.proposal ? (
					<UncontrolledDropdown
						innavbar='true'
						style={{
							borderTop: '2px solid rgba(255,255,255,.5)',
							borderBottom: '2px solid rgba(255,255,255,.5)',
							borderLeft: '2px solid rgba(255,255,255,.5)',
						}}>
						<span
							style={{
								position: 'absolute',
								top: '-7.5px',
								left: '5px',
								color: 'rgba(255,255,255,.5)',
								fontSize: '10px',
								backgroundColor: '#343a40',
								paddingLeft: '2px',
								paddingRight: '2px',
							}}>
							Proposal
						</span>
						<DropdownToggle nav caret>
							Proposal
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem href='/proposalList'>Proposal List</DropdownItem>
							{user &&
								(user.permissionLevel === 'Admin' || user.permissionLevel === 'Executive') ? (
								<div>
									<DropdownItem href='/proposalRates'>Rates</DropdownItem>
									<DropdownItem href='/invoices'>Invoices</DropdownItem>
								</div>
							) : null}
						</DropdownMenu>
					</UncontrolledDropdown>
				) : null}
				{user && user.proposal ? (
					<UncontrolledDropdown
						innavbar='true'
						style={{
							borderTop: '2px solid rgba(255,255,255,.5)',
							borderBottom: '2px solid rgba(255,255,255,.5)',
							borderRight: '2px solid rgba(255,255,255,.5)',
						}}>
						<DropdownToggle nav caret>
							Third Parties
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem href='/clientsList'>Clients</DropdownItem>
							<DropdownItem href='/vendorsList'>Vendors</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				) : null}
				{user && user.task ? (
					<UncontrolledDropdown
						innavbar='true'
						style={{
							borderTop: '2px solid rgba(255,255,255,.5)',
							borderBottom: '2px solid rgba(255,255,255,.5)',
							borderLeft: '2px solid rgba(255,255,255,.5)',
						}}>
						<span
							style={{
								position: 'absolute',
								top: '-7.5px',
								left: '5px',
								color: 'rgba(255,255,255,.5)',
								fontSize: '10px',
								backgroundColor: '#343a40',
								paddingLeft: '2px',
								paddingRight: '2px',
							}}>
							Project
						</span>
						<DropdownToggle nav caret>
							Projects
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem href='/projectlist'>Projects</DropdownItem>
							{user &&
								(user.permissionLevel === 'Manager' || user.permissionLevel === 'Executive') ? (
								<DropdownItem href='/projectCalendar'>Projects Calendar</DropdownItem>
							) : null}
						</DropdownMenu>
					</UncontrolledDropdown>
				) : null}
				{user && user.task ? (
					<UncontrolledDropdown
						innavbar='true'
						style={{
							borderTop: '2px solid rgba(255,255,255,.5)',
							borderBottom: '2px solid rgba(255,255,255,.5)',
						}}>
						<DropdownToggle nav caret>
							Task
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem href='/tasklist'>List</DropdownItem>
							<DropdownItem href='/taskdashboard'>Dashboard</DropdownItem>
							{user &&
								(user.permissionLevel === 'Manager' || user.permissionLevel === 'Executive') ? (
								<DropdownItem href='/taskapproval'>Approval</DropdownItem>
							) : null}
						</DropdownMenu>
					</UncontrolledDropdown>
				) : null}
				<NavItem>
					<NavLink
						innavbar='true'
						style={{
							borderTop: '2px solid rgba(255,255,255,.5)',
							borderBottom: '2px solid rgba(255,255,255,.5)',
						}}
						href='/expenselist'>
						Expense
					</NavLink>
				</NavItem>
				{user && user.task ? (
					<UncontrolledDropdown
						innavbar='true'
						style={{
							borderTop: '2px solid rgba(255,255,255,.5)',
							borderBottom: '2px solid rgba(255,255,255,.5)',
							borderRight: '2px solid rgba(255,255,255,.5)',
						}}>
						<DropdownToggle nav caret>
							Reports
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem href='/reportlist'>List</DropdownItem>
							<DropdownItem href='/reportreview'>Calendar</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				) : null}
				<NavItem>
					<Dropdown isOpen={this.state.dropdownOpen} toggle={this.closeDropdown}>
						<DropdownToggle nav caret>
							Leave
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem href='/leaveCalendar'>Leave Calendar</DropdownItem>
							<DropdownItem href='/leaves'>Leave List</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</NavItem>
				<NavItem>
					<NavLink href='/feedback'>Feedback/Bugs</NavLink>
				</NavItem>
				<NavItem style={{ marginRight: 10 }}>
					<Logout />
				</NavItem>
				<NavItem
					style={{
						position: 'relative',
						right: 0,
					}}>
					<a href='#' onClick={this.showNotification}>
						<FontAwesomeIcon color='white' style={{ fontSize: '2rem' }} icon={faBell} />
						<span className='badge badge-primary badge-pill'>
							{this.filterNotification().filter((e) => !e.isSeen).length
								? this.filterNotification().filter((e) => !e.isSeen).length
								: null}
						</span>
					</a>
					{/*                 The Visibilty of notifications are controlled with redux state.                                                       */}
					<Card className='notificationSection' hidden={this.props.auth.hideNotifications}>
						<span
							style={{
								color: 'white',
								position: 'absolute',
								right: -3,
								top: '-20px',
								fontSize: 20,
							}}>
							&#9650;
						</span>
						<ul className='list-group'>
							{this.filterNotification()
								.slice(0, 5)
								.map((e, index) => {
									// It displays 5 notifications only
									const taskName = tasks
										.filter((task) => task._id === e.task_id)
										.map((task) => task.name); // To get the name of task.
									const employeeName = employees
										.filter((employee) => employee.user_id === e.commentedBy_id)
										.map((employee) => employee.name); // To get the employee name.
									return (
										<li
											key={index}
											style={e.isSeen ? seenNotif : unseenNotif}
											className='list-group-item'>
											<a href='#' onClick={this.props.toggleTaskIndex.bind(this, e.task_id)}>
												{employeeName} commented on <b>{taskName}</b>
												<br />
												<span fontSize={4}>{e.date.substring(0, 10)}</span>
											</a>
										</li>
									);
								})}
							<center style={{ backgroundColor: '#DCDCDC' }}>
								{' '}
								<a href='/Notifications'>All Notifications</a>
							</center>
						</ul>
					</Card>
				</NavItem>
			</Fragment>
		);
		const guestLinks =
			this.props.auth.token === null ? (
				<Fragment>
					<NavItem>
						<RegisterModal />
					</NavItem>
					<NavItem>
						<LoginModal />
					</NavItem>
				</Fragment>
			) : null;

		return (
			<div>
				<Navbar color='dark' dark expand='sm' className='mb-5'>
					<Container>
						<NavbarBrand href='/'>
							<img style={{ width: '90px' }} top='true' src={Qworky_Logo} />
						</NavbarBrand>
						<NavbarToggler onClick={this.toggle} />
						<Collapse isOpen={this.state.isOpen} navbar>
							<div>
								{isDevEnv ? <div style={{
									alignItems: 'center',
									display: 'flex',
									textTransform: 'uppercase',
									fontWeight: 'bold',
									fontSize: '14px',
									color: '#e2ff00',
									border: '3px solid #e2ff007a',
									borderRadius: '0.4rem',
									padding: '2px 8px',
									margin: '3px 0',
									width: 'fit-content',
								}}>Test Database</div> : null}
							</div>
							<Nav className='ml-auto' navbar>
								{isAuthenticated ? authLinks : guestLinks}
							</Nav>
						</Collapse>
					</Container>
				</Navbar>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	notifications: state.authR.notifications,
	auth: state.authR,
	task: state.taskR,
	employee: state.employeeR,
});
export default connect(mapStateToProps, {
	getTasks,
	removeSeenNotifications,
	getEmployees,
	toggleTaskIndex,
	showNotifications,
	hideNotifications,
})(AppNavbar);
