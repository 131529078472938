import axios from 'axios';
import {
	GET_COMPANY,
	COMPANY_LOADING,
	UPDATE_COMPANY,
	UPDATE_COMPANY_FAIL,
	GET_COMPANIES,
	ADD_DEPARTMENT,
	GET_USERS,
	ADD_COMPANY,
	TOGGLE_DEPARTMENT_MODAL,
	TOGGLE_USER_MODAL,
	FILTER_COMPANY,
	DELETE_COMPANY,
	TOGGLE_PERMISSION_MODAL,
	OPEN_DEPARTMENT_MODAL,
	TOGGLE_MODULE_MODAL,
	UPDATE_COMPANY_TIME_INPUTS,
	GET_COMPANY_TIME_INPUTS,
	REGISTER_FAIL,
	REGISTER_SUCCESS,
	UPDATE_DEPARTMENT,
	ADD_PROPOSAL_TASK,
	UPDATE_PROPOSAL_TASK,
	BILLING_STARTED,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import { register } from './authActions';
import { getUsersByCompany } from './employeeActions';

export const getCompanies = () => (dispatch, getState) => {
	dispatch(setCompanyLoading());
	axios
		.get(`/api/companies`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_COMPANIES,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getCompanyTimeInputs = () => (dispatch, getState) => {
	axios
		.get(`/api/companies/timeInputs`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_COMPANY_TIME_INPUTS,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
export const getUsers = () => (dispatch, getState) => {
	axios
		.get(`/api/users`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_USERS,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getCompany = () => (dispatch, getState) => {
	dispatch(setCompanyLoading());
	axios
		.get(`/api/companies/company`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_COMPANY,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const registerCompany = (newCompany) => (dispatch, getState) => {
	var errorMessage = [];
	axios
		.post(`/api/users/checkuser`, newCompany, tokenConfig(getState))
		.then((res) => {
			errorMessage = res.data;
			axios
				.post(
					`/api/companies/checkcompany/${newCompany.shortname}`,
					errorMessage,
					tokenConfig(getState)
				)
				.then((res) => {
					if (res.data.length > 0) {
						dispatch({
							type: REGISTER_FAIL,
							payload: res.data,
						});
					} else {
						axios
							.post(`/api/companies/add`, newCompany, tokenConfig(getState))
							.then((res) => {
								dispatch({
									type: ADD_COMPANY,
									payload: res.data,
								});
								newCompany.permissionLevel = 'Executive';
								newCompany.company_id = res.data._id;
								newCompany.points = 0;
								var email = {
									template: 'ResetPassword',
									email: newCompany.email,
									title: 'Welcome to Qworky',
									description:
										'Thanks for joining our little community. We do look forward to helping make our tools work for you. Contact us to see how we can improve your experience',
									descriptiontitle: 'Welcome',
									buttonLink: 'https://qworky.herokuapp.com/',
									buttontitle: 'Get Started',
									key: 'nnnlwe19219n1b2--u0qjasca',
								};
								var object = JSON.stringify(email);
								axios.post(
									'https://script.google.com/macros/s/AKfycbwaVATD7Fvhqs3xKzhr-dvgbSTXY52h_rbFxN3PRfRuC3U58VD8/exec',
									object
								);
								dispatch(register(newCompany, newCompany.name, true));
							})
							.catch((err) =>
								dispatch(returnErrors(err.response.data, err.response.status, 'ADD_COMPANY_FAIL'))
							);
					}
				})
				.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
		})
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const addCompany = (newCompany) => (dispatch, getState) => {
	axios
		.post(`/api/companies/add`, newCompany, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: ADD_COMPANY,
				payload: res.data,
			})
		)
		.catch((err) =>
			dispatch(returnErrors(err.response.data, err.response.status, 'ADD_COMPANY_FAIL'))
		);
};

export const updateCompany = (company) => (dispatch, getState) => {
	dispatch(setCompanyLoading());
	axios
		.post(`/api/companies/update`, company, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_COMPANY,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const updateUserFee = (requestBody) => (dispatch, getState) => {
	dispatch(setCompanyLoading());
	axios
		.post(`/api/companies/userfee`, requestBody, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_COMPANY,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const updateBillingUser = (requestBody) => (dispatch, getState) => {
	dispatch(setCompanyLoading());
	axios
		.post(`/api/companies/billinguser`, requestBody, tokenConfig(getState))
		.then((res) => dispatch(getUsersByCompany()))
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const startBilling = (requestBody) => (dispatch, getState) => {
	dispatch(setCompanyLoading());
	axios
		.post(`/api/companies/startbilling`, requestBody, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: BILLING_STARTED,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const setCompanyLoading = () => {
	return {
		type: COMPANY_LOADING,
	};
};

export const deleteDepartment = (_id, i) => (dispatch, getState) => {
	const object = {
		_id: _id,
		index: i,
	};
	axios
		.post(`/api/companies/department`, object, tokenConfig(getState))
		.then((res) => {
			dispatch(getCompanies());
			dispatch({
				type: UPDATE_COMPANY,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const deleteProposalTask = (_id, i) => (dispatch, getState) => {
	const object = {
		_id: _id,
		index: i,
	};
	axios
		.post(`/api/companies/proposalTask`, object, tokenConfig(getState))
		.then((res) => {
			dispatch(getCompanies());
			dispatch({
				type: UPDATE_COMPANY,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const addDepartment = (newDepartment, _id) => (dispatch, getState) => {
	const object = {
		_id,
		newDepartment,
	};
	axios
		.post(`/api/companies/department/add`, object, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: ADD_DEPARTMENT,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const addProposalTask = (newProposalTask, _id) => (dispatch, getState) => {
	const object = {
		_id,
		newProposalTask,
	};
	axios
		.post(`/api/companies/proposalTask/add`, object, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: ADD_PROPOSAL_TASK,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const updateDepartment = (object) => (dispatch, getState) => {
	axios
		.post(`/api/companies/department/update`, object, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_DEPARTMENT,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const updateProposalTask = (object) => (dispatch, getState) => {
	axios
		.post(`/api/companies/proposalTask/update`, object, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_PROPOSAL_TASK,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const deleteUser = (_id, shortname) => (dispatch, getState) => {
	axios
		.delete(`/api/companies/user/${_id}/${shortname}`, tokenConfig(getState))
		.then((res) => {
			dispatch(getUsers());
			dispatch({
				type: UPDATE_COMPANY,
				payload: [],
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const updatePermissionLevel = (object, shortname) => (dispatch, getState) => {
	axios
		.post(`/api/companies/user/permission`, object, tokenConfig(getState))
		.then(() => {
			object.shortname = shortname;
			axios.post(`/api/companies/employee/permission`, object, tokenConfig(getState)).then(() => {
				dispatch(getUsers());
				dispatch({
					type: UPDATE_COMPANY,
					payload: [],
				});
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const filterCompanies = (filters) => (dispatch) => {
	dispatch({
		type: FILTER_COMPANY,
		payload: filters,
	});
};

export const activeState = (_id) => (dispatch, getState) => {
	const object = {
		_id,
	};
	axios
		.post(`/api/companies/disable`, object, tokenConfig(getState))
		.then((res) => {
			dispatch(getCompanies());
			dispatch({
				type: UPDATE_COMPANY,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const toggleTask = (_id) => (dispatch, getState) => {
	var obj = {
		_id,
	};
	dispatch(setCompanyLoading());
	axios
		.post(`/api/companies/toggletask`, obj, tokenConfig(getState))
		.then((res) => {
			dispatch(getCompanies());
			dispatch({
				type: UPDATE_COMPANY,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const toggleProposal = (_id) => (dispatch, getState) => {
	var obj = {
		_id,
	};
	dispatch(setCompanyLoading());
	axios
		.post(`/api/companies/toggleproposal`, obj, tokenConfig(getState))
		.then((res) => {
			dispatch(getCompanies());
			dispatch({
				type: UPDATE_COMPANY,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EMPLOYEE_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

export const toggleDepartmentIndex = (_id) => (dispatch) => {
	dispatch({
		type: TOGGLE_DEPARTMENT_MODAL,
		payload: _id,
	});
};

export const toggleUserIndex = (_id, shortname) => (dispatch) => {
	dispatch({
		type: TOGGLE_USER_MODAL,
		payload: { _id, shortname },
	});
};

export const togglePermissionIndex = (_id) => (dispatch) => {
	dispatch({
		type: TOGGLE_PERMISSION_MODAL,
		payload: _id,
	});
};

export const toggleModuleIndex = (_id) => (dispatch) => {
	dispatch({
		type: TOGGLE_MODULE_MODAL,
		payload: _id,
	});
};

export const openDepartmentModal = (_id) => (dispatch) => {
	dispatch({
		type: OPEN_DEPARTMENT_MODAL,
		payload: _id,
	});
};
export const updateCompanyTimeInputs = (body) => (dispatch, getState) => {
	axios
		.post('/api/companies/update/timeInputs', body, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_COMPANY_TIME_INPUTS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const updateCompanyDetail = (company) => (dispatch, getState) => {
	axios
		.patch(`/api/companies/update/company`, company, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_COMPANY,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_COMPANY_FAIL'));
			dispatch({
				type: UPDATE_COMPANY_FAIL,
			});
		});
};

// export const setInitialTimeInputs = () => {
//     dispatch({
//         type: TIME_INITIAL,
//     })
// }
