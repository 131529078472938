/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import JumperRegistration from '../../JumperRegistration/JumperRegistration';

const UpdateRequest = (props) => {

    return (
        <div>
            <Modal isOpen={props.modal} toggle={props.toggle} >
                <ModalBody>
                    <JumperRegistration
                        request={props.request}
                        updateRequest={props.updateRequest}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default UpdateRequest;