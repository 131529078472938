import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateFeedback, updateFeedbackStatus } from '../../actions/feedbacksActions';

import {
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Label,
	Input,
	Row,
	Col,
	Button,
} from 'reactstrap';

const UpdateModal = ({ employees, feedback_id, feedback, picToggleModal, closePicModal }) => {
	const dispatch = useDispatch();

	const [pic_user_id, setPicUser_Id] = useState('');
	const [status, setStatus] = useState('');

	const onChange = (e) => {
		setPicUser_Id(e.target.value);
	};

	useEffect(() => {
		setStatus({
			status: feedback.status,
		});
	}, [feedback]);

	const assignPic = (e) => {
		e.preventDefault();
		const updateFeedbackR = {
			feedback_id,
			pic_user_id: pic_user_id,
		};
		dispatch(updateFeedback(updateFeedbackR));
		closePicModal();
	};

	const updateStatus = (e) => {
		e.preventDefault();
		const updateStatus = {
			status: status,
		};
		dispatch(updateFeedbackStatus(feedback_id, updateStatus));
		closePicModal();
	};

	return (
		<Fragment>
			<Modal isOpen={picToggleModal} toggle={closePicModal} className='modal-lg'>
				<ModalHeader toggle={closePicModal}>
					<strong>Assign and Update </strong>
				</ModalHeader>
				<ModalBody>
					<Row xs='2'>
						<Col>
							<FormGroup>
								<Label>PIC</Label>
								<Input
									type='select'
									name='pic_user_id'
									defaultValue={pic_user_id}
									id='pic_user_id'
									onChange={onChange}>
									<option value=''>Select PIC</option>
									{employees
										.filter((employee) => employee.permissionLevel !== 'Disabled')
										.map(({ _id, user_id, name }) => (
											<option key={_id} value={user_id}>
												{name}
											</option>
										))}
								</Input>
							</FormGroup>
						</Col>

						<Col>
							<FormGroup>
								<Label>Status</Label>
								<Input
									type='select'
									id='status'
									name='status'
									onChange={({ target }) => setStatus(target.value)}
									required>
									<option>{status.status}</option>
									<option>Pending</option>
									<option>In Progress</option>
									<option>Hold</option>
									<option>Completed</option>
								</Input>
							</FormGroup>
						</Col>
					</Row>
					<Row xs='2'>
						<Col>
							<Button
								color='dark'
								className='rowAlign'
								style={{ marginTop: '2rem' }}
								onClick={(e) => assignPic(e)}
								block>
								Assign
							</Button>
						</Col>
						<Col>
							<Button
								color='dark'
								className='rowAlign'
								style={{ marginTop: '2rem' }}
								onClick={(e) => updateStatus(e)}
								block>
								Update
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</Fragment>
	);
};

export default UpdateModal;
