import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyTimeInputs } from '../../actions/companyActions';
import { reduceInputBoxSize } from '../../actions/detailActions';
import { Table } from 'reactstrap';
// This is a childl component of the Update Details component.
const FeesCalculatorTable = (props) => {
	const [stagesCost, setStagesCost] = useState([]); // An array of objects.[{stageNumber: 1, stageFees: 50},....]
	const [totalCost, setTotalCost] = useState(0);

	const intTimeInputs = useSelector((state) => state.companyR.intTimeInputs);
	const extCostInputs = useSelector((state) => state.companyR.extTimeInputs);

	const reduxStages = useSelector((state) =>
		state.stageR.stages.filter(({ proposalNumber }) => proposalNumber === props.proposalNumber)
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompanyTimeInputs());
	}, [])
		;
	useEffect(() => {
		if (intTimeInputs) {
			// The condtition is for not getting error
			var result = []; // The result array will store all the fees objects per stage.
			reduxStages.map(e => {
				result.push({
					stageNumber: e.stage,
					stageFee: 0,
				});
			});
			props.details.forEach((detail) => {
				// The fees of current revision of the details
				if (props.currentRevisionNum === detail.revisionNumber) {
					detail.intTimeInputs.forEach((e) => {
						if (Object.values(e)[0]) {
							intTimeInputs.forEach((x) => { // This is coming from company side.
								return x.id === Object.keys(e)[0]  // Checking the id
									? result.map((y) =>
										y.stageNumber === detail.stageNumber // Checking the stage number.
											? (y.stageFee += Object.values(e)[0] * x.rate)
											: y
									)
									: null;
							});
						}
					});
					detail.extCostInputs.forEach(e => {
						extCostInputs.map(el => { // The data is coming from company side.
							if (el.id === Object.keys(e)[0]) { // Checking the ids. 
								return result.map(x =>
									x.stageNumber === detail.stageNumber ? (x.stageFee += Object.values(e)[0]) : x
								);
							}
						})
					});
				}
				// THe fees of details revision history.
				else if (detail.revision) { // For not geeting error.
					detail.revision
						.filter(({ revisionNumber }) => revisionNumber === props.currentRevisionNum)
						.map((detail) => {
							detail.intTimeInputs.forEach((e) => {
								if (Object.values(e)[0]) {
									intTimeInputs.forEach((x) => {
										return x.id === Object.keys(e)[0]
											? result.map((y) =>
												y.stageNumber === detail.stageNumber
													? (y.stageFee += Object.values(e)[0] * x.rate)
													: y
											)
											: null;
									});
								}
							});
							detail.extCostInputs.forEach((e) => {
								return result.map((x) =>
									x.stageNumber === detail.stageNumber ? (x.stageFee += Object.values(e)[0]) : x
								);
							});
						});
				}
			});
			setStagesCost([...result]); // Passing the result of the calculation to our states
			setTotalCost(
				result.length
					? result.reduce((total, el) => {
						return total + el.stageFee;
					}, 0)
					: null
			);
		}
	}, [props, props.currentRevisionNum]);

	if (totalCost) {
		return (
			<Table onClick={() => dispatch(reduceInputBoxSize())}>
				<thead>
					<tr>
						<th>Stage</th>
						<th>Without Profit</th>
						<th>With Profit</th>
						<th>With Profit + Contingency</th>
						<th>Marked Up</th>
					</tr>
				</thead>
				<tbody>
					{stagesCost.map((cost, index) => {
						return (
							<tr key={index}>
								<td>{cost.stageNumber}</td>
								<td>{cost.stageFee.toFixed(0)}</td>
								<td>{(cost.stageFee * 1.1).toFixed(0)}</td>
								<td>
									<span style={{ marginLeft: '60px' }}>{(cost.stageFee * 1.2).toFixed(0)}</span>
								</td>
								<td>{(cost.stageFee * 1.3).toFixed(0)}</td>
							</tr>
						);
					})}
					<tr>
						<td>Total</td>
						<td>{totalCost ? totalCost.toFixed(0) : null}</td>
						<td>{(totalCost * 1.1).toFixed(0)}</td>
						<td>
							<span style={{ marginLeft: '60px' }}>{(totalCost * 1.2).toFixed(0)}</span>
						</td>
						<td>{(totalCost * 1.3).toFixed(0)}</td>
					</tr>
				</tbody>
				<br />
			</Table>
		);
	} else {
		return null;
	}
};

export default FeesCalculatorTable;
