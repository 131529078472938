import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ProjectList from './components/projectComponents/ProjectList';
import TaskList from './components/taskComponents/TaskList';
import ReportList from './components/reportComponents/ReportList';
import ReportReview from './components/reportComponents/ReportReview';
import UpdateTask from './components/taskComponents/UpdateTask';
import UpdateProject from './components/projectComponents/UpdateProject';
import UpdateReport from './components/reportComponents/UpdateReport';
import UserPage from './components/userComponents/UserPage';
import ResetPasswordRequest from './components/auth/ResetPasswordRequest';
import CompanyList from './components/AdminComponents/CompanyList';
import ResetPassword from './components/auth/ResetPassword';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import requireAuth from './utils/requireAuth';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/authActions';
import { hideNotifications } from './actions/userActions';
import TaskDashboard from './components/taskComponents/TaskDashboard';
import TaskApproval from './components/taskComponents/TaskApproval';
import FeedbacksAndBugs from './components/feedback&bugComponents/FeedbacksAndBugs';
import FeedbacksAndBugsList from './components/feedback&bugComponents/FeedbacksAndBugsList';
import NotificationsPage from './components/NotificationsPage';
import ProjectCalendar from './components/projectComponents/ProjectCalendar';
import ProposalList from './components/proposalComponent/ProposalList';
import ProposalRates from './components/proposalComponent/ProposalRates';
import PropopsalSummary from './components/proposalComponent/ProposalSummary';
import UpdateProposal from './components/proposalComponent/UpdateProposal';
import ClientList from './components/clientComponent/ClientList';
import UpdateClient from './components/clientComponent/UpdateClient';
import VendorList from './components/vendorComponent/VendorList';
import UpdateVendor from './components/vendorComponent/UpdateVendor';
import StaffList from './components/executiveComponents/StaffList';
import RewardShop from './components/rewardComponents/RewardShop';
import RegisterCompany from './components/auth/RegisterCompany';
import LeaveDetails from './components/leaveTrackerComponent/LeaveDetails';
import LeaveTrackerList from './components/leaveTrackerComponent/LeaveTrackerList';
import LeaveCalendar from './components/leaveTrackerComponent/LeaveCalender';
import UpdateLeave from './components/leaveTrackerComponent/UpdateLeave';
import RegisterBilling from './views/RegisterBilling';
import PdfDocument from './components/proposalComponent/InvoicePdf';
import PaymentScreen from './views/PaymentScreen';
import InvoiceList from './views/InvoiceList';
import Expense from './components/expenseComponents/Expense';
import JumperRegistration from './views/JumperRegistration/JumperRegistration'
import JumperManagement from './views/JumperManagement/JumperManagement'

// import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'

class App extends Component {
	componentWillMount() {
		store.dispatch(loadUser());
	}
	closeNotifications = () => {
		if (!store.getState().authR.hideNotifications) {
			store.dispatch(hideNotifications()); // It closes notifications bar when background is clicked.
		}
	};
	render() {
		return (
			<Provider store={store}>
				<Router>
					<div>
						<div onClick={this.closeNotifications}>
							{/* Special Routes for Qworky Managent */}
							<Route path='/paymentscreen' exact component={requireAuth(PaymentScreen, 'qworky')} />
							<Route path='/companylist' exact component={requireAuth(CompanyList, 'qworky')} />
							<Route
								path='/feedbacks'
								exact
								component={requireAuth(FeedbacksAndBugsList, 'qworky')}
							/>

							{/* Routes For General Features */}
							<Route path='/' exact component={requireAuth(UserPage)} />
							<Route path='/jumper-registration' exact component={JumperRegistration} />
							<Route path='/jumper-management' exact component={requireAuth(JumperManagement)} />
							<Route path='/resetpassword' exact component={ResetPasswordRequest} />
							<Route path='/changepassword/:token' exact component={ResetPassword} />
							<Route path='/feedback' exact component={requireAuth(FeedbacksAndBugs)} />
							<Route path='/Notifications' exact component={requireAuth(NotificationsPage)} />
							<Route path='/user/:id' exact component={requireAuth(UserPage)} />
							<Route
								path='/registerbilling/:company_id'
								exact
								component={requireAuth(RegisterBilling)}
							/>
							<Route path='/registerCompany' exact component={RegisterCompany} />

							{/* Routes for Leave Tracker Features */}
							<Route path='/leavedetails' exact component={requireAuth(LeaveDetails)} />
							<Route path='/leaves' exact component={requireAuth(LeaveTrackerList)} />
							<Route path='/leaveCalendar' exact component={requireAuth(LeaveCalendar)} />
							<Route path='/editLeave/:id' exact component={requireAuth(UpdateLeave)} />

							{/* Routes for Management Features */}
							<Route path='/stafflist' exact component={requireAuth(StaffList)} />

							{/* Routes for Gamified Task Management */}
							{
								<div>
									<Route
										path='/projectCalendar'
										exact
										component={requireAuth(ProjectCalendar, 'task')}
									/>
									<Route path='/projectlist' exact component={requireAuth(ProjectList, 'task')} />
									<Route
										path='/editProject/:id'
										exact
										component={requireAuth(UpdateProject, 'task')}
									/>
									<Route path='/tasklist' exact component={requireAuth(TaskList, 'task')} />
									<Route
										path='/taskdashboard'
										exact
										component={requireAuth(TaskDashboard, 'task')}
									/>
									<Route path='/taskapproval' exact component={requireAuth(TaskApproval, 'task')} />
									<Route path='/editTask/:id' exact component={requireAuth(UpdateTask, 'task')} />
									<Route path='/reportlist' exact component={requireAuth(ReportList, 'task')} />
									<Route path='/reportreview' exact component={requireAuth(ReportReview, 'task')} />
									<Route
										path='/editReport/:id'
										exact
										component={requireAuth(UpdateReport, 'task')}
									/>
								</div>
							}
							{
								// Rewards Related Components.
								<div>
									<Route path='/rewardShop' exact component={requireAuth(RewardShop)} />
								</div>
							}

							{/* Routes for Proposal Features */}
							<div>
								<Route
									path='/proposalList'
									exact
									component={requireAuth(ProposalList, 'proposal')}
								/>
								<Route
									path='/proposalRates'
									exact
									component={requireAuth(ProposalRates, 'proposal')}
								/>
								<Route path='/invoices' exact component={requireAuth(InvoiceList, 'proposal')} />
								<Route path='/clientsList' exact component={requireAuth(ClientList, 'proposal')} />
								<Route
									path='/updateClient/:id'
									exact
									component={requireAuth(UpdateClient, 'proposal')}
								/>
								<Route path='/vendorsList' exact component={requireAuth(VendorList, 'proposal')} />
								<Route
									path='/updateVendor/:id'
									exact
									component={requireAuth(UpdateVendor, 'proposal')}
								/>
								<Route
									path='/updateProposal/:id'
									exact
									component={requireAuth(UpdateProposal, 'proposal')}
								/>
								<Route
									path='/proposalSummary/:id'
									exact
									component={requireAuth(PropopsalSummary, 'proposal')}
								/>
							</div>

							{/* Routes for Expense Tracker */}
							<Route path='/expenselist' exact component={requireAuth(Expense, 'expense')} />
						</div>
					</div>
				</Router>
			</Provider>
		);
	}
}

export default App;
