import React, { Component, Fragment } from 'react';
import { Button, Container, Table, Col, Input, Row, Label } from 'reactstrap';
import { connect } from 'react-redux';
import {
	getReports,
	deleteReport,
	toggleReportIndex,
	filterReports,
} from '../../actions/reportActions';
import ReportModal from './reportModal';
import ReportDetails from './ReportDetails';
import ProjectDetails from '../projectComponents/ProjectDetails';
import TaskDetails from '../taskComponents/TaskDetails';
import SortComponent from '../SortComponent';
import ExportCSV from '../ExportCSV';
import PropTypes from 'prop-types';
import ConfirmButton from '../userComponents/ConfirmButton';
import { getEmployees } from '../../actions/employeeActions';
import { getProjects, toggleProjectIndex } from '../../actions/projectActions';
import { getTasks, toggleTaskIndex } from '../../actions/taskActions';
import { getCompany } from '../../actions/companyActions';
import { getDetails } from '../../actions/detailActions';

class TaskList extends Component {
	state = {
		Month: new Date().getMonth() + 1,
		Year: new Date().getFullYear(),
		Overdue: localStorage.getItem('OverdueRl')
			? JSON.parse(window.localStorage.getItem('OverdueRl'))
			: false, // fiter related
		DepartmentSelector: localStorage.getItem('DepartmentSelectorRl')
			? localStorage.getItem('DepartmentSelectorRl')
			: '',
		EmployeeSelector: localStorage.getItem('EmployeeSelectorRl')
			? localStorage.getItem('EmployeeSelectorRl')
			: '', // fiter related
		departments: localStorage.getItem('departmentsRl')
			? JSON.parse(localStorage.getItem('departmentsRl'))
			: [], // fiter related
		project: localStorage.getItem('projectRl') ? localStorage.getItem('projectRl') : '',
		employees: [],
		RangeStart: localStorage.getItem('RangeStartRl') ? localStorage.getItem('RangeStartRl') : '', // fiter related
		RangeEnd: localStorage.getItem('RangeEndRl') ? localStorage.getItem('RangeEndRl') : '', // fiter related
		resetRangeStartRl: false, // reset fiter related
		resetRangeEndRl: false, // reset fiter related
		resetFilterRl: false, // reset fiter related
		filtercolors: [
			'#e1bee7',
			'#64b5f6',
			'#c5e1a5',
			'#f48fb1',
			'#f06292',
			'#ffee58',
			'#ffab91',
			'#80cbc4',
		],
		sortCriteria: localStorage.getItem('sortCriteriaRl')
			? localStorage.getItem('sortCriteriaRl')
			: 'reportdate',
		sortDirection: localStorage.getItem('sortDirectionRl')
			? localStorage.getItem('sortDirectionRl')
			: -1,
	};

	componentDidMount() {
		this.props.getReports();
		this.props.getEmployees();
		this.props.getProjects();
		this.props.getTasks();
		this.props.getCompany();
		this.props.getDetails();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.company.company !== this.props.company.company) {
			this.setState({
				departments:
					this.state.departments.length > 0
						? this.state.departments
						: this.props.company.company.departments
							.filter(({ active }) => active)
							.map((e, index) => {
								return {
									name: e.name,
									id: e.id,
									filterState: true,
									color: this.state.filtercolors[index],
									index: index,
								};
							}),
				employees: this.props.employee.employees.filter(
					(employee) => employee.permissionLevel !== 'Disabled'
				),
			});
		}
		if (
			prevProps.report.reports !== this.props.report.reports ||
			prevState.departments !== this.state.departments ||
			prevState.Year !== this.state.Year ||
			prevState.Month !== this.state.Month ||
			prevState.Individual !== this.state.Individual ||
			prevState.EmployeeSelector !== this.state.EmployeeSelector ||
			prevState.project !== this.state.project ||
			prevState.RangeEnd !== this.state.RangeEnd ||
			prevState.Overdue !== this.state.Overdue ||
			prevState.RangeStart !== this.state.RangeStart
		) {
			localStorage.setItem('EmployeeSelectorRl', this.state.EmployeeSelector);
			localStorage.setItem('departmentsRl', JSON.stringify(this.state.departments));
			localStorage.setItem('DepartmentSelectorRl', this.state.DepartmentSelector);
			localStorage.setItem('projectRl', this.state.project);
			localStorage.setItem('OverdueRl', this.state.Overdue);
			localStorage.setItem('RangeEndRl', this.state.RangeEnd);
			localStorage.setItem('RangeStartRl', this.state.RangeStart);

			var filters = {};
			filters.department = this.state.departments.filter((e) => e.filterState).map((e) => e.id);
			if (this.state.EmployeeSelector) {
				filters.user_id = this.state.EmployeeSelector;
			}
			if (this.state.project) {
				filters.project_id = this.state.project;
			}
			var d = '';
			if (this.state.RangeEnd) {
				d = new Date(this.state.RangeEnd);
				d = new Date(d.setDate(d.getDate() + 1)).toISOString().substr(0, 10);
			}
			if (this.state.Overdue) {
				filters.overdue = this.state.Overdue;
			}
			filters.dateRange = [this.state.RangeStart, d];
			this.props.filterReports(filters, false);
		}

		if (prevState.DepartmentSelector !== this.state.DepartmentSelector) {
			this.setState({
				employees: this.state.DepartmentSelector
					? this.props.employee.employees.filter(
						({ department }) => department === this.state.DepartmentSelector
					)
					: this.props.employee.employees.filter(
						(employee) => employee.permissionLevel !== 'Disabled'
					),
			});
		}
		// Resetting the task filtering
		if (prevState.resetRangeStartRl !== this.state.resetRangeStartRl) {
			this.setState({ RangeStart: '' });
			localStorage.setItem('RangeStartRl', '');
		}
		if (prevState.resetRangeEndRl !== this.state.resetRangeEndRl) {
			this.setState({ RangeEnd: '' });
			localStorage.setItem('RangeEndRl', '');
		}
		if (prevState.resetFilterRl !== this.state.resetFilterRl) {
			this.setState({
				RangeEnd: '',
				RangeStart: '',
				Overdue: false,
				EmployeeSelector: '',
				project: '',
				DepartmentSelector: '',
			});
			this.setState({
				departments: this.props.company.company.departments.map((e, index) => {
					return {
						name: e.name,
						id: e.id,
						filterState: true,
						color: this.state.filtercolors[index],
						index: index,
					};
				}),
			});
			// Removing the user filter preferences from the local storage.
			localStorage.setItem('RangeStartRl', '');
			localStorage.setItem('RangeEndRl', '');
			localStorage.setItem('OverdueRl', '');
			localStorage.setItem('EmployeeSelectorRl', '');
			localStorage.setItem('departmentsRl', '');
			localStorage.setItem('projectRl', '');
			localStorage.setItem('DepartmentSelectorRl', '');
		}

		// Local Storage for sortCriteria and Direction
		if (
			prevState.sortCriteria !== this.state.sortCriteria ||
			prevState.sortDirection !== this.state.sortDirection
		) {
			localStorage.setItem('sortCriteriaRl', this.state.sortCriteria);
			localStorage.setItem('sortDirectionRl', this.state.sortDirection);
		}
	}

	sortFunction = (sortCriteria) => {
		this.setState({
			sortCriteria,
			sortDirection: -this.state.sortDirection,
		});
	};

	onDeleteClick = (id) => {
		var confirm = window.confirm('Delete this Report?');
		if (confirm) {
			this.props.deleteReport(id);
		}
	};

	overdueFilter = () => {
		this.setState({
			Overdue: !this.state.Overdue,
		});
	};

	departmentFilter = (i) => {
		this.setState({
			departments: this.state.departments.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		const { filteredReports, reports } = this.props.report;
		console.log('this.props.report', this.props.report)
		const { employees } = this.props.employee;
		const { tasks } = this.props.task;
		const { user } = this.props.auth;
		const { projects } = this.props.project;
		//File Name for exporting excel
		const excelProject =
			this.state.project && projects
				? projects.filter((e) => e._id === this.state.project).map((e) => e.name)
				: 'All Reports';
		const excelRangeStart = this.state.RangeStart
			? ' From ' + this.state.RangeStart.substr(0, 10)
			: '';
		const excelRangeEnd = this.state.RangeEnd ? ' Untill ' + this.state.RangeEnd.substr(0, 10) : '';
		const excelEmployee =
			this.state.EmployeeSelector && employees
				? ` (${employees
					.filter((e) => e.user_id === this.state.EmployeeSelector)
					.map((e) => e.name)})`
				: '';
		const filename =
			new Date().toISOString().substr(0, 16) +
			' ' +
			excelProject +
			excelRangeStart +
			excelRangeEnd +
			excelEmployee;

		return (
			<Container>
				<ReportDetails />
				<ProjectDetails />
				<TaskDetails />
				<ReportModal />
				<Row style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Start Range</Label>
						<Input
							type='date'
							name='RangeStart'
							onChange={this.onChange}
							value={this.state.RangeStart}
						/>
					</Col>
					<div
						style={{
							margin: '2rem 0 0 -0.5rem',
							padding: '.3rem .3rem .2rem .3rem',
							border: '.5px solid #D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ resetRangeStartRl: !this.state.resetRangeStartRl })}>
						X
					</div>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>End Range</Label>
						<Input
							type='date'
							name='RangeEnd'
							onChange={this.onChange}
							value={this.state.RangeEnd}
						/>
					</Col>
					<div
						style={{
							margin: '2rem 1rem 0 -0.5rem',
							padding: '.3rem .3rem .2rem .3rem',
							border: '.5px solid 	#D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ resetRangeEndRl: !this.state.resetRangeEndRl })}>
						X
					</div>
					<Col xs='0.5'>
						<p
							style={{
								color: 'red',
								fontSize: '40px',
								marginTop: '17px',
								marginBottom: '-17px',
								padding: '0px',
								opacity: this.state.Overdue ? '1' : '0.3',
								textAlign: 'center',
								cursor: 'pointer',
							}}
							onClick={this.overdueFilter}>
							!!
						</p>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Project</Label>
						<Input type='select' name='project' onChange={this.onChange} value={this.state.project}>
							<option></option>
							{this.props.project.projects.map((project) => (
								<option key={project._id} value={project._id}>
									{project.name}
								</option>
							))}
						</Input>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>PIC Department</Label>
						<Input
							type='select'
							name='DepartmentSelector'
							onChange={this.onChange}
							value={this.state.DepartmentSelector}>
							<option value=''>Select to Filter PIC</option>
							{this.state.departments.map(({ name, id }) => (
								<option key={id} value={id}>
									{name}
								</option>
							))}
						</Input>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>PIC</Label>
						<Input
							type='select'
							name='EmployeeSelector'
							onChange={this.onChange}
							value={this.state.EmployeeSelector}>
							<option></option>
							{this.state.employees.map(({ _id, name, user_id }) => (
								<option key={_id} value={user_id}>
									{name}
								</option>
							))}
						</Input>
					</Col>
					{user.proposal && (
						<Col>
							<Label style={{ fontSize: '12px', color: 'grey' }}>Stage</Label>
							<Input
								type='number'
								name='stageNumber'
								onChange={this.onChange}
								value={this.state.stageNumber}
							/>
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						<Table>
							<tbody>
								<tr>
									{this.state.departments.map(({ name, filterState, color, index }) => (
										<td
											key={name}
											style={{
												opacity: filterState ? '1' : '0.3',
												backgroundColor: color,
												textAlign: 'center',
												cursor: 'pointer',
											}}
											onClick={this.departmentFilter.bind(this, index)}>
											{name}
										</td>
									))}
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				<a onClick={() => this.setState({ resetFilterRl: !this.state.resetFilterRl })} href='#'>
					Reset Filter?
				</a>
				<div style={{ display: 'inline', float: 'right', marginTop: '-10px' }}>
					<ExportCSV table='reportlist' filename={filename} />
				</div>
				<Table id='reportlist' hover>
					<thead style={{ fontSize: '14px' }}>
						<tr>
							<th width='10%' className='rowAlign'>
								Date
								<SortComponent onClick={() => this.sortFunction('reportdate')} />
							</th>
							<th width='10%' className='rowAlign'>
								Project
							</th>
							{user.proposal && (
								<th width='5%' className='rowAlign'>
									Stage
								</th>
							)}
							<th width='10%' className='rowAlign'>
								Task
							</th>
							<th width='10%' className='rowAlign'>
								PIC
							</th>
							<th width='5%' className='rowAlign'>
								Time
							</th>
							<th width='45%' className='rowAlign' style={{ textAlign: 'left' }}>
								Details
							</th>
							{user.permissionLevel === 'Executive' && <th></th>}
						</tr>
					</thead>
					<tbody style={{ fontSize: '14px' }}>
						{filteredReports
							.sort((a, b) => {
								if (!b[this.state.sortCriteria]) {
									return -1;
								}
								if (!a[this.state.sortCriteria]) {
									return 1;
								}
								if (a[this.state.sortCriteria] > b[this.state.sortCriteria]) {
									return this.state.sortDirection;
								}
								if (a[this.state.sortCriteria] < b[this.state.sortCriteria]) {
									return -this.state.sortDirection;
								}
								if (a.user_id > b.user_id) {
									return 1;
								}
								if (a.user_id < b.user_id) {
									return -1;
								}
								return 0;
							})
							.map(
								({
									_id,
									reportdate,
									description,
									user_id,
									task_id,
									project_id,
									numberOfHours,
									detail_id,
									proposal_id,
									name
								}) => {
									const details = user.proposal
										? this.props.detail.details.filter((detail) => detail._id === detail_id)
										: [];
									if (
										this.state.stageNumber &&
										this.state.stageNumber != details.map((detail) => detail.stageNumber)[0]
									) {
										return null;
									}
									return (
										<tr key={_id}>
											<td className='rowAlign'>
												{reports.map((report) => {
													if (_id === report._id) {
														return (
															<span
																onClick={this.props.toggleReportIndex.bind(this, _id)}
																className='tableLink'>
																{reportdate ? reportdate.substring(0, 10) : ''}
															</span>
														);
													}
												})}
											</td>
											<td className='rowAlign'>
												<span
													onClick={() => this.props.toggleProjectIndex(project_id)}
													className='tableLink'>
													{projects.length > 0
														? projects
															.filter((project) => project._id === project_id)
															.map((project) => project.name)
														: null}
												</span>
											</td>
											{user.proposal && (
												<td className='rowAlign'>
													{task_id ? '-' : details.map((detail) => detail.stageNumber)}
												</td>
											)}
											<td className='rowAlign'>
												{
													project_id === '5f224a84b2fbde22f5fee090' ? <span
														className='tableLink'
														onClick={() => this.props.toggleProjectIndex.bind(this, project_id)}>
														{this.props.proposal.proposals.find(
															({ _id }) => _id === proposal_id
														)?.proposalNumber} - {name}
													</span>
														: task_id ? (
															<span
																className='tableLink'
																onClick={() => this.props.toggleTaskIndex(task_id)}>
																{tasks
																	.filter((task) => task._id === task_id)
																	.map((task) => {
																		if (task.proposal_id) {
																			const proposalNumber = this.props.proposal.proposals.find(
																				({ _id }) => _id === task.proposal_id
																			)?.proposalNumber;
																			return proposalNumber + ' - ' + task.name;
																		}
																		return task.name;
																	})}
															</span>
														) : (
															details.map((detail) => {
																return detail.description
															})
														)}
											</td>
											<td className='rowAlign'>
												{employees
													.filter((employee) => employee.user_id === user_id)
													.map((employee) => (
														<span
															key={employee._id}
															onClick={() => window.open('/user/' + employee.user_id, '_blank')}
															className='tableLink'>
															{employee.name}
														</span>
													))}
											</td>
											<td className='rowAlign'>{numberOfHours}</td>
											<td
												className='rowAlign'
												style={{ textAlign: 'left', whiteSpace: 'pre-wrap', overflow: 'hidden' }}>
												{description}
											</td>
											{user.permissionLevel === 'Executive' && (
												<td className='rowAlign'>
													<Button
														className='remove-btn'
														color='danger'
														size='sm'
														alertTitle={'Remove this Report?'}
														onClick={() => this.onDeleteClick(_id)}>
														&times;
													</Button>
												</td>
											)}
										</tr>
									);
								}
							)}
					</tbody>
				</Table>
			</Container>
		);
	}
}

TaskList.propTypes = {
	getReports: PropTypes.func.isRequired,
	deleteReport: PropTypes.func.isRequired,
	getProjects: PropTypes.func.isRequired,
	getTasks: PropTypes.func.isRequired,
	toggleReportIndex: PropTypes.func.isRequired,
	toggleProjectIndex: PropTypes.func.isRequired,
	toggleTaskIndex: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	filterReports: PropTypes.func.isRequired,
	report: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	report: state.reportR, // getting reducer from /reducers/index.js
	employee: state.employeeR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js
	task: state.taskR, // getting reducer from /reducers/index.js
	detail: state.detailR,
	company: state.companyR,
	proposal: state.proposalR,
});

export default connect(mapStateToProps, {
	getReports,
	deleteReport,
	toggleReportIndex,
	toggleProjectIndex,
	toggleTaskIndex,
	getEmployees,
	getProjects,
	getTasks,
	getCompany,
	filterReports,
	getDetails,
})(TaskList);
