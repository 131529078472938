import React, { Component, Fragment } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Label,
	Input,
	Row,
	Col,
	Table,
	Collapse,
	CardBody,
	Card,
	CardHeader,
	Media,
} from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { toggleTaskIndex, commentTask, deleteComment } from '../../actions/taskActions';
import { toggleReportIndex } from '../../actions/reportActions';
import { createNotification } from '../../actions/userActions';
import PropTypes from 'prop-types';
import ReportDetails from '../reportComponents/ReportDetails';
import ReportSummary from '../reportComponents/ReportSummary';

class TaskDetails extends Component {
	state = {
		collapse: false,
		comment: '',
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	submitComment = (task_id, user_id, createdBy_id) => {
		//  user_id = The task PIC user_id ,createdBy_id = The task creator user id
		var uniqueID = this.idGenerator(); // The unique ID will be used in comment and notification.
		if (this.state.comment) {
			if (user_id !== this.props.auth.user.id) {
				// It avoids making notification for the comments that made by the user it self.
				var newNotification = {
					id: uniqueID, // Notification Id which will be same with the corresponding comment Id.
					comment: this.state.comment, // The comment text.
					task_id: task_id, // THe task id which notification is about.
					commentedBy_id: this.props.auth.user.id, // The user_id of the commenter.
					isSeen: false,
					date: new Date(),
					// isDeleted property to be added.
				}; // It creates a new notification for the new added comment
				this.props.createNotification(newNotification, user_id); // It adds notificaton to task PIC
			}
			if (createdBy_id !== this.props.auth.user.id) {
				var newNotification = {
					// user_id: createdBy_id, // It is only used to find the user in the routes api.
					id: uniqueID, // Notification Id which will be same with the corresponding comment Id.
					comment: this.state.comment,
					task_id: task_id,
					commentedBy_id: this.props.auth.user.id,
					isSeen: false,
					date: new Date(),
				}; // It creates a new notification for the new added comment
				this.props.createNotification(newNotification, createdBy_id); // It adds a notification to the task creator
			}
			var newComment = {
				id: uniqueID, // Comment Id
				comment: this.state.comment, // Comment text
				user_id: this.props.auth.user.id, // the commenter user_id
				isDeleted: false,
				date: new Date(),
			};
			this.props.commentTask(newComment, task_id);
			this.setState({
				comment: '',
			});
		}
	};
	idGenerator = () => {
		// The function generates Id consist of numbers and letters
		return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2); // The ID length is 22 eg.. 8hfhals5fkd
	};

	deleteComment = (comment_id, comment, user_id, comment_date, task_id) => {
		const deleteComment = {
			id: comment_id, // Comment Id
			comment: comment, // The comment text
			user_id: user_id, // The commenter
			date: comment_date,
			isDeleted: true, // It deletes the comment
		};
		this.props.deleteComment(deleteComment, task_id);
		// The corresponding notification should be deleted as well.
	};
	toggle = () => {
		this.setState({
			collapse: !this.state.collapse,
		});
	};

	render() {
		const { task } = this.props.task;
		const { projects } = this.props.project;
		const { employees } = this.props.employee;
		const Reports = this.props.report.reports;
		const { user } = this.props.auth;

		return task ? (
			<Fragment>
				<ReportDetails />
				<Modal
					isOpen={this.props.task.modal}
					toggle={this.props.toggleTaskIndex.bind(this, '')}
					className='modal-lg'>
					<ModalHeader toggle={this.props.toggleTaskIndex.bind(this, '')}>
						<strong>{task.name}</strong>
					</ModalHeader>
					<ModalBody>
						<Row xs='2'>
							<Col>
								<FormGroup>
									<Label>Task ID</Label>
									<Input type='text' defaultValue={task._id} disabled />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Project</Label>
									<Input
										type='text'
										defaultValue={projects
											.filter((project) => project._id === task.project_id)
											.map((project) => project.name)}
										disabled
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row xs='2'>
							<Col>
								<FormGroup>
									<Label>Department</Label>
									<Input
										type='text'
										defaultValue={
											this.props.company.company.departments
												? this.props.company.company.departments.find(
														({ id }) => id === task.department
												  ).name
												: null
										}
										disabled
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>PIC</Label>
									<Input
										type='text'
										defaultValue={employees
											.filter((employee) => employee.user_id === task.user_id)
											.map((employee) => employee.name)}
										disabled
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row xs='2'>
							<Col>
								<FormGroup>
									<Label>Points</Label>
									<Input type='text' defaultValue={task.points} disabled />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Difficulty</Label>
									<Input type='text' defaultValue={task.reward} disabled />
								</FormGroup>
							</Col>
						</Row>
						<Row xs='2'>
							<Col>
								<FormGroup>
									<Label>Status</Label>
									<Input type='text' defaultValue={task.status} disabled />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Deadline</Label>
									<Input type='text' defaultValue={task.deadline.substring(0, 10)} disabled />
								</FormGroup>
							</Col>
						</Row>
						<Row xs='3'>
							<Col>
								<FormGroup>
									<Label>Creation Date</Label>
									<Input
										type='text'
										defaultValue={task.creationDate ? task.creationDate.substring(0, 10) : '-'}
										disabled
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Start Date</Label>
									<Input
										type='text'
										defaultValue={task.startdate ? task.startdate.substring(0, 10) : '-'}
										disabled
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Completion Date</Label>
									<Input
										type='text'
										defaultValue={task.enddate ? task.enddate.substring(0, 10) : '-'}
										disabled
									/>
								</FormGroup>
							</Col>
						</Row>
						<FormGroup>
							<Label>Description</Label>
							<Input type='textarea' id='descriptionBox' defaultValue={task.description} disabled />
						</FormGroup>
						<ReportSummary
							Reports={Reports}
							ReportIds={task.reports}
							Employees={employees}
							toggleReportIndex={this.props.toggleReportIndex}
						/>
						<Card>
							<CardHeader onClick={this.toggle}>
								Comments {this.state.collapse ? <Button close /> : <Button close>&#x2B;</Button>}
							</CardHeader>
							<Collapse isOpen={this.state.collapse}>
								<CardBody>
									{task.comments
										.filter((e) => !e.isDeleted)
										.map(({ comment, id, user_id, date }, index) => {
											const photo = employees
												.filter((employee) => employee.user_id === user_id)
												.map((employee) => employee.photo)[0];
											return (
												<Card
													key={index}
													style={{
														marginBottom: '1rem',
														borderColor: '#6c757d',
													}}>
													<CardHeader
														style={{
															padding: '1px',
															background: '#6c757d',
														}}
														color='primary'>
														<Media style={{ alignItems: 'center' }}>
															<Media style={{ maxWidth: '5%', verticalAlign: 'center' }}>
																<Media
																	style={{ width: '100%', height: 'auto' }}
																	src={
																		photo
																			? photo
																			: 'https://drive.google.com/uc?id=1Gfp32yqSwYs8T0zwvEdcfEz9lV05ozCB'
																	}
																	alt='Profile Photo'
																/>
															</Media>
															<Media
																body
																style={{
																	paddingLeft: '1rem',
																	color: 'white',
																}}>
																<strong>
																	{employees
																		.filter((employee) => employee.user_id === user_id)
																		.map((employee) => employee.name)}
																</strong>
															</Media>
															<p
																style={{
																	color: '#ededed',
																	fontSize: 'small',
																	paddingRight: '0.5rem',
																}}>
																{date ? date.substring(0, 10) : null}
															</p>
														</Media>
													</CardHeader>
													<CardBody>
														<p style={{ whiteSpace: 'pre-wrap' }}>{comment}</p>
														{this.props.auth.user.id === user_id ? (
															<a href='#'>
																<FontAwesomeIcon
																	style={{
																		color: 'black',
																		fontSize: '1.5rem',
																		position: 'absolute',
																		right: '10px',
																		bottom: '7px',
																	}}
																	onClick={() => {
																		this.deleteComment(id, comment, user_id, date, task._id);
																	}}
																	icon={faTrashAlt}
																/>
															</a>
														) : null}
													</CardBody>
												</Card>
											);
										})}
									<Input
										type='textarea'
										name='comment'
										onChange={this.onChange}
										placeholder='Comment here'
										value={this.state.comment}
									/>
									<Button
										color='secondary'
										size='sm'
										style={{
											float: 'right',
											marginTop: '0.5rem',
											marginBottom: '0.5rem',
										}}
										onClick={this.submitComment.bind(
											this,
											task._id,
											task.user_id,
											task.createdBy_id
										)}>
										Submit
									</Button>
								</CardBody>
							</Collapse>
						</Card>
						{
							// Checking the user permission for Editing the task
							user.permissionLevel === 'Manager' || user.permissionLevel === 'Executive' ? (
								<Button
									color='dark'
									style={{ marginTop: '1rem' }}
									href={`/editTask/${task._id}`}
									block>
									Edit Task
								</Button>
							) : null
						}
					</ModalBody>
				</Modal>
			</Fragment>
		) : null;
	}
}

TaskDetails.propTypes = {
	toggleTaskIndex: PropTypes.func.isRequired,
	toggleReportIndex: PropTypes.func.isRequired,
	commentTask: PropTypes.func.isRequired,
	task: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR, // getting reducer from /reducers/index.js
	employee: state.employeeR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js
	report: state.reportR,
	auth: state.authR,
	company: state.companyR,
});

export default connect(mapStateToProps, {
	toggleTaskIndex,
	toggleReportIndex,
	commentTask,
	deleteComment,
	createNotification,
})(TaskDetails);
