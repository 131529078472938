import React, { Component } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Card,
    CardBody
} from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { resetPassword } from '../../actions/authActions'
import { clearErrors } from '../../actions/errorActions'

class ResetPasswordRequest extends Component {
    state = {
        company_id: '',
        email: '',
        message: null,
        redirect: false,
        messageSuccess: null
    }

    componentDidUpdate(prevProps) {
        const { error, auth } = this.props
        if (error !== prevProps.error) {
            //Check for register error
            if (error.id === 'RESET_FAIL') {
                this.setState({ message: error.message.message })
            } else {
                this.setState({ message: null })
            }
        }
        if (auth.redirect !== prevProps.auth.redirect) {
            if (auth.redirect) {
                this.setState({ messageSuccess: auth.message })
            }
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = e => {
        e.preventDefault();
        //Clear errors
        this.props.clearErrors()
        this.setState({ messageSuccess: null })

        const { email } = this.state

        // create a user object
        const user = {
            email
        }
        // Attemp to Register
        this.props.resetPassword(user)
    }

    render() {
        return (
            <div>
                <Card
                    style={
                        {
                            width: "40%",
                            margin: "auto"
                        }
                    }
                >
                    <CardBody
                        style={
                            {
                                padding: "5%"
                            }
                        }
                    >
                        {this.state.message ? <Alert color="danger">{this.state.message}</Alert> : null}
                        {this.state.messageSuccess ? <Alert color="success">{this.state.messageSuccess}</Alert> : null}
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Label for="email" >Email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Registered Email"
                                    onChange={this.onChange}
                                    required
                                />
                            </FormGroup>
                            <Button
                                color="dark"
                                style={{ marginTop: "2rem" }}
                                block
                            >
                                Reset Password
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

ResetPasswordRequest.propTypes = {
    error: PropTypes.object.isRequired,
    resetPassword: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    error: state.errorR,
    auth: state.authR
})

export default connect(
    mapStateToProps,
    {
        resetPassword,
        clearErrors
    }
)(ResetPasswordRequest);