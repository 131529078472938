import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { uploadProfilePhoto } from '../../actions/employeeActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
class ProfilePhoto extends Component {
	state = {
		modal: false,
		photo: '',
		_id: '',
		display: '',
		message: '',
		file: '',
	};

	componentDidUpdate(prevProps) {
		if (prevProps.employee.employee !== this.props.employee.employee) {
			const { employee } = this.props.employee;
			this.setState({
				_id: employee.map((employee) => employee._id).join(''),
			});
		}
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	addPhoto = (e) => {
		this.setState({
			message: '',
		});
		const file = e.target.files[0];
		if (file) {
			if (file.size > 2097152 || file.type.substr(0, 5) !== 'image') {
				return this.setState({
					message: 'Requires Image File below 2mb',
				});
			}

			this.setState({
				// change file to blob type here
				file: URL.createObjectURL(file),
				photo: file,
			});
		}
	};

	focus = (e) => {
		this.setState({
			dragFocus: true,
		});
	};

	unfocus = (e) => {
		this.setState({
			dragFocus: false,
		});
	};

	onSubmit = (e) => {
		e.preventDefault();
		const object = {
			_id: this.state._id,
		};
		var reader = new FileReader();

		reader.readAsDataURL(this.state.photo);
		reader.onloadend = (e) => {
			object.photo = e.target.result;
			this.props.uploadProfilePhoto(object);
			this.toggle();
		};
	};

	render() {
		return (
			<div>
				<Button color='primary' onClick={this.toggle} style={{ borderRadius: '50%' }}>
					<FontAwesomeIcon color='white' style={{ fontSize: '1em' }} icon={faUpload} />
				</Button>

				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Profile Photo</ModalHeader>

					<ModalBody>
						{this.state.message ? <Alert color='danger'>{this.state.message}</Alert> : null}
						<Form onSubmit={this.onSubmit}>
							<label
								htmlFor='photo'
								onMouseOver={this.focus}
								onMouseOut={this.unfocus}
								onDragEnter={this.focus}
								onDragLeave={this.unfocus}
								className='focusFile'
								style={{
									width: '100%',
									minHeight: '8px',
									height: 'auto',
									display: 'block',
									lineHeight: '8rem',
									border: '1px solid rgba(0,0,0,.125)',
									boxShadow: this.state.dragFocus ? '0 0 0 0.2rem rgba(38,143,255,.5)' : 'none',
									borderRadius: '5px',
									left: 0,
									top: 0,
									textAlign: 'center',
									cursor: 'pointer',
									position: 'relative',
								}}>
								<input
									type='file'
									name='photo'
									id='photo'
									accept='image/*'
									onChange={this.addPhoto}
									style={{
										width: '100%',
										height: '100%',
										opacity: '0',
										position: 'absolute',
										top: 0,
										left: 0,
										overflow: 'hidden',
									}}
								/>

								{this.state.file ? (
									<img style={{ width: '100%', height: 'auto' }} src={this.state.file} />
								) : (
									<FontAwesomeIcon
										color='rgba(0,0,0,.125)'
										style={{
											fontSize: this.state.dragFocus ? '3rem' : '2rem',
											verticalAlign: 'middle',
										}}
										icon={faUpload}
									/>
								)}
							</label>
							{this.state.file}
							<Button color='dark' style={{ marginTop: '2rem' }} block disabled={!this.state.photo}>
								Upload Photo
							</Button>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

ProfilePhoto.propTypes = {
	employee: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	uploadProfilePhoto: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	employee: state.employeeR,
	auth: state.authR,
	company: state.companyR,
});

export default connect(mapStateToProps, { uploadProfilePhoto })(ProfilePhoto);
