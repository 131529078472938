import React, { Component } from 'react';
import { Button, Container, Table, Row, Col, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { getTasks, deleteTask, toggleTaskIndex, filterTasks } from '../../actions/taskActions';
import TaskModal from './taskModal';
import ProjectDetails from '../projectComponents/ProjectDetails';
import TaskDetails from './TaskDetails';
import PropTypes from 'prop-types';
import { getEmployees, getEmployee } from '../../actions/employeeActions';
import { getProjects, toggleProjectIndex } from '../../actions/projectActions';
import { getReports } from '../../actions/reportActions';
import { getCompany } from '../../actions/companyActions';
import { getDetails } from '../../actions/detailActions';
import SortComponent from '../SortComponent';
import { getProposals } from '../../actions/proposalActions';

class TaskList extends Component {
	state = {
		// The Local storage is used to save user filter preferences. The filter will be consistent across renders.
		Overdue: localStorage.getItem('Overdue')
			? JSON.parse(window.localStorage.getItem('Overdue'))
			: false, // fiter related
		EmployeeSelector: localStorage.getItem('EmployeeSelector')
			? localStorage.getItem('EmployeeSelector')
			: '', // fiter related
		departments: localStorage.getItem('departments')
			? JSON.parse(localStorage.getItem('departments'))
			: [], // fiter related
		status: localStorage.getItem('status')
			? JSON.parse(localStorage.getItem('status'))
			: ['In Progress', 'Completed', 'Hold', 'Review', 'Cancelled'], // fiter related
		RangeStart: localStorage.getItem('RangeStart') ? localStorage.getItem('RangeStart') : '', // fiter related
		RangeEnd: localStorage.getItem('RangeEnd') ? localStorage.getItem('RangeEnd') : '', // fiter related
		project: localStorage.getItem('project') ? localStorage.getItem('project') : '', // fiter related
		DepartmentSelector: localStorage.getItem('DepartmentSelector')
			? localStorage.getItem('DepartmentSelector')
			: '',
		resetRangeStart: false, // reset fiter related
		resetRangeEnd: false, // reset fiter related
		resetFilter: false, // reset fiter related
		employees: [],
		filtercolors: [
			'#e1bee7',
			'#64b5f6',
			'#c5e1a5',
			'#f48fb1',
			'#f06292',
			'#ffee58',
			'#ffab91',
			'#80cbc4',
		],
		sortCriteria: localStorage.getItem('sortCriteriaTl')
			? localStorage.getItem('sortCriteriaTl')
			: 'name',
		sortDirection: localStorage.getItem('sortDirectionTl')
			? localStorage.getItem('sortDirectionTl')
			: 1,
	};

	componentDidMount() {
		this.props.getCompany();
		this.props.getTasks();
		this.props.getReports();
		this.props.getEmployee();
		this.props.getEmployees();
		this.props.getProjects();
		this.props.getDetails();
		this.props.getProposals();
	}
	// onDeleteClick function does not used
	onDeleteClick = (id) => {
		const remove = window.confirm('Remove this Task?');
		if (remove) {
			this.props.deleteTask(id);
		}
	};
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.company.company !== this.props.company.company) {
			this.setState({
				departments:
					this.state.departments.length > 0
						? this.state.departments
						: this.props.company.company.departments
								.filter(({ active }) => active)
								.map((e, index) => {
									return {
										name: e.name,
										id: e.id,
										filterState: true,
										color: this.state.filtercolors[index],
										index: index,
									};
								}),
				status: this.state.status[0].name
					? this.state.status
					: this.state.status.map((e, index) => {
							return {
								name: e,
								filterState: true,
								color: '#b3b3b3',
								index: index,
							};
					  }),
				employees: this.props.employee.employees.filter(
					(employee) => employee.permissionLevel !== 'Disabled'
				),
			});
		}
		if (
			prevProps.task.tasks !== this.props.task.tasks ||
			prevState.departments !== this.state.departments ||
			prevState.status !== this.state.status ||
			prevState.EmployeeSelector !== this.state.EmployeeSelector ||
			prevState.project !== this.state.project ||
			prevState.Overdue !== this.state.Overdue ||
			prevState.RangeEnd !== this.state.RangeEnd ||
			prevState.RangeStart !== this.state.RangeStart
		) {
			localStorage.setItem('EmployeeSelector', this.state.EmployeeSelector);
			localStorage.setItem('departments', JSON.stringify(this.state.departments));
			localStorage.setItem('status', JSON.stringify(this.state.status));
			localStorage.setItem('project', this.state.project);
			localStorage.setItem('Overdue', this.state.Overdue);
			localStorage.setItem('RangeEnd', this.state.RangeEnd);
			localStorage.setItem('RangeStart', this.state.RangeStart);

			var filters = {};
			filters.department = this.state.departments.filter((e) => e.filterState).map((e) => e.id);
			filters.status = this.state.status.filter((e) => e.filterState).map((e) => e.name);
			if (this.state.EmployeeSelector) {
				filters.user_id = this.state.EmployeeSelector;
			}
			if (this.state.project) {
				filters.project_id = this.state.project;
			}
			var d = '';
			if (this.state.RangeEnd) {
				d = new Date(this.state.RangeEnd);
				d = new Date(d.setDate(d.getDate() + 1)).toISOString().substr(0, 10);
			}
			if (this.state.Overdue) {
				filters.overdue = this.state.Overdue;
			}
			filters.dateRange = [this.state.RangeStart, d];
			this.props.filterTasks(filters, false);
		}
		if (prevState.DepartmentSelector !== this.state.DepartmentSelector) {
			localStorage.setItem('DepartmentSelector', this.state.DepartmentSelector);
			this.setState({
				employees: this.state.DepartmentSelector
					? this.props.employee.employees.filter(
							({ department }) => department === this.state.DepartmentSelector
					  )
					: this.props.employee.employees.filter(
							(employee) => employee.permissionLevel !== 'Disabled'
					  ),
			});
		}

		// Resetting the task filtering
		if (prevState.resetRangeStart !== this.state.resetRangeStart) {
			this.setState({ RangeStart: '' });
			localStorage.setItem('RangeStart', '');
		}
		if (prevState.resetRangeEnd !== this.state.resetRangeEnd) {
			this.setState({ RangeEnd: '' });
			localStorage.setItem('RangeEnd', '');
		}
		if (prevState.resetFilter !== this.state.resetFilter) {
			this.setState({
				RangeEnd: '',
				RangeStart: '',
				Overdue: false,
				EmployeeSelector: '',
				project: '',
				DepartmentSelector: '',
			});
			var status = ['In Progress', 'Completed', 'Hold', 'Review', 'Cancelled'];
			this.setState({
				status: status.map((e, index) => {
					return {
						name: e,
						filterState: e === 'Cancelled' ? false : true,
						color: '#b3b3b3',
						index: index,
					};
				}),
				departments: this.props.company.company.departments
					.filter(({ active }) => active)
					.map((e, index) => {
						return {
							name: e.name,
							id: e.id,
							filterState: true,
							color: this.state.filtercolors[index],
							index: index,
						};
					}),
			});
			// Removing the user filter preferences from the local storage.
			localStorage.setItem('RangeStart', '');
			localStorage.setItem('RangeEnd', '');
			localStorage.setItem('Overdue', '');
			localStorage.setItem('EmployeeSelector', '');
			localStorage.setItem('departments', '');
			localStorage.setItem('status', '');
			localStorage.setItem('project', '');
			localStorage.setItem('DepartmentSelector', '');
		}

		// Local Storage for sortCriteria and Direction
		if (
			prevState.sortCriteria !== this.state.sortCriteria ||
			prevState.sortDirection !== this.state.sortDirection
		) {
			localStorage.setItem('sortCriteriaTl', this.state.sortCriteria);
			localStorage.setItem('sortDirectionTl', this.state.sortDirection);
		}
	}

	overdueFilter = () => {
		this.setState({
			Overdue: !this.state.Overdue,
		});
	};

	departmentFilter = (i) => {
		this.setState({
			departments: this.state.departments.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};
	statusFilter = (i) => {
		this.setState({
			status: this.state.status.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	sortFunction = (sortCriteria) => {
		this.setState({
			sortCriteria,
			sortDirection: -this.state.sortDirection,
		});
	};

	render() {
		const { tasks, filteredTasks } = this.props.task;
		const { employees } = this.props.employee;
		const { user } = this.props.auth;
		const { projects } = this.props.project;
		var d = new Date();
		d.setHours(0, 0, 0, 0);
		d.setDate(d.getDate() + 1);
		return (
			<Container>
				<ProjectDetails />
				<TaskDetails />
				<TaskModal />
				<Row style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline After</Label>
						<Input
							type='date'
							name='RangeStart'
							onChange={this.onChange}
							value={this.state.RangeStart}
							ref='DateRange'
						/>
					</Col>
					<div
						style={{
							margin: '2rem 0 0 -0.5rem',
							padding: '.3rem .3rem .2rem .3rem',
							border: '.5px solid #D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ resetRangeStart: !this.state.resetRangeStart })}>
						X
					</div>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline Before</Label>
						<Input
							type='date'
							name='RangeEnd'
							onChange={this.onChange}
							value={this.state.RangeEnd}
						/>
					</Col>
					<div
						style={{
							margin: '2rem 1rem 0 -0.5rem',
							padding: '.3rem .3rem .2rem .3rem',
							border: '.5px solid 	#D3D3D3',
							color: 'red',
							borderRadius: '.2rem',
							cursor: 'pointer',
						}}
						onClick={() => this.setState({ resetRangeEnd: !this.state.resetRangeEnd })}>
						X
					</div>
					<Col xs='0.5'>
						<p
							style={{
								color: 'red',
								fontSize: '40px',
								marginTop: '17px',
								marginBottom: '-17px',
								padding: '0px',
								opacity: this.state.Overdue ? '1' : '0.3',
								textAlign: 'center',
								cursor: 'pointer',
							}}
							onClick={this.overdueFilter}>
							!!
						</p>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Project</Label>
						<Input type='select' name='project' onChange={this.onChange} value={this.state.project}>
							<option value=''></option>
							{this.props.project.projects.map((project) => (
								<option key={project._id} value={project._id}>
									{project.name}
								</option>
							))}
						</Input>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>PIC Department</Label>
						<Input
							type='select'
							name='DepartmentSelector'
							onChange={this.onChange}
							value={this.state.DepartmentSelector}>
							<option value=''>Select to Filter PIC</option>
							{this.state.departments
								? this.state.departments.map(({ name, id }) => (
										<option key={id} value={id}>
											{name}
										</option>
								  ))
								: null}
						</Input>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>PIC</Label>
						<Input
							type='select'
							name='EmployeeSelector'
							onChange={this.onChange}
							value={this.state.EmployeeSelector}>
							<option></option>
							{this.state.employees.map(({ _id, name, user_id }) => (
								<option key={_id} value={user_id}>
									{name}
								</option>
							))}
						</Input>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table>
							<tbody>
								<tr>
									{this.state.departments.map(({ name, filterState, color, index }) => (
										<td
											key={name}
											style={{
												opacity: filterState ? '1' : '0.3',
												backgroundColor: color,
												textAlign: 'center',
												cursor: 'pointer',
											}}
											onClick={this.departmentFilter.bind(this, index)}>
											{name}
										</td>
									))}
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table>
							<tbody>
								<tr>
									{this.state.status.map(({ name, filterState, color, index }) => (
										<td
											key={index}
											width='20%'
											style={{
												opacity: filterState ? '1' : '0.3',
												backgroundColor: color,
												textAlign: 'center',
												border: '1px solid #969696',
												cursor: 'pointer',
											}}
											onClick={this.statusFilter.bind(this, index)}>
											{name}
										</td>
									))}
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>
				<a onClick={() => this.setState({ resetFilter: !this.state.resetFilter })} href='#'>
					Reset Filter?
				</a>
				<Table hover>
					<thead
						style={{
							fontSize: '14px',
						}}>
						<tr>
							<th width='12.5%' className='rowAlign'>
								Name
								<SortComponent onClick={() => this.sortFunction('name')} />
							</th>
							<th width='12.5%' className='rowAlign'>
								Project
							</th>
							<th width='10%' className='rowAlign'>
								Department
							</th>
							<th width='10%' className='rowAlign'>
								Created By
							</th>
							<th width='10%' className='rowAlign'>
								PIC
							</th>
							<th width='10%' className='rowAlign'>
								{' '}
								Status
							</th>
							<th width='10%' className='rowAlign'>
								Creation
								<SortComponent onClick={() => this.sortFunction('creationDate')} />
							</th>
							<th width='10%' className='rowAlign'>
								Deadline <SortComponent onClick={() => this.sortFunction('deadline')} />
							</th>
							<th width='10%' className='rowAlign'>
								Pickup/ Completion
							</th>
							<th width='5%' className='rowAlign'>
								Points
							</th>
						</tr>
					</thead>
					<tbody
						style={{
							fontSize: '14px',
						}}>
						{filteredTasks
							.sort((a, b) => {
								if (!b[this.state.sortCriteria]) {
									return -1;
								}
								if (!a[this.state.sortCriteria]) {
									return 1;
								}
								if (a[this.state.sortCriteria] > b[this.state.sortCriteria]) {
									return this.state.sortDirection;
								}
								if (a[this.state.sortCriteria] < b[this.state.sortCriteria]) {
									return -this.state.sortDirection;
								}
								if (a.name > b.name) {
									return 1;
								}
								if (a.name < b.name) {
									return -1;
								}
								return 0;
							})
							.map(
								({
									_id,
									name,
									department,
									user_id,
									status,
									startdate,
									enddate,
									createdBy_id,
									creationDate,
									deadline,
									points,
									project_id,
								}) => {
									const createdBy = employees
										.filter((employee) => employee.user_id === createdBy_id)
										.map((employee) => employee.name);
									var proposalNumber;
									return (
										<tr key={_id}>
											<td className='rowAlign'>
												{tasks.map((task) => {
													if (_id === task._id) {
														if (task.proposal_id) {
															proposalNumber = this.props.proposal.proposals
																.filter(({ _id }) => _id === task.proposal_id)
																.map((proposal) => proposal.proposalNumber);
														}
														return (
															<a
																href='#'
																key={task._id}
																onClick={this.props.toggleTaskIndex.bind(this, _id)}>
																{name}
															</a>
														);
													}
												})}
											</td>
											<td className='rowAlign'>
												<a href='#' onClick={() => this.props.toggleProjectIndex(project_id)}>
													{projects.length > 0
														? projects
																.filter((project) => project._id === project_id)
																.map((project) => {
																	if (project.proposal && proposalNumber) {
																		return proposalNumber;
																	}
																	return project.name;
																})
														: null}
												</a>
											</td>
											<td className='rowAlign'>
												{this.props.company.company.departments
													? this.props.company.company.departments.find(
															({ id }) => id === department
													  ).name
													: ''}
											</td>
											<td className='rowAlign'>{createdBy}</td>
											<td className='rowAlign'>
												{employees
													.filter((employee) => employee.user_id === user_id)
													.map((employee) => (
														<a key={employee._id} href={'/user/' + employee.user_id}>
															{employee.name}
														</a>
													))}
											</td>
											<td className='rowAlign'>{status}</td>
											<td className='rowAlign'>
												{creationDate ? creationDate.substring(0, 10) : '-'}
											</td>
											<td
												className='rowAlign'
												style={{
													color: new Date(deadline) < d && status === 'In Progress' ? 'red' : '',
												}}>
												{deadline ? deadline.substring(0, 10) : '-'}
											</td>
											<td className='rowAlign'>
												{startdate ? startdate.substring(0, 10) : '-'}
												<br />
												{enddate ? enddate.substring(0, 10) : '-'}
											</td>
											<td className='rowAlign'>{points}</td>
											{}
										</tr>
									);
								}
							)}
					</tbody>
				</Table>
			</Container>
		);
	}
}

TaskList.propTypes = {
	getTasks: PropTypes.func.isRequired,
	deleteTask: PropTypes.func.isRequired,
	getProjects: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	getEmployee: PropTypes.func.isRequired,
	filterTasks: PropTypes.func.isRequired,
	toggleTaskIndex: PropTypes.func.isRequired,
	getDetails: PropTypes.func.isRequired,
	getProposals: PropTypes.func.isRequired,
	toggleProjectIndex: PropTypes.func.isRequired,
	task: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	report: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR, // getting reducer from /reducers/index.js
	employee: state.employeeR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js
	report: state.reportR,
	company: state.companyR,
	proposal: state.proposalR,
});

export default connect(mapStateToProps, {
	getTasks,
	deleteTask,
	toggleTaskIndex,
	getEmployees,
	getEmployee,
	getProjects,
	getReports,
	getCompany,
	filterTasks,
	getDetails,
	toggleProjectIndex,
	getProposals,
})(TaskList);
