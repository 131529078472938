import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, Table, Alert } from 'reactstrap'
import { connect } from 'react-redux'
import { toggleReviewIndex, reviewTask } from '../../actions/taskActions'
import { toggleReportIndex } from "../../actions/reportActions";
import PropTypes from 'prop-types';
import ReportDetails from '../reportComponents/ReportDetails';

class SendForReview extends Component {
    state = {
        message: null,
        values: []
    }

    componentDidUpdate(prevProps) {
        const { tasks } = this.props.task
        if (prevProps.task.tasks !== tasks) {
            this.setState({
                values: tasks.map(({ _id, status, checklist }) => {
                    if (status === "In Progress") {
                        return {
                            _id: _id,
                            checklist: checklist.map(() => false)
                        }
                    } else {
                        return ""
                    }
                })
            })
        }
    }

    handleInputChange = (index, j, e) => {
        this.state.values[index].checklist[j] = e.target.checked
    }

    SendForReview = (index) => {
        this.setState({
            message: null
        })
        const { checklist, _id } = this.state.values[index]
        for (var i = 0; i < checklist.length; i++) {
            if (!checklist[i]) {
                return this.setState({
                    message: "Checklist Required"
                })
            }
        }
        var date = new Date() // The value of date will be the current date.
        date = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getDate()}`
        const task = {
            _id: _id,
            status: "Review",
            enddate: date
        }
        this.props.toggleReviewIndex(index, this.props.task.reviewModal)
        this.props.reviewTask(task)
    }

    render() {
        const { tasks } = this.props.task
        const { employees } = this.props.employee
        const Reports = this.props.report.reports
        return (
            <Fragment>
                <ReportDetails />
                {tasks.map(({ _id, name, description, deadline, reports, checklist }, index) => (
                    <Modal
                        key={_id}
                        isOpen={this.props.task.reviewModal[index]}
                        toggle={this.props.toggleReviewIndex.bind(this, index, this.props.task.reviewModal)}
                        className="modal-lg"
                    >
                        <ModalHeader
                            toggle={this.props.toggleReviewIndex.bind(this, index, this.props.task.reviewModal)}
                        >
                            <strong>{name}</strong>
                        </ModalHeader>
                        <ModalBody>
                            <Row xs="2">
                                <Col>
                                    <FormGroup>
                                        <Label>Deadline</Label>
                                        <Input
                                            type="text"
                                            defaultValue={deadline.substring(0, 10)}
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Description</Label>
                                <Input
                                    type="textarea"
                                    defaultValue={description}
                                    disabled
                                />
                            </FormGroup>
                            <Table size="sm">
                                <thead>
                                    <tr>
                                        <th>Report Date</th>
                                        <th>PIC</th>
                                        <th>Details</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Reports.map(({ _id, reportdate, description, user_id }, index) => {
                                        if (reports.includes(_id)) {
                                            return (
                                                <tr
                                                    key={_id}
                                                >
                                                    <td><a href="#" onClick={this.props.toggleReportIndex.bind(this, index, this.props.report.modal)}>{reportdate.substring(0, 10)}</a></td>
                                                    <td>{employees.filter(employee => employee.user_id === user_id).map(employee => employee.name)}</td>
                                                    <td>{description}</td>
                                                </tr>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </tbody>
                            </Table>
                            {checklist.map((checklistitem, j) => (
                                <FormGroup check key={j}>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            onChange={e => this.handleInputChange(index, j, e)}
                                        />
                                        {checklistitem}
                                    </Label>
                                </FormGroup>
                            ))}
                            <br />
                            {this.state.message ? <Alert color="danger">{this.state.message}</Alert> : null}
                            <Button
                                color="dark"
                                onClick={this.SendForReview.bind(this, index)}
                                block
                            >
                                Send For Review
                            </Button>
                        </ModalBody>
                    </Modal>
                ))}
            </Fragment>
        )
    }
}

SendForReview.propTypes = {
    toggleReviewIndex: PropTypes.func.isRequired,
    toggleReportIndex: PropTypes.func.isRequired,
    reviewTask: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
    employee: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    report: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    task: state.taskR, // getting reducer from /reducers/index.js
    employee: state.employeeR, // getting reducer from /reducers/index.js
    project: state.projectR, // getting reducer from /reducers/index.js
    report: state.reportR
})

export default connect(
    mapStateToProps,
    { toggleReviewIndex, toggleReportIndex, reviewTask }
)(SendForReview);