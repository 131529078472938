import { GET_JOBS, CREATE_JOB } from '../actions/types'; 
const initialState = {
    jobs:[]
}
const jobReducer = function(state=initialState,action) {
    switch(action.type){
        case GET_JOBS: 
            return {
                ...state,
                jobs: action.payload
            }
        case CREATE_JOB:
            return {
                ...state,
                jobs: [...state.jobs, action.payload]
            }
        default: return state
    }
}
export default jobReducer