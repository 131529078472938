import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import { createInvoices, getInvoices } from '../actions/paymentActions'

import { Table, Container, Button } from 'reactstrap'

const PaymentScreen = props => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.authR.user);
    const invoices = useSelector((state) => state.paymentR.invoices.items)
    useEffect(() => {
        if (auth.id !== '5eb521dbba35400db0b1274c') return null
        dispatch(getInvoices())
    }, [auth])
    if (auth.id !== '5eb521dbba35400db0b1274c') return null

    const trigger = () => {
        dispatch(createInvoices())
    }

    console.log(invoices)
    return (
        <Container>
            <Button color='dark' onClick={trigger}>
                Trigger Billing
			</Button>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Email</th>
                        <th>Company</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices && invoices.map(({ id, detail, amount, status, primary_recipients }) => {
                        const billing_info = primary_recipients[0].billing_info
                        return (
                            <tr key={id}>
                                <td>{detail.invoice_number}</td>
                                <td>{detail.invoice_date}</td>
                                <td>{billing_info.email_address}</td>
                                <td>{billing_info.business_name}</td>
                                <td>{amount.value}</td>
                                <td>{status}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Container>
    )
}

export default PaymentScreen