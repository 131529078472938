import React, { Fragment, useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    approveExpense,
    updateExpense,
    deleteExpense,
    getExpenses,
    getExpense,
} from '../../actions/expenseTracker';
import { getProjects } from '../../actions/projectActions';
import { getEmployees } from '../../actions/employeeActions';
import ExpenseUpdateModal from './ExpenseUpdateModal';
import ConfirmButton from '../userComponents/ConfirmButton'

const ExpenseList = ({ user }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEmployees());
        dispatch(getProjects());
        dispatch(getExpenses());
    }, []);

    const isEmployee = user.permissionLevel === 'Employee';

    // DATA
    const allEmployeeList = useSelector((state) => state.employeeR.employees);
    const projects = useSelector((state) => state.projectR.projects);
    const allExpenseList = useSelector((state) => state.expenseR.expenses);
    const filteredExpenseList = isEmployee ?
        allExpenseList.filter((exp) => exp.user_id === user.id) :
        allExpenseList;

    // STATE
    const [selectedExpense, setSelectedExpense] = useState(null)
    const [errorMessage, setErrorMessage] = useState('');
    const [approved, setApproved] = useState(undefined);
    const [toggleModalForm, setToggleModalForm] = useState(false);

    // FUNCTION
    useEffect(() => {
        setToggleModalForm(true)
    })

    const isSameObject = (object1, object2) => {

        var string1 = JSON.stringify(object1);
        var string2 = JSON.stringify(object2);

        return string1 === string2
    }
    const getProjectName = (id) =>
        projects.length === 0 ? id :
            projects.filter((p) => p._id === id)[0].name;
    const getEmployeeName = (id) =>
        allEmployeeList.filter((usr) => usr.user_id === id)[0].name;

    const onClickEdit = (expense) => {
        setSelectedExpense(expense);
        onToggleModalForm()
    }

    const onChangeApprove = (expense, bool = undefined) => {
        var updateExpense = { ...expense };
        updateExpense.approval = bool === undefined ?
            !expense.approval : bool

        if (!isSameObject(expense, updateExpense)) {
            dispatch(approveExpense(updateExpense));
            console.log('expense approval updated')
        }
    }

    const expenseAction = (expense) => {
        if (!isEmployee) {
            if (expense.approval !== undefined) {
                return expense.approval ? <strong style={{ color: '#28a745' }}>Approved</strong> : <strong style={{ color: '#949494' }}>Rejected</strong>;
            } else {
                return <span><ConfirmButton onClick={() => onChangeApprove(expense, true)} alertTitle={'Approve Expense Request?'} color="success" >Approve</ConfirmButton><ConfirmButton onClick={() => onChangeApprove(expense, false)} alertTitle={'Reject Expense Request?'} color="danger" >Reject</ConfirmButton></span>
            }
        } else {
            return expense.approval !== undefined ? expense.approval ? <strong style={{ color: '#28a745' }}>Approved</strong> : <strong style={{ color: '#949494' }}>Rejected</strong> : <Button color="secondary " onClick={() => onClickEdit(expense)}>Edit</Button>
        }
    };

    const onToggleModalForm = () => {
        setToggleModalForm(!toggleModalForm);
    };

    return (
        <Fragment>
            { selectedExpense && toggleModalForm ? <ExpenseUpdateModal
                projects={projects}
                user={user}
                propModalFormData={selectedExpense} propToggleModalForm={toggleModalForm} /> : null}
            <Table>
                <thead>
                    <tr>
                        <th>Requested Date</th>
                        <th>Expense Name</th>
                        <th>Description</th>
                        <th>Project</th>
                        <th>Photo</th>
                        <th>Amount</th>
                        <th>Request By</th>
                        <th>Approval</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredExpenseList.map((expense, index) => (
                        <tr key={index}>
                            <td scope="row">{expense.createdAt.substring(0, 10)}</td>
                            <td>{expense.name}</td>
                            <td>{expense.description || "-"}</td>
                            <td>{getProjectName(expense.project_id)}</td>
                            <td><a href={expense.photo || "#"} target="_blank">{expense.photo || "-"}</a></td>
                            <td>{expense.currency} {expense.amount}</td>
                            <td>{getEmployeeName(expense.user_id)}</td>
                            <td>{expenseAction(expense)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Fragment>
    );
};

export default ExpenseList;
