import axios from 'axios';
import {
	GET_REPORTS,
	ADD_REPORT,
	DELETE_REPORT,
	REPORT_LOADING,
	TOGGLE_REPORT_MODAL,
	GET_REPORT,
	UPDATE_REPORT,
	UPDATE_REPORT_FAIL,
	FILTER_REPORT,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import { addReporttoTask } from './taskActions';
import { addReporttoProject } from './projectActions';
import { addReporttoProposal } from './proposalActions';

export const getReports = () => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.get(`/api/reports`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_REPORTS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const addReport = (newReport) => (dispatch, getState) => {
	axios
		.post(`/api/reports`, newReport, tokenConfig(getState))
		.then((res) => {
			if (newReport.project_id === '5f224a84b2fbde22f5fee090') {
				dispatch(addReporttoProposal(newReport.proposal_id, res.data));
			}
			if (newReport.task_id) {
				dispatch(addReporttoTask(newReport.task_id, res.data));
			}
			if (newReport.detail_id) {
				dispatch(addReporttoProject(newReport.project_id, res.data));
			}

			dispatch({
				type: ADD_REPORT,
				payload: res.data,
			});
		})
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const deleteReport = (id) => (dispatch, getState) => {
	axios
		.delete(`/api/reports/${id}`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: DELETE_REPORT,
				payload: id,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const updateReport = (id, report) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.post(`/api/reports/update/${id}`, report, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_REPORT,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_REPORT_FAIL'));
			dispatch({
				type: UPDATE_REPORT_FAIL,
			});
		});
};

export const getReport = (id) => (dispatch, getState) => {
	dispatch(setItemsLoading());
	axios
		.get(`/api/reports/${id}`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_REPORT,
				payload: res.data,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const filterReports = (filters, calendarState) => (dispatch) => {
	dispatch({
		type: FILTER_REPORT,
		payload: {
			filters: filters,
			calendarState: calendarState,
		},
	});
};

export const toggleReportIndex = (_id) => (dispatch) => {
	dispatch({
		type: TOGGLE_REPORT_MODAL,
		payload: _id,
	});
};

export const setItemsLoading = () => {
	return {
		type: REPORT_LOADING,
	};
};
