import {
	GET_INVOICES,
	INVOICE_LOADING,
	CREATE_INVOICES,
} from '../actions/types';

const initialState = {
	invoices: [],
	loading: false,
	message: null,
};

const paymentReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_INVOICES:
			return {
				...state,
				invoices: action.payload,
				loading: false,
			};
		case INVOICE_LOADING:
			return {
				...state,
				loading: true,
			}
		case CREATE_INVOICES:
			return {
				...state,
				loading: false,
				message: "Invoices Created",
			}
		default:
			return state;
	}
};

export default paymentReducer;
