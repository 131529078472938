import React, { Fragment, useEffect, useState } from 'react';
import {
    Form,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    CustomInput,
    Alert,
    Spinner
} from 'reactstrap';
import { getProjects } from '../../actions/projectActions';

import { useDispatch, useSelector } from 'react-redux';
import { createExpense, getExpense } from '../../actions/expenseTracker';
import { readFile, isSameObject } from './ExpenseHelper'

const ExpenseCreateModal = (props) => {
    const dispatch = useDispatch();

    const expenseReqFormModel =
    {
        name: '',
        project_id: '',
        amount: '',
        currency: 'RM',
        description: '',
        photo: '',
        user_id: '',
        file: ''
    };

    // FUNCTION
    useEffect(() => {
        dispatch(getProjects());
    }, []);

    // DATA
    const projects = useSelector((state) => state.projectR.projects);
    const isLoading = useSelector((state) => state.expenseR.loading);
    const createdExpense = useSelector((state) => state.expenseR.expense);
    const message = useSelector((state) => state.expenseR.message);
    const errorMessageR = useSelector(state => state.errorR.message.message)
    const user = props.user;
    const currency = ['RM', '$'];
    // TODO(shelomoh): add more currency

    // STATES
    // const [loading, setLoading] = useState(false);
    const [toggleCreateModalForm, setToggleCreateModalForm] = useState(false);
    const [modalFormData, setModalFormData] = useState(expenseReqFormModel);

    const { file } = modalFormData;

    const [errorMessage, setErrorMessage] = useState('');

    const toggleModalForm = () => {
        setToggleCreateModalForm(!toggleCreateModalForm);
    };

    const onUploadPhoto = (e) => {
        var file = e.target.files[0];
        if (file) {
            if (file.size > 2097152 || file.type.substr(0, 5) !== 'image') {
                return setErrorMessage(
                    'Requires Image File below 2mb'
                );
            }
            setModalFormData({
                ...modalFormData,
                photo: URL.createObjectURL(file),
                file: file,
            });
        }
    };


    const onChangeModalForm = (e) => setModalFormData({
        ...modalFormData,
        [e.target.name]: e.target.value,
    });


    const resetModal = () => {
        setModalFormData(expenseReqFormModel);
        toggleModalForm();

        console.log('reset Modal');
    };

    const onSubmitModalForm = async (e) => {
        e.preventDefault();
        if (!isSameObject(expenseReqFormModel, modalFormData)
        ) {
            const newExpense = { ...modalFormData, user_id: user.id };
            ;
            if (file) {
                newExpense.photo = await readFile(file);
            }
            dispatch(createExpense(newExpense));
        }
        resetModal()
    };

    return (
        <Fragment>
            <Button
                color="dark"
                style={{ marginBottom: '2rem' }}
                onClick={toggleModalForm}
                disabled={isLoading}
            >
                Create New Expense Request
        </Button>
            <span>
                {isLoading && <Spinner animation="border" />}
                {message && <Alert color='success'>{message}</Alert>}
                {errorMessageR && <Alert color='danger'>{errorMessageR}</Alert>}
            </span>
            <Modal isOpen={toggleCreateModalForm} toggle={toggleModalForm} centered>
                <Form onSubmit={onSubmitModalForm}>

                    <ModalHeader toggle={toggleModalForm}>
                        Create Expense Request
          </ModalHeader>

                    <ModalBody>
                        {/* NAME */}
                        <FormGroup>
                            <Label for="name">Expense Name</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Enter name"
                                onChange={onChangeModalForm}
                                required
                            />
                        </FormGroup>

                        {/* PROJECT */}
                        <FormGroup>
                            <Label for="project_id">Project</Label>
                            <Input
                                type="select"
                                name="project_id"
                                id="project_id"
                                value={modalFormData.project_id}
                                onChange={onChangeModalForm}
                                required
                            >
                                <option value="">Select Project</option>
                                {projects.map((p, index) => (
                                    <option key={p._id} value={p._id}>{p.name}</option>
                                ))}
                            </Input>
                        </FormGroup>

                        {/* AMOUNT */}
                        <FormGroup>
                            <Label for="amount"> Amount</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <Input
                                        type="select"
                                        name="currency"
                                        id="currency"
                                        value={modalFormData.currency}
                                        onChange={onChangeModalForm}
                                        required
                                    >
                                        {currency.map((c) => (
                                            <option key={c}>{c}</option>
                                        ))}
                                    </Input>
                                </InputGroupAddon>
                                <Input
                                    name="amount"
                                    id="amount"
                                    placeholder="Amount"
                                    min={0}
                                    type="number"
                                    value={modalFormData.amount}
                                    onChange={onChangeModalForm}
                                />
                            </InputGroup>
                        </FormGroup>

                        {/* DESCRIPTION */}
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                placeholder="Description"
                                value={modalFormData.description}
                                onChange={onChangeModalForm}
                            />
                        </FormGroup>

                        {/* UPLOAD PHOTO */}
                        <FormGroup>
                            <Label for="exampleCustomFileBrowser">Receipt Photo</Label>
                            <CustomInput
                                type="file"
                                name="photo"
                                id="photo"
                                accept="image/*"
                                htmlFor="photo"
                                onChange={onUploadPhoto}
                            />
                            {errorMessage ? (
                                <Alert style={{ textAlign: 'center' }} color="warning">
                                    {errorMessage}
                                </Alert>
                            ) : null}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type='submit'> Request Expense</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment >
    );
};

export default ExpenseCreateModal;
