import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input,Row,Col, FormGroup,Button } from 'reactstrap'
import { toggleClientModal } from '../../actions/clientAction';

export class ClientDetails extends Component {
    
    render() {
        const { client } = this.props.client
        return (
           <Modal isOpen={this.props.client.detailsModal} toggle={this.props.toggleClientModal.bind(this,"detailsModal")}>
               <ModalHeader toggle={this.props.toggleClientModal.bind(this,"detailsModal")}>{client.name}</ModalHeader>
               <ModalBody>
                   <Row>
                        <Col>
                            <FormGroup>
                                <Label>PIC</Label>
                                <Input disabled value={client.PIC_name} /> 
                            </FormGroup>
                        </Col>
                       <Col>
                        <FormGroup>
                            <Label>Contact Number</Label>
                            <Input disabled value={client.contactNo}/>
                        </FormGroup>
                       </Col>
                   </Row>
                    <Row>
                        <Col>
                        <FormGroup>
                            <Label>Adderess</Label>
                            <Input disabled value={client.adderess} />
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input disabled value={client.email} />
                        </FormGroup> 
                        </Col>
                    </Row>
               </ModalBody>
               <ModalFooter>
                   <Button href={`/updateClient/${client._id}`} block>Update Client</Button>
               </ModalFooter>
           </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
  client: state.clientR  
})



export default connect(mapStateToProps,{ toggleClientModal })(ClientDetails)
