import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Row, Col } from 'reactstrap';
import { getCompanyTimeInputs } from '../../actions/companyActions'

function DetailsTable(props) {
    const [headerIndex, toggleHeader] = useState(0) // To toggle the header the value will be 1 or 0 
    const [details, setDetails] = useState([])

    const reduxDetails = useSelector(state => state.detailR.details.filter(({ proposal_id }) => proposal_id === props.proposal_id))
    const intTimeInputs = useSelector(state => state.companyR.intTimeInputs)
    const extCostInputs = useSelector(state => state.companyR.extTimeInputs)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCompanyTimeInputs())
        setDetails([...reduxDetails])
    }, [])

    if (details.length) {
        return (
            <Fragment>
                <Row style={{ paddingTop: "20px" }}>
                    <Col xs="3" style={{ textAlign: "center" }}>Task Details</Col>
                    <Col xs="7" style={{ textAlign: "center" }}>
                        <span style={{ position: "relative", left: "50px" }}>
                            {headerIndex === 0 ? "Time Inputs" : "Other Inputs"}
                        </span>
                    </Col>
                    <Col xs="2">
                        <Button color="primary" style={{ marginBottom: "20px", marginLeft: "30px" }}
                            onClick={() => toggleHeader(curr => curr === 0 ? 1 : 0)}>
                            Toggle
                        </Button>
                    </Col>
                </Row>
                <Table>
                    <thead>
                        <tr>
                            <th>Stage</th>
                            <th>Description</th>
                            {
                                headerIndex === 0 ?
                                    intTimeInputs.map(e => <th key={e.id}>{e.name}</th>)
                                    : extCostInputs.map(e => <th key={e.id}>{e.name}</th>)

                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            details.map(detail => {
                                return <tr key={detail._id}>
                                    <td>{detail.stageNumber}</td>
                                    <td>{detail.description}</td>
                                    {
                                        headerIndex === 0 ?
                                            intTimeInputs.map(e => {
                                                var value = ''
                                                detail.intTimeInputs.map(x => Object.keys(x)[0] === e.id ?
                                                    value = Object.values(x)[0] : ''
                                                )
                                                return <td key={e.id}>{value}</td>
                                            }) :
                                            extCostInputs.map(e => {
                                                var value = ''
                                                detail.extCostInputs.map(x => Object.keys(x)[0] === e.id ?
                                                    value = Object.values(x)[0] : ''
                                                )
                                                return <td key={e.id}>{value}</td>
                                            })
                                    }
                                </tr>
                            })
                        }

                    </tbody>
                </Table>
            </Fragment>
        )
    } else {
        return null
    }

}

export default DetailsTable
