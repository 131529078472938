import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getProposals, toggleProposalModal, getProposalInModal } from '../../actions/proposalActions'
import { getClients } from '../../actions/clientAction';
import { Table, Button } from 'reactstrap'
import ProposalDetails from './ProposalDetails'
import SendProposalForReview from './SendProposalForReview';

function UserProposal() {
    const dispatch = useDispatch()
    const userId = useSelector(state => state.authR.user.id)
    const proposals = useSelector(state => state.proposalR.proposals.filter(({ PIC_id, status }) => PIC_id === userId && status !== "Completed"))
    const clients = useSelector(state => state.clientR.clients)

    useEffect(() => {
        dispatch(getProposals())
        dispatch(getClients())
    }, [])
    const displayProposalModal = proposal_id => {
        dispatch(getProposalInModal(proposal_id))
        dispatch(toggleProposalModal(''))
    }
    const toggleReviewModal = proposal_id => {
        dispatch(getProposalInModal(proposal_id))
        dispatch(toggleProposalModal('reviewModal'))
    }

    return (
        <Fragment>
            < ProposalDetails />
            < SendProposalForReview />
            <h5 style={{ marginTop: "0.5rem" }}><center><b>My Proposals</b></center></h5>
            <Table>
                <thead>
                    <tr>
                        <th width="15%">ProposalNumber</th>
                        <th width="30%">Client</th>
                        <th width="30%">Description</th>
                        <th width="15%">Revision Number</th>
                        <th width="10"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        proposals.length > 0 ? proposals.map(proposal => {
                            return <tr key={proposal._id}>
                                <td>
                                    <a href="#" onClick={() => displayProposalModal(proposal._id)}>{proposal.proposalNumber}</a>
                                </td>
                                <td>{clients.filter(({ _id }) => _id === proposal.client_id).map(e => e.name)}</td>
                                <td>{proposal.description}</td>
                                <td style={{ backgroundColor: "", display: 'flex', justifyContent: 'center' }}>
                                    <span >{proposal.revisionNumber}</span>
                                </td>
                                <td>
                                    {
                                        proposal.status === "In Progress" ?
                                            <Button
                                                color="primary"
                                                size="sm"
                                                onClick={() => toggleReviewModal(proposal._id)}>
                                                Review
                                        </Button> :
                                            proposal.status
                                    }

                                </td>
                            </tr>
                        }) : <Fragment><td>N/A</td><td>N/A</td><td>N/A</td><td>N/A</td><td>N/A</td></Fragment>
                    }
                </tbody>
            </Table>
        </Fragment>
    )
}

export default UserProposal
