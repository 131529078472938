import React from 'react';

import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	Row,
	Col,
	FormGroup,
	Label,
	Input,
	CustomInput,
	Alert,
} from 'reactstrap';
import { remainingLeaveCal } from './HelperFunctions';

const LeaveModal = ({
	modalForm,
	toggle,
	onSubmit,
	messageSuccess,
	msg,
	chosenDates,
	message,
	reduxEmployees,
	changeCheckbox,
	onChangeModal,
	pictureChange,
	selectedDates,
	auth,
	type,
	reason,
}) => {
	let currentUser = reduxEmployees.find((employee) => employee.user_id === auth.id);
	const annualLeaves = currentUser && currentUser.annualLeaves;
	const takenLeaves = currentUser && currentUser.takenLeaves;
	const remaining = remainingLeaveCal(annualLeaves, takenLeaves);

	return (
		<div>
			<Modal isOpen={modalForm} toggle={toggle}>
				<ModalHeader toggle={toggle}>New Leave Request Form</ModalHeader>
				<ModalBody>
					<Form onSubmit={onSubmit}>
						<Col>
							{messageSuccess ? (
								<Alert color='success' style={{ textAlign: 'center' }}>
									{messageSuccess}
								</Alert>
							) : null}
						</Col>
						<Col>
							{chosenDates.length < 1 && msg ? <Alert color='danger'> {msg} </Alert> : null}
						</Col>
						<Col>
							{message ? (
								<Alert style={{ textAlign: 'center' }} color='warning'>
									{message}
								</Alert>
							) : null}
						</Col>
						<Row>
							<Col xs='6'>
								<FormGroup>
									<Label>Chosen Dates</Label>
									{chosenDates.length ? (
										chosenDates.map((date, index) => {
											return (
												<p
													key={index}
													style={{
														backgroundColor: '#D3D3D3',
														margin: '3px',
														padding: '5px',
														textAlign: 'center',
														borderRadius: '3px',
													}}>
													{date}
												</p>
											);
										})
									) : (
										<Alert color='warning'>Please select date first.</Alert>
									)}
								</FormGroup>
							</Col>
						</Row>

						<Row>
							<Col xs='6'>
								<FormGroup>
									<Label>Leave Allocation</Label>
									<Input
										type='text'
										name='annualLeaves'
										id='annualLeaves'
										value={annualLeaves}
										disabled></Input>
								</FormGroup>
							</Col>
							<Col xs='6'>
								<FormGroup>
									<Label>Leave Remaining</Label>
									<Input type='text' name='remaining' id='remain' value={remaining} disabled />
								</FormGroup>
							</Col>
						</Row>

						<Row>
							<Col xs='6'>
								<FormGroup>
									<Label>Selected Days</Label>
									<Input
										type='text'
										name='selectedDays'
										id='remain'
										value={selectedDates}
										disabled
									/>
								</FormGroup>
							</Col>
							<Col xs='6' style={{ marginTop: '1.7rem' }}>
								<FormGroup check>
									<Label check>
										<Input
											type='checkbox'
											name='checkbox'
											value={chosenDates}
											onChange={changeCheckbox}
										/>
										Half Day
									</Label>
								</FormGroup>
							</Col>
						</Row>
						<FormGroup>
							<Label>Leave Type</Label>
							<Input
								type='select'
								name='type'
								id='type'
								value={type}
								onChange={(e) => onChangeModal(e)}>
								<option></option>
								<option>Annual</option>
								<option>Unpaid</option>
								<option>Advance</option>
								<option>Emergency</option>
								<option>Medical</option>
								<option>Replacement</option>
								<option>Other</option>
							</Input>
						</FormGroup>
						<FormGroup>
							<Label for='exampleCustomFileBrowser'>Upload Image</Label>
							<CustomInput
								type='file'
								name='photo'
								accept='image/*'
								htmlFor='photo'
								onChange={(e) => pictureChange(e)}
								id='photo'
							/>
						</FormGroup>
						<FormGroup>
							<Label for='ProjectDescription'>Reason</Label>
							<Input
								type='textarea'
								name='reason'
								id='reason'
								rows='4'
								placeholder='Add Reason'
								value={reason}
								onChange={(e) => onChangeModal(e)}
								required
							/>
						</FormGroup>
						<Button color='dark' style={{ marginTop: '2rem' }} block>
							Request Leave
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default LeaveModal;
