import { GET_INVOICE_NUMBER, CREATE_INVOICE_NUMBER, GET_INTERNAL_INVOICES, UPDATE_INTERNAL_INVOICE } from '../actions/types'

const initialState = {
    invoices: [],
    invoiceNumber: {}
}
const invoiceNumberReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_INVOICE_NUMBER:
            return {
                ...state,
                invoiceNumber: action.payload
            }
        case CREATE_INVOICE_NUMBER:
            return {
                ...state,
                invoiceNumber: action.payload
            }
        case GET_INTERNAL_INVOICES:
            return {
                ...state,
                invoices: action.payload
            }
        case UPDATE_INTERNAL_INVOICE:
            return {
                ...state,
                invoices: state.invoices.map(e => {
                    if (e._id === action.payload._id) {
                        return action.payload
                    } else {
                        return e
                    }
                }),
                successMessage: 'Invoice Updated'
            }
        default: return {
            ...state,
            successMessage: ''
        }
    }
}
export default invoiceNumberReducer;
