import React, { Component, Fragment } from 'react';
import {
	Row,
	Col,
	Table,
	Input,
	Container,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import { getTasks, toggleTaskIndex, toggleReviewIndex } from '../../actions/taskActions';
import {
	getProjects,
	toggleProjectIndex,
	assignProject,
	unAssignProject,
} from '../../actions/projectActions';
import { getReports } from '../../actions/reportActions';
import { getEmployee, getEmployees, uploadProfilePhoto } from '../../actions/employeeActions';
import { getCompany } from '../../actions/companyActions';
import PropTypes from 'prop-types';
import calenedarCalculator from '../../utils/calenedarCalculator';
import ProjectDetails from './ProjectDetails';
import ConfirmButton from '../userComponents/ConfirmButton';
import DateRangeModal from './DateRangeModal';
// Note: The user_id props is coming from UserPage Component.and some of the ternary operator in the component used that to
//  show different UI and functionality. As the project calendar in UserPage component is only for employee without any project assignment functionality.
class UserCalendar extends Component {
	months = [
		'Jan',
		'Feb',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	date = new Date();
	state = {
		dates: calenedarCalculator(
			new Date(`1 ${this.months[this.date.getMonth()]} ${this.date.getFullYear()}`)
		), //  The whole months dates in an array form.
		weeks: [
			...Array(
				calenedarCalculator(
					new Date(`1 ${this.months[this.date.getMonth()]} ${this.date.getFullYear()}`)
				).length / 7
			).keys(),
		], // The number of weeks in th month.
		month: this.date.getMonth(),
		year: this.date.getFullYear(),
		currentDate:
			this.date.getDate() + '/' + (this.date.getMonth() + 1) + '/' + this.date.getFullYear(),
		tableRows: [false, false, false, false, false, false], // It is used for hiding weeks in calendar.
		employee_userID: null, // To get employee user id from the the input box.
		project_ID: null, // To get project id from the input box.
		choosenDates: [], // It stores the selected dates for project assignment.
		unAssignMode: false, // To unassign projects.
		unAssignProjectsInfo: [], // It stores the information regarding selected projet for unassigment like project_id and date. It will be used to change the selected project background color.
		displayModal: false, // To controls the visibility of project modal selection.
		dateRangeModal: false, // control the visibility of the date Range modal
		errorMessages: [], //To display error messages when assigning date range
	};

	componentDidMount() {
		if (!this.props.user_id) {
			this.props.getEmployees();
			this.props.getProjects();
		}
	}
	toggleModal = () => {
		// It controls the modal visibility.
		this.setState({
			displayModal: !this.state.displayModal,
			unAssignMode: false,
			unAssignProjectsInfo: [],
		});
	};
	onChangeDate = ({ target }) => {
		// It stores the month and year input to the corresponding state property and display calendar based on that.
		var month = '',
			year = ''; // THe input will be stored here temporary
		target.name === 'month' ? (month = target.value) : (year = target.value);
		this.setState({ [target.name]: Number(target.value) });
		if (month) {
			this.setState({
				dates: calenedarCalculator(new Date(`1 ${this.months[month]} ${this.state.year}`)),
				weeks: [
					...Array(
						calenedarCalculator(new Date(`1 ${this.months[month]} ${this.state.year}`)).length / 7
					).keys(),
				],
			});
		} else if (year) {
			this.setState({
				dates: calenedarCalculator(new Date(`1 ${this.months[this.state.month]} ${year}`)),
				weeks: [
					...Array(
						calenedarCalculator(new Date(`1 ${this.months[this.state.month]} ${year}`)).length / 7
					).keys(),
				],
			});
		}
	};

	changeToPrevMonth = () => {
		// It changed the dates in the calendar to previous month.
		if (this.state.month > 0) {
			// If the month is not January it just switch to previous month.
			this.setState({
				month: this.state.month - 1,
				dates: calenedarCalculator(
					new Date(`1 ${this.months[this.state.month - 1]} ${this.state.year}`)
				),
				weeks: [
					...Array(
						calenedarCalculator(
							new Date(`1 ${this.months[this.state.month - 1]} ${this.state.year}`)
						).length / 7
					).keys(),
				],
			});
		} else {
			this.setState({
				// If the month is January so we switch the date last year December.
				month: 11,
				year: this.state.year - 1,
				dates: calenedarCalculator(new Date(`1 ${this.months[11]} ${this.state.year - 1}`)),
				weeks: [
					...Array(
						calenedarCalculator(new Date(`1 ${this.months[11]} ${this.state.year - 1}`)).length / 7
					).keys(),
				],
			});
		}
	};
	changeToNextMonth = () => {
		// Switching the date in the calendar to next month.
		if (this.state.month > 10) {
			// If the month is December it switches the date to next year January.
			this.setState({
				month: 0, //
				year: this.state.year + 1, // To switch to next year.
				dates: calenedarCalculator(new Date(`1 ${this.months[0]} ${this.state.year + 1}`)),
				weeks: [
					...Array(
						calenedarCalculator(new Date(`1 ${this.months[0]} ${this.state.year + 1}`)).length / 7
					).keys(),
				],
			});
		} else {
			// If the month is not December then we will just change to next month.
			this.setState({
				month: this.state.month + 1,
				dates: calenedarCalculator(
					new Date(`1 ${this.months[this.state.month + 1]} ${this.state.year}`)
				),
				weeks: [
					...Array(
						calenedarCalculator(
							new Date(`1 ${this.months[this.state.month + 1]} ${this.state.year}`)
						).length / 7
					).keys(),
				],
			});
		}
	};
	changeToCurrentMonth = () => {
		this.setState({
			dates: calenedarCalculator(
				new Date(`1 ${this.months[this.date.getMonth()]} ${this.date.getFullYear()}`)
			), //  The whole months dates in an array form.
			weeks: [
				...Array(
					calenedarCalculator(
						new Date(`1 ${this.months[this.date.getMonth()]} ${this.date.getFullYear()}`)
					).length / 7
				).keys(),
			], // The number of weeks in th month.
			month: this.date.getMonth(),
			year: this.date.getFullYear(),
		});
	};
	onChangeInput = ({ target }) => {
		// it stores the selected user and project information to the corresponding state property.
		this.setState({
			[target.name]: target.value,
		});
	};
	// chooseDate is used for project assignment functionlity.
	chooseDate = (date) => {
		// This function pushes the selected date or it will delete the date from the choosenDates array.
		if (this.state.choosenDates.includes(date)) {
			// If the date is there then the use want to deleted it from the choosenDates array.
			this.setState({
				choosenDates: this.state.choosenDates.filter((e) => e !== date),
			});
		} else {
			// If the date is not in array then we push the selected date to the choosenDates to array.
			this.setState({
				choosenDates: [...this.state.choosenDates, date],
			});
		}
	};
	hideWeek = (index) => {
		// It controls the visibility of a week in the calendar.
		var tableRows = this.state.tableRows;
		tableRows[index] = !this.state.tableRows[index];
		this.setState({ tableRows: tableRows });
	};
	unAssignProjectsMode = () => {
		// This enables the unAssign project functionlity.
		this.setState({
			unAssignMode: !this.state.unAssignMode,
			choosenDates: [], // In unassign project mode the choosen dates array will be empty.
			unAssignProjectsInfo: [],
		});
	};
	unAssignProjectsColor = (projectDetails) => {
		// THe selected projects will be pushed to the state to change their background color.
		if (this.state.unAssignProjectsInfo.includes(projectDetails)) {
			// If the projects is already selected then we exclude in second click from the user.
			this.setState({
				unAssignProjectsInfo: this.state.unAssignProjectsInfo.filter((e) => e !== projectDetails),
			});
		} else {
			this.setState({
				unAssignProjectsInfo: [...this.state.unAssignProjectsInfo, projectDetails],
			});
		}
	};
	unAssignProjects = () => {
		this.props.projects.forEach((x) => {
			// The filter is used to group the same projects that are selected by the user for project unassign.
			if (this.state.unAssignProjectsInfo.filter((e) => e._id === x._id).length) {
				this.props.unAssignProject(
					x._id,
					this.state.unAssignProjectsInfo.filter((e) => e._id === x._id)
				);
			}
		});

		this.setState({ unAssignProjectsInfo: [] });
	};
	assignProject = (dates) => {
		this.setState({ unAssignMode: false, unAssignProjectsInfo: [], errorMessages: [] }); // The unassign project mode will be disable with all it's functionality.
		const selectedDates = dates.length > 0 ? dates : this.state.choosenDates;
		var errorArray = new Array();
		if (!this.state.employee_userID) {
			errorArray.push('Select Employee Calendar');
		}
		if (!this.state.project_ID) {
			errorArray.push('Project is Required');
		}
		if (selectedDates.length < 1) {
			errorArray.push('Select Dates');
		}
		if (errorArray.length > 0) {
			return this.setState({
				errorMessages: errorArray,
			});
		}
		if (this.state.project_ID && this.state.employee_userID && selectedDates.length) {
			const body = [];
			selectedDates.forEach((e) => {
				var object = {};
				object.user_id = this.state.employee_userID;
				object.date = e;
				body.push(object);
			});
			this.props.assignProject(this.state.project_ID, body);
			this.setState({
				choosenDates: [],
				displayModal: false,
				dateRangeModal: false,
				project_ID: '',
				startdate: '',
				enddate: '',
			});
		} else {
			alert('Information are not complete');
		}
	};

	setDateRange = () => {
		this.setState({
			errorMessages: [],
		});
		var choosenDates = new Array();
		var currentDate = new Date(this.state.startdate);
		const enddate = new Date(this.state.enddate);
		while (currentDate <= enddate) {
			const date = `${currentDate.getDate()}/${
				currentDate.getMonth() + 1
			}/${currentDate.getFullYear()}`;
			choosenDates.push(date);
			currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
		}
		var errorArray = new Array();
		if (!this.state.employee_userID) {
			errorArray.push('Select Employee Calendar');
		}
		if (!this.state.project_ID) {
			errorArray.push('Project is Required');
		}
		if (!this.state.startdate) {
			errorArray.push('Start Date is Required');
		}
		if (!this.state.enddate) {
			errorArray.push('End Date is Required');
		}
		if (errorArray.length > 0) {
			return this.setState({
				errorMessages: errorArray,
			});
		}
		this.assignProject(choosenDates);
	};

	toggleDateRange = () => {
		this.setState({
			dateRangeModal: !this.state.dateRangeModal,
		});
	};
	render() {
		const { employees } = this.props.employee;
		const projects = this.props.projects;
		const user_id = this.props.user_id ? this.props.user_id : this.state.employee_userID;
		const errors = this.state.errorMessages.map((errorMessage) => (
			<Alert key={errorMessage} color='danger'>
				{errorMessage}
			</Alert>
		));
		return (
			<Fragment>
				<DateRangeModal
					dateRangeModal={this.state.dateRangeModal}
					toggleDateRange={() => this.toggleDateRange()}
					project_ID={this.state.project_ID}
					onChange={this.onChangeInput}
					projects={projects}
					startdate={this.state.startdate}
					enddate={this.state.enddate}
					setDateRange={() => this.setDateRange()}
					errorMessages={this.state.errorMessages}
				/>
				<ProjectDetails />
				<Container>
					<Row>
						<Col xs={this.props.user_id ? '3' : '2'}>
							<Input
								type='select'
								name='month'
								value={this.state.month}
								onChange={this.onChangeDate}>
								<option value={0}>January</option>
								<option value={1}>February</option>
								<option value={2}>March</option>
								<option value={3}>April</option>
								<option value={4}>May</option>
								<option value={5}>June</option>
								<option value={6}>July</option>
								<option value={7}>August</option>
								<option value={8}>September</option>
								<option value={9}>October</option>
								<option value={10}>November</option>
								<option value={11}>December</option>
							</Input>
						</Col>
						<Col xs={this.props.user_id ? '3' : '2'}>
							<Input type='select' name='year' value={this.state.year} onChange={this.onChangeDate}>
								<option>2019</option>
								<option>2020</option>
								<option>2021</option>
								<option>2022</option>
								<option>2023</option>
							</Input>
						</Col>
						<Col xs='1'>
							<p onClick={this.changeToPrevMonth} style={{ marginRight: '0px', cursor: 'pointer' }}>
								&#10094;
							</p>
						</Col>
						<Col xs='1'>
							<p onClick={this.changeToNextMonth} style={{ marginLeft: '0px', cursor: 'pointer' }}>
								&#10095;
							</p>
						</Col>
						<Col
							xs='1'
							style={{
								backgroundColor: 'green',
								borderRadius: '1rem',
								textAlign: 'center',
								cursor: 'pointer',
							}}
							onClick={this.changeToCurrentMonth}>
							<span style={{ position: 'relative', top: '0.5rem', color: '#FFFFFF' }}>Today</span>
						</Col>
						{this.props.user_id ? null : (
							<Fragment>
								<Col xs='3'>
									<Input
										type='select'
										name='employee_userID'
										value={this.state.employee_userID}
										onChange={this.onChangeInput}>
										<option>Select Employee</option>
										{employees.map((e, i) => (
											<option key={i} value={e.user_id}>
												{e.name}
											</option>
										))}
									</Input>
								</Col>
								{this.state.unAssignMode ? ( // The remove button will be visible if the the user select any project to unassign.
									<Col xs='2'>
										<ConfirmButton
											color='danger'
											alertTitle={'Remove this Project?'}
											onClick={this.unAssignProjects}>
											Remove
										</ConfirmButton>
									</Col>
								) : null}
							</Fragment>
						)}
					</Row>
					<br />
					<Table>
						<thead>
							<tr>
								{['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
									(day, i) => (
										<th
											style={{
												border: '1px solid black',
												width: '14.3%',
												padding: '10px',
												textAlign: 'center',
											}}
											key={i}>
											{day}
										</th>
									)
								)}
							</tr>
						</thead>
						<tbody>
							{this.state.weeks.map((index) => {
								return (
									<tr
										key={index}
										style={{
											margin: '0px',
											textAlign: 'center',
											border: '1.5px solid black',
											height: this.state.tableRows[index] ? '0px' : '3rem',
										}}>
										{this.state.dates.slice(index * 7, (index + 1) * 7).map((date, index) => {
											// display of a week of the month
											var assignedEmployees = [];
											projects.map((e) => {
												e.assigned_employees.map((x) => {
													// The array of assigend projects to employees
													if (x.date === date && x.user_id === user_id) {
														x.projectName = e.name; // To include the name of project.
														x._id = e._id; // To include the id of the project for rendering the project modal.
														assignedEmployees.push(x); // It is used to show the project assigned to the user.
													}
												});
												// console.log(e.assigned_employees);
											});

											var dateLabel = (
												<p
													onClick={
														this.props.user_id ? this.hideWeek.bind(this, index, date) : null
													}
													style={{
														margin: '-0.8rem',
														textAlign: 'center',
														color: 'white',
														cursor: this.props.user_id ? 'pointer' : 'default',
														backgroundColor: date === this.state.currentDate ? 'green' : '#757575',
													}}>
													{date}
												</p>
											);
											return (
												<td
													key={index}
													style={{
														border: '1px solid black',
														cursor: this.props.user_id ? 'default' : 'pointer',
														backgroundColor: this.state.unAssignMode
															? null
															: this.state.choosenDates.includes(date)
															? '#385231'
															: 'white',
													}}
													onClick={
														this.props.user_id
															? null
															: this.state.unAssignMode
															? null
															: this.chooseDate.bind(this, date)
													} // Choose date function store dates to change thier background color.
												>
													{dateLabel}
													{this.state.tableRows[index] ? null : (
														<span style={{ height: '0.7rem', display: 'block' }}></span>
													)}
													{assignedEmployees.length // It checks if the there are any projects.
														? assignedEmployees.map((e, i) => {
																// The project Modal works only from UserPage Component.
																return (
																	<a
																		key={i}
																		href={this.props.user_id ? '#' : null}
																		onClick={
																			this.props.user_id
																				? this.props.toggleProjectIndex.bind(this, e._id)
																				: null
																		}>
																		<p
																			hidden={this.state.tableRows[index]}
																			onClick={
																				this.state.unAssignMode
																					? this.unAssignProjectsColor.bind(this, e)
																					: null
																			}
																			style={{
																				marginTop: '0.3rem',
																				fontSize: '0.8rem',
																				marginBottom: '-0.2rem',
																				marginLeft: '-0.7rem',
																				marginRight: '-0.7rem',
																				cursor: 'pointer',
																				backgroundColor: this.state.unAssignProjectsInfo.filter(
																					(i) =>
																						i.user_id === e.user_id &&
																						i.date === e.date &&
																						i._id === e._id
																				).length
																					? 'red'
																					: '#c2becf',
																				borderRadius: '8px',
																			}}>
																			{e.projectName}
																		</p>
																	</a>
																);
														  })
														: ''}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</Table>

					{this.props.user_id ? null : (
						<div style={{ display: 'flex', justifyContent: 'space-around' }}>
							<Button color='danger' onClick={this.unAssignProjectsMode}>
								Unassign Projects
							</Button>
							<Button color='primary' onClick={this.toggleDateRange}>
								Assign Date Range
							</Button>
							<Button color='primary' onClick={this.toggleModal}>
								Assign Project
							</Button>
						</div>
					)}
					<br />
					<br />
					<Modal isOpen={this.state.displayModal} toggle={this.toggleModal}>
						<ModalHeader toggle={this.toggleModal}>Select Project</ModalHeader>
						<ModalBody>
							{errors}
							<Input
								type='select'
								name='project_ID'
								value={this.state.project_ID}
								onChange={this.onChangeInput}>
								<option>Select Here</option>
								{projects.map((e, i) => (
									<option key={i} value={e._id}>
										{e.name}
									</option>
								))}
							</Input>
							<br />
						</ModalBody>
						<ModalFooter>
							<Button color='primary' onClick={this.assignProject}>
								Submit
							</Button>
						</ModalFooter>
					</Modal>
				</Container>
			</Fragment>
		);
	}
}

UserCalendar.propTypes = {
	getTasks: PropTypes.func.isRequired,
	getEmployee: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	getReports: PropTypes.func.isRequired,
	getEmployees: PropTypes.func.isRequired,
	getProjects: PropTypes.func.isRequired,
	uploadProfilePhoto: PropTypes.func.isRequired,
	employee: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	task: PropTypes.object.isRequired,
	user_id: PropTypes.string.isRequired,
	toggleTaskIndex: PropTypes.func.isRequired,
	toggleReviewIndex: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR,
	report: state.reportR,
	employee: state.employeeR,
	auth: state.authR,
	company: state.companyR,
	projects: state.projectR.projects.filter((e) => e.status === 'In Progress'), // Only In Progress Projects will be taken
});

export default connect(mapStateToProps, {
	toggleTaskIndex,
	toggleReviewIndex,
	getTasks,
	getReports,
	getEmployee,
	getEmployees,
	getCompany,
	getProjects,
	uploadProfilePhoto,
	toggleProjectIndex,
	assignProject,
	unAssignProject,
})(UserCalendar);
