import axios from 'axios';
import { returnErrors } from "./errorActions";
import { tokenConfig } from './authActions'
import { GET_CLIENTS, GET_CLIENT, GET_CLIENT_IN_MODAL, DELETE_CLIENT, CREATE_CLIENT, UPDATE_CLIENT, TOGGLE_CLIENT_MODAL } from './types';

export const createClient = newClient => (dispatch, getState) => {
    axios.post
        (`/api/clients`, newClient, tokenConfig(getState))
        .then(res => dispatch({
            type: CREATE_CLIENT,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const getClients = () => (dispatch, getState) => {
    axios.get
        ('/api/clients/', tokenConfig(getState))
        .then(res => dispatch({
            type: GET_CLIENTS,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const getClient = id => (dispatch, getState) => {
    axios.get
        (`/api/clients/${id}`, tokenConfig(getState))
        .then(res => dispatch({
            type: GET_CLIENT,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}

export const updateClient = (updatedClient, id) => (dispatch, getState) => {
    axios.post
        (`/api/clients/update/${id}`, updatedClient, tokenConfig(getState))
        .then(res => dispatch({
            type: UPDATE_CLIENT,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const deleteClient = (id) => (dispatch, getState) => {
    axios.delete
        (`/api/clients/${id}`, tokenConfig(getState))
        .then(() => dispatch({
            type: DELETE_CLIENT,
            payload: id
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const getClientInModal = (id) => dispatch => {
    dispatch({
        type: GET_CLIENT_IN_MODAL,
        payload: id
    })
}
export const toggleClientModal = (input) => dispatch => {
    dispatch({
        type: TOGGLE_CLIENT_MODAL,
        payload: input
    })
}