import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
	Container,
	Card,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
	CardSubtitle,
	Button,
	Col,
	Row,
	Input,
	Form,
	FormGroup,
	Label,
	Alert,
	Modal,
	ModalHeader,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { createReward } from '../../actions/rewardActions';

export const RewardShop = (props) => {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [price, setPrice] = useState('');
	const [availableNumbers, setAvailableNumbers] = useState('');
	const [photo, setPhoto] = useState('');
	const [file, setFile] = useState('');
	const [message, setMessage] = useState('');
	const [dragFocus, setDragFocus] = useState(false);

	const dispatch = useDispatch();

	const onChange = ({ target }) => {
		const file = target.files[0];
		if (file) {
			if (file.size > 2097152 || file.type.substr(0, 5) !== 'image') {
				return setMessage('Requires Image File below 2mb');
			}
			setPhoto(URL.createObjectURL(file));
			setFile(file);
		}
	};
	const onSubmit = (e) => {
		e.preventDefault();
		const newReward = {
			name: name,
			description: description,
			price: price,
			availableNumbers: availableNumbers,
		};
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = (e) => {
			newReward.photo = e.target.result;
			dispatch(createReward(newReward));
			props.toggleModal(false);
		};
	};
	return (
		<Modal isOpen={props.showModal} toggle={() => props.toggleModal(!props.showModal)}>
			<Card>
				<Form onSubmit={onSubmit}>
					<CardBody>
						{message ? (
							<Alert color='danger' style={{ textAlign: 'center' }}>
								{message}
							</Alert>
						) : null}
						<ModalHeader style={{ padding: '0px' }} toggle={() => props.toggleModal(false)}>
							Reward Photo
						</ModalHeader>
						<Label
							htmlFor='photo'
							onMouseOver={() => setDragFocus(true)}
							onMouseOut={() => setDragFocus(false)}
							onDragEnter={() => setDragFocus(true)}
							onDragLeave={() => setDragFocus(false)}
							className='focusFile'
							style={{
								width: '100%',
								minHeight: '8px',
								maxHeight: '50%',
								height: 'auto',
								display: 'block',
								lineHeight: '8rem',
								border: '1px solid rgba(0,0,0,.125)',
								boxShadow: dragFocus ? '0 0 0 0.2rem rgba(38,143,255,.5)' : 'none',
								borderRadius: '5px',
								left: 0,
								top: 0,
								textAlign: 'center',
								cursor: 'pointer',
								position: 'relative',
							}}>
							<Input
								id='photo'
								type='file'
								name='photo'
								accept='image/*'
								onChange={onChange}
								style={{
									width: '100%',
									height: '100%',
									opacity: '0',
									position: 'absolute',
									top: 0,
									left: 0,
									overflow: 'hidden',
								}}
								required
							/>
							{photo ? (
								<CardImg top width='100%' src={photo} alt='Card image cap' />
							) : (
									<FontAwesomeIcon
										color='rgba(0,0,0,.125)'
										style={{
											fontSize: dragFocus ? '3rem' : '2rem',
											verticalAlign: 'middle',
										}}
										icon={faUpload}
									/>
								)}
						</Label>
						<CardTitle>
							<FormGroup>
								<Label>Name The Reward</Label>
								<Input
									maxLength='25'
									type='text'
									placeholder='Keep it short'
									value={name}
									onChange={({ target }) => setName(target.value)}
									required
								/>
							</FormGroup>
						</CardTitle>
						<FormGroup>
							<Label>Description</Label>
							<Input
								type='textarea'
								maxLength='28'
								placeholder='28 character max'
								value={description}
								onChange={({ target }) => setDescription(target.value)}
								required
							/>
						</FormGroup>
						<FormGroup>
							<Label>Add Gold Price</Label>
							<Input
								type='number'
								placeholder='Price'
								value={price}
								onChange={({ target }) => setPrice(Number(target.value))}
								required
							/>
						</FormGroup>
						<FormGroup>
							<Label>Available Numbers</Label>
							<Input
								type='number'
								placeholder='what is the reward limit'
								value={availableNumbers}
								onChange={({ target }) => setAvailableNumbers(Number(target.value))}
								required
							/>
						</FormGroup>
						<Button color='primary' type='submit' block>
							Create Reward
						</Button>
					</CardBody>
				</Form>
			</Card>
		</Modal>
	);
};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
	createReward,
})(RewardShop);
