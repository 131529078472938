import {
    GET_DETAILS, CREATE_DETAIL, UPDATE_DETAIL, DELETE_DETAIL, DELETE_DETAILS,
    GET_SPECIFIC_DETAILS, REDUCE_INPUT_BOX_SIZE, EXPAND_INPUT_BOX_SIZE, STORE_DETAIL_IN_REVISION
}
    from './types'
import axios from 'axios';
import { returnErrors } from "./errorActions";
import { tokenConfig } from './authActions';
import { updateDetailsOrder } from './proposalActions'

export const createDetail = (newDetail, body) => (dispatch, getState) => {
    axios.post
        (`/api/details`, newDetail, tokenConfig(getState))
        .then(res => {
            body.detailsOrder = [...body.detailsOrder, res.data._id]
            dispatch(updateDetailsOrder(body))
            dispatch({
                type: CREATE_DETAIL,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const getDetails = () => (dispatch, getState) => {
    axios.get
        (`/api/details`, tokenConfig(getState))
        .then(res => dispatch({
            type: GET_DETAILS,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const getSpecificDetails = proposal_id => (dispatch, getState) => {
    axios.get
        (`/api/details/specificDetails/${proposal_id}`, tokenConfig(getState))
        .then(res => dispatch({
            type: GET_SPECIFIC_DETAILS,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const deleteDetail = id => (dispatch, getState) => {
    axios.delete
        (`/api/details/${id}`, tokenConfig(getState))
        .then(() => dispatch({
            type: DELETE_DETAIL,
            payload: id
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const deleteDetails = proposal_id => (dispatch, getState) => {
    axios.delete
        (`/api/details/delete/${proposal_id}`, tokenConfig(getState))
        .then(() => dispatch({
            type: DELETE_DETAILS,
            payload: proposal_id
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const updateDetail = (updatedDetail, id) => (dispatch, getState) => {
    axios.post
        (`/api/details/update/${id}`, updatedDetail, tokenConfig(getState))
        .then(res => dispatch({
            type: UPDATE_DETAIL,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const storeDetailInRevision = (id, detail) => (dispatch, getState) => {
    axios.post
        (`/api/details/updateDetail/${id}`, detail, tokenConfig(getState))
        .then(res => dispatch({
            type: STORE_DETAIL_IN_REVISION,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status))
        })
}
export const reduceInputBoxSize = () => dispatch => {
    dispatch({
        type: REDUCE_INPUT_BOX_SIZE,
    })
}
export const expandInputBoxSize = index => dispatch => {
    dispatch({
        type: EXPAND_INPUT_BOX_SIZE,
        payload: index
    })
}