import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addReport } from '../../actions/reportActions';
import { addReporttoTask } from '../../actions/taskActions';
import { getDetails } from '../../actions/detailActions';
import { getProposals } from '../../actions/proposalActions';
import { getCompany } from '../../actions/companyActions';

class AddReportModal extends Component {
	state = {

	}
	componentDidMount() {
		this.props.getDetails();
		this.props.getProposals();
		this.props.getCompany();
	}
	onChange = (e) => {
		if (e.target.name === "task_id") {
			const { tasks } = this.props.task;
			const proposal_id = tasks.find(({ _id }) => _id === e.target.value).proposal_id
			return this.setState({
				[e.target.name]: e.target.value,
				proposal_id
			});
		}
		if (e.target.name === "project_id") {
			this.setState({
				[e.target.name]: e.target.value,
				task_id: "",
				detail_id: "",
				stage: "",
				proposal_id: "",
			});
		} else {
			this.setState({ [e.target.name]: e.target.value });
		}
	};

	onSubmit = (e) => {
		e.preventDefault();

		var newReport = {
			reportdate: this.props.currentDate ? this.props.currentDate : this.state.reportdate,
			numberOfHours: Number(this.state.numberOfHours),
			project_id: this.state.project_id,
			description: this.state.description,
			user_id: this.props.auth.user.id,
		};
		if (this.state.task_id) {
			newReport.task_id = this.state.task_id
		} else {
			newReport.detail_id = this.state.detail_id
		}
		if (this.state.overdueReason) {
			newReport.overdueReason = this.state.overdueReason;
		}
		if (this.state.project_id === '5f224a84b2fbde22f5fee090') {
			newReport.proposal_id = this.state.proposal_id
			newReport.department = this.state.department

			if (this.state.name && this.state.name !== '') {
				newReport.name = this.state.name
			}
		}
		// Add task via addReport action
		this.props.addReport(newReport);
		//close modal
		this.setState({
			reportdate: '',
			description: '',
			user_id: '',
			task_id: '',
			project_id: '',
			numberOfHours: '',
			overdueReason: '',
			detail_id: '',
		});
		this.props.toggle();
	};

	render() {
		const { projects } = this.props.project;
		const { tasks } = this.props.task;
		const { id } = this.props.auth.user;
		var d = new Date();
		d.setHours(0, 0, 0, 0);
		d.setDate(d.getDate());
		const overdue = (
			<Fragment>
				<FormGroup>
					<Label>Overdue Reason</Label>
					<Input
						type='textarea'
						name='overdueReason'
						defaultValue={this.state.overdueReason}
						placeholder='Reason for Delay?'
						onChange={this.onChange}
						required
					/>
				</FormGroup>
			</Fragment>
		);
		const proposal_id = projects.filter(project => project._id === this.state.project_id).map(({ proposal_id }) => proposal_id)[0]
		return (
			<Fragment>
				<Modal isOpen={this.props.modal} toggle={this.props.toggle}>
					<ModalHeader toggle={this.props.toggle}>Create New Report</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label>Report Date</Label>
								{this.props.currentDate ?
									<Input
										type='date'
										name='reportdate'
										onChange={this.onChange}
										value={this.props.currentDate}
										required
										disabled
									/> :
									<Input
										type='date'
										name='reportdate'
										defaultValue={this.state.reportdate}
										onChange={this.onChange}
										required
									/>}
							</FormGroup>
							<FormGroup>
								<Label>Project</Label>
								<Input type='select' name='project_id' defaultValue={this.state.project_id} onChange={this.onChange} required>
									<option value=''>Select Project</option>
									{projects.sort((a, b) => {
										if (a.name > b.name) { return 1 }
										if (a.name < b.name) { return -1 }
										return 0
									}).map(({ _id, name, status }) =>
										status === 'In Progress' ? (
											<option key={_id} value={_id}>
												{name}
											</option>
										) : (
											''
										)
									)}
								</Input>
							</FormGroup>
							{/* check if project_id is proposal id */}
							{
								this.state.project_id === '5f224a84b2fbde22f5fee090' ?
									<>
										<FormGroup>
											<Label>Proposal Number</Label>
											<Input
												type='select'
												name='proposal_id'
												defaultValue={this.state.proposal_id}
												onChange={this.onChange}
												required>
												<option value=''>Select Proposal Number</option>
												{this.props.proposal.proposals
													.filter(({ status }) => {
														// filtering proposal by particulars status
														const statusFilterList = ['Completed', 'Canceled']
														return !statusFilterList.includes(status)
													})
													.sort(function (a, b) {
														if (
															Number(a.proposalNumber.substring(2, 4)) <
															Number(b.proposalNumber.substring(2, 4))
														) {
															return -1;
														}
														if (
															Number(a.proposalNumber.substring(2, 4)) >
															Number(b.proposalNumber.substring(2, 4))
														) {
															return 1;
														}
														return 0;
													})
													.sort(function (a, b) {
														if (
															Number(a.proposalNumber.substring(4, 6)) <
															Number(b.proposalNumber.substring(4, 6))
														) {
															return -1;
														}
														if (
															Number(a.proposalNumber.substring(4, 6)) >
															Number(b.proposalNumber.substring(4, 6))
														) {
															return 1;
														}
														return 0;
													})
													.map(({ _id, proposalNumber, description }) =>
														<option key={_id} value={_id}>
															{`${proposalNumber} - ${description}`}
														</option>
													)}
											</Input>
										</FormGroup>
										<FormGroup>
											<Label for='TaskName'>Name</Label>
											<Input
												type='select'
												name='name'
												id='TaskName'
												placeholder='Add Task Name'
												defaultValue={this.state.name}
												onChange={this.onChange}
												required>
												<option></option>
												{this.props.company.company.proposalTasks?.map((proposalTask) => (
													<option key={proposalTask.id}>{proposalTask.name}</option>
												))}
											</Input>
										</FormGroup>
										<FormGroup>
											<Label for='Department'>Department</Label>
											<Input
												type='select'
												name='department'
												id='Department'
												defaultValue={this.state.department}
												onChange={this.onChange}
												required>
												<option value=''>Select Department</option>
												{this.props.company.company.departments
													? this.props.company.company.departments.map((department, index) => (
														<option key={department.id} value={department.id}>
															{department.name}
														</option>
													))
													: null}
											</Input>
										</FormGroup>
									</> :
									proposal_id ?
										<div>
											<FormGroup>
												<Label>Stage</Label>
												<Input type='number' name='stage' defaultValue={this.state.stage} onChange={this.onChange} required />
											</FormGroup>
											<FormGroup>
												<Label>Proposal Item</Label>
												<Input type='select' name='detail_id' defaultValue={this.state.detail_id} onChange={this.onChange} required>
													<option value=''>Select Description</option>
													{this.props.detail.details.filter(detail => detail.proposal_id === proposal_id).map(({ _id, description, stageNumber }) => {
														if (this.state.stage) {
															if (this.state.stage == stageNumber) {
																return <option key={_id} value={_id}>
																	{description}
																</option>
															}
														} else {
															return <option key={_id} value={_id}>
																{description}
															</option>
														}
													})}
												</Input>
											</FormGroup>
										</div> :
										<FormGroup>
											<Label>Task</Label>
											<Input type='select' name='task_id' defaultValue={this.state.task_id} onChange={this.onChange} required>
												<option value=''>Select Task</option>
												{this.state && this.state.project_id ? tasks
													.filter((task) => task.project_id === this.state.project_id)
													.map(({ user_id, _id, name, status }) => {
														if (status === 'In Progress' && id === user_id) {
															return (
																<option key={_id} value={_id}>
																	{name}
																</option>
															);
														} else {
															return null;
														}
													}) : null}
											</Input>
										</FormGroup>
							}
							<FormGroup>
								<Label>Description</Label>
								<Input
									id='descriptionBox'
									type='textarea'
									name='description'
									defaultValue={this.state.description}
									placeholder='What did you do today?'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label>Number Of Hours</Label>
								<Input
									type='number'
									step={0.5}
									name='numberOfHours'
									defaultValue={this.state.numberOfHours}
									placeholder='How many hours you worked?'
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							{this.state && this.state.task_id ? new Date(
								tasks
									.filter((task) => task._id === this.state.task_id)
									.map((task) => {
										return task.deadline;
									})
							) < d
								? overdue
								: null : null}
							<Button color='dark' style={{ marginTop: '2rem' }} block>
								Add Report
							</Button>
						</Form>
					</ModalBody>
				</Modal>
			</Fragment>
		);
	}
}

AddReportModal.propTypes = {
	getCompany: PropTypes.func.isRequired,
	addReport: PropTypes.func.isRequired,
	addReporttoTask: PropTypes.func.isRequired,
	getProposals: PropTypes.func.isRequired,
	getDetails: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	task: state.taskR, // getting reducer from /reducers/index.js
	project: state.projectR, // getting reducer from /reducers/index.js,
	auth: state.authR,
	detail: state.detailR,
	company: state.companyR,
	proposal: state.proposalR,
});

export default connect(mapStateToProps, {
	addReport, addReporttoTask, getDetails, getProposals,
	getCompany,
})(AddReportModal);
