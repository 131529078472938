import React, { Fragment, useState, useEffect, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Table, } from 'reactstrap'
import { updatePayBreakDown } from '../../actions/stageActions'
import { createInvoiceNumber } from '../../actions/invoiceActions';
import { PDFExport } from '@progress/kendo-react-pdf';
// The props are coming from Proposal Summary Component.
const styles = {
    image: {
        display: "flex", justifyContent: 'center', width: "100%"
    },
    firstTable: {
        display: "flex", flexDirection: "row"
    },
    clientAdderress: {
        paddingRight: "50px",
        paddingTop: "30px"
    },
    totalBox: {
        display: "flex", flexDirection: "row",
        position: "relative", left: "60%",
        height: "180px",
    },
    textFeilds: {
        border: "1px solid lightgray",
        width: "10%",
        fontSize: 12,
        height: "100%"
    },
    amountsFeilds: {
        border: "1px solid lightgray",
        width: "20%",
        height: "100%"
    },
    secondTableCell: {
        borderRight: "1px solid lightgray", textAlign: "center"
    },
    subTotal: {
        borderBottom: "1px solid lightgray",
        textAlign: "center",
        paddingTop: "10px",
        height: 50
    },
    sst: {
        borderBottom: "1px solid lightgray",
        textAlign: "center",
        height: 50
    },
    sstAmount: {
        borderBottom: "1px solid lightgray", height: 65,
        textAlign: 'center', paddingTop: "10px",
    },
    totalAmount: {
        padding: "10px", height: 65, textAlign: 'center',
    },
    companyAdderressDiv: {
        width: "100%",
        height: "130px",
        display: "flex",
        alignItems: "flex-end"
    },
    companyAdderress: {
        marginLeft: "30%", marginRight: "30%",
        marginBottom: "0px"
    }

}
const MyDocument = props => {
    // The data coming from redux.
    const company = useSelector(state => state.companyR.company)
    const client = useSelector(state => state.clientR.client)
    const proposal = useSelector(state => state.proposalR.proposal)
    const invoiceNumber = useSelector(state => state.invoiceR.invoiceNumber)
    // Calculating Date
    const date = new Date()
    const currentDate = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`
    // Calaculating the payble amount (excl sst)
    const amount = ((props.stage.payBreakdown[props.stepIndex].percentage / 100) * props.stage.fees).toFixed(2)
    // The code for generating PDF
    var pdfDocument;
    const generatePdf = () => {
        pdfDocument.save()
    }

    return (
        <Container>
            <PDFExport
                ref={component => pdfDocument = component}
                fileName={`Invoice Number${invoiceNumber.invoiceNumber}`}
                author="Qworky"
                paperSize='A4'
                scale={0.7}
                margin="0.7cm"
                avoidLinks={true}
            >
                <div style={{ fontSize: 13 }}>
                    <div style={{ ...styles.image }}>
                        <img
                            width="600px" height="100px"
                            // src="https://drive.google.com/uc?id=15NYl2ySarH_CKDbV-L56W4y3PgaquzNd" 
                            src={require("../../images/jtk.png")}
                            alt="image"
                        />
                    </div>
                    <h3><center>Invoice</center></h3>
                    {/* The first table */}
                    <div style={{ ...styles.firstTable }}>
                        <Table style={{ width: "60%" }}>
                            <tbody style={{ borderLeft: "1px solid lightgray" }}>
                                <tr>
                                    <td>To : {client.name}</td>
                                </tr>
                                <tr style={{ height: "176px", borderBottom: "1px solid lightgray" }}>
                                    <td
                                        style={{ ...styles.clientAdderress }}
                                    >
                                        {client.adderress}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table style={{ width: "40%" }}>
                            <tbody style={{ border: "1px solid lightgray" }}>
                                <tr>
                                    <td width="50%" style={{ borderRight: "1px solid lightgray" }}>Invoice No:</td>
                                    <td width="50%">{invoiceNumber.invoiceNumber}</td>
                                </tr>
                                <tr>
                                    <td style={{ borderRight: "1px solid lightgray" }}>Proposal No:</td>
                                    <td>{proposal.proposalNumber}</td>
                                </tr>
                                <tr>
                                    <td style={{ borderRight: "1px solid lightgray" }}>Date:</td>
                                    <td>{currentDate}</td>
                                </tr>
                                <tr>
                                    <td style={{ borderRight: "1px solid lightgray" }}>Pages:</td>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <td style={{ borderRight: "1px solid lightgray" }}>Attention:</td>
                                    <td>{client.PIC}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    {/* The second table */}
                    <Table style={{ marginBottom: "0px" }}>
                        <tbody style={{ border: "1px solid lightgray" }}>
                            <tr>
                                <td width="40%" style={{ borderRight: "1px solid lightgray" }}>Description</td>
                                <td width="20%" style={{ ...styles.secondTableCell }}>Amount (EXCL.SST)</td>
                                <td width="10%" style={{ ...styles.secondTableCell }} >SST</td>
                                <td width="20%" style={{ ...styles.secondTableCell }}>Total(INCL.SST)</td>
                                <td width="10%">Tax Code</td>
                            </tr>
                            <tr style={{ height: '100px' }}>
                                <td style={{ borderRight: "1px solid lightgray" }}>{proposal.description}</td>
                                <td style={{ borderRight: "1px solid lightgray" }}></td>
                                <td style={{ borderRight: "1px solid lightgray" }}></td>
                                <td style={{ borderRight: "1px solid lightgray" }}></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style={{ borderRight: "1px solid lightgray" }}>{props.stage.stage}</td>
                                <td style={{ borderRight: "1px solid lightgray" }}></td>
                                <td style={{ borderRight: "1px solid lightgray" }}></td>
                                <td style={{ borderRight: "1px solid lightgray" }}></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style={{ borderRight: "1px solid lightgray" }}>{props.stage.payBreakdown[props.stepIndex].step}</td>
                                <td style={{ ...styles.secondTableCell }}>{amount}</td>
                                <td style={{ ...styles.secondTableCell }}>{(amount * 0.06).toFixed(2)}</td>
                                <td style={{ ...styles.secondTableCell }}>{(amount * 1.06).toFixed(2)}</td>
                                <td style={{ textAlign: "center" }}>S06</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div
                        style={{ ...styles.totalBox }}
                    >
                        <div
                            style={{ ...styles.textFeilds }}
                        >
                            <p style={{ ...styles.subTotal }}>SUB TOTAL</p>
                            <p style={{ ...styles.sst }}>SST @ 6%</p>
                            <p style={{ textAlign: "center", height: 80 }}>GRAND TOTAL</p>
                        </div>
                        <div
                            style={{ ...styles.amountsFeilds }}
                        >
                            <p style={{ ...styles.subTotal, marginBottom: 0 }}>RM {amount}</p>
                            <p style={{ ...styles.sstAmount }}>RM {(amount * 0.06).toFixed(2)}</p>
                            <p style={{ ...styles.totalAmount }}>RM {(amount * 1.06).toFixed(2)}</p>
                        </div>
                    </div>

                    <div style={{ fontSize: 13, width: "40%" }}>
                        <p style={{ marginBottom: "0px" }}>Term of Payment : 7 days</p>
                        <p style={{ marginBottom: "0px" }}>
                            Term of Payment : 7 days
                            the right to impose an interest of 1.5% per month on overdue account.
                            All cheque(s) to be crossed and made payable to JTK Consult Sdn Bhd.
                        </p>
                        <p style={{ marginBottom: "0px" }}>Bank Details: CIMB Bank Berhad</p>
                        <p style={{ marginBottom: "0px" }}>
                            Branch : Taman Tun Dr Ismail, Kuala Lumpur
                            Account No: 8000 670 252
                        </p>
                        <p> Account No: 8000 670 252</p>
                    </div>
                    <div
                        style={{ ...styles.companyAdderressDiv }}
                    >
                        <p style={{ ...styles.companyAdderress }}>
                            {
                                company.adderess
                            }
                        </p>

                    </div>
                    <br></br>
                </div>

            </PDFExport>
            <Button
                block
                onClick={generatePdf}
                color="primary"
            >
                Download
            </Button>
        </Container >
    )
};
export default MyDocument