import React, { Component } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleEditModal, executiveUpdateEmployee } from '../../actions/employeeActions';
import ConfirmButton from '../userComponents/ConfirmButton';

class UpdateStaff extends Component {
	state = {
		_id: '',
		user_id: '',
		name: '',
		department: '',
		email: '',
		phonenumber: '',
		permissionLevel: '',
		annualLeaves: 0,
		takenLeaves: 0,
		leaveHistory: [],
		leaveTrackerClicked: false,
		alreadyAdded: false,
	};

	componentDidUpdate(prevProps) {
		const { employee } = this.props.employee;
		if (prevProps.employee.employee !== employee) {
			this.setState({
				_id: employee._id,
				user_id: employee.user_id,
				name: employee.name,
				department: employee.department,
				email: employee.email,
				phonenumber: employee.phonenumber,
				permissionLevel: employee.permissionLevel,
				annualLeaves: employee.annualLeaves,
				takenLeaves: employee.takenLeaves,
				leaveHistory: employee.leaveHistory,
				leaveTrackerClicked: false,
				alreadyAdded: false,
			});
		}
	}

	onLeaveAddSubmit = (e) => {
		e.preventDefault();
		this.props.executiveUpdateEmployee(this.state);
		this.setState({
			leaveTrackerClicked: true,
			alreadyAdded: true,
		});
	};

	addLeaveTrackerHistory = (e, annualLeaves, takenLeaves) => {
		e.preventDefault();

		const currentYear = new Date().getFullYear();
		const leaveObj = this.state.leaveHistory;
		const alreadyExist =
			leaveObj === undefined
				? []
				: leaveObj.length
				? leaveObj.filter((leave) => leave['year'] === currentYear)
				: [];

		// check for current year obj already exist
		if (leaveObj === undefined || !leaveObj.length) {
			// add object here if the leave tracker is empty array
			this.setState({
				leaveHistory: [
					{
						year: currentYear,
						annualLeaves: annualLeaves ? annualLeaves : 0,
						takenLeaves: takenLeaves ? takenLeaves : 0,
					},
				],
				leaveTrackerClicked: true,
				alreadyAdded: false,
			});
		}
		if (leaveObj !== undefined && leaveObj.length && !alreadyExist.length) {
			// this is for every new year
			this.setState({
				leaveHistory: [
					...leaveObj,
					{
						year: currentYear,
						annualLeaves: annualLeaves ? annualLeaves : 0,
						takenLeaves: 0,
					},
				],
				leaveTrackerClicked: true,
				alreadyAdded: false,
			});
		}
	};

	resetLeaves = () => {
		const obj = { ...this.state };
		obj.annualLeaves = this.state.annualLeaves ? this.state.annualLeaves : 0; // check later for updating the leave.
		obj.takenLeaves = 0;
		this.props.executiveUpdateEmployee(obj);
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onChangeLeave = (e) => {
		// const currentYear = new Date().getFullYear();
		// if (leaveHistoryArr !== undefined && leaveHistoryArr.length) {
		// 	let leaveData = [...leaveHistoryArr];
		// 	let curLeave = leaveData.find((leave) => leave['year'] === currentYear);

		// 	curLeave.annualLeaves = Number(e.target.value);
		// 	curLeave.takenLeaves = Number(this.state.takenLeaves);
		// 	this.setState({
		// 		leaveHistory: leaveData,
		// 	});
		// }
		this.setState({ [e.target.name]: Number(e.target.value) });
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.props.executiveUpdateEmployee(this.state);
	};

	render() {
		const { employee } = this.props.employee;
		const annualLeaves = this.state.annualLeaves;
		const takenLeaves = this.state.takenLeaves;
		var d = new Date();
		var month = d.getMonth();
		var date = d.getDate();
		const currentYear = new Date().getFullYear();
		const leaveObj = this.state.leaveHistory;

		// a check for - before leaveHistory added users
		const currentYearData =
			leaveObj === undefined
				? []
				: leaveObj.length
				? leaveObj.filter((leave) => leave['year'] === currentYear)
				: [];
		const trackerIndexNum = leaveObj === undefined ? 0 : leaveObj.length ? leaveObj.length - 1 : 0; // this is to get current year's index number in the leave tracker array
		// used for showing info text
		const clicked = this.state.leaveTrackerClicked;
		const added = this.state.alreadyAdded;

		return (
			<Modal
				isOpen={this.props.employee.modal}
				toggle={this.props.toggleEditModal.bind(this, employee.user_id)}>
				<ModalHeader toggle={this.props.toggleEditModal.bind(this, employee.user_id)}>
					Update Staff
				</ModalHeader>
				<ModalBody>
					{this.props.employee.message ? (
						<Alert color='success'>{this.props.employee.message}</Alert>
					) : null}
					<Form onSubmit={this.onSubmit}>
						<FormGroup>
							<Label>Name</Label>
							<Input
								type='text'
								name='name'
								placeholder='Add Employee Name'
								onChange={this.onChange}
								defaultValue={this.state.name}
								required
							/>
						</FormGroup>
						<FormGroup>
							<Label>Department</Label>
							<Input
								type='select'
								name='department'
								onChange={this.onChange}
								defaultValue={this.state.department}
								required>
								<option></option>
								{this.props.company.company.departments
									? this.props.company.company.departments.map((department) => (
											<option key={department.id} value={department.id}>
												{department.name}
											</option>
									  ))
									: null}
							</Input>
						</FormGroup>
						<FormGroup>
							<Label>Email</Label>
							<Input
								type='email'
								name='email'
								onChange={this.onChange}
								defaultValue={this.state.email}
								required
							/>
						</FormGroup>
						<FormGroup>
							<Label>Phone</Label>
							<Input
								type='text'
								name='phonenumber'
								onChange={this.onChange}
								defaultValue={this.state.phonenumber}
								required
							/>
						</FormGroup>
						<FormGroup>
							<Label>Permission</Label>
							<Input
								type='select'
								name='permissionLevel'
								onChange={this.onChange}
								defaultValue={this.state.permissionLevel}
								required>
								<option>Disabled</option>
								<option>Employee</option>
								<option>Manager</option>
								{this.props.auth.user.permissionLevel === 'Executive' ? (
									<option>Secretary</option>
								) : null}
								{this.props.auth.user.permissionLevel === 'Executive' ? (
									<option>Executive</option>
								) : null}
							</Input>
						</FormGroup>
						<FormGroup>
							<Label>Annual Leaves</Label>
							<Input
								type='number'
								name='annualLeaves'
								step={0.5}
								onChange={(e) => this.onChangeLeave(e)}
								value={this.state.annualLeaves}
								required></Input>
						</FormGroup>
						<FormGroup>
							<Label>Taken Leaves</Label>
							<Input
								type='number'
								name='takenLeaves'
								step={0.5}
								onChange={(e) => this.onChangeLeave(e)}
								value={this.state.takenLeaves}
								required></Input>
						</FormGroup>
						{/* <> */}
						{/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<Label>Leave Tracker for {currentYear} </Label>
								{!clicked && !currentYearData.length ? (
									<Button
										onClick={(e) => this.addLeaveTrackerHistory(e, annualLeaves, takenLeaves)}
										size='sm'
										color='secondary'>
										Add new leave tracker
									</Button>
								) : (
									clicked &&
									!added &&
									currentYearData.length && (
										<Button onClick={this.onLeaveAddSubmit} size='sm' color='info'>
											save
										</Button>
									)
								)}
							</div>
						</>
						{(clicked && !added) || (clicked && !currentYearData.length) ? (
							<p style={{ color: 'green' }}>
								Please save to create new leave tracker for {currentYear}
							</p>
						) : null} */}

						{/* {(clicked && (leaveObj === undefined || leaveObj.length)) ||
						leaveObj === undefined ||
						(leaveObj.length && currentYearData.length) ? (
							<FormGroup>
								<Label>Annual Leaves </Label>
								<Input
									type='number'
									step={0.5}
									name='annualLeaves'
									onChange={(e) =>
										this.onChangeLeaveTracker(e, trackerIndexNum, currentYear, leaveObj)
									}
									// value={leaveObj[trackerIndexNum][currentYear]['annualLeaves']}
									required></Input>
							</FormGroup>
						) : null} */}
						{/* this is to show only after december 25th */}
						{/* {month === 11 && date > 25 ? (
							<ConfirmButton
								className='remove-btn'
								color='danger'
								size='sm'
								alertTitle={'Reset annual leaves and taken leaves?'}
								onClick={() => this.resetLeaves()}>
								Start a new year
							</ConfirmButton>
						) : null} */}

						<Button
							color='dark'
							style={{
								marginTop: '2rem',
							}}
							onClick={this.onSubmit}
							block>
							Update
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

UpdateStaff.propTypes = {
	employee: PropTypes.object.isRequired,
	toggleEditModal: PropTypes.func.isRequired,
	executiveUpdateEmployee: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	employee: state.employeeR,
	company: state.companyR,
	auth: state.authR,
});

export default connect(mapStateToProps, { toggleEditModal, executiveUpdateEmployee })(UpdateStaff);
