import React, {Component} from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Card,
    CardBody
} from 'reactstrap'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import { changePassword } from '../../actions/authActions'
import { clearErrors } from '../../actions/errorActions'
import { Redirect } from 'react-router-dom';

class ResetPassword extends Component {
    state = {
        password: '',
        password2: '',
        message: null,
        redirect: false,
        messageSuccess: null
    }
    
    componentDidUpdate(prevProps) {
        const { error, auth } = this.props
        if (error!== prevProps.error) {  
            //Check for register error
            if(error.id === 'RESET_FAIL') {
                this.setState({message: error.message.message})
            } else {
                this.setState({ message: null })
            }
        }
        if (auth.redirect!== prevProps.auth.redirect) {
            this.setState({ messageSuccess:auth.message })
            setTimeout(() => {
                this.setState ({redirect: true})
            }, 3500);
            
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit = e => {
        e.preventDefault();
        //Clear errors
        this.props.clearErrors()
        const token = this.props.match.params.token
        const { password,password2} = this.state

        // create a user object
        const newPassword = {
            token,
            password,
            password2
        }

        //Attemp to Register
        this.props.changePassword(newPassword)
    }

    render() {
        if(this.state.redirect){
            return (<Redirect to='/'/>)
        } else {
            return (
                <div>
                    <Card
                     style = {
                        {
                            width: "40%",
                            margin: "auto"
                        }
                     }
                    >
                        <CardBody
                            style = {
                                {
                                    padding: "5%"
                                }
                            }
                        >
                            { this.state.message? <Alert color="danger">{ this.state.message }</Alert>: null }
                            { this.state.messageSuccess? <Alert color="success">{ this.state.messageSuccess }</Alert>: null }
                            <Form onSubmit={this.onSubmit}>
                                <FormGroup>
                                    <Label for = "password" >Password</Label>
                                    <Input 
                                        type = "password"
                                        name = "password"
                                        id = "password"
                                        onChange = {this.onChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for = "password2" >Confirm Password</Label>
                                    <Input 
                                        type = "password"
                                        name = "password2"
                                        id = "password2"
                                        onChange = {this.onChange}
                                        required
                                    />
                                </FormGroup>
                                <Button
                                    color= "dark"
                                    style = {{marginTop: "2rem"}}
                                    block
                                >
                                    Reset Password
                                </Button>
                            </Form>
                        </CardBody>
                    </Card> 
                </div>
            )
        }
    }
}

ResetPassword.propTypes = {
    error: PropTypes.object.isRequired,
    changePassword: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    error: state.errorR,
    auth: state.authR
})

export default connect(
    mapStateToProps, 
        { 
            changePassword,
            clearErrors 
        }
)(ResetPassword);