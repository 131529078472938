import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'reactstrap';

// import state
import { getAnnouncements } from '../../../actions/announcementActions';
import megaphone from '../../../images/megaphone.png';
import { deadlineChecker } from './HelperFunc';

const AnnouncementMessage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAnnouncements());
	}, [dispatch]);

	const announcements = useSelector((state) => state.announcementR.announcements);
	const activeAnnouncements = announcements.filter((ann) => ann.status === 'active');

	const [filteredAnnouncement, setFilteredAnnouncement] = useState(null);

	const [schedule, setSchedule] = useState(null);
	const [remainingTime, setRemainingTime] = useState('');
	const [startTimer, setStartTimer] = useState('');

	useEffect(() => {
		if (filteredAnnouncement) {
			setStartTimer(true);
			filteredAnnouncement.map((ann) => {
				if (!ann.schedule_date) return;
				console.log('ann', ann);
				var scheduleDate = new Date(ann.schedule_date);
				var scheduleTime = ann.schedule_time;
				var deadlineDate = new Date(ann.deadline_date);
				var deadlineTime = ann.deadline_time;
				var currentDate = new Date();
				var equalDate = scheduleDate.getDate() === currentDate.getDate();
				var equalDeadlineDate = deadlineDate.getDate() === currentDate.getDate();

				console.log('scheduleDate', scheduleDate);
				console.log('currentDate', currentDate);

				if (currentDate < scheduleDate) {
					// start the timer till scheduleDate
					// var milliseconds = scheduleDate - currentDate;
					// setRemainingTime(milliseconds / 1000);
				}
				// if equal date and the hour or minute is less then the schedule time
				if (equalDate) {
					// start the timer till scheduleDate
					var milliseconds = scheduleDate - currentDate;
					// console.log('milliseconds', milliseconds);
					setRemainingTime(milliseconds / 1000);
				}

				if (currentDate > deadlineDate) {
					// start the countdown to update the localStorage status
				}
				// if equal date and the hour or minute is less then the deadline time
				if (equalDeadlineDate) {
					// start the countdown to update the localStorage status
				}
			});
		}
	}, [filteredAnnouncement]);

	useEffect(() => {
		if (startTimer && remainingTime) {
			if (remainingTime < 0) {
				// update localStorage the filterState to be true
			} else {
				setTimeout(function () {
					// console.log('remainingTime', remainingTime);
					setRemainingTime(remainingTime - 1);
				}, 1000);
			}
		}
	}, [remainingTime]);

	useEffect(() => {
		if (activeAnnouncements) {
			// getting data form local Storage after click cancel button
			if (localStorage.getItem('announcement')) {
				setFilteredAnnouncement(JSON.parse(localStorage.getItem('announcement')));
			} else {
				// map data to have filterState true when log in or after clearing out local Storage
				setFilteredAnnouncement(
					activeAnnouncements.map((ann) => {
						return {
							_id: ann._id,
							description: ann.description,
							title: ann.title,
							schedule_date: ann.schedule_date,
							schedule_time: ann.schedule_time,
							deadline_date: ann.deadline_date,
							deadline_time: ann.deadline_time,
							filterState: true,
						};
					})
				);
			}
		}
	}, [announcements]);

	const removeStorage = (id) => {
		// changing the state filterState value to false when click x button
		setFilteredAnnouncement(
			filteredAnnouncement.map((e, j) => {
				if (id === e._id) {
					e.filterState = false;
					return e;
				} else {
					return e;
				}
			})
		);
		//  update it to localStorage after mapping the data
		localStorage.setItem('announcement', JSON.stringify(filteredAnnouncement));
	};

	return (
		filteredAnnouncement &&
		filteredAnnouncement.map((ann, index) => {
			if (!ann.filterState) return;
			return (
				<div
					key={index}
					style={{
						color: '#000',
						background: '#F9E490',
						padding: '2rem',
						marginBottom: '1.1rem',
					}}>
					<div style={{ display: 'flex', display: 'relative' }}>
						<Col>
							<div
								onClick={() => removeStorage(ann._id)}
								style={{
									cursor: 'pointer',
									position: 'absolute',
									top: '-43px',
									right: '-28px',
									fontSize: '1.8rem',
								}}>
								x
							</div>
							<img
								style={{
									width: '60px',
									height: '80px',
									objectFit: 'contain',
									position: 'absolute',
									top: '-60px',
									left: '-55px',
									opacity: '0.8',
								}}
								src={megaphone}
								alt='megaphone'
							/>
							<h3 style={{ textAlign: 'center', textTransform: 'capitalize' }}> {ann.title}</h3>
							<h6 className='ml-3'>{ann.description}</h6>
						</Col>
					</div>
				</div>
			);
		})
	);
};

export default AnnouncementMessage;
