import { GET_VENDORS, TOGGLE_VENDOR_MODAL,CREATE_VENDOR,UPDATE_VENDOR,GET_VENDOR_IN_MODAL,DELETE_VENDOR ,GET_VENDOR } from './types';
import { tokenConfig } from './authActions'
import { returnErrors } from './errorActions'
import axios from 'axios'

export const getVendors = () => (dispatch,getState) => {
    axios.get
    ('/api/vendors',tokenConfig(getState))
    .then(res => dispatch({
        type:GET_VENDORS,
        payload: res.data 
    }))
    .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status))
    })
}
export const getVendor = (id) => (dispatch,getState) => {
    axios.get
    (`/api/vendors/${id}`,tokenConfig(getState))
    .then(res => dispatch({
        type:GET_VENDOR,
        payload: res.data 
    }))
    .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status))
    })
}  
export const createVendor = newVendor => (dispatch,getState) => {
    axios.post
    ('/api/vendors',newVendor,tokenConfig(getState))
    .then(res => dispatch({
        type:CREATE_VENDOR,
        payload: res.data 
    }))
    .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status))
    })
}
export const updateVendor = (updatedVendor,id) => (dispatch,getState) => {
    axios.post
    (`/api/vendors/update/${id}`,updatedVendor,tokenConfig(getState))
    .then(res => dispatch({
        type:UPDATE_VENDOR,
        payload: res.data 
    }))
    .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status))
    })
}
export const deleteVendor = (id) => (dispatch,getState) => {
    axios.delete
    (`/api/vendors/${id}`,tokenConfig(getState))
    .then(() => dispatch({
        type:DELETE_VENDOR,
        payload: id 
    }))
    .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status))
    })   
}
export const getVendorInModal = id => dispatch => {
    dispatch({
        type: GET_VENDOR_IN_MODAL,
        payload: id
    })
} 
export const toggleVendorModal = (input) => dispatch => {
    dispatch({
        type:TOGGLE_VENDOR_MODAL,
        payload:input
    })
}