import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Button,
	Modal,
	ModalHeader,
	FormGroup,
	ModalBody,
	Form,
	Label,
	Input,
	Alert,
} from 'reactstrap';

// import actions
import { createAnnouncement } from '../../../actions/announcementActions';

const Announcement = () => {
	const dispatch = useDispatch();
	const [modal, setModal] = useState(false);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [schedule_date, setSchedule_date] = useState('');
	const [schedule_time, setSchedule_time] = useState('');
	const [deadline_date, setDeadline_date] = useState('');
	const [deadline_time, setDeadline_time] = useState('');

	const [successMsg, setSuccessMsg] = useState('');

	const company = useSelector((state) => state.companyR.company);
	const announcement = useSelector((state) => state.announcementR.announcement);

	const toggle = () => setModal(!modal);
	const [visible, setVisible] = useState(true);
	const onDismiss = () => setVisible(false);

	const cleanUp = () => {
		setDescription('');
		setTitle('');
	};

	useEffect(() => {
		if (announcement) {
			setSuccessMsg('Announcement Created Successfully!');
			setTimeout(() => {
				setSuccessMsg('');
				cleanUp();
			}, 7000);
		}
	}, [announcement]);

	const onSubmit = (e) => {
		e.preventDefault();
		const newAnn = {
			company_id: company._id,
			title,
			description,
			schedule_date,
			schedule_time,
			deadline_date,
			deadline_time,
			status: 'inactive',
		};
		dispatch(createAnnouncement(newAnn));
	};

	// console.log('schedule_date', schedule_date);
	// console.log('schedule_time', schedule_time);
	// console.log('deadline_date', deadline_date);
	// console.log('deadline_time', deadline_time);

	return (
		<div>
			<Button size='sm' color='primary' onClick={toggle}>
				Create Announcement
			</Button>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader toggle={toggle}>Create Announcement</ModalHeader>
				<ModalBody>
					<Form onSubmit={(e) => onSubmit(e)}>
						{successMsg && (
							<Alert color='success' isOpen={visible} toggle={onDismiss}>
								{successMsg}
							</Alert>
						)}
						<FormGroup>
							<Label for='title'>Title</Label>
							<Input
								type='text'
								name='title'
								id='title'
								placeholder='Add Title'
								value={title}
								onChange={(e) => setTitle(e.target.value)}
								required
							/>
						</FormGroup>
						<div style={{ display: 'flex' }}>
							<div style={{ width: '48%' }}>
								<FormGroup>
									<Label for='schedule_date'>Schedule Date</Label>
									<Input
										type='date'
										name='schedule_date'
										id='schedule_date'
										value={schedule_date.split('T')[0]}
										onChange={(e) => setSchedule_date(e.target.value)}
									/>
								</FormGroup>
							</div>
							<div style={{ width: '48%', marginLeft: '1rem' }}>
								<FormGroup>
									<Label for='schedule_time'>Schedule Time</Label>
									<Input
										type='time'
										name='schedule_time'
										id='schedule_time'
										value={schedule_time}
										onChange={(e) => setSchedule_time(e.target.value)}
									/>
								</FormGroup>
							</div>
						</div>
						<div style={{ display: 'flex' }}>
							<div style={{ width: '48%' }}>
								<FormGroup>
									<Label for='deadline_date'>Deadline Date</Label>
									<Input
										type='date'
										name='deadline_date'
										id='deadline_date'
										value={deadline_date.split('T')[0]}
										onChange={(e) => setDeadline_date(e.target.value)}
									/>
								</FormGroup>
							</div>
							<div style={{ width: '48%', marginLeft: '1rem' }}>
								<FormGroup>
									<Label for='deadline_time'>Deadline Time</Label>
									<Input
										type='time'
										name='deadline_time'
										id='deadline_time'
										value={deadline_time}
										onChange={(e) => setDeadline_time(e.target.value)}
									/>
								</FormGroup>
							</div>
						</div>

						<FormGroup>
							<Label for='description'>Description</Label>
							<Input
								type='textarea'
								name='description'
								id='description'
								rows='5'
								placeholder='Add Description'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								required
							/>
						</FormGroup>
						<div style={{ float: 'right' }}>
							<Button type='submit' color='primary'>
								Create Announcement
							</Button>{' '}
							<Button color='secondary' onClick={toggle}>
								Cancel
							</Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default Announcement;
