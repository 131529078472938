import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getLeaves } from '../../actions/leaveTrackerActions';
import { getEmployees } from '../../actions/employeeActions';
import { remainingLeaveCal } from './HelperFunctions';

import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Label,
	Input,
	Row,
	Col,
	Alert,
} from 'reactstrap';

const LeaveDetails = ({
	openModal,
	closeModal,
	leave,
	employees,
	toggleCommentModal,
	alertMessage,
	executive,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getEmployees());
		dispatch(getLeaves());
	}, []);

	const leaveTaker = employees.find((employee) => employee.user_id === leave.user_id);

	const remainingLeaves = remainingLeaveCal(
		leaveTaker && leaveTaker.annualLeaves,
		leaveTaker && leaveTaker.takenLeaves
	);

	return (
		<Fragment>
			<Modal isOpen={openModal} toggle={closeModal} className='modal-lg'>
				<ModalHeader toggle={closeModal}>
					<strong>Leave Details</strong>
				</ModalHeader>
				<ModalBody>
					<Col xs={4}>
						{alertMessage ? (
							<Alert style={{ textAlign: 'center' }} color='danger'>
								{alertMessage}
							</Alert>
						) : null}
					</Col>
					<Row>
						<Col>
							<FormGroup>
								<Label>Leave ID</Label>
								<Input type='text' defaultValue={leave._id} disabled />
							</FormGroup>
						</Col>
					</Row>
					<Row xs='2'>
						<Col>
							<FormGroup>
								<Label>Leave Allocation</Label>
								<Input
									type='text'
									defaultValue={employees
										.filter((employee) => employee.user_id === leave.user_id)
										.map((employee) => employee.annualLeaves)}
									disabled
								/>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Leave Remaining</Label>
								<Input type='text' defaultValue={remainingLeaves && remainingLeaves} disabled />
							</FormGroup>
						</Col>
					</Row>
					<Row xs='2'>
						<Col>
							<FormGroup>
								<Label>Type</Label>
								<Input type='text' defaultValue={leave.type} disabled />
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Selected Dates</Label>
								<Input
									type='text'
									defaultValue={leave.selectedDays ? leave.numberOfDays : null}
									disabled
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row xs='2'>
						<Col>
							<FormGroup>
								<Label>Status</Label>
								<Input type='text' defaultValue={leave.status} disabled />
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<Label>Request Date</Label>
								<Input
									type='text'
									defaultValue={leave.createdate ? leave.createdate.substring(0, 10) : null}
									disabled
								/>
							</FormGroup>
						</Col>
					</Row>
					{leave.photo && (
						<FormGroup>
							<Label>Photo</Label>
							<Input type='text' id='photo' defaultValue={leave.photo} disabled />
						</FormGroup>
					)}
					<FormGroup>
						<Label>Reason</Label>
						<Input type='textarea' rows='3' id='reason' defaultValue={leave.reason} disabled />
					</FormGroup>
					<Row xs='3'>
						<Col>
							<Label>Selected Dates</Label>
							{leave.selectedDays &&
								leave.selectedDays.map((date, index) => {
									return (
										<p
											key={index}
											style={{
												backgroundColor: '#D3D3D3',
												margin: '3px',
												padding: '5px',
												textAlign: 'center',
												borderRadius: '3px',
											}}>
											{date}
										</p>
									);
								})}
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							{leave.status === 'Review' && executive ? (
								<Fragment>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											paddingTop: '2rem',
										}}>
										<Button
											onClick={() => toggleCommentModal('cancel')}
											color='danger'
											size='sm'
											style={{ width: '45%' }}>
											Reject
										</Button>

										<Button
											onClick={() => toggleCommentModal('approve')}
											color='success'
											size='sm'
											style={{ width: '45%' }}>
											Approve
										</Button>
									</div>
								</Fragment>
							) : null}
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								color='dark'
								disabled={!executive && leave.status !== 'Review'}
								style={{ marginTop: '1rem' }}
								href={`/editLeave/${leave._id}`}
								block>
								Edit Leave
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</Fragment>
	);
};

export default LeaveDetails;
