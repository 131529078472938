import axios from 'axios';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

import {
    GET_EXPENSES,
    CREATE_EXPENSE,
    DELETE_EXPENSE,
    GET_EXPENSE,
    UPDATE_EXPENSE,
    APPROVE_EXPENSE,
    EXPENSE_LOADING,
    CREATE_EXPENSE_FAIL,
    UPDATE_EXPENSE_FAIL,
    APPROVAL_EXPENSE_FAIL,
    DELETE_EXPENSE_FAIL
} from './types';

// INDEX
export const getExpenses = () => (dispatch, getState) => {
    dispatch(setExpenseLoading());

    axios
        .get(`/api/expenses`, tokenConfig(getState))
        .then((res) => dispatch({ type: GET_EXPENSES, payload: res.data }))
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// GET
export const getExpense = (id) => (dispatch, getState) => {
    dispatch(setExpenseLoading());

    axios
        .get(`/api/expenses/${id}`, tokenConfig(getState))
        .then((res) => dispatch({ type: GET_EXPENSE, payload: res.data }))
        .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

// CREATE - POST
export const createExpense = (expense) => (dispatch, getState) => {
    dispatch(setExpenseLoading());
    expense.key = 'nnnlwe19219n1b2--u0qjasca';
    var image = JSON.stringify(expense);
    if (expense.photo) {
        axios
            .post(
                'https://script.google.com/macros/s/AKfycbw2smMZXthCF_XAHvF2XzrKKwobh-kC0afqf3wbGYRhHvx8xWDL0j3Fyt5QNvtq2D0-HQ/exec',
                image
            ).then((photoRes) => {
                var newExpense = { ...expense, photo: photoRes.data };
                axios
                    .post(`/api/expenses`, newExpense, tokenConfig(getState))
                    .then((res) => {
                        dispatch({ type: CREATE_EXPENSE, payload: res.data })
                        console.log('Expense Requested')
                    }).catch((err) => {
                        dispatch(returnErrors(err.response.data, err.response.status, 'CREATE_EXPENSE_FAIL'));
                        dispatch({
                            type: CREATE_EXPENSE_FAIL,
                        });
                    })

            }
            ).catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status, 'CREATE_EXPENSE_FAIL'));
                dispatch({
                    type: CREATE_EXPENSE_FAIL,
                });
            })
    } else {
        try {
            axios
                .post(`/api/expenses`, expense, tokenConfig(getState))
                .then((res) => {
                    dispatch({ type: CREATE_EXPENSE, payload: res.data })
                    console.log('Expense Requested')
                })
        } catch (err) {
            dispatch(returnErrors(err.response.data, err.response.status, 'CREATE_EXPENSE_FAIL'));
            dispatch({
                type: CREATE_EXPENSE_FAIL,
            });
        }
    }
};

// UPDATE - PATCH
export const updateExpense = (expense, isNewPhoto) => (dispatch, getState) => {
    dispatch(setExpenseLoading());
    if (isNewPhoto) {
        expense.key = 'nnnlwe19219n1b2--u0qjasca';
        var image = JSON.stringify(expense);

        axios
            .post(
                'https://script.google.com/macros/s/AKfycbw2smMZXthCF_XAHvF2XzrKKwobh-kC0afqf3wbGYRhHvx8xWDL0j3Fyt5QNvtq2D0-HQ/exec',
                image
            )
            .then((photoRes) => {
                expense.photo = photoRes.data
                axios
                    .patch(`/api/expenses`, expense, tokenConfig(getState))
                    .then((res) => {
                        dispatch({ type: UPDATE_EXPENSE, payload: res.data })
                        console.log('expense request updated')
                    }).catch((err) => {
                        dispatch(returnErrors(err.response.data, err.response.status))
                    })
                console.log('expense request updated')
            }
            ).catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EXPENSE_FAIL'));
                dispatch({
                    type: UPDATE_EXPENSE_FAIL,
                });
            })
    } else {
        axios
            .patch(`/api/expenses`, expense, tokenConfig(getState))
            .then((res) => dispatch({ type: UPDATE_EXPENSE, payload: res.data })).catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_EXPENSE_FAIL'));
                dispatch({
                    type: UPDATE_EXPENSE_FAIL,
                });
            })
        console.log('expense request updated')

    }
};

// APPROVE - PATCH
export const approveExpense = (req) => (dispatch, getState) => {
    dispatch(setExpenseLoading());

    axios
        .patch(`/api/expenses/approval`, req, tokenConfig(getState))
        .then((res) => dispatch({ type: APPROVE_EXPENSE, payload: res.data }))
        .catch((err) => {
            dispatch(returnErrors(err.response.data, err.response.status, 'APPROVAL_EXPENSE_FAIL'));
            dispatch({
                type: APPROVAL_EXPENSE_FAIL,
            });
        }
        );
};

// DELETE
export const deleteExpense = (id) => (dispatch, getState) => {
    dispatch(setExpenseLoading());

    axios
        .delete(`/api/expenses/${id}`, tokenConfig(getState))
        .then((res) => dispatch({ type: DELETE_EXPENSE, payload: id }))
        .catch((err) => {
            dispatch(returnErrors(err.response.data, err.response.status, 'DELETE_EXPENSE_FAIL'));
            dispatch({
                type: DELETE_EXPENSE_FAIL,
            });
        });
};

const setExpenseLoading = () => ({ type: EXPENSE_LOADING });
