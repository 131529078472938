import {
	GET_LEAVES,
	ADD_LEAVE,
	DELETE_LEAVE,
	GET_LEAVE,
	UPDATE_LEAVE,
	UPDATE_LEAVE_FAIL,
	LEAVE_LOADING,
	TOGGLE_LEAVE_MODAL,
	FILTER_LEAVE,
} from '../actions/types';

const initialState = {
	leaves: [],
	leave: '',
	filteredLeaves: [],
	loading: false,
	message: null,
};

const leaveTrackerReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_LEAVES:
			return {
				...state,
				leaves: action.payload,
				loading: false,
			};
		case GET_LEAVE:
			return {
				...state,
				leave: action.payload,
				loading: false,
			};
		case ADD_LEAVE:
			return {
				...state,
				leaves: [action.payload, ...state.leaves],
			};
		case DELETE_LEAVE:
			return {
				...state,
				leaves: state.leaves.filter((leave) => leave._id !== action.payload),
			};
		case LEAVE_LOADING:
			return {
				...state,
				message: null,
				loading: true,
			};

		case UPDATE_LEAVE_FAIL:
			return {
				...state,
				message: null,
			};
		case TOGGLE_LEAVE_MODAL:
			return {
				...state,
				leave: state.leaves.filter(({ _id }) => _id === action.payload)[0],
			};
		case UPDATE_LEAVE:
			return {
				...state,
				leave: action.payload,
				leaves: state.leaves.map((leave) => {
					if (leave._id === action.payload._id) {
						return action.payload;
					} else {
						return leave;
					}
				}),
				message: 'Leave Updated Successfully',
				loading: false,
			};
		case FILTER_LEAVE:
			// Filter leaves based on criteria provieded
			var arr = state.leaves.filter((leave) =>
				Object.keys(action.payload.filters).every((key) => {
					// special handling
					if (key === 'year') {
						// dates are stored in string and getting back the last four digits
						return leave.selectedDays
							.map((date) => Number(date.substring(date.length - 4, date.length)))
							.includes(action.payload.filters[key]);
					}
					//check if the filter is an array of elements
					if (Array.isArray(action.payload.filters[key])) {
						return action.payload.filters[key].some((keyEle) => leave[key].includes(keyEle));
					}
					return leave[key].includes(action.payload.filters[key]);
				})
			);
			return {
				...state,
				filteredLeaves: arr,
			};
		default:
			return state;
	}
};

export default leaveTrackerReducer;
