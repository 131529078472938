import React, { Fragment, useEffect, useState } from 'react';
import {
	getFeedbacks,
	toggleFeedbackModal,
	deleteFeedback,
	filterFeedbacks,
} from '../../actions/feedbacksActions';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Label, Input, Row, Col, Container } from 'reactstrap';
import { getEmployees } from '../../actions/employeeActions';
import { getCompanies } from '../../actions/companyActions';
import { getUsers } from '../../actions/userActions.js';
import FeedbackDetails from './FeedbackDetails';
import UpdateModal from './UpdateModal';
import ConfirmButton from '../userComponents/ConfirmButton';

const FeedbacksAndBugsList = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getFeedbacks());
		dispatch(getEmployees());
		dispatch(getUsers());
		dispatch(getCompanies());
	}, []);

	// data from redux
	const feedbacks = useSelector((state) => state.feedbackR.feedbacks);
	const feedback = useSelector((state) => state.feedbackR.feedback);
	const reduxEmployees = useSelector((state) => state.employeeR.employees); //
	const reduxUser = useSelector((state) => state.authR.user);
	const reduxUsers = useSelector((state) => state.authR.users);
	const reduxCompanies = useSelector((state) => state.companyR.companies);
	const user = useSelector((state) => state.authR.user);
	const reduxFilterFeedbacks = useSelector((state) => state.feedbackR.filterFeedbacks);
	const [company, setCompany] = useState('');
	const [pic, setPic] = useState('');
	const [modal, setModal] = useState(false);
	const [picModal, setPicModal] = useState(false);
	const [feedbackId, setFeedbackId] = useState('');
	var statusArr = ['Pending', 'In Progress', 'Completed', 'Hold'];
	const [status, setStatus] = useState(statusArr);

	// getting as array of objects without this can't change setStatus
	const newStatus = Object.keys(status).map((key) => {
		return status[key];
	});

	useEffect(() => {
		// mapping key and value for filter feedback in redux
		const filters = { status: statusArr, company_id: company, user_id: pic };
		// setting filterState to be feedbacks status
		filters.status =
			newStatus[0].length < 5 && newStatus[0].filter((e) => e.filterState).map((each) => each.name);
		dispatch(filterFeedbacks(filters));
	}, [status, company, feedbacks, pic]);

	useEffect(() => {
		// setting the status to be object
		setStatus({
			status: statusArr.map((e, index) => {
				return {
					name: e,
					filterState: true,
					color: '#b3b3b3',
					index: index,
				};
			}),
		});
	}, [feedback]);

	const toggleModal = () => {
		setModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};
	// for Update Modal Modal
	const picToggleModal = (id) => {
		setFeedbackId(id);
		setPicModal(true);
	};
	const closePicModal = () => {
		setPicModal(false);
	};
	const onDeleteClick = (id) => {
		dispatch(deleteFeedback(id));
	};

	const statusFilter = (i) => {
		setStatus({
			// update the newStatus
			status: newStatus[0].map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};

	const qworkyUsers = reduxEmployees.filter((employee) => employee.permissionLevel !== 'Disabled');
	return (
		qworkyUsers && (
			<Fragment>
				<Container>
					<FeedbackDetails
						feedbacks={feedbacks}
						employees={reduxEmployees}
						reduxCompanies={reduxCompanies}
						user={reduxUser}
						openModal={modal}
						user={user}
						reduxUsers={reduxUsers}
						feedback={feedback}
						closeModal={() => closeModal()}
					/>

					<UpdateModal
						picToggleModal={picModal}
						employees={reduxEmployees}
						feedback_id={feedbackId}
						feedback={feedback}
						user_id={pic}
						closePicModal={() => closePicModal()}
					/>
					<Row style={{ marginBottom: '1rem' }}>
						{' '}
						<Col>
							<Label style={{ fontSize: '12px', color: 'grey' }}>Company</Label>
							<Input
								type='select'
								onChange={({ target }) => setCompany(target.value)}
								value={company}>
								<option></option>

								{reduxCompanies.map(({ companyname, shortname, _id }) => (
									<option key={shortname} value={_id}>
										{companyname}
									</option>
								))}
							</Input>
						</Col>
						<Col>
							<Label style={{ fontSize: '12px', color: 'grey' }}>PIC</Label>
							<Input type='select' value={pic} onChange={({ target }) => setPic(target.value)}>
								<option></option>
								{reduxEmployees.map(({ name, user_id }) => (
									<option key={name} value={user_id}>
										{' '}
										{name}
									</option>
								))}
							</Input>
						</Col>
					</Row>
					<Row>
						<Col>
							<Table>
								<tbody>
									<tr>
										{/* length < 5 for status object cos the very first render length is 7 with status arr so */}
										{newStatus[0].length < 5 &&
											newStatus[0].map(({ name, filterState, color, index }) => (
												<td
													key={index}
													width='25%'
													style={{
														opacity: filterState ? '1' : '0.3',
														backgroundColor: color,
														textAlign: 'center',
														border: '1px solid #969696',
														cursor: 'pointer',
													}}
													onClick={() => statusFilter(index)}>
													{name}
												</td>
											))}
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Container>

				<Table hover style={{ margin: '0 auto', width: '90%' }}>
					<thead>
						<tr>
							<th className='rowAlign'>Submitted Date </th>
							<th className='rowAlign'>Title</th>
							<th width='25%'>Bugs to Fix</th>
							<th width='10%'>Submitted By</th>
							<th className='rowAlign'>Company </th>
							<th className='rowAlign'> PIC</th>
							<th width='10%' className='rowAlign'>
								Status
							</th>
							<th width='2%' className='rowAlign'>
								Issues Link
							</th>
						</tr>
					</thead>
					<tbody>
						{reduxFilterFeedbacks.map(
							({
								_id,
								user_id,
								company_id,
								createdAt,
								description,
								photo,
								status,
								title,
								pic_user_id,
							}) => {
								return (
									<tr key={_id}>
										<td className='rowAlign' style={{ padding: '5px' }}>
											{createdAt.substring(0, 10)}{' '}
										</td>
										<td className='rowAlign'>
											<a
												href='#'
												onClick={() => {
													toggleModal();
													dispatch(toggleFeedbackModal(_id));
												}}>
												{title}
											</a>
										</td>

										<td style={{ whiteSpace: 'pre-wrap', padding: '1rem' }}>{description}</td>

										<td>
											{reduxUsers
												.filter((user) => user._id === user_id && user.company_id === company_id)
												.map((user) => user.email)}
										</td>

										<td className='rowAlign'>
											{reduxCompanies
												.filter((company) => company._id === company_id)
												.map((company) => company.companyname)}
										</td>
										<td className='rowAlign'>
											<a
												href='#'
												onClick={() => {
													picToggleModal(_id);
													dispatch(toggleFeedbackModal(_id));
												}}>
												{pic_user_id
													? reduxEmployees
															.filter((employee) => employee.user_id === pic_user_id)
															.map(({ name }) => name)
													: 'Assign To'}
											</a>
										</td>

										<td className='rowAlign'>
											{' '}
											<a
												href='#'
												onClick={() => {
													picToggleModal(_id);
													dispatch(toggleFeedbackModal(_id));
												}}>
												{status}
											</a>
										</td>
										<td className='rowAlign'>
											<a href={photo} target='_blank' style={{ fontSize: '0.9rem' }}>
												{photo}
											</a>
										</td>

										{user.permissionLevel === 'Executive' ? (
											<td className='rowAlign'>
												<ConfirmButton
													className='remove-btn'
													color='danger'
													size='sm'
													alertTitle={'Remove this feedback?'}
													onClick={() => onDeleteClick(_id)}>
													&times;
												</ConfirmButton>
											</td>
										) : null}
									</tr>
								);
							}
						)}
					</tbody>
				</Table>
			</Fragment>
		)
	);
};

export default FeedbacksAndBugsList;
