import React, { Component } from 'react';
import { Container, Table, Button, Input, Row, Col, Label } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	getEmployees,
	filterEmployees,
	getUsersByCompany,
	toggleEditModal,
	updateLeaveHistory,
} from '../../actions/employeeActions';
import { openDepartmentModal } from '../../actions/companyActions';
import { getCompany, updateCompanyDetail } from '../../actions/companyActions';
import { getTasks } from '../../actions/taskActions';
import {
	deleteProposalTask,
	addProposalTask,
	updateProposalTask,
} from '../../actions/companyActions';
import UpdateStaff from './updateStaff';
import DepartmentModal from '../AdminComponents/departmentModal';
import BillingModal from './BillingModal';
import ConfirmButton from '../userComponents/ConfirmButton';
import { v4 as uuidv4 } from 'uuid';
import ProposalTaskOptions from './ProposalTaskOptions';
import Announcement from '../userComponents/announcement/AnnouncementModal';
import AnnouncementList from '../userComponents/announcement/AnnouncementList';
import { remainingLeaveCal } from '../leaveTrackerComponent/HelperFunctions';

class StaffList extends Component {
	state = {
		employees: [],
		departments: [],
		RangeStart: '',
		RangeEnd: '',
		permissionLevel: 'Employee,Manager,Executive,Secretary',
		filtercolors: [
			'#e1bee7',
			'#64b5f6',
			'#c5e1a5',
			'#f48fb1',
			'#f06292',
			'#ffee58',
			'#ffab91',
			'#80cbc4',
		],
		billingModal: false,
	};
	componentDidMount() {
		this.props.getCompany();
		this.props.getEmployees();
		this.props.getUsersByCompany();
		this.props.getTasks();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.company.company !== this.props.company.company) {
			this.setState({
				departments: this.props.company.company.departments
					? this.props.company.company.departments
							.filter(({ active }) => active)
							.map((e, index) => {
								return {
									name: e.name,
									id: e.id,
									filterState: true,
									color: this.state.filtercolors[index],
									index: index,
								};
							})
					: [],
				employees: this.props.employee.employees,
			});
		}
		if (
			prevProps.employee.employees !== this.props.employee.employees ||
			prevState.departments !== this.state.departments ||
			prevState.permissionLevel !== this.state.permissionLevel ||
			prevState.RangeEnd !== this.state.RangeEnd ||
			prevState.RangeStart !== this.state.RangeStart
		) {
			var filters = {};
			var d = '';
			if (this.state.RangeEnd) {
				d = new Date(this.state.RangeEnd);
				d = new Date(d.setDate(d.getDate() + 1)).toISOString().substr(0, 10);
			}
			filters.permissionLevel = this.state.permissionLevel.split(',');
			filters.department = this.state.departments.filter((e) => e.filterState).map((e) => e.id);
			filters.dateRange = [this.state.RangeStart, d];
			this.props.filterEmployees(filters, false);
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	departmentFilter = (i) => {
		this.setState({
			departments: this.state.departments.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			}),
		});
	};

	onProposalTask = (_id) => {
		const objectProposalTask = {
			id: uuidv4(),
			name: this.state.newProposalTask,
			active: true,
		};
		this.props.addProposalTask(objectProposalTask, _id);
	};
	onDeleteProposalTask = (_id, i) => {
		this.props.deleteProposalTask(_id, i);
	};
	onEditProposalTask = (_id, i) => {
		this.setState({
			proposalTasks: this.props.company.company.proposalTasks.filter(({ active }) => active),
		});
	};
	updateProposalTask = (e, i) => {
		var proposalTasks = this.state.proposalTasks;
		proposalTasks[i].name = e.target.value;
		this.setState({ proposalTasks });
	};
	setNewProposalTask = (_id) => {
		const proposalTasks = this.state.proposalTasks;
		var object = {
			proposalTasks,
			_id,
		};
		this.props.updateProposalTask(object);
	};

	onDeleteClick = (id) => {
		this.props.deleteProject(id);
	};

	toggleBillingModal = () => {
		this.setState({
			billingModal: !this.state.billingModal,
		});
	};

	addLeaveHistoryTracker = (employees) => {
		const currentYear = new Date().getFullYear();
		employees.map((employee) => {
			// if (employee.user_id !== '5f02e2a74b1b286d307bc27c') return;
			const alreadyExist = employee.leaveHistory.length
				? employee.leaveHistory.filter((leave) => leave['year'] === currentYear)
				: [];
			const obj = {
				_id: employee._id,
			};
			if (!alreadyExist.length) {
				// add object here if the leave tracker is employeepty array
				obj.leaveHistory = [
					{
						year: currentYear,
						annualLeaves: employee.annualLeaves ? employee.annualLeaves : 0,
						takenLeaves: employee.takenLeaves ? employee.takenLeaves : 0,
					},
				];
				// console.log('obj', obj);
				// dispatch update
				this.props.updateLeaveHistory(obj);
				return employee;
			}
			if (!alreadyExist.length && employee.leaveHistory.length) {
				obj.leaveHistory = [
					...employee.leaveHistory,
					{
						year: currentYear,
						annualLeaves: employee.annualLeaves ? employee.annualLeaves : 0,
						takenLeaves: employee.takenLeaves ? employee.takenLeaves : 0,
					},
				];
				this.props.updateLeaveHistory(obj);
				return employee;
			}
		});
	};

	updateLeaveHistoryTracker = (employees) => {
		const currentYear = new Date().getFullYear();
		employees.map((employee) => {
			const data = employee.leaveHistory.length
				? employee.leaveHistory.filter((leave) => leave['year'] === currentYear)
				: [];
			const obj = {
				_id: employee._id,
			};

			if (data.length) {
				let leaveData = [...employee.leaveHistory];
				let curLeave = leaveData.find((leave) => leave['year'] === currentYear);
				curLeave.annualLeaves = employee.annualLeaves ? employee.annualLeaves : 0;
				curLeave.takenLeaves = employee.takenLeaves ? employee.takenLeaves : 0;
				obj.leaveHistory = leaveData;

				this.props.updateLeaveHistory(obj);
				return employee;
			}
		});
	};

	resetAnnualLeavesAndTakenLeaves = (employees, currentCompany) => {
		const currentYear = new Date().getFullYear();
		employees.map((em) => {
			const newObj = {
				_id: em._id,
				takenLeaves: 0,
				annualLeaves: em.annualLeaves ? em.annualLeaves : 0,
			};
			this.props.updateLeaveHistory(newObj);
			return em;
		});

		const comObj = {
			_id: currentCompany._id,
			leaveYear: currentYear,
		};

		this.props.updateCompanyDetail(comObj);
	};

	render() {
		const { filteredEmployees } = this.props.employee;

		const { tasks } = this.props.task;
		const { user, users } = this.props.auth;
		const billingUser = users.length > 0 && users.find((e) => e.billingUser);
		const currentUser = this.props.auth.user.permissionLevel;
		const currentYear = new Date().getFullYear();
		const currentCompany = this.props.company.company;
		const alreadyExistData = filteredEmployees.filter((employee) =>
			employee.leaveHistory.find((leave) => leave['year'] === currentYear)
		);

		const alreadyAdded = alreadyExistData.length === filteredEmployees.length;
		return currentUser === 'Executive' || currentUser === 'Secretary' ? (
			<Container>
				<BillingModal
					billingModal={this.state.billingModal}
					toggleBillingModal={this.toggleBillingModal}
					billingUser={billingUser}
					users={users}
				/>
				<Row>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Company ID</Label>
						<Input value={this.props.auth.user.company_id} disabled />
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>User Fee</Label>
						<Input value={this.props.company.company.userFee} disabled />
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Active Users</Label>
						<Input value={users.filter((e) => e.permissionLevel !== 'Disabled').length} disabled />
					</Col>
					<Col>
						<a onClick={this.toggleBillingModal}>
							<Label style={{ fontSize: '12px', color: 'grey' }}>Billing User</Label>
							<Input style={{ cursor: 'pointer' }} value={billingUser.email} disabled />
						</a>
					</Col>
				</Row>
				{currentUser === 'Executive' ? <DepartmentModal /> : null}
				<UpdateStaff />
				<Row style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Created After</Label>
						<Input
							type='date'
							name='RangeStart'
							onChange={this.onChange}
							defaultValue={this.state.RangeStart}
						/>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Created Before</Label>
						<Input
							type='date'
							name='RangeEnd'
							onChange={this.onChange}
							defaultValue={this.state.RangeEnd}
						/>
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Permission</Label>
						<Input
							type='select'
							name='permissionLevel'
							onChange={this.onChange}
							defaultValue={this.state.permissionLevel}>
							<option value={'Employee,Manager,Executive,Secretary'}></option>
							<option>Disabled</option>
							<option>Employee</option>
							<option>Manager</option>
							<option>Secretary</option>
							<option>Executive</option>
						</Input>
					</Col>
				</Row>
				<Row>
					<Col>
						<Table>
							<tbody>
								<tr>
									{this.state.departments.map(({ name, filterState, color, index }) => (
										<td
											key={name}
											style={{
												opacity: filterState ? '1' : '0.3',
												backgroundColor: color,
												textAlign: 'center',
												cursor: 'pointer',
											}}
											onClick={this.departmentFilter.bind(this, index)}>
											{name}
										</td>
									))}
								</tr>
							</tbody>
						</Table>
					</Col>
				</Row>

				<Col style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div>
						{currentUser === 'Executive' ? (
							<a
								href='#'
								style={{
									display: 'inline-block',
									marginBottom: '1rem',
									marginTop: '-0.5rem',
									fontSize: '12px',
									marginRight: '0.5rem',
								}}
								onClick={this.props.openDepartmentModal.bind(this, user.company_id)}>
								Manage Departments
							</a>
						) : null}
						{this.props.auth.user.proposal ? (
							<ProposalTaskOptions
								company_id={user.company_id}
								proposalTasks={this.props.company.company.proposalTasks}
								onChange={this.onChange}
								onProposalTask={this.onProposalTask}
								onDeleteClick={this.onDeleteProposalTask}
								onEditClick={this.onEditProposalTask}
								updateProposalTask={this.updateProposalTask}
								setNewProposalTask={this.setNewProposalTask}
							/>
						) : null}
					</div>
					<div style={{ display: 'flex', paddingTop: '0.3rem', marginBottom: '1rem' }}>
						{alreadyAdded ? (
							<div style={{ fontSize: '0.8rem' }}>Leave history added </div>
						) : (
							<a
								href='#'
								onClick={() => this.addLeaveHistoryTracker(filteredEmployees)}
								style={{ fontSize: '0.8rem' }}>
								Add leave history
							</a>
						)}

						{!currentCompany.leaveYear || currentCompany.leaveYear !== currentYear ? (
							<ConfirmButton
								className='remove-btn'
								color='info'
								size='sm'
								style={{
									marginLeft: '0.5rem',
									marginTop: '-0.3rem',
								}}
								alertTitle={'Are you sure?'}
								onClick={() => {
									this.resetAnnualLeavesAndTakenLeaves(filteredEmployees, currentCompany);
									this.updateLeaveHistoryTracker(filteredEmployees);
								}}>
								Start a new year
							</ConfirmButton>
						) : null}

						{/* {alreadyExistData.length ? (
							<a
								href='#'
								onClick={() => this.updateLeaveHistoryTracker(filteredEmployees)}
								style={{ fontSize: '0.8rem' }}>
								{this.state.updated ? 'History updated' : 'Update leave history'}
							</a>
						) : null} */}
					</div>
					<div>
						<div style={{ display: 'flex' }}>
							<AnnouncementList />
							<Announcement />
						</div>
					</div>
				</Col>

				<Table hover responsive className='table-sm'>
					<thead style={{ fontSize: '14px' }}>
						<tr>
							<th>Register Date</th>
							<th>Name</th>
							<th>Department</th>
							<th>Email</th>
							<th>Phone #</th>
							<th>Points</th>
							<th>Allocation</th>
							<th>Remaining</th>
							<th>Permission</th>
							<th></th>
						</tr>
					</thead>
					<tbody style={{ fontSize: '14px' }}>
						{filteredEmployees.map(
							({
								_id,
								createdAt,
								email,
								name,
								department,
								phonenumber,
								points,
								annualLeaves,
								takenLeaves,
								user_id,
								permissionLevel,
							}) => {
								const remainingLeaves = remainingLeaveCal(annualLeaves, takenLeaves);
								const completedTasks = tasks.filter(
									(task) => task.status === 'Completed' && task.user_id === user_id
								);
								var userPoints = 0;
								completedTasks.forEach((task) => (userPoints = userPoints + task.points));
								return (
									<tr key={_id}>
										<td>{createdAt.substr(0, 10)}</td>
										<td>
											<a href={'/user/' + user_id}>{name}</a>
										</td>
										<td>
											{this.props.company.company.departments && department
												? this.props.company.company.departments
														.filter(({ id }) => department === id)
														.map((department) => department.name)
												: null}
										</td>
										<td>{email}</td>
										<td>{phonenumber}</td>
										<td>{userPoints}</td>
										<td>{annualLeaves}</td>
										<td>{remainingLeaves}</td>
										<td>{permissionLevel}</td>
										<td>
											<a href='#' onClick={this.props.toggleEditModal.bind(this, user_id)}>
												Edit
											</a>
										</td>

										{user.permissionLevel === 'Manager' ? (
											<td hidden>
												<ConfirmButton
													className='remove-btn'
													color='danger'
													size='sm'
													alertTitle={'Remove this Project?'}
													onClick={() => this.onDeleteClick(_id)}>
													&times;
												</ConfirmButton>
											</td>
										) : null}
									</tr>
								);
							}
						)}
					</tbody>
				</Table>
			</Container>
		) : null;
	}
}

StaffList.propTypes = {
	getEmployees: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	filterEmployees: PropTypes.func.isRequired,
	getUsersByCompany: PropTypes.func.isRequired,
	openDepartmentModal: PropTypes.func.isRequired,
	addProposalTask: PropTypes.func.isRequired,
	deleteProposalTask: PropTypes.func.isRequired,
	updateProposalTask: PropTypes.func.isRequired,
	getTasks: PropTypes.func.isRequired,
	employee: PropTypes.object.isRequired,
	updateLeaveHistory: PropTypes.func.isRequired,
	updateCompanyDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	employee: state.employeeR,
	auth: state.authR,
	task: state.taskR,
	company: state.companyR,
});

export default connect(mapStateToProps, {
	getEmployees,
	getCompany,
	filterEmployees,
	getUsersByCompany,
	toggleEditModal,
	openDepartmentModal,
	getTasks,
	addProposalTask,
	deleteProposalTask,
	updateProposalTask,
	updateLeaveHistory,
	updateCompanyDetail,
})(StaffList);
