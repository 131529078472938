import {
	GET_PROJECTS,
	ADD_PROJECT,
	DELETE_PROJECT,
	PROJECT_LOADING,
	UPDATE_PROJECT,
	TOGGLE_PROJECT_MODAL,
	GET_PROJECT,
	UPDATE_PROJECT_FAIL,
	FILTER_PROJECT,
	ASSIGN_PROJECT,
	UNASSIGN_PROJECT,
} from '../actions/types';

const initialState = {
	projects: [],
	project: '', //used for Update Project page
	filteredProjects: [],
	loading: false,
	modal: false,
	message: null,
};

const projectReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_PROJECTS:
			return {
				...state,
				projects: action.payload,
				loading: false,
			};
		case GET_PROJECT:
			return {
				...state,
				project: action.payload,
				loading: false,
			};
		case DELETE_PROJECT:
			return {
				...state,
				projects: state.projects.filter((project) => project._id !== action.payload),
			};
		case ADD_PROJECT:
			return {
				...state,
				projects: [...state.projects, action.payload],
			};
		case ASSIGN_PROJECT:
		case UNASSIGN_PROJECT:
			return {
				...state,
				projects: state.projects.map((e) => (e._id === action.payload._id ? action.payload : e)),
			};
		case PROJECT_LOADING:
			return {
				...state,
				message: null,
				loading: true,
			};
		case TOGGLE_PROJECT_MODAL:
			return {
				...state,
				project: state.projects.filter(({ _id }) => _id === action.payload)[0],
				modal: !state.modal,
			};
		case UPDATE_PROJECT_FAIL:
			return {
				...state,
				message: null,
			};
		case UPDATE_PROJECT:
			return {
				...state,
				project: action.payload,
				message: 'Project Updated Successfully',
				loading: false,
			};
		case FILTER_PROJECT:
			// Filter projects based on criteria provieded
			var arr = state.projects.filter((project) =>
				Object.keys(action.payload.filters).every((key) => {
					//check if the filter is an array of elements
					if (Array.isArray(action.payload.filters[key])) {
						if (key === 'dateRange') {
							var filter1 = true;
							var filter2 = true;
							if (action.payload.filters.dateRange[0] !== '') {
								filter1 = project.deadline > action.payload.filters.dateRange[0] ? true : false;
							}
							if (action.payload.filters.dateRange[1] !== '') {
								filter2 = project.deadline < action.payload.filters.dateRange[1] ? true : false;
							}
							var filter = filter1 && filter2 ? true : false;
							return filter;
						}
						return action.payload.filters[key].some((keyEle) => project[key].includes(keyEle));
					}
					return project[key] ? project[key].includes(action.payload.filters[key]) : null;
				})
			);
			if (action.payload.calendarState) {
				//Create an object for each date to hold the values of the daily entries
				var dates = {};
				console.log(dates);
				console.log(action.payload.filters.deadline);
				for (var i = 0; i < action.payload.filters.deadline.length; i++) {
					dates[action.payload.filters.deadline[i].substr(0, 10)] = [];
				}

				//Push the entries for the day into respective object element
				for (var j = 0; j < arr.length; j++) {
					dates[arr[j].deadline.substr(0, 10)].push(arr[j]);
				}
			}
			return {
				...state,
				filteredProjects: action.payload.calendarState ? dates : arr,
			};
		default:
			return state;
	}
};

export default projectReducer;
