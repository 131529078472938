import React, { Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';

const ProgressChart = (props) => {
	var dateLabel;
	var dataArr = [];

	switch (props.dateIndicator) {
		// three months report week lists
		case 'month':
			var weeks = [];
			for (let i = 0; i < 12; i++) {
				weeks.push('W - ' + (12 - i));
			}
			dateLabel = weeks;
			//to create an array with all the points values
			for (var i = 0; i < dateLabel.length; i++) {
				var points = 0;
				const dateStart = new Date(props.date);
				const dateEnd = new Date(props.date.setDate(props.date.getDate() + 7));
				// creating points array by totalling objects with enddate within the date range
				for (var j = 0; j < props.data.length; j++) {
					if (
						new Date(props.data[j]['enddate']) > dateStart &&
						new Date(props.data[j]['enddate']) < dateEnd
					) {
						points = points + props.data[j]['points'];
					}
				}
				// Add the sum of the points for the dateRange
				dataArr.push(points);
			}

			break;
		// 12 months report month list
		case 'year':
			var year = [];
			for (let i = 0; i < 12; i++) {
				year.push('M - ' + (12 - i));
			}
			dateLabel = year;
			//to create an array with all the points values
			for (var i = 0; i < dateLabel.length; i++) {
				var points = 0;
				const dateStart = new Date(props.date);
				const dateEnd = new Date(props.date.setDate(props.date.getDate() + 28));
				// creating points array by totalling objects with enddate within the date range
				for (var j = 0; j < props.data.length; j++) {
					if (
						new Date(props.data[j]['enddate']) > dateStart &&
						new Date(props.data[j]['enddate']) < dateEnd
					) {
						points = points + props.data[j]['points'];
					}
				}
				// Add the sum of the points for the dateRange
				dataArr.push(points);
			}
			break;
		default:
			// Getting previous week dates in sequence
			var lastWeek = [];
			var dates = 0;
			for (let i = 0; i < 8; i++) {
				const date = new Date();
				const eachDay = date.setDate(date.getDate() - dates++);
				const days = new Date(eachDay).toString();
				lastWeek.push(days.substr(7, 3) + `/${date.getMonth() + 1}/${date.getFullYear()}`);
			}

			dateLabel = lastWeek.reverse();

			for (var i = 0; i < dateLabel.length; i++) {
				var points = 0;
				const dateStart = new Date(props.date);
				const dateEnd = new Date(props.date.setDate(props.date.getDate() + 1));
				// creating points array by totalling objects with enddate within the date range
				for (var j = 0; j < props.data.length; j++) {
					if (
						new Date(props.data[j]['enddate']) > dateStart &&
						new Date(props.data[j]['enddate']) < dateEnd
					) {
						// Add the sum of the points for the dateRange
						points = points + props.data[j]['points'];
					}
				}
				dataArr.push(points);
			}

			break;
	}

	var totalPoints = dataArr.reduce((a, b) => a + b, 0);

	const chartData = {
		labels: dateLabel,

		datasets: [
			{
				data: dataArr,
				backgroundColor: [
					'rgba(255, 99, 132, 0.6)',
					'rgba(54, 162, 235, 0.6)',
					'rgba(255, 206, 86, 0.6)',
					'rgba(75, 192, 192, 0.6)',
					'rgba(153, 102, 255, 0.6)',
					'rgba(255, 159, 64, 0.6)',
					'rgba(255, 155, 145, 0.6)',
					'rgba(255, 102, 102, 0.6)',
					'rgba(54, 162, 200, 0.6)',
					'rgba(255, 150, 86, 0.6)',
					'rgba(75, 132, 172, 0.6)',
					'rgba(153, 142, 200, 0.6)',
				],
			},
		],
	};
	return (
		<Fragment>
			<Bar
				// getting
				data={chartData}
				options={{
					title: {
						// graph title goes here ..........
						// display: true,
						// text: 'Collected work points ',
						// fontSize: 20,
					},
					legend: {
						display: false,
						position: 'top',
						align: 'end',
					},
				}}></Bar>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	task: state.taskR,
	report: state.reportR,
	employee: state.employeeR,
	auth: state.authR,
	company: state.companyR,
	project: state.projectR,
});
export default connect(mapStateToProps, {})(ProgressChart);
