import React, { useState, useEffect } from 'react';
import { Container, ListGroupItem, ListGroup, Table, Row, Col, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLeaves,
	toggleLeaveModal,
	filterLeaves,
	createLeave,
} from '../../actions/leaveTrackerActions';
import './styleLeave.css';
import { filtercolors, months, typeArr, daysOfWeek, monthsList, yearsList } from './LeaveData';
import {
	renderStatus,
	statusxAdmin,
	changeToPrevMonth,
	changeToNextMonth,
	changeToCurrentMonth,
} from './HelperFunctions';
import { getEmployees } from '../../actions/employeeActions';
import { getCompany } from '../../actions/companyActions';
import calenedarCalculator from '../../utils/calenedarCalculator';
import LeaveDetails from './LeaveDetails';
import LeaveModal from './LeaveModal';
import axios from 'axios';
import CreateButtons from './CreateButtons';

const LeaveCalendar = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getLeaves());
		dispatch(getEmployees());
		dispatch(getCompany());
	}, []);

	// Date format and time selection
	var date = new Date();
	const [dates, setDates] = useState(
		calenedarCalculator(new Date(`1 ${months[date.getMonth()]} ${date.getFullYear()}`))
	);
	const [weeks, setWeeks] = useState([
		...Array(
			calenedarCalculator(new Date(`1 ${months[date.getMonth()]} ${date.getFullYear()}`)).length / 7
		).keys(),
	]);
	const [month, setMonth] = useState(date.getMonth());
	const [year, setYear] = useState(date.getFullYear());
	const [currentDate, setCurrentDate] = useState(
		date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
	);
	// const and var for this leave
	const [typeR, setTypeR] = useState([]);
	const [calendarDisplay, setCalendarDisplay] = useState([]);
	const [modalDetail, setModalDetail] = useState(false);
	const [modalForm, setModalForm] = useState(false);
	const [chosenDates, setChosenDates] = useState([]);
	const [message, setMessage] = useState('');
	const [pic, setPic] = useState(null);
	const [messageSuccess, setMessageSuccess] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [leaveRequestMode, setLeaveRequestMode] = useState(false);
	const [allEmailList, setAllEmailList] = useState([]);
	// Redux state
	const reduxEmployees = useSelector((state) => state.employeeR.employees);
	const reduxLeaves = useSelector((state) => state.leaveR.leaves);
	const auth = useSelector((state) => state.authR.user);
	const executive = reduxEmployees.map((employee) => employee.permissionLevel === 'Executive');

	const currentUser = reduxEmployees.find((employee) => employee.user_id === auth.id);
	const filteredLeaves = useSelector((state) => state.leaveR.filteredLeaves);
	const leave = useSelector((state) => state.leaveR.leave);
	const execEmailList =
		reduxEmployees &&
		reduxEmployees.filter((e) => e.permissionLevel === 'Executive').map((each) => each.email);
	const alreadyExist = execEmailList && execEmailList.includes(currentUser && currentUser.email);

	// console.log('alreadyExist', alreadyExist);

	useEffect(() => {
		if (execEmailList && !alreadyExist && currentUser) {
			setAllEmailList([...execEmailList, currentUser.email]);
		}
		if (alreadyExist) {
			setAllEmailList(execEmailList);
		}
	}, [execEmailList.length]);

	// Effect for month and year selection changes
	useEffect(() => {
		setDates(calenedarCalculator(new Date(`1 ${months[month]} ${year}`)));
		setWeeks([
			...Array(calenedarCalculator(new Date(`1 ${months[month]} ${year}`)).length / 7).keys(),
		]);
	}, [month, year]);

	// Effect for setting pic user for first render
	useEffect(() => {
		if (auth) setPic(auth.id);
	}, [auth]);

	useEffect(() => {
		if (!reduxLeaves || !typeR) return;
		const filters = { user_id: pic };
		filters.type = typeR.length && typeR.filter((e) => e.filterState).map((each) => each.name);
		dispatch(filterLeaves(filters));
	}, [typeR, pic, reduxLeaves]);

	useEffect(() => {
		setTypeR(
			typeArr.map((e, index) => {
				return {
					name: e,
					filterState: true,
					color: filtercolors[index],
					index: index,
				};
			})
		);
	}, []);

	//Effect for creating key object dates array for filtering leave types
	useEffect(() => {
		const leaveByDate = {};
		// to hide rejected leave from displaying in frontend
		var filteredRejectedLeaves = filteredLeaves.filter(
			(leave) => leave.status !== 'Rejected' && leave.status !== 'Cancelled'
		);
		// adding as object
		dates.forEach((date) => (leaveByDate[date] = []));
		for (var i = 0; i < filteredRejectedLeaves.length; i++) {
			for (var j = 0; j < filteredRejectedLeaves[i]['selectedDays'].length; j++) {
				if (dates.includes(filteredRejectedLeaves[i]['selectedDays'][j].substring(0, 10))) {
					leaveByDate[filteredRejectedLeaves[i]['selectedDays'][j].substring(0, 10)].push(
						filteredRejectedLeaves[i]
					);
				}
			}
		}
		setCalendarDisplay(leaveByDate);
	}, [filteredLeaves, dates, pic]);

	const typeFilter = (i) => {
		setTypeR(
			typeR.map((e, j) => {
				if (i === j) {
					e.filterState = !e.filterState;
					return e;
				} else {
					return e;
				}
			})
		);
	};

	// Form Modal section
	const [formData, setFormData] = useState({
		type: '',
		photo: '',
		file: '',
		message: '',
		msg: '',
		reason: '',
		checkbox: false,
	});
	const { type, photo, reason, msg, file, checkbox } = formData;
	const selectedDates = checkbox ? parseInt(chosenDates.length) - 0.5 : chosenDates.length;

	// Functions for Leave form request related functionality
	const selectDate = (inputDate) => {
		// if want to remove from after selecting
		if (chosenDates.includes(inputDate)) {
			setChosenDates(chosenDates.filter((e) => e !== inputDate));
		} else {
			// adding it to the list of chosen dates array
			setChosenDates([...chosenDates, inputDate]);
		}
	};

	const pictureChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			if (file.size > 2097152 || file.type.substr(0, 5) !== 'image') {
				return setMessage('Requires Image File below 2mb');
			}
			setFormData({
				...formData,
				photo: URL.createObjectURL(file),
				file: file,
			});
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (chosenDates.length < 0.5) {
			setFormData({
				...formData,
				msg: 'Select dates first!',
			});
		} else {
			const newLeave = {
				reason: reason,
				type: type,
				photo: photo,
				status: 'Review',
				selectedDays: chosenDates,
				numberOfDays: selectedDates,
				createdate: new Date(),
			};
			const sendEmailResponse = await axios.post('/api/leaves/email-upon-leave-request', {
				reason,
				type,
				chosenDates,
				selectedDates,
				email: allEmailList,
				userName: currentUser.name,
			});

			if (file) {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = (e) => {
					newLeave.photo = e.target.result;
					dispatch(createLeave(newLeave));
				};
			} else {
				dispatch(createLeave(newLeave));
			}
			if (sendEmailResponse.data.accepted) {
				setMessageSuccess('Leave Request Email Sent!');
			} else {
				setErrorMessage("Error, Email did'nt send!");
			}
		}
		setChosenDates([]);
		setLeaveRequestMode(!leaveRequestMode);
		setFormData({
			...formData,
			type: '',
			photo: '',
			file: '',
			message: '',
			reason: '',
			msg: '',
			checkbox: false,
		});
		toggle();
	};
	const changeCheckbox = () => {
		setFormData({
			...formData,
			checkbox: !checkbox,
		});
	};

	// Modal options for Details and From Leave request
	const toggleModal = () => {
		setModalDetail(true);
	};
	const closeModal = () => {
		setModalDetail(false);
	};
	const toggle = () => {
		setModalForm(!modalForm);
	};
	const onChangeModal = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

	return (
		<Container>
			<LeaveDetails
				employees={reduxEmployees}
				leave={leave}
				closeModal={() => closeModal()}
				openModal={modalDetail}
				chosenDates={chosenDates}
			/>
			<LeaveModal
				modalForm={modalForm}
				toggle={toggle}
				onSubmit={onSubmit}
				messageSuccess={messageSuccess}
				msg={msg}
				chosenDates={chosenDates}
				message={message}
				reduxEmployees={reduxEmployees}
				changeCheckbox={changeCheckbox}
				onChangeModal={onChangeModal}
				pictureChange={pictureChange}
				auth={auth}
				selectedDates={selectedDates}
				type={type}
				reason={reason}
			/>
			<Row>
				<Col>
					<Table>
						<tbody>
							<tr>
								{typeR.length
									? typeR.map(({ name, filterState, color, index }) => (
											<td
												key={name}
												style={{
													opacity: filterState ? '1' : '0.3',
													backgroundColor: color,
													textAlign: 'center',
													cursor: 'pointer',
												}}
												onClick={() => typeFilter(index)}>
												{name}
											</td>
									  ))
									: null}
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
			<Row>
				<Col xs='2'>
					<Input
						type='select'
						name='month'
						value={month}
						onChange={(e) => setMonth(Number(e.target.value))}>
						{monthsList.map((e, i) => (
							<option key={i} value={i}>
								{e}
							</option>
						))}
					</Input>
				</Col>
				<Col xs='2'>
					<Input
						type='select'
						name='year'
						value={year}
						onChange={(e) => setYear(Number(e.target.value))}>
						{yearsList.map((e, i) => (
							<option key={i}>{e}</option>
						))}
					</Input>
				</Col>
				<Col xs='1'>
					<p
						onClick={() =>
							changeToPrevMonth(month, months, year, setMonth, setYear, setDates, setWeeks)
						}
						className='change-prev-month-icon'>
						&#10094;
					</p>
				</Col>
				<Col xs='1'>
					<p
						onClick={() =>
							changeToNextMonth(month, months, year, setMonth, setYear, setDates, setWeeks)
						}
						className='change-next-month-icon'>
						&#10095;
					</p>
				</Col>

				<Col
					xs='1'
					className='current-month'
					onClick={() => changeToCurrentMonth(months, date, setDates, setWeeks, setMonth, setYear)}>
					<span className='today-btn'>Today</span>
				</Col>
				{executive ? (
					<Col xs='3'>
						<Input
							type='select'
							name='pic'
							value={pic}
							id='user_id'
							onChange={({ target }) => setPic(target.value)}>
							<option value=''>Select Employee</option>
							{reduxEmployees
								.filter((employee) => employee.permissionLevel !== 'Disabled')
								.map(({ _id, user_id, name }) => (
									<option key={_id} value={user_id}>
										{name}
									</option>
								))}
						</Input>
					</Col>
				) : null}
			</Row>
			<Table className='mt-3'>
				<thead>
					<tr>
						{daysOfWeek.map((day, i) => (
							<th className='daysOfWeek' key={i}>
								{day}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{weeks.length &&
						weeks.map((index) => {
							return (
								<tr
									key={index}
									style={{
										cursor: leaveRequestMode ? 'pointer' : 'default',
									}}
									className='weeks-pointer'>
									{dates.length &&
										dates.slice(index * 7, (index + 1) * 7).map((date, index) => {
											var dateLabel = (
												<div
													style={{
														cursor: leaveRequestMode ? 'pointer' : 'default',
														backgroundColor: date === currentDate ? 'green' : '#757575',
													}}
													className='dates-pointer'>
													{date}
												</div>
											);
											return (
												<td
													key={index}
													onClick={() => (leaveRequestMode ? selectDate(date) : null)}
													style={{
														border: '1px solid black',
														backgroundColor: chosenDates.includes(date) ? '#385231' : 'white',
													}}>
													<div>{dateLabel}</div>
													{calendarDisplay[date]
														? calendarDisplay[date].map(({ _id, user_id, type, status }, i) => {
																return (
																	<ListGroup key={i}>
																		<a key={i} onClick={() => dispatch(toggleLeaveModal(_id))}>
																			<ListGroupItem
																				key={i}
																				onClick={() => (!leaveRequestMode ? toggleModal() : null)}
																				className='leaveRequestMode'
																				style={{
																					cursor: !leaveRequestMode ? 'pointer' : 'default',
																					backgroundColor:
																						typeR.length &&
																						typeR
																							.filter((e) => e.name === type)
																							.map((e) => e.color),
																					opacity: status === 'Review' && '0.5',
																				}}>
																				{!pic && executive
																					? renderStatus(reduxLeaves, _id, status, reduxEmployees)
																					: pic && statusxAdmin(reduxLeaves, _id, status, user_id)}
																			</ListGroupItem>
																		</a>
																	</ListGroup>
																);
														  })
														: null}
												</td>
											);
										})}
								</tr>
							);
						})}
				</tbody>
			</Table>
			<CreateButtons
				chosenDates={chosenDates}
				setChosenDates={setChosenDates}
				leaveRequestMode={leaveRequestMode}
				toggle={toggle}
				setLeaveRequestMode={setLeaveRequestMode}
			/>
		</Container>
	);
};

export default LeaveCalendar;
