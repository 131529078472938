import axios from 'axios';
import {
	CREATE_NOTIFICATION,
	REMOVE_SEEN_NOTIFICATION,
	DELETE_NOTIFICATION,
	HIDE_NOTIFICATIONS,
	SHOW_NOTIFICATIONS,
	CHANGE_PASSWORD,
	GET_USERS,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

// Getting All The Users
export const getUsers = () => (dispatch) => {
	axios
		.get(`/api/users`)
		.then((res) =>
			dispatch({
				type: GET_USERS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

// Changing the user account password
export const changePassword = (id, body) => (dispatch, getState) => {
	axios.post(`/api/users/changePassword/${id}`, body, tokenConfig(getState)).then((res) =>
		dispatch({
			type: CHANGE_PASSWORD,
			payload: res.data,
		})
	);
};

// Creating Notification
export const createNotification = (newNotification, user_id) => (dispatch, getState) => {
	axios
		.post(`/api/users/notification/${user_id}`, newNotification, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: CREATE_NOTIFICATION,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'Create Notification Failed'));
		});
};
// Removing Those notification that are seen by changing the isSeen property to true.
export const removeSeenNotifications = (notifications, id) => (dispatch, getState) => {
	axios
		.post(`/api/users/seenNotification/${id}`, notifications, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: REMOVE_SEEN_NOTIFICATION,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(
				returnErrors(err.response.data, err.response.status, 'Remove Seen Notification Failed')
			);
		});
};
// Hiding notification when window is clicked.
export const hideNotifications = () => (dispatch) => {
	dispatch({
		type: HIDE_NOTIFICATIONS,
	});
};

export const showNotifications = () => (dispatch) => {
	dispatch({
		type: SHOW_NOTIFICATIONS,
	});
};
