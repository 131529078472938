import React, { Component } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	ModalFooter,
	Alert,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateEmployee } from '../../actions/employeeActions';
import { changePassword } from '../../actions/userActions';

class UserModal extends Component {
	state = {
		passwordModal: false,
		prevPassword: '',
		newPassword: '',
		confirmPassword: '',
		message: '',
		modal: false,
		phonenumber: '',
		department: [],
		departments: [],
		_id: '',
	};

	componentDidUpdate(prevProps) {
		if (prevProps.employee.employee !== this.props.employee.employee) {
			const { employee } = this.props.employee;
			this.setState({
				phonenumber: employee.map((employee) => employee.phonenumber)[0],
				department: employee.map((employee) => employee.department)[0],
				departments: this.props.company.company.departments,
				_id: employee.map((employee) => employee._id),
			});
		} else if (prevProps.auth.message !== this.props.auth.message) {
			this.setState({
				message: this.props.auth.message,
			});
		}
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};
	togglePasswordModal = () => {
		this.setState({
			passwordModal: !this.state.passwordModal,
			modal: false,
		});
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	changePassword = (e) => {
		e.preventDefault();
		if (this.state.newPassword === this.state.confirmPassword) {
			const body = {
				prevPassword: this.state.prevPassword,
				newPassword: this.state.newPassword,
			};
			this.props.changePassword(this.props.auth.user.id, body);
		} else {
			this.setState({ message: 'Password not matched!' });
		}
	};
	onSubmit = (e) => {
		e.preventDefault();
		const employeeDetails = {
			phonenumber: this.state.phonenumber,
			department: this.state.department,
		};
		this.props.updateEmployee(employeeDetails, this.state._id);
		this.toggle();
	};

	render() {
		return (
			<div>
				<Button color='dark' onClick={this.toggle} block>
					Update Details
				</Button>

				<Modal isOpen={this.state.modal} toggle={this.toggle}>
					<ModalHeader toggle={this.toggle}>Update Details</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label>Phone Number</Label>
								<Input
									type='text'
									name='phonenumber'
									defaultValue={this.state.phonenumber}
									onChange={this.onChange}
								/>
							</FormGroup>
							<FormGroup>
								<Label>Department</Label>
								<Input type='select' name='department' onChange={this.onChange}>
									<option>{this.state.department}</option>
									{this.state.departments
										? this.state.departments.map((department) => (
												<option key={department}>{department.name}</option>
										  ))
										: null}
								</Input>
								<p style={{ cursor: 'pointer' }} onClick={this.togglePasswordModal}>
									<u>Change Password</u>
								</p>
							</FormGroup>
							<Button color='dark' style={{ marginTop: '2rem' }} block>
								Update Project
							</Button>
						</Form>
					</ModalBody>
				</Modal>
				<Modal isOpen={this.state.passwordModal} toggle={this.togglePasswordModal}>
					<ModalHeader toggle={this.togglePasswordModal}>Changing Password</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.changePassword}>
							<FormGroup>
								<Label>Previous Password</Label>
								<Input
									type='password'
									name='prevPassword'
									value={this.state.prevPassword}
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label>New Password</Label>
								<Input
									type='password'
									name='newPassword'
									value={this.state.newPassword}
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							<FormGroup>
								<Label>Confirm Password</Label>
								<Input
									type='password'
									name='confirmPassword'
									value={this.state.confirmPassword}
									onChange={this.onChange}
									required
								/>
							</FormGroup>
							{this.state.message ? (
								<Alert
									color={
										this.state.message !== 'Password Changed Successfully!' ? 'danger' : 'success'
									}
									style={{ textAlign: 'center' }}>
									{this.state.message}
								</Alert>
							) : null}
							<Button color='dark' style={{ marginTop: '2rem' }} block>
								Submit
							</Button>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

UserModal.propTypes = {
	employee: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	updateEmployee: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	employee: state.employeeR,
	auth: state.authR,
	company: state.companyR,
});

export default connect(mapStateToProps, { updateEmployee, changePassword })(UserModal);
