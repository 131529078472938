import React from 'react';
import {
	Input,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Row,
	Col,
	Alert,
} from 'reactstrap';

const DateRangeModal = (props) => {
	const errors = props.errorMessages.map((errorMessage) => (
		<Alert key={errorMessage} color='danger'>
			{errorMessage}
		</Alert>
	));
	return (
		<Modal isOpen={props.dateRangeModal} toggle={() => props.toggleDateRange()}>
			<ModalHeader toggle={() => props.toggleDateRange()}>Select Date Range</ModalHeader>
			<ModalBody>
				{errors}
				<Label style={styles.label}>Project</Label>
				<Input
					type='select'
					name='project_ID'
					value={props.project_ID}
					onChange={(e) => props.onChange(e)}>
					<option>Select Here</option>
					{props.projects.map((e, i) => (
						<option key={i} value={e._id}>
							{e.name}
						</option>
					))}
				</Input>
				<br />
				<Row>
					<Col>
						<Label style={styles.label}>Start Date</Label>
						<Input
							type='date'
							name='startdate'
							defaultValue={props.startdate}
							onChange={(e) => props.onChange(e)}
						/>
					</Col>
					<Col>
						<Label style={styles.label}>End Date</Label>
						<Input
							type='date'
							name='enddate'
							defaultValue={props.enddate}
							onChange={(e) => props.onChange(e)}
						/>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button color='primary' onClick={() => props.setDateRange()}>
					Submit
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const styles = {
	label: {
		fontSize: '12px',
		color: 'grey',
	},
};

export default DateRangeModal;
