import {
	CREATE_FEEDBACK,
	GET_FEEDBACKS,
	CANCEL_FEEDBACK,
	UPDATE_FEEDBACK,
	TOGGLE_FEEDBACK_MODAL,
	GET_FEEDBACK,
	DELETE_FEEDBACK,
	FILTER_FEEDBACK,
	UPDATE_FEEDBACK_STATUS,
	UPDATE_FEEDBACK_FAIL,
	COMMENT_FEEDBACK,
	DELETE_FEEDBACK_COMMENT,
} from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import axios from 'axios';

export const getFeedbacks = () => (dispatch, getState) => {
	axios
		.get('/api/feedbacks', tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: GET_FEEDBACKS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const getFeedback = (id) => (dispatch, getState) => {
	axios
		.get(`/api/feedback/${id}`, tokenConfig(getState))
		.then((res) => {
			dispatch({
				type: GET_FEEDBACK,
				payload: res.data,
			});
		})
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status));
		});
};

export const createFeedback = (newFeedback) => (dispatch, getState) => {
	newFeedback.key = 'nnnlwe19219n1b2--u0qjasca';

	var image = JSON.stringify(newFeedback);
	// create an image to drive
	if (newFeedback.photo) {
		axios
			.post(
				'https://script.google.com/macros/s/AKfycbw2smMZXthCF_XAHvF2XzrKKwobh-kC0afqf3wbGYRhHvx8xWDL0j3Fyt5QNvtq2D0-HQ/exec',
				image
			)
			.then((res) => {
				newFeedback.photo = res.data;
				// create a feedback
				axios
					.post('/api/feedbacks', newFeedback, tokenConfig(getState))
					.then((res) =>
						dispatch({
							type: CREATE_FEEDBACK,
							payload: res.data,
						})
					)
					.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
			})
			.catch((err) => {
				dispatch(returnErrors(err.response.data, err.response.status));
			});
	} else {
		// create a feedback without photo
		axios
			.post('/api/feedbacks', newFeedback, tokenConfig(getState))
			.then((res) =>
				dispatch({
					type: CREATE_FEEDBACK,
					payload: res.data,
				})
			)
			.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
	}
};

export const toggleFeedbackModal = (input) => (dispatch) => {
	dispatch({
		type: TOGGLE_FEEDBACK_MODAL,
		payload: input,
	});
};

export const commentFeedback = (newComment, feedbackId) => (dispatch, getState) => {
	axios
		.post(`/api/feedbacks/comment/${feedbackId}`, newComment, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: COMMENT_FEEDBACK,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_FEEDBACK_FAIL'));
			dispatch({
				type: UPDATE_FEEDBACK_FAIL,
			});
		});
};

export const deleteFeedbackComment = (deletedComment, feedbackId) => (dispatch, getState) => {
	axios
		.post(`/api/feedbacks/deletingComment/${feedbackId}`, deletedComment, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: DELETE_FEEDBACK_COMMENT,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_FEEDBACK_FAIL'));
			dispatch({
				type: UPDATE_FEEDBACK_FAIL,
			});
		});
};

export const updateFeedback = (feedback) => (dispatch, getState) => {
	axios
		.post(`/api/feedbacks/update`, feedback, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_FEEDBACK,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_FEEDBACK_FAIL'));
			dispatch({
				type: UPDATE_FEEDBACK_FAIL,
			});
		});
};

export const updateFeedbackStatus = (id, feedback) => (dispatch, getState) => {
	axios
		.post(`/api/feedbacks/update/${id}`, feedback, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: UPDATE_FEEDBACK_STATUS,
				payload: res.data,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_FEEDBACK_FAIL'));
			dispatch({
				type: UPDATE_FEEDBACK_FAIL,
			});
		});
};

export const cancelFeedback = (feedback) => (dispatch, getState) => {
	axios
		.post('/api/feedbacks/cancelFeedback', feedback, tokenConfig(getState))
		.then(() =>
			dispatch({
				type: CANCEL_FEEDBACK,
				payload: feedback,
			})
		)
		.catch((err) => {
			dispatch(returnErrors(err.response.data, err.response.status, 'UPDATE_FEEDBACK_FAIL'));
			dispatch({
				type: UPDATE_FEEDBACK_FAIL,
			});
		});
};

export const deleteFeedback = (id) => (dispatch, getState) => {
	axios
		.delete(`/api/feedbacks/${id}`, tokenConfig(getState))
		.then((res) =>
			dispatch({
				type: DELETE_FEEDBACK,
				payload: id,
			})
		)
		.catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const filterFeedbacks = (filters) => (dispatch) => {
	dispatch({
		type: FILTER_FEEDBACK,
		payload: {
			filters: filters,
		},
	});
};
