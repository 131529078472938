import {
	GET_COMPANY,
	COMPANY_LOADING,
	UPDATE_COMPANY,
	UPDATE_COMPANY_FAIL,
	GET_COMPANIES,
	GET_USERS,
	ADD_COMPANY,
	TOGGLE_DEPARTMENT_MODAL,
	TOGGLE_USER_MODAL,
	FILTER_COMPANY,
	DELETE_COMPANY,
	TOGGLE_PERMISSION_MODAL,
	OPEN_DEPARTMENT_MODAL,
	TOGGLE_MODULE_MODAL,
	GET_COMPANY_TIME_INPUTS,
	UPDATE_COMPANY_TIME_INPUTS,
	REGISTER_FAIL,
	REGISTER_SUCCESS,
	ADD_DEPARTMENT,
	UPDATE_DEPARTMENT,
	ADD_PROPOSAL_TASK,
	UPDATE_PROPOSAL_TASK,
	BILLING_STARTED,
} from '../actions/types';

const initialState = {
	companies: [],
	company: [],
	shortname: '',
	users: [],
	user: null,
	intTimeInputs: [], // [ Array of objects ]
	extTimeInputs: [], // [ Array of objects ]
	filteredUsers: [],
	filteredCompanies: [],
	loading: false,
	modal: false,
	userModal: false,
	moduleModal: false,
	permissionModal: false,
	errors: [],
	registerSuccess: false,
};

const companyReducer = function (state = initialState, action) {
	switch (action.type) {
		case ADD_PROPOSAL_TASK:
		case ADD_DEPARTMENT:
			return {
				...state,
				company: action.payload,
			};
		case UPDATE_PROPOSAL_TASK:
		case UPDATE_DEPARTMENT:
			return {
				...state,
				company: action.payload,
			};
		case GET_COMPANY:
			return {
				...state,
				company: action.payload,
				loading: false,
			};
		case GET_COMPANIES:
			return {
				...state,
				companies: action.payload,
				loading: false,
			};
		case REGISTER_FAIL:
			return {
				...state,
				errors: action.payload,
			};
		case REGISTER_SUCCESS:
			return {
				...state,
				registerSuccess: true,
			};
		case GET_COMPANY_TIME_INPUTS:
		case UPDATE_COMPANY_TIME_INPUTS:
			return {
				intTimeInputs: action.payload.intTimeInputs,
				extTimeInputs: action.payload.extTimeInputs,
				company: action.payload,
			};
		case FILTER_COMPANY:
			// Filter companies based on criteria provieded
			var arr = state.companies.filter((company) =>
				Object.keys(action.payload).every((key) => {
					//check if the filter is an array of elements
					if (Array.isArray(action.payload[key])) {
						if (key === 'dateRange') {
							var filter1 = true;
							var filter2 = true;
							if (action.payload.dateRange[0] !== '') {
								filter1 = company.createdAt > action.payload.dateRange[0] ? true : false;
							}
							if (action.payload.dateRange[1] !== '') {
								filter2 = company.createdAt < action.payload.dateRange[1] ? true : false;
							}
							var filter = filter1 && filter2 ? true : false;
							return filter;
						}
						return action.payload[key].some((keyEle) => company[key].includes(keyEle));
					}
					if (key === 'disabled') {
						return action.payload[key] ? company[key] : !company[key];
					}
					return company[key] ? company[key].includes(action.payload[key]) : null;
				})
			);
			return {
				...state,
				filteredCompanies: arr,
			};
		case ADD_COMPANY:
			return {
				...state,
				companies: [...state.companies, action.payload],
			};
		case DELETE_COMPANY:
			return {
				...state,
				companies: state.companies.filter(({ _id }) => _id !== action.payload),
			};
		case TOGGLE_DEPARTMENT_MODAL:
			return {
				...state,
				company: action.payload
					? state.companies.filter(({ _id }) => _id === action.payload)[0]
					: state.company,
				modal: !state.modal,
			};
		case OPEN_DEPARTMENT_MODAL:
			return {
				...state,
				modal: !state.modal,
			};
		case TOGGLE_USER_MODAL:
			return {
				...state,
				filteredUsers: state.users.filter(({ company_id }) => company_id === action.payload._id),
				shortname: action.payload.shortname,
				userModal: !state.userModal,
			};
		case TOGGLE_PERMISSION_MODAL:
			return {
				...state,
				user: state.users.filter(({ _id }) => _id === action.payload)[0],
				permissionModal: !state.permissionModal,
			};
		case TOGGLE_MODULE_MODAL:
			return {
				...state,
				company: action.payload
					? state.companies.filter(({ _id }) => _id === action.payload)[0]
					: [],
				moduleModal: !state.moduleModal,
			};
		case GET_USERS:
			return {
				...state,
				users: action.payload,
			};
		case COMPANY_LOADING:
			return {
				...state,
				loading: true,
			};
		case UPDATE_COMPANY:
			return {
				...state,
				company: action.payload,
				companies: state.companies.map((e) => {
					if (e._id === action.payload._id) {
						return action.payload;
					} else {
						return e;
					}
				}),
			};
		case BILLING_STARTED:
			return {
				...state,
				message: 'Billing Started Successfully',
			};
		default:
			return state;
	}
};

export default companyReducer;
