import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container,Card, CardHeader, CardBody, CardFooter, FormGroup, Label, Input, Row,Form,Col, Button, Alert } from 'reactstrap';
import { getVendor,updateVendor } from '../../actions/vendorActions';

export class UpdateVendor extends Component {
    state = {
        name:'',
        email:'',
        contactNo:'',
        PIC:'',
        rate:'',
        message: null
    }
    componentDidMount(){
       this.props.getVendor(this.props.match.params.id) 
    }
    onChangeInput = ({ target }) => {
        this.setState({ [target.name]: target.value })
    }
    componentDidUpdate(prevProps,prevState) {
        if(prevProps.vendor.message !== this.props.vendor.message){
            this.setState({ message:this.props.vendor.message })
        }else if(prevProps.error !== this.props.error){
            this.setState({ message: 'Something Went Wrong'})
        }
    }
    onSubmit = (e) => {
        e.preventDefault()
        const { vendor } = this.props.vendor
        const updatedVendor = {
            name: this.state.name?this.state.name:vendor.name, 
            email: this.state.email?this.state.email:vendor.email, 
            contactNo: this.state.contactNo?this.state.contactNo:vendor.contactNo, 
            PIC_name: this.state.PIC?this.state.PIC:vendor.PIC_name, 
            rate: this.state.rate?this.state.rate:vendor.rate 
        }
        this.props.updateVendor(updatedVendor,vendor._id)
    }
    render() {
        const { vendor } = this.props.vendor
        return (
            <Container>
            <Card>
            {
                this.state.message?
                <Alert color={this.state.message.substring(0,9) === "Something"?'danger':"success"} 
                    style={{textAlign:"center"}}>
                    {this.state.message}
                </Alert>
                :null
            }
            <Form onSubmit={this.onSubmit}>
                <CardHeader>Updating Vendor Details</CardHeader>
                <CardBody>
                  
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input defaultValue={vendor.name} name="name" type="text" onChange={this.onChangeInput} required />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Contact Person</Label>
                                    <Input defaultValue={vendor.PIC_name} name="PIC" type="text" onChange={this.onChangeInput} required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Contact Number</Label>
                                    <Input defaultValue={vendor.contactNo} type="text" name="contactNo" onChange={this.onChangeInput} required />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Hourly Rate</Label>
                                    <Input defaultValue={vendor.rate} type="number" name="rate" onChange={this.onChangeInput} required />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input defaultValue={vendor.email} type="email" name="email" onChange={this.onChangeInput} required />
                        </FormGroup>
                 
                </CardBody>
                <CardFooter>
                    <Button type="submit" block>Submit Changes</Button>
                   
                </CardFooter>
                </Form>
            </Card>
        </Container>
        )
    }
}

const mapStateToProps = state => ({
    vendor: state.vendorR,
    error: state.errorR.message
})

export default connect(mapStateToProps,{
getVendor,updateVendor
})(UpdateVendor)
