import React, { Component } from 'react';
import {
	Container,
	Table,
	Button,
	Input,
	Row,
	Col,
	Label,
	Alert,
	ListGroup,
	ListGroupItem,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	getCompanies,
	getUsers,
	toggleDepartmentIndex,
	activeState,
	toggleUserIndex,
	filterCompanies,
	toggleModuleIndex,
} from '../../actions/companyActions';
import { getInvoices } from '../../actions/paymentActions';
import CompanyModal from './companyModal';
import DepartmentModal from './departmentModal';
import UserModal from './userModal';
import ModuleModal from './moduleModal';
import UserFeeModal from './UserFeeModal';
import CreateInvoices from './CreateInvoices';

class CompanyList extends Component {
	state = {
		RangeEnd: '',
		RangeStart: '',
		disabled: false,
		userFeeModal: false,
		company_id: '',
		userFee: '',
	};

	componentDidMount() {
		this.props.getCompanies();
		this.props.getUsers();
		this.props.getInvoices();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.company.companies !== this.props.company.companies ||
			prevProps.company.users !== this.props.company.users ||
			prevState.RangeEnd !== this.state.RangeEnd ||
			prevState.RangeStart !== this.state.RangeStart ||
			prevState.disabled !== this.state.disabled
		) {
			var filters = {};
			var d = '';
			if (this.state.RangeEnd) {
				d = new Date(this.state.RangeEnd);
				d = new Date(d.setDate(d.getDate() + 1)).toISOString().substr(0, 10);
			}
			filters.dateRange = [this.state.RangeStart, d];
			filters.disabled = this.state.disabled;
			this.props.filterCompanies(filters);
		}
	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	changeStatus = (_id) => {
		const change = window.confirm('Update Status?');
		if (change) {
			this.props.activeState(_id);
		}
	};

	filterStatus = () => {
		this.setState({
			disabled: !this.state.disabled,
		});
	};

	toggleUserFeeModal = (company_id, userFee) => {
		this.setState({
			userFeeModal: !this.state.userFeeModal,
			company_id,
			userFee,
		});
	};

	updateUserStatus = (_id) => {
		this.props.activeState(_id);
	};

	render() {
		const { filteredCompanies, users } = this.props.company;
		const { user } = this.props.auth;
		const { message, invoices } = this.props.payment;

		return user.id === '5ff1f1e091297a8bb46b2bb6' ? (
			<Container>
				{message && <Alert color='success'>{message}</Alert>}
				<DepartmentModal />
				<UserModal />
				<ModuleModal />
				<UserFeeModal
					userFeeModal={this.state.userFeeModal}
					toggleUserFeeModal={this.toggleUserFeeModal}
					company_id={this.state.company_id}
					userFee={this.state.userFee}
				/>
				<CompanyModal /> <CreateInvoices updateUserStatus={this.updateUserStatus} />
				<Row style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline After</Label>
						<Input type='date' name='RangeStart' onChange={this.onChange} />
					</Col>
					<Col>
						<Label style={{ fontSize: '12px', color: 'grey' }}>Deadline Before</Label>
						<Input type='date' name='RangeEnd' onChange={this.onChange} />
					</Col>
					<Button
						className='remove-btn'
						color={this.state.disabled ? 'danger' : 'success'}
						style={{
							color: 'black',
							marginTop: '2rem',
						}}
						size='sm'
						onClick={this.filterStatus}>
						{this.state.disabled ? 'Inactive' : 'Active'}
					</Button>
				</Row>
				<Table hover>
					<thead style={{ fontSize: '14px' }}>
						<tr>
							<th className='rowAlign'>ID</th>
							<th className='rowAlign'>Name</th>
							<th className='rowAlign'>Start Date</th>
							<th className='rowAlign'>Departments</th>
							<th className='rowAlign'>Employees</th>
							<th className='rowAlign'>Modules</th>
							<th className='rowAlign'>User Fee</th>
							<th className='rowAlign'>Invoices</th>
							<th className='rowAlign'>Status</th>
						</tr>
					</thead>
					<tbody style={{ fontSize: '14px' }}>
						{filteredCompanies.map(
							({
								_id,
								departments,
								companyname,
								createdAt,
								userFee,
								disabled,
								shortname,
								proposal,
								task,
								billingHistory,
							}) => {
								const status1 =
									billingHistory[0] &&
									invoices.items &&
									invoices.items.find((e) => e.id === billingHistory[0].invoiceNo).status;
								const status2 =
									billingHistory[1] &&
									invoices.items &&
									invoices.items.find((e) => e.id === billingHistory[1].invoiceNo).status;
								// Logic to update status based on payment
								if (status1 && status2) {
									// if(status1!== 'PAID' && status2 !== 'PAID' && !disabled){
									// 	this.updateUserStatus(_id)
									// }
									// if (status1 === 'PAID' && status2 === 'PAID' && disabled) {
									// 	this.updateUserStatus(_id);
									// }
								}
								return (
									<tr key={_id}>
										<td className='rowAlign'>{_id}</td>
										<td className='rowAlign'>{companyname}</td>
										<td className='rowAlign'>{createdAt ? createdAt.substring(0, 10) : ''}</td>
										<td className='rowAlign'>
											<a href='#' onClick={this.props.toggleDepartmentIndex.bind(this, _id)}>
												{departments.length}
											</a>
										</td>
										<td className='rowAlign'>
											<a href='#' onClick={this.props.toggleUserIndex.bind(this, _id, shortname)}>
												{users.filter(({ company_id }) => company_id === _id).length}
											</a>
										</td>
										<td className='rowAlign'>
											<a href='#' onClick={this.props.toggleModuleIndex.bind(this, _id)}>
												{proposal & task ? 2 : proposal || task ? 1 : 0}
											</a>
										</td>
										<td className='rowAlign'>
											<a href='#' onClick={() => this.toggleUserFeeModal(_id, userFee)}>
												{userFee ? userFee : 0}
											</a>
										</td>
										<td className='rowAlign'>
											{billingHistory && (
												<ListGroup>
													{status1 && (
														<ListGroupItem
															style={{ padding: '2px' }}
															color={status1 === 'PAID' ? 'success' : 'danger'}>
															{status1}
														</ListGroupItem>
													)}
													{status2 && (
														<ListGroupItem
															style={{ padding: '2px' }}
															color={status2 === 'PAID' ? 'success' : 'danger'}>
															{status2}
														</ListGroupItem>
													)}
												</ListGroup>
											)}
										</td>
										<td className='rowAlign'>
											<Button
												className='remove-btn'
												color={disabled ? 'danger' : 'success'}
												style={{
													color: 'black',
												}}
												size='sm'
												onClick={this.changeStatus.bind(this, _id)}>
												{disabled ? 'Inactive' : 'Active'}
											</Button>
										</td>
									</tr>
								);
							}
						)}
					</tbody>
				</Table>
			</Container>
		) : null;
	}
}
// : null
CompanyList.propTypes = {
	getCompanies: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired,
	toggleDepartmentIndex: PropTypes.func.isRequired,
	toggleModuleIndex: PropTypes.func.isRequired,
	activeState: PropTypes.func.isRequired,
	toggleUserIndex: PropTypes.func.isRequired,
	filterCompanies: PropTypes.func.isRequired,
	getInvoices: PropTypes.func.isRequired,
	company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	company: state.companyR, // getting reducer from /reducers/index.js
	auth: state.authR,
	payment: state.paymentR,
});

export default connect(mapStateToProps, {
	getCompanies,
	getUsers,
	toggleDepartmentIndex,
	activeState,
	toggleUserIndex,
	filterCompanies,
	toggleModuleIndex,
	getInvoices,
})(CompanyList);
