import React, { Fragment, useEffect, useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Input,
	Alert,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { updateBillingUser } from '../../actions/companyActions';

const BillingModal = (props) => {
	const [billingUser, setBillingUser] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		setBillingUser(props.billingUser._id);
	}, [props.billingUser]);

	const UpdateBillingUser = (e) => {
		e.preventDefault();
		setErrorMessage('');
		if (billingUser === props.billingUser._id) {
			return setErrorMessage('Current Billing User');
		}
		const requestBody = {
			newBillingId: billingUser,
			oldBillingId: props.billingUser._id,
		};
		dispatch(updateBillingUser(requestBody));
		props.toggleBillingModal();
	};

	return (
		<Modal isOpen={props.billingModal} toggle={props.toggleBillingModal}>
			<ModalHeader toggle={props.toggleBillingModal}>Modal title</ModalHeader>
			<Form onSubmit={(e) => UpdateBillingUser(e)}>
				<ModalBody>
					{errorMessage && <Alert color='danger'>{errorMessage}</Alert>}
					<FormGroup>
						<Input
							type='select'
							value={billingUser}
							onChange={(e) => setBillingUser(e.target.value)}
							required>
							{props.users
								.filter((e) => e.permissionLevel !== 'Disabled')
								.map((e) => {
									return (
										<option key={e} value={e._id}>
											{e.email}
										</option>
									);
								})}
						</Input>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' type='submit'>
						Update Billing User
					</Button>{' '}
					<Button color='secondary' onClick={props.toggleBillingModal}>
						Cancel
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default BillingModal;
